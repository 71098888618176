<template>
  <div>
    <div @click="doPredefined">
      <v-autocomplete
          ref="field"
          v-model="internalValue.value"
          clearable
          item-text="text"
          item-value="value"
          persistent-hint
          :disabled="!itemList || !itemList.length|| internalValue.type === 'skipped'"
          :error-messages="(!itemList || !itemList.length)? 'empty hint' : null"
          :hint="$t('public.registration.profile.fields.birthYear.hint')"
          :items="itemList"
          :label="$t('public.registration.profile.fields.birthYear.label')"
          :no-data-text="$t('public.registration.profile.fields.birthYear.errors.search')"
          :placeholder="$t('public.registration.profile.fields.birthYear.placeholder')"
          :rules="internalValue.type === 'skipped' ? []:rules"></v-autocomplete>
    </div>
    <a @click="doSkip" style="display:block;" class="starling-text" :class="{'text--disabled': internalValue.type !== 'skipped','font-weight-bold': internalValue.type === 'skipped'}">{{ $t('public.registration.profile.fields.birthYear.skip.label') }}</a>

  </div>
</template>

<script>

const PREDEFINED = 'predefined';
const SKIPPED = 'skipped';

export default {
  name: 'registration-field-birth-year',
  props: {
    value: Object,
    defaultValue: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      internalValue: this.value || { value: null, type: PREDEFINED },
      loading: false,
      itemList: null,
      timeout: null,
      rules: [
        v => !!v || this.$t('common.errors.required'),
      ],
    };
  },
  watch: {
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
      }
    },
    internalValue: {
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  mounted() {
    if (this.defaultValue) {
      this.doSkip();
    }
    this.itemList = [ ...Array(65).keys() ].map(e => {
      const val = e + new Date().getFullYear() - 80;
      return {
        text: val + '',
        value: val,
      };
    }).reverse();
  },
  methods: {
    doSkip() {
      this.internalValue = { value: null, type: SKIPPED };
    },
    doPredefined() {
      if (this.internalValue.type === SKIPPED) {
        this.internalValue = { value: null, type: PREDEFINED };
      }
      this.$nextTick(() => {
        this.$refs.field.onClick();
      });
    },
  },
};
</script>

<style scoped>

</style>
