export default {
  title: 'Verificación del correo electrónico',
  subtitle: 'Introduce una nueva contraseña para tu cuenta.',
  content: [
    'Te hemos enviado un código de confirmación por correo electrónico.',
    'Introduce el código en los próximos 10 minutos, antes de que caduque.',
    'Si no has recibido un código, sigue los pasos a continuación para solucionar el problema:',
    '1. Revisa tu carpeta de correo no deseado.',
    '2. Si no encuentras el correo electrónico que contiene el código de confirmación en esta carpeta, envía un correo electrónico a <a target="_blank" href="mailto:members@starlingminds.com">members@starlingminds.com</a> para solicitar asistencia técnica.',
  ],
  buttons: {
    verify: 'Verificar mi código',
    generate_token: 'Reenviar código',
    log_in_again: 'Iniciar sesión de nuevo',
  },
  success: {
    generate_token: 'Hemos enviado un nuevo código a tu dirección de correo electrónico.',
  },
  error: {
    EXPIRED: 'Este código ha caducado.',
    WRONG_CODE: 'El código no es válido.',
    MISMATCH: 'El código no es válido.',
    TOO_MANY_TRIES: 'Has utilizado códigos no válidos demasiadas veces. Inicia sesión de nuevo.',
    LIMIT_EXCEEDED: 'Has utilizado códigos no válidos demasiadas veces. Por favor inténtalo de nuevo después de un tiempo.',
    CANNOT_GENERATE: 'Un nouveau code ne peut pas être généré. Por favor inténtalo de nuevo después de un tiempo.',
    default: 'Se ha producido un error.',
  },
};
