


































import Vue, { PropType } from 'vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import { mapState } from 'vuex';
import { RootState } from '@/scripts/store/types';

export default Vue.extend({
  name: 'account-login',
  components: {
    PrimaryButton,
  },
  props: {
    referralKey: {
      type: String,
      required: true,
    },
    referral: {
      type: Object as PropType<any>,
      required: true,
    },
    userIdentity: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState({
      partner: state => {
        return (state as RootState).identity;
      },
      locale(): string {
        return localStorage.getItem('currLang') as string;
      },
    }),
    partnerName(): string {
      return this.partner && this.partner.content && this.partner.content['NAME'] && this.partner.content['NAME'][this.locale] ? this.partner.content['NAME'][this.locale] : 'Starling';
    },
  },
  methods: {
    login() {
      this.$router.push({
        name: 'sign_in',
        params: {
          email: this.referral.email,
        },
      });
    },
  },
});

