import { BoilerplateView, TopNavDesktopView, TopNavMobileView } from '../common';
import store from '../../store';

const ProductPlayerView = resolve => {
  require.ensure([ '@/views/products/ProductPlayer.vue' ], () => {
    resolve(require('@/views/products/ProductPlayer.vue'));
  }, 'product');
};

export default {
  path: 'webinars',
  components: {
    topNavDesktop: TopNavDesktopView,
    topNavMobile: TopNavMobileView,
    default: BoilerplateView,
  },
  children: [
    {
      name: 'webinars',
      path: '',
      redirect: { name: 'webinars-menu' },
    },
    {
      name: 'webinars-menu',
      path: process.env.VUE_APP_WEBINARS_UEN,
      component: ProductPlayerView,
      props: { uen: process.env.VUE_APP_WEBINARS_UEN },
      beforeEnter(to, from, next) {
        store.commit('navigation/topDisableBackButton');
        next();
      },
    },
    {
      name: 'webinars-run',
      path: ':uen',
      component: ProductPlayerView,
      props: true,
      beforeEnter(to, from, next) {
        store.commit('navigation/topEnableBackButton');
        next();
      },
    },
  ],
};
