import { BoilerplateView, TopNavDesktopView, TopNavMobileView } from '../common';
import store from '../../store';

const ProfileView = resolve => {
  require.ensure([ '@/views/profile/Profile.vue' ], () => {
    resolve(require('@/views/profile/Profile.vue'));
  }, 'user');
};
const ProfileAccountView = resolve => {
  require.ensure([ '@/views/profile/ProfileAccount.vue' ], () => {
    resolve(require('@/views/profile/ProfileAccount.vue'));
  }, 'user');
};
const ProfileMyStarlingView = resolve => {
  require.ensure([ '@/views/profile/ProfileMyStarling.vue' ], () => {
    resolve(require('@/views/profile/ProfileMyStarling.vue'));
  }, 'my_starling');
};
const ProfileSettingsView = resolve => {
  require.ensure([ '@/views/profile/ProfileSettings.vue' ], () => {
    resolve(require('@/views/profile/ProfileSettings.vue'));
  }, 'user');
};

const MilestonesView = resolve => {
  require.ensure([ '@/views/profile/Milestones.vue' ], () => {
    resolve(require('@/views/profile/Milestones.vue'));
  }, 'milestones');
};
const CertificatesView = resolve => {
  require.ensure([ '@/views/profile/Certificates.vue' ], () => {
    resolve(require('@/views/profile/Certificates.vue'));
  }, 'certificates');
};

export default {
  path: 'profile',
  components: {
    topNavDesktop: TopNavDesktopView,
    topNavMobile: TopNavMobileView,
    default: BoilerplateView,
  },
  beforeEnter(to, from, next) {
    next();
  },
  children: [
    {
      name: 'profile',
      path: '',
      redirect: { name: 'my-starling' },
      component: ProfileView,
      beforeEnter(to, from, next) {
        store.commit('navigation/topEnableBackButton');
        store.commit('navigation/topHeader', 'app.profile.header_title');
        next();
      },
      children: [
        {
          name: 'profile-comments',
          path: 'comments',
          redirect: { name: 'community-comments' },
        },
        {
          name: 'my-starling',
          path: 'my-starling',
          component: ProfileMyStarlingView,
        },
        {
          name: 'profile-account',
          path: 'profile-account',
          component: ProfileAccountView,
        },
        {
          name: 'profile-settings',
          path: 'profile-settings',
          component: ProfileSettingsView,
        },
      ],
    },
    {
      name: 'milestones',
      path: 'milestones',
      component: MilestonesView,
      beforeEnter(to, from, next) {
        store.commit('navigation/topEnableBackButton');
        store.commit('navigation/topHeader', 'app.milestones.header_title');
        next();
      },
    },
    {
      name: 'certificates',
      path: 'certificates',
      component: CertificatesView,
      beforeEnter(to, from, next) {
        store.commit('navigation/topEnableBackButton');
        store.commit('navigation/topHeader', 'app.certificates.header_title');
        next();
      },
    },
  ],
};
