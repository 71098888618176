






import Vue from 'vue';

export default Vue.extend({
  name: 'custom-icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
    stroke: {
      type: String,
      default: 'currentColor',
    },
  },
  computed: {
    href() {
      return `#${this.name}`;
    },
    iconSize() {
      switch (this.size) {
        case 'xs': return '0.5em';
        case 'sm': return '0.75em';
        case 'md': return '1.25em';
        case 'lg': return '1.5em';
        case 'xl': return '1.75em';
        default: return '1em';
      }
    },
  },
});
