<template>
<svg style="display:inline; margin: 0; padding: 0; width: auto;"
     class="videos-icon"
     viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M26.7042 26H25.1423V24.692H26.7042C27.7991 24.692 28.6922 23.8025 28.6922 22.7038V3.29625C28.6922 2.20124 27.8028 1.30804 26.7042 1.30804H7.29577C6.20092 1.30804 5.30785 2.1975 5.30785 3.29625V4.30157H4V3.29625C4.00374 1.47995 5.47974 0 7.29577 0H26.7042C28.5203 0 30 1.47995 30 3.29625V22.7075C30 24.5238 28.524 26 26.7042 26Z"/>
  <path
          class="icon-content"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.40008 29.0322H23.4421C24.7679 29.0322 25.8421 27.958 25.8384 26.6322V6.59393C25.8384 5.26806 24.7642 4.19385 23.4383 4.19385H3.40008C2.07421 4.19385 1 5.26806 1 6.59393V26.6322C1 27.958 2.07421 29.0322 3.40008 29.0322ZM9.02356 24.5364V8.78371L20.0962 16.4121L9.02356 24.5364Z"/>
  <path d="M22.5503 29.9954H3.27343C1.4697 29.9954 0 28.4981 0 26.6604V7.0215C0 5.18389 1.4697 3.68658 3.27343 3.68658H22.5503C24.354 3.68658 25.8238 5.18389 25.8238 7.0215V26.6604C25.82 28.4981 24.354 29.9954 22.5503 29.9954ZM3.27343 5.00996C2.186 5.00996 1.29898 5.90986 1.29898 7.0215V26.6604C1.29898 27.7683 2.18229 28.672 3.27343 28.672H22.5503C23.6378 28.672 24.5248 27.7721 24.5248 26.6604V7.0215C24.5248 5.91364 23.6415 5.00996 22.5503 5.00996H3.27343Z"/>
  <path d="M26.7261 26.305H25.1747V24.9816H26.7261C27.8135 24.9816 28.7006 24.0817 28.7006 22.9701V3.33492C28.7006 2.22706 27.8172 1.32338 26.7261 1.32338H7.44921C6.36178 1.32338 5.47476 2.22328 5.47476 3.33492V4.35203H4.17578V3.33492C4.17578 1.49731 5.64549 0 7.44921 0H26.7261C28.5298 0 29.9995 1.49731 29.9995 3.33492V22.9739C29.9958 24.8115 28.5298 26.305 26.7261 26.305Z"/>
  <path d="M8.27539 26.0668V7.41095L21.1427 16.4477L8.27539 26.0668ZM9.57437 9.92915V23.4541L18.9048 16.478L9.57437 9.92915Z"/>
</svg>
</template>

<script>
export default {
  name: 'videos-icon',
};
</script>

<style scoped>
.videos-icon {
  fill: currentColor;
  stroke: transparent;
}

.videos-icon .icon-content {
  fill: transparent;
  stroke: currentColor;
}

.videos-icon.activated .icon-content,
.router-link-active .v-icon.videos-icon .icon-content {
  fill: currentColor;
  stroke: currentColor;
}
</style>
