export default {
  1005: 'We\'re unable to connect right now.',
  1205: 'No account was found for this email',
  1215: 'The password you entered is incorrect',
  1301: 'Your password reset key has been sent',
  1305: 'No account was found for this email',
  1311: 'Password has been successfully reset',
  1315: 'Password reset key is invalid',
  1401: 'Your account has been created',
  1405: 'This email is already in use',
  1415: 'Your access code is not valid',
  2102: 'Your profile has been updated',
  2112: 'Your language has been set',
  2103: 'Your account has been deleted',
  3101: 'Your goal was added',
  3102: 'Your goal was updated',
  3104: 'Goal completed',
  3112: 'Your goal was restored',
  3122: 'Goal pinned',
  3103: 'Your goal was deleted',
  3201: 'Your thought was added',
  3202: 'Your thought was updated',
  3212: 'Your thought was restored',
  3222: 'Thought pinned',
  3203: 'Your thought was deleted',
  3301: 'Your mood was added',
  3302: 'Your mood was updated',
  3312: 'Your mood was restored',
  3322: 'Mood pinned',
  3303: 'Your mood was deleted',
  3401: 'Assessment completed',
  4201: 'Your response was added',
  4202: 'Your response was updated',
  4203: 'Your response was deleted',
  4301: 'Your Bookmark was added',
  4302: 'Your Bookmark was removed',
  5101: 'Your comment was added',
  5102: 'Your comment was updated',
  5112: 'Comment liked',
  5103: 'Your comment was deleted',
  6102: 'Notification preferences updated',
};
