export default {
  widget: {
    background: {
      name: 'Background',
    },
    body: {
      name: 'Body',
    },
    eyes: {
      name: 'Eyes',
      icon: 'mdi-eye',
    },
    beak: {
      name: 'Beak',
      icon: 'mdi-eye',
    },
  },
  body: {
    crow: {
      name: 'Crow',
    },
    duck: {
      name: 'Duck',
    },
    eagle: {
      name: 'Eagle',
    },
    heron: {
      name: 'Heron',
    },
    owl: {
      name: 'Owl',
    },
    plover: {
      name: 'Plover',
    },
    sparrow: {
      name: 'Sparrow',
    },
    toucan: {
      name: 'Toucan',
    },
  },
  eyes: {
    standard: {
      name: 'Front',
    },
    left: {
      name: 'Left',
    },
    right: {
      name: 'Right',
    },
    closed: {
      name: 'Closed',
    },
  },
  customize: {
    title: 'Customize your avatar',
    tagline: 'This will represent you in the community.',
  },
  unsaved: {
    title: 'You have unsaved changes',
    content: 'If you continue you will lose any unsaved changes to your avatar.',
  },
};
