import Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    addIssueInfo(data: Object): void;
  }
}

declare global {
  interface Window {
    ATL_JQ_PAGE_PROPS: Object;
    JIRA_ADDITIONAL_INFO: Object | null;
  }
}
export default Vue.extend({
  data() {
    return {
    };
  },
  computed: {
    descriptionText(): string {
      return `\n\n\n\n-----\n*Logged user name:* ${this.firstName}\n*Logged user e-mail:* ${this.email}`;
    },
    firstName(): string {
      return this.$store.getters.firstName ? this.$store.getters.firstName : '';
    },
    email(): string {
      return this.$store.getters.email ? this.$store.getters.email : '';
    },
    fieldValues(): Object {
      return {
        description: this.descriptionText,
        recordWebInfo: '1',
        recordWebInfoConsent: [ '1' ],
        customfield_10842: this.email, // User Account
      };
    },
  },
  methods: {
    issueCollector(): void {
      if (!process.env.VUE_APP_JIRA_PATH || !document.body) return;

      if (this.$store.state.jiraScript) {
        document.body.removeChild(this.$store.state.jiraScript);
      }

      if (!this.$store.getters.email || !this.$store.getters.email.includes('@starlingminds.com')) {
        return;
      }

      window.ATL_JQ_PAGE_PROPS = {
        fieldValues: this.fieldValues,
        environment: window.JIRA_ADDITIONAL_INFO,
      };

      window.JIRA_ADDITIONAL_INFO = null;

      this.$store.state.jiraScript = document.createElement('script');
      this.$store.state.jiraScript.setAttribute('src', process.env.VUE_APP_JIRA_PATH);
      document.body.appendChild(this.$store.state.jiraScript);
    },
    addIssueInfo(data: Object): void {
      window.JIRA_ADDITIONAL_INFO = data;
      this.issueCollector();
    },
  },
});
