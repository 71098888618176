export default {
  title: 'Reset my password',
  subtitle: {
    provider: 'If you registered using one of our sign-in partners, please use the corresponding link below to gain access to your account.',
    email: 'If you registered using your email address, please enter it below and we will send you details on how to reset your password.',
  },
  success: {
    redirect: 'Please check your email for more details to reset your password.',
    action: 'Sign in',
  },
  fields: {
    email: 'Email',
  },
  buttons: {
    reset_password: 'Reset password',
  },
};
