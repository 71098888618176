export default {
  notify: {
    title: 'Starling se met à jour !',
    message: 'Nous serons indisponibles pour une courte période de maintenance dans <strong>{countdown}</strong> pendant environ <strong>{duration}</strong>.<br/> Nous vous prions de nous excuser pour l\'inconvenience et apprécions votre patience pendant que nous travaillons à améliorer Starling !',
  },
  in_progress: {
    title: 'Starling se met à jour!',
    message: [
      'Nous sommes temporairement indisponible pour procéder à une mise à jour.',
      'Nous vous prions de nous excuser pour l\'inconvenience et apprécions votre patience pendant que nous travaillons à améliorer Starling!',
    ],
    estimation: 'Estimation du temps restant : {remaining}',
    unknown_estimation: 'Veuillez réessayer plus tard!',
    over_estimated: 'Oups, nous sommes en retard... encore!',
  },
  done: {
    title: 'Starling a été mis à jour!',
    message: [ 'Nous sommes de nouveau en ligne!', 'Veuillez cliquer le bouton ci-dessous pour retourner sur Starling.' ],
  },
  scheduled: {
    title: 'Starling se met à jour !',
    message: [ 'Nous serons sous peu temporairement indisponible pour procéder à une mise à jour.', 'Nous vous prions de nous excuser pour l\'inconvenience et apprécions votre patience pendant que nous travaillons à améliorer Starling!' ],
    estimation: 'Maintenance will start in : {remainingBeforeMaintenance}',
  },
};
