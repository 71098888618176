var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "60%",
        viewBox: "0 0 231 166",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M130.041 90.0242C128.76 88.6586 126.689 88.6586 125.407 90.0242L111.43 105.08L97.6545 90.2343C96.3829 88.8687 94.3211 88.8687 93.0561 90.2343C91.7846 91.5998 91.7846 93.8408 93.0561 95.2063L106.832 110.018L92.9585 124.969C91.6805 126.334 91.6805 128.575 92.9585 129.976C94.2398 131.341 96.3146 131.341 97.5959 129.976L111.469 115.025L125.245 129.871C126.516 131.236 128.578 131.236 129.846 129.871C131.118 128.505 131.118 126.264 129.846 124.899L116.067 110.088L130.041 95.0313C131.32 93.6307 131.32 91.4248 130.041 90.0242V90.0242ZM110.5 55C79.8501 55 55 79.8363 55 110.5C55 141.164 79.8501 166 110.5 166C141.15 166 166 141.164 166 110.5C166 79.8363 141.15 55 110.5 55Z",
          fill: "#FF4850"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M78.1242 27.2518C74.1885 27.2518 71 24.0615 71 20.1259C71 16.1902 74.1885 13 78.1242 13C82.0615 13 85.2501 16.1902 85.2501 20.1259C85.2501 24.0615 82.0615 27.2518 78.1242 27.2518Z",
          stroke: "#EDEDED",
          "stroke-width": "4"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M206.124 137.252C202.189 137.252 199 134.062 199 130.126C199 126.19 202.189 123 206.124 123C210.062 123 213.25 126.19 213.25 130.126C213.25 134.062 210.062 137.252 206.124 137.252Z",
          stroke: "#FEB63D",
          "stroke-width": "4"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M222.455 153.393L222.461 153.398C223.068 154.005 224.052 154.005 224.66 153.398L226.427 151.632L228.194 153.398C228.801 154.006 229.785 154.006 230.392 153.398L230.398 153.394C231.006 152.786 231.006 151.801 230.398 151.194L228.631 149.427L230.398 147.66C231.006 147.053 231.006 146.069 230.398 145.461L230.393 145.456C229.785 144.848 228.8 144.848 228.193 145.456L226.427 147.222L224.661 145.456C224.052 144.849 223.068 144.849 222.461 145.456L222.455 145.461C221.848 146.068 221.848 147.053 222.455 147.661L224.222 149.427L222.455 151.193C221.848 151.801 221.848 152.786 222.455 153.393Z",
          fill: "#23CDFD"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M13.1761 116.352C16.0349 116.352 18.3523 114.035 18.3523 111.176C18.3523 108.317 16.0349 106 13.1761 106C10.3174 106 8 108.317 8 111.176C8 114.035 10.3174 116.352 13.1761 116.352Z",
          fill: "#FF4850"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M193.983 47.8269C196.841 47.8269 199.159 45.5095 199.159 42.6508C199.159 39.7921 196.841 37.4746 193.983 37.4746C191.124 37.4746 188.807 39.7921 188.807 42.6508C188.807 45.5095 191.124 47.8269 193.983 47.8269Z",
          fill: "#FF4850"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M218.046 70.8519C220.117 70.8519 221.796 69.1727 221.796 67.1013C221.796 65.0299 220.117 63.3507 218.046 63.3507C215.974 63.3507 214.295 65.0299 214.295 67.1013C214.295 69.1727 215.974 70.8519 218.046 70.8519Z",
          fill: "#EDEDED"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M153.751 47.5012C155.822 47.5012 157.501 45.822 157.501 43.7506C157.501 41.6792 155.822 40 153.751 40C151.679 40 150 41.6792 150 43.7506C150 45.822 151.679 47.5012 153.751 47.5012Z",
          fill: "#23CDFD"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M47.7506 7.50119C49.822 7.50119 51.5012 5.822 51.5012 3.7506C51.5012 1.6792 49.822 0 47.7506 0C45.6792 0 44 1.6792 44 3.7506C44 5.822 45.6792 7.50119 47.7506 7.50119Z",
          fill: "#FFA74F"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M3.7506 96.5012C5.822 96.5012 7.50119 94.822 7.50119 92.7506C7.50119 90.6792 5.822 89 3.7506 89C1.6792 89 0 90.6792 0 92.7506C0 94.822 1.6792 96.5012 3.7506 96.5012Z",
          fill: "#EDEDED"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M30.1761 68.3523C33.0349 68.3523 35.3523 66.0349 35.3523 63.1761C35.3523 60.3174 33.0349 58 30.1761 58C27.3174 58 25 60.3174 25 63.1761C25 66.0349 27.3174 68.3523 30.1761 68.3523Z",
          stroke: "#23CDFD",
          "stroke-width": "4"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }