import { Module } from 'vuex';
import { RootState } from '../../types';
import { Certificate, CertificatePdf, Certificates, Milestone, Milestones, MyStarlingState, Timelines } from './types';

const defaultState: MyStarlingState = {
  milestones: undefined,
  certificates: undefined,
  timelines: undefined,
};

export const myStarling: Module<MyStarlingState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    setMilestones(state, milestones: Milestones) {
      milestones.milestones.forEach(m => {
        m.forceDisplay = false;
      });
      state.milestones = milestones;
    },
    setCertificates(state, certificates: Certificates) {
      state.certificates = certificates;
    },
    setTimelines(state, timelines: Timelines) {
      state.timelines = timelines;
    },
  },
  actions: {
    getMilestones({ commit, state, dispatch }) {
      const callback = (res: Certificates) => {
        commit('setMilestones', res);
        return res;
      };
      return dispatch('$apiRequest', [ '/my_starling/milestones', callback ], { root: true });
    },
    getMilestone({ commit, state, dispatch }, id: string) {
      const callback = (res: Certificate) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/my_starling/milestones/${id}`, callback ], { root: true });
    },
    readMilestone({ commit, state, dispatch }, milestone: Milestone) {
      const callback = (res: Certificate) => {
        return dispatch('getMilestones');
      };
      return dispatch('$apiRequest', [ `/my_starling/milestones/${milestone.id}/_read`, callback, 'post' ], { root: true });
    },
    getCertificates({ commit, state, dispatch }) {
      const callback = (res: Certificates) => {
        commit('setCertificates', res);
        return res;
      };
      return dispatch('$apiRequest', [ '/my_starling/certificates', callback ], { root: true });
    },
    getCertificate({ commit, state, dispatch }, id: string) {
      const callback = (res: Certificate) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/my_starling/certificates/${id}`, callback ], { root: true });
    },
    claimCertificate({ commit, state, dispatch }, { certificate, fullname }: {
      certificate: Certificate,
      fullname: string
    }) {
      const callback = (res: any) => {
        return dispatch('getCertificates');
      };
      return dispatch('$apiRequest', [ `/my_starling/certificates/${certificate.id}/_claim`, callback, 'post', { fullname } ], { root: true });
    },
    downloadCertificate({ commit, state, dispatch }, certificate: Certificate): Promise<CertificatePdf> {
      if (!certificate.downloadUrl) throw new Error('Certificate is not yet downloadable.');

      return fetch(certificate.downloadUrl, {
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        method: 'get',
      }).then(response => {
        if (response.status == 200) {
          const contentDisposition = response.headers.get('content-disposition');
          const filename = contentDisposition && contentDisposition.indexOf('filename') ? contentDisposition.split('filename="')[1].split('"')[0] : 'certificate.pdf';
          return response.blob().then(blob => {
            return { filename, url: URL.createObjectURL(blob), blob } as CertificatePdf;
          });
        }
        throw new Error('An error occured during the certificate download');
      });
    },
    getTimelines({ commit, state, dispatch }) {
      const callback = (res: Certificates) => {
        commit('setTimelines', res);
        return res;
      };
      return dispatch('$apiRequest', [ '/my_starling/timelines', callback ], { root: true });
    },
  },
};
