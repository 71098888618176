import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import fr from './fr';
import es from './es';
import * as moment from 'moment';

Vue.use(VueI18n);

export const locale = 'en';
const loadedLanguages = [ locale ];

const i18n = new VueI18n({
  locale,
  messages: {
    en,
    fr,
    es,
  },
});

function applyLanguage(lang, disableWarns) {
  i18n.locale = lang;
  if (disableWarns) {
    i18n.silentTranslationWarn = true;
  } else {
    i18n.silentTranslationWarn = false;
  }
  moment.locale(lang);
  return lang;
}

export function setLanguage(lang) {
  if (!lang) lang = 'en';
  if (i18n.locale !== lang) {
    localStorage.setItem('currLang', lang);
    if (loadedLanguages.includes(lang)) return applyLanguage(lang);

    return import(`./${lang}`).then(messages => {
      i18n.setLocaleMessage(lang, messages.default);
      loadedLanguages.push(lang);
      return applyLanguage(lang);
    });
  }
  return Promise.resolve(lang);
}

export function disableTranslation() {
  if (loadedLanguages.includes('none')) return applyLanguage('none', true);

  return import(`./none`).then(messages => {
    i18n.setLocaleMessage('none', messages.default);
    loadedLanguages.push('none');
    return applyLanguage('none');
  });
}

export function enableTranslation() {
  setLanguage(localStorage.getItem('currLang'));
}

export function isTranslationEnabled() {
  return i18n.locale !== 'none';
}

const cachedLocale = localStorage.getItem('currLang');
if (cachedLocale) {
  setLanguage(cachedLocale);
} else {
  setLanguage('en');
}

export default i18n;
