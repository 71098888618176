<template>
<svg style="display:inline; margin: 0; padding: 0; width: auto;"
     class="home-filled-icon"
     viewBox="0 0 32.23 32.37">
  <defs>
    <clipPath id="clip-path">
      <rect class="home-1" x="0.66" y="0.64" width="30.91" height="31"/>
    </clipPath>
  </defs>
  <path class="home-2"
        d="M30.77,14.13h0L18.12,1.48a2.85,2.85,0,0,0-4,0L1.44,14.12h0a2.85,2.85,0,0,0,0,4A2.79,2.79,0,0,0,3.33,19h.59v9.3a3.35,3.35,0,0,0,3.34,3.35h5a.91.91,0,0,0,.91-.91v-7.3a1.52,1.52,0,0,1,1.52-1.52h2.92a1.52,1.52,0,0,1,1.52,1.52v7.3a.91.91,0,0,0,.91.91h5a3.35,3.35,0,0,0,3.34-3.35V19h.47a2.86,2.86,0,0,0,2-4.87Zm-1.29,2.75a1.06,1.06,0,0,1-.73.3H27.37a.91.91,0,0,0-.91.91V28.3a1.52,1.52,0,0,1-1.52,1.53h-4V23.44a3.35,3.35,0,0,0-3.34-3.34H14.64a3.35,3.35,0,0,0-3.34,3.34v6.39h-4A1.52,1.52,0,0,1,5.74,28.3V18.09a.91.91,0,0,0-.91-.91H3.43a1,1,0,0,1-.71-.3,1.05,1.05,0,0,1,0-1.47h0L15.37,2.77a1,1,0,0,1,1.46,0L29.48,15.41h0a1.05,1.05,0,0,1,0,1.47Z"/>
  <g class="home-3">
    <path class="home-2"
          d="M30.74,14.13h0L18.13,1.48a2.84,2.84,0,0,0-4,0L1.5,14.12h0a2.87,2.87,0,0,0,0,4A2.83,2.83,0,0,0,3.38,19H4v9.31A3.34,3.34,0,0,0,7.3,31.64h4.94a.9.9,0,0,0,.9-.9v-7.3a1.52,1.52,0,0,1,1.52-1.52h2.91a1.52,1.52,0,0,1,1.52,1.52v7.3a.9.9,0,0,0,.9.9h4.94a3.34,3.34,0,0,0,3.33-3.33V19h.46a2.86,2.86,0,0,0,2-4.87Z"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'home-filled-icon',
};
</script>

<style scoped lang="css">
.home-filled-icon .home-1 {
  fill: currentColor;
}

.home-filled-icon path.home-2 {
  fill: currentColor;
  stroke: currentColor;
}

.home-filled-icon .home-3 path.home-2 {
  fill: currentColor;
}

.router-link-active .v-icon.home-filled-icon .home-3 path.home-2,
.home-icon.activated .home-3 path.home-2 {
  fill: currentColor;
}
</style>
