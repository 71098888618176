export const BoilerplateView = resolve => {
  require.ensure([ '@/views/Boilerplate.vue' ], () => {
    resolve(require('@/views/Boilerplate.vue'));
  }, 'boilerplate');
};

export const TopNavDesktopView = resolve => {
  require.ensure([ '@/views/components/navigation/top-nav-desktop.vue' ], () => {
    resolve(require('@/views/components/navigation/top-nav-desktop.vue'));
  }, 'navigation');
};

export const TopNavMobileView = resolve => {
  require.ensure([ '@/views/components/navigation/top-nav-mobile.vue' ], () => {
    resolve(require('@/views/components/navigation/top-nav-mobile.vue'));
  }, 'navigation');
};
