var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: _vm.iconSize,
        height: _vm.iconSize,
        fill: _vm.fill,
        stroke: _vm.stroke
      }
    },
    [_c("use", { attrs: { "xlink:href": _vm.href } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }