export default {
  account: {
    title: 'Register',
    fields: {
      email: {
        title: 'What is your email ?',
      },
      password: {
        title: 'Set your secure password',
      },
      passwordConfirmation: {
        title: 'Please confirm your password',
      },
    },
    buttons: {
      signup: 'Sign-up',
    },
    error: {
      message: 'There was an error while creating your account.',
    },
  },
  verify: {
    start: {
      title: 'Email Verification',
      content: [
        'A verification link is being sent to: <strong>{email}</strong>.',
        'You have <strong>1 hour</strong> to click on the link before it expires.',
        'Didn’t get a link?',
        '<ol><li>Verify the above email address is correct. If not, <a href="/landing/sign_up">click here</a> to register with the correct email. </li><li>Check your spam or junk folder in your email.</li><li>If the link is not there, please email <a href="mailto:members@starlingminds.com?subject=Account%20verification%20link%20not%20received">members@starlingminds.com</a> for technical support.</li></ol>',
      ],
    },
    end: {
      valid: {
        title: 'Email Verified',
        content: [
          'Thank you for verifying your email address. Now, set up a secure password for your account.',
        ],
        button: 'Set my password',
      },
      invalid: {
        title: 'Invalid Link',
        content: [
          'The link you clicked on is invalid is invalid or you reloaded the page.',
        ],
      },
      error: {
        title: 'An error occured',
        content: [
          'An error occured.',
        ],
      },
      confirmed: {
        title: 'Account confirmed',
        content: [
          'Your account <strong>{email}</strong> is verified, you can now log in.',
        ],
        button: 'Sign in',
      },
      expired: {
        title: 'Expired Link',
        content: [
          'The link has expired. Use the button below to receive a new link to continue setting up your account.',
        ],
        button: 'Resend',
      },
    },
  },
  setup_referral: {
    claim_account: {
      title: 'Welcome to {name}!',
      content: [ 'We\'re so glad you\'re here.', 'Being off work can be an uncertain, stressful, and emotional time. We’re here to support you as you navigate it.' ],
      account_cta: 'Let’s start by setting up your account.',
      fields: {
        email: {
          title: 'What is your email?',
        },
        password: {
          title: 'Set your secure password',
        },
      },
    },
    accept_referral: {
      title: 'It\'s about you.',
      content: 'Now that we’ve set up your account, let’s confirm a few details about you.',
      name: {
        title: 'Your name',
        content: 'This is how the application will address you, it is not public.',
        fields: {
          firstname: {
            title: 'What would you like us to call you?',
          },
        },
      },
      profile: {
        title: 'Your profile',
        content: 'Starling delivers personalized content and community based on these factors.',
        fields: {
          birthYear: {
            title: 'When were you born?',
          },
          gender: {
            title: 'How do you identify?',
          },
          memberCode: {
            title: 'What is your member ID ?',
          },
        },
      },
      community: {
        title: 'Community settings',
        content: 'Customize your avatar and codename for use in our anonymous Community.',
      },
      privacy: {
        title: 'Your privacy is important',
        content: {
          MENTAL_FITNESS: {
            privacy: 'Your activity within {name} is highly confidential. We will only share with your provider what is strictly necessary to support your return to work. <br/><br/>Please review the Terms of Use and the Privacy Policy.',
            privacy_clinician: '{name} delivers personalized content and community based on your responses. Your activity within {name} is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.',
          },
          RTH_MENTAL: {
            privacy: 'Your activity within {name} is highly confidential. We will only share with your provider what is strictly necessary to support your return to work. <br/><br/>Please review the Terms of Use and the Privacy Policy.',
            privacy_clinician: '{name} delivers personalized content and community based on your responses. Your activity within {name} is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.',
          },
          RTH_PHYSICAL: {
            privacy: 'Your activity within {name} is highly confidential. We will only share with your provider what is strictly necessary to support your return to work. <br/><br/>Please review the Terms of Use and the Privacy Policy.',
            privacy_clinician: '{name} delivers personalized content and community based on your responses. Your activity within {name} is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.',
          },
          RTH_VETERANS: {
            privacy: 'Your activity within {name} is highly confidential. We will only share with your provider what is strictly necessary to support your return to work. <br/><br/>Please review the Terms of Use and the Privacy Policy.',
            privacy_clinician: '{name} delivers personalized content and community based on your responses. Your activity within {name} is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.',
          },
        },
      },
      buttons: {
        register: 'Let\'s go!',
      },
    },
    account_login: {
      title: 'Existing account',
      content: 'It looks like you already have an account under the email address <strong>{email}</strong>.',
      signin_cta: 'Click below to sign in and accept your invitation.',
      button: 'Sign in',
    },
    incorrect_account: {
      title: 'Incorrect account',
      content: 'The account you are currently logged into does not match the email address of your invitation.',
      signin_cta: 'Please logout and login to your account with the email <strong>{email}</strong>.',
      button: 'Log out',
    },
    account_logged: {
      title: 'Accept your invitation',
      content: '<strong>{caseManager}</strong> thinks that the Starling Return-to-Health program could be helpful for you:',
      button: 'Accept',
    },
  },
  setup_access_code: {
    title: 'Welcome to {name}!',
    content: 'Check in with your mental fitness and discover simple strategies to manage stress, anxiety, and depression.',
    account_cta: 'Let’s start by setting up your account.',
    fields: {
      access_code: {
        title: 'What’s your access code?',
        tips: {
          text: 'You can get this from your sponsoring organization. If you don\'t have a code or sponsoring organization, {0}.',
          mailto: {
            label: 'we can help',
            to: 'members@starlingminds.com',
            subject: 'What\'s my access code?',
            body: 'Organization / Union name: ###organization### \nProfession: ###profession###\n\nYour message:\n###freeText###',
          },
          additionalProperties: {
            explanation: 'To help member support quickly locate your access code, please complete the form below. If you\'re unsure, please leave these fields blank, and member support will follow up with you.',
            fields: [
              {
                type: 'input',
                id: 'organization',
                label: 'Organization / Union name',
                placeholder: '',
              },
              {
                type: 'input',
                id: 'profession',
                label: 'Profession',
                placeholder: '',
              },
              {
                type: 'textarea',
                id: 'freeText',
                label: 'Anything else you want us to know',
                placeholder: '',
              },
            ],
          },
        },
      },
      memberCode: {
        title: 'What is your member ID ?',
      },
      profession: {
        title: 'What is your occupation?',
      },
      businessUnit: {
        title: 'Where do you work?',
      },
    },
  },
  setup_profile: {
    title: 'It\'s about you.',
    content: 'Now that we’ve set up your account, let’s confirm a few details about you.',
    profile: {
      title: 'Your profile',
      content: {
        MENTAL_FITNESS: {
          privacy: '{name} delivers personalized content and community based on your responses. Everything you do here is 100% confidential. We do not sell or share any of your personal information with anyone.',
          privacy_clinician: '{name} delivers personalized content and community based on your responses. Your activity within {name} is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.',
        },
        RTH_MENTAL: {
          privacy: '{name} delivers personalized content and community based on your responses. Your activity within {name} is highly confidential. We will only share with your provider what is strictly necessary to support your return to work.',
          privacy_clinician: '{name} delivers personalized content and community based on your responses. Your activity within {name} is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.',
        },
        RTH_PHYSICAL: {
          privacy: '{name} delivers personalized content and community based on your responses. Your activity within {name} is highly confidential. We will only share with your provider what is strictly necessary to support your return to work.',
          privacy_clinician: '{name} delivers personalized content and community based on your responses. Your activity within {name} is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.',
        },
        RTH_VETERANS: {
          privacy: '{name} delivers personalized content and community based on your responses. Your activity within {name} is highly confidential. We will only share with your provider what is strictly necessary to support your return to work.',
          privacy_clinician: '{name} delivers personalized content and community based on your responses. Your activity within {name} is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.',
        },
      },
      fields: {
        firstname: {
          title: 'What name do you prefer to use?',
          content: 'This is how we’ll address you. It is not public or visible to other members.',
        },
        birthYear: {
          title: 'When were you born?',
        },
        gender: {
          title: 'How do you identify?',
        },
      },
    },
    community: {
      title: 'Community settings',
      content: 'Customize your avatar and codename for use in our anonymous Community.',
    },
    buttons: {
      register: 'Let\'s go!',
    },
  },
};
