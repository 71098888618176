export default {
  title: 'Se connecter avec un code à usage unique',
  content: {
    start: 'Veuillez entrer votre adresse courriel pour recevoir un code de connexion à usage unique.',
    end: 'Veuillez entrer le code que vous avez reçu.',
  },
  fields: {
    email: 'Adresse courriel',
    otp_code: 'Code à usage unique',
  },
  buttons: {
    send_otp: 'Envoyer un code',
    sign_in: 'Pas encore inscrit?',
  },
};
