<template>
  <v-btn v-bind="$attrs" @click="$emit('click',$event)"
         class="starling-button-secondary font-weight-bold ma-0"
         :class="{'primary-large': $attrs.large !== undefined}"
         round style="opacity:1;">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'secondary-button',
};
</script>

<style scoped>
.starling-button-secondary,
.starling-button-secondary::after {
  border-radius: var(--button-radius) !important;
  color: var(--secondary-button-text-color) !important;
  background-color: var(--secondary-button-color) !important;
}

.starling-button-secondary:hover:before {
  background-color: var(--secondary-button-hover) !important;
  opacity: var(--secondary-button-hover-opacity) !important;
  transition: .3s cubic-bezier(.25, .8, .5, 1) !important;
}
</style>
