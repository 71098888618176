<template>
  <landing-central-card>
    <v-card flat class="setup_profile-card flexcard" :elevation="elevation" :min-height="cardHeight" :style="cardStyles" :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'pa-0'">
      <v-card-loader v-if="this.loading"></v-card-loader>

      <v-form autocomplete="off"
        v-if="loaded"
        transition="slide-y-reverse-transition"
        ref="form"
        v-model="valid">

        <v-card-text class="pa-0">
          <v-layout row wrap justify-center>
            <v-flex xs12 sm11 md10>
              <h1 class="starling-h1">
                {{ $t('public.signup.setup_profile.title', { name: partnerName})}}
              </h1>
            </v-flex>

            <v-flex xs12 sm11 md10 mt-3>
              <p class="starling-body starling-content">
                {{ $t('public.signup.setup_profile.content', { name: partnerName}) }}
              </p>
            </v-flex>

            <v-flex xs12 sm11 md10 mt-3>
              <h3 class="starling-h3">
                {{ $t('public.signup.setup_profile.profile.title', { name: partnerName }) }}
              </h3>
            </v-flex>

            <v-flex xs12 sm11 md10 mt-3>
              <p class="starling-body starling-content">
                {{ $t(`public.signup.setup_profile.profile.content.${accessCodeStep.program}.${privacyDisclaimer ? 'privacy_clinician' : 'privacy'}`, { name: partnerName })
              }}</p>
            </v-flex>

            <v-flex xs12 sm11 md10 mt-3>
              <p class="starling-body font-weight-medium">
                {{ $t('public.signup.setup_profile.profile.fields.firstname.title', { name: partnerName }) }}
              </p>
              <registration-field-firstname v-model="form.name" class="mt-2"/>
              <p class="starling-body starling-content mt-3">
                  {{ $t('public.signup.setup_profile.profile.fields.firstname.content', { name: partnerName }) }}</p>
            </v-flex>

            <v-flex xs12 sm11 md10 mt-3 v-if="!defaultYearOfBirth">
              <p class="starling-body font-weight-medium">
                {{ $t('public.signup.setup_profile.profile.fields.birthYear.title', { name: partnerName }) }}
              </p>
              <registration-field-birth-year v-model="form.birthYear" class="mt-2"/>
            </v-flex>

            <v-flex xs12 sm11 md10 mt-3 v-if="!defaultGender">
              <p class="starling-body font-weight-medium">
                {{ $t('public.signup.setup_profile.profile.fields.gender.title', { name: partnerName }) }}
              </p>
              <registration-field-gender v-model="form.gender" class="mt-2"/>
            </v-flex>

            <v-flex xs12 sm11 md10 mt-3>
              <h3 class="starling-h3">
                {{ $t('public.signup.setup_profile.community.title', { name: partnerName }) }}
              </h3>
            </v-flex>

            <v-flex xs12 sm11 md10 mt-3>
              <p class="starling-body starling-content">
                {{ $t('public.signup.setup_profile.community.content', { name: partnerName }) }}
              </p>
              <v-layout row wrap align-center justify-center>
                <v-flex xs8 sm6 md4 lg3 pa-2 text-center>
                  <starling-avatar-input :value="form.avatar ? JSON.parse(form.avatar):null" @saved="onUpdateAvatar"/>
                </v-flex>
                <v-flex xs12 sm6 md8 lg9 align-center justify-center text-center>
                  <registration-field-codename class="mt-2" v-model="form.codename"
                                               @required-codename="provideCodename"/>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12>
                  <v-btn
                      class="primary--text font-weigth-bold"
                      @click="provideCodename"
                      flat block large>
                    <v-icon left class="mr-2" style="width:24px">$vuetify.icons.starling_randomize</v-icon>
                    {{ $t('public.registration.profile.fields.codename.action') }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs12 sm11 md10 mt-3>
              <registration-field-marketing v-model="form.newsletter"/>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions class="pa-0 pt-3">
          <v-layout row wrap justify-center align-center gap-xs-3>
            <v-flex xs12 text-center pt-3>
              <primary-button
                  id="btn-do-register"
                  type="button"
                  large
                  :disabled="!valid || loading"
                  :loading="loading"
                  @click="setupProfile">
                {{ $t('public.signup.setup_profile.buttons.register') }}
              </primary-button>
            </v-flex>

            <v-flex xs12 text-center>
              <v-btn
                  id="btn-cancel"
                  flat round
                  :disabled="loading"
                  @click="toSetupAccessCode">
                {{ $t('common.actions.back') }}
              </v-btn>
            </v-flex>

            <v-flex xs12 text-center>
              <span>2&nbsp;/&nbsp;2</span>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-form>
    </v-card>
  </landing-central-card>
</template>

<script>
import LandingCentralCard from '@/views/landing/landing-central-card.vue';
import { Auth } from 'aws-amplify';
import { SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY, SETUP_ACCESS_CODE_STORAGE_KEY } from './SetupAccessCode.vue';
import RegistrationFieldCodename from '@/views/landing/register/components/registration-field-codename.vue';
import RegistrationFieldGender from '@/views/landing/register/components/registration-field-gender.vue';
import vCardLoader from '@/views/components/loader/v-card-loader.vue';
import RegistrationFieldBirthYear from '@/views/landing/register/components/registration-field-birth-year.vue';
import RegistrationFieldFirstname from '@/views/landing/register/components/registration-field-firstname.vue';
import RegistrationFieldMarketing from '@/views/landing/register/components/registration-field-marketing.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import { mapState } from 'vuex';
import StarlingAvatarInput from '@/views/avatar/starling-avatar-input.vue';

export const SETUP_PROFILE_STORAGE_KEY = 'SETUP_PROFILE_STORAGE_KEY';
export default {
  components: {
    StarlingAvatarInput,
    PrimaryButton,
    RegistrationFieldMarketing,
    RegistrationFieldFirstname,
    RegistrationFieldBirthYear,
    vCardLoader,
    RegistrationFieldGender,
    RegistrationFieldCodename,
    LandingCentralCard,
  },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    const accessCodeStorageString = localStorage.getItem(SETUP_ACCESS_CODE_STORAGE_KEY);

    let initialForm = {
      name: null,
      birthYear: null,
      gender: null,
      genderValue: null,
      professionKey: null,
      newsletter: false,
      avatar: null,
      image: null,
      codename: null,
    };

    if (localStorage.getItem(SETUP_PROFILE_STORAGE_KEY)) {
      this.$log.debug('Found existing stored profile information', localStorage.getItem(SETUP_PROFILE_STORAGE_KEY));
      const savedState = JSON.parse(localStorage.getItem(SETUP_PROFILE_STORAGE_KEY));
      if (savedState.valid) {
        initialForm = savedState.value;
      }
    }
    return {
      accessCodeStep: JSON.parse(accessCodeStorageString),
      loaded: false,
      loading: false,
      valid: false,
      form: initialForm,
      defaultGender: false,
      defaultBusinessUnit: null,
      defaultProfession: null,
      defaultYearOfBirth: false,
      privacyDisclaimer: false,
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
    partnerName() {
      return this.partner && this.partner.content && this.partner.content['NAME'] && this.partner.content['NAME'][this.locale] ? this.partner.content['NAME'][this.locale] : 'Starling';
    },
  },
  beforeCreate() {
    Auth.currentSession().then(() => {
      if (!(this.$store.state.identity.userIdentity.location && this.$store.state.identity.userIdentity.location['@type'] && this.$store.state.identity.userIdentity.location['@type'] === 'nowhere')) {
        this.$log.debug('User is not @type=nowhere, redirecting to home');
        this.$router.push({ name: 'home' });
      }
    }, () => {
      this.$log.debug('User is not authenticated, go back to sign-in');
      this.$router.push({ name: 'sign_in' });
    });
  },
  mounted() {
    if (!this.accessCodeStep) {
      this.$router.push({ name: 'setup_access_code' });
      return;
    }

    if (this.accessCodeStep.accessCode) {
      this.$store.dispatch('identity/findAccessCode', this.accessCodeStep.accessCode).then(ac => {
        return this.$store.dispatch('getDefaultFields', this.accessCodeStep.accessCode).then(res => {
          this.defaultBusinessUnit = res.businessUnit;
          this.defaultProfession = res.profession;
          this.defaultGender = res.displayGender;
          this.defaultYearOfBirth = res.displayYearOfBirth;
        }).then(() => {
          return this.$store.dispatch('getClinicianDisclaimer', this.accessCode).then(res => {
            this.privacyDisclaimer = res.clinicianDisclaimer;
          });
        });
      }).finally(() => {
        this.loaded = true;
      });
    } else if (this.accessCodeStep.accessCodeImprint) {
      this.$store.dispatch('identity/findAccessCodeByImprint', this.accessCodeStep.accessCodeImprint).then(ac => {
        return this.$store.dispatch('getDefaultFieldsByImprint', this.accessCodeStep.accessCodeImprint).then(res => {
          this.defaultBusinessUnit = res.businessUnit;
          this.defaultProfession = res.profession;
          this.defaultGender = res.displayGender;
          this.defaultYearOfBirth = res.displayYearOfBirth;
        }).then(() => {
          return this.$store.dispatch('getClinicianDisclaimerWithImprint', this.accessCodeImprint).then(res => {
            this.privacyDisclaimer = res.clinicianDisclaimer;
          });
        });
      }).finally(() => {
        this.loaded = true;
      });
    }
  },
  methods: {
    setupProfile() {
      this.loading = true;

      Auth.currentSession().then(session => {
        this.form.gender = this.form.gender ? this.form.gender : {
          value: null,
          type: 'skipped',
          custom: null,
        };
        this.form.birthYear = this.form.birthYear ? this.form.birthYear : {
          value: null,
          type: 'skipped',
          custom: null,
        };
        const businessUnit = this.accessCodeStep.businessUnit ? this.accessCodeStep.businessUnit : {
          value: null,
          type: 'predefined',
          custom: null,
        };

        const toRegister = {};
        toRegister.accessCode = this.accessCodeStep.accessCode;
        toRegister.accessCodeImprint = this.accessCodeStep.accessCodeImprint;
        toRegister.memberCode = this.accessCodeStep.memberCode;
        toRegister.email = session.getIdToken().payload.email;
        toRegister.userName = session.getIdToken().payload['cognito:username'];
        toRegister.agreement = true;
        toRegister.newsletter = this.form.newsletter;
        toRegister.name = this.form.name;
        toRegister.codename = this.form.codename;
        toRegister.avatar = this.form.avatar;
        toRegister.image = this.form.image;
        toRegister.gender = this.form.gender.type === 'custom' ? this.form.gender.custom : this.form.gender.value;
        toRegister.genderSelectionType = this.form.gender.type;
        toRegister.birthYear = this.form.birthYear.value;
        toRegister.birthYearSelectionType = this.form.birthYear.type;
        toRegister.professionId = this.defaultProfession ? this.defaultProfession : this.accessCodeStep.professionId;
        toRegister.businessUnitId = this.defaultBusinessUnit ? this.defaultBusinessUnit : (businessUnit.type === 'custom' ? null : businessUnit.value);
        toRegister.businessUnitOther = this.defaultBusinessUnit ? null : (businessUnit.type === 'custom' ? businessUnit.custom : null);
        toRegister.businessUnitSelectionType = this.defaultBusinessUnit ? 'predefined' : businessUnit.type;
        toRegister.businessUnitSublevels = businessUnit.filters;
        toRegister.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Vancouver';
        toRegister.locale = localStorage.getItem('currLang') || 'en';

        return this.$store.dispatch('externalUserRegister', toRegister).then(res => {
          this.clearFormData();
          if (res.status && res.status !== 204) {
            this.$log.error('An error occured', res);
          } else {
            return new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
              return this.$store.dispatch('identity/configureAuth').then(res => {
                window.location.path = '';
                window.location.reload();
              });
            });
          }
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    clearFormData() {
      localStorage.removeItem(SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY);
      localStorage.removeItem(SETUP_ACCESS_CODE_STORAGE_KEY);
      localStorage.removeItem(SETUP_PROFILE_STORAGE_KEY);
    },
    toSetupAccessCode() {
      this.$router.push({ name: 'setup_access_code' });
    },
    onUpdateAvatar(event) {
      this.form.avatar = JSON.stringify(event.options);
      this.form.image = event.image;
    },
    provideCodename() {
      return this.$store.dispatch('generateRegistrationCodename').then(res => {
        this.form.codename = res.codename;
      });
    },
  },
};
</script>

<style scoped>
.setup_profile-card {
  border-radius: 15px;
}
</style>
