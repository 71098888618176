import { ActionContext, Module } from 'vuex';
import { RootState } from '../../types';
import { AccessCodeState } from '@/scripts/store/modules/access_codes/types';

export const defaultState: AccessCodeState = {};

export const accessCode: Module<AccessCodeState, RootState> = {
  namespaced: true,
  state: defaultState,
  actions: {
    validate(context: ActionContext<AccessCodeState, RootState>, accessCode: string) {
      const callback = (res: any) => res;
      return context.dispatch('$apiRequest', [ '/registration/accessCode/_validate', callback, 'post', { accessCode } ], { root: true });
    },
    validateByImprint(context: ActionContext<AccessCodeState, RootState>, accessCodeImprint: string) {
      const callback = (res: any) => res;
      return context.dispatch('$apiRequest', [ '/registration/accessCode/_validateImprint', callback, 'post', { accessCodeImprint } ], { root: true });
    },
  },
};
