import Vue from 'vue';
import store from '@/scripts/store/index';
import router from '@/scripts/router';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { DeviceInfo, Device } from '@capacitor/device';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { PushNotifications } from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { CapacitorUpdater } from '@capgo/capacitor-updater';

declare module 'vue/types/vue' {
  interface Vue {
    capacitorDeepLink(callback: Function): void,
    capacitorGetDeviceInfo(): Promise<DeviceInfo>,
    capacitorBrowserOpen(url: string, target?: string): void;
    capacitorBrowserClose(): Promise<void>;
    capacitorSaveFile(filename: string, blob: Blob): void;
    capacitorRegisterPushNotifications(): void;
    capacitorCheckPushPermission(): Promise<void>;
    capacitorGetVersion(): Promise<string>;
  }
}

export default Vue.extend({
  methods: {
    capacitorDeepLink(callback: Function) {
      if (!Capacitor.isNativePlatform()) return;
      CapacitorApp.addListener('appUrlOpen', ({ url }) => {
        (Vue as any).$log.debug('App opened with URL: ', url);
        callback(url);
      });
    },
    capacitorGetDeviceInfo(): Promise<DeviceInfo> {
      return Device.getInfo();
    },
    capacitorBrowserOpen(url: string, target?: string) {
      if (Capacitor.isNativePlatform()) {
        Browser.open({ url, windowName: target || '_self' });
      } else {
        window.open(url, target || '_blank');
      }
    },
    capacitorBrowserClose() {
      return Browser.close();
    },
    capacitorSaveFile(filename: string, blob: Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        return Filesystem.writeFile({
          path: filename,
          data: reader.result as string,
          directory: Directory.Cache,
        }).then(() => {
          return Filesystem.getUri({
            directory: Directory.Cache,
            path: filename,
          });
        }).then((uriResult) => {
          return FileOpener.openFile({ path: uriResult.uri });
        });
      };
      reader.readAsDataURL(blob);
    },
    capacitorRegisterPushNotifications() {
      if (!Capacitor.isNativePlatform()) return;

      this.pushNotificationRegisterNotifications().then(() => {
        return this.pushNotificationAddListeners();
      }).catch((err) => {
        this.$log.error('Error registering to push notifications', err);
      });
    },
    capacitorCheckPushPermission(): Promise<void> {
      return PushNotifications.checkPermissions().then(permStatus => {
        if (permStatus.receive === 'granted') {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Permission not granted'));
      });
    },
    async pushNotificationRegisterNotifications() {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.removeAllListeners();
      await PushNotifications.register();
    },
    async pushNotificationAddListeners() {
      const _self = this;
      await PushNotifications.addListener('registration', token => {
        _self.updatePushNotificationToken(token.value);
        // return this.pushNotificationGetDeliveredNotifications();
      });

      await PushNotifications.addListener('registrationError', err => {
        this.$log.error('Push notification registration error', err);
      });

      // await PushNotifications.addListener('pushNotificationReceived', notification => {
      //   handle notification automatically - not in use
      // });

      await PushNotifications.addListener('pushNotificationActionPerformed', pushNotification => {
        const data = pushNotification?.notification?.data;
        if (data?.['starling.route']) {
          const route = data['starling.route'];
          const params = data['starling.params'] ? JSON.parse(data['starling.params']) : undefined;
          this.$router.push({ name: route, params }).catch(() => {});
        }
      });
    },
    // async pushNotificationGetDeliveredNotifications() {
    //   const notificationList = await PushNotifications.getDeliveredNotifications();
    //   handle delivered notifications - not in use
    // },
    async updatePushNotificationToken(token: string) {
      const deviceInfo: DeviceInfo = await this.capacitorGetDeviceInfo();
      await this.$store.dispatch('savePushNotificationToken', { token, ...deviceInfo });
    },
    capacitorGetVersion(): Promise<string> {
      const version = process.env.PACKAGE_VERSION || '1.0.0';
      if (!Capacitor.isNativePlatform()) return Promise.resolve(version);
      return CapacitorUpdater.current().then(appInfo => {
        if (appInfo?.bundle?.version) return Promise.resolve(appInfo?.bundle?.version);
        return Promise.resolve(version);
      });
    },
  },
});

function capacitorUpdaterCheckUpdate(): void {
  CapacitorUpdater.getLatest().then(latest => {
    if (!latest?.url || !latest?.version) throw new Error('Invalid update bundle');
    store.commit('setLoadingProgress', 0);
    return CapacitorUpdater.download({ url: latest.url, version: latest.version, sessionKey: latest.sessionKey });
  }).then(data => {
    if (!data?.id) throw new Error('Invalid data');
    const route = {
      path: router.currentRoute.path,
      query: router.currentRoute.query,
      params: router.currentRoute.params,
    };
    localStorage.setItem('afterUpdateRoute', JSON.stringify(route));
    store.commit('setLoadingProgress', 999);
    return CapacitorUpdater.set({ id: data.id });
  }).catch(err => {
    (Vue as any).$log.debug('UpdaterCheck', err);
    store.commit('setLoadingProgress', 999);
    localStorage.removeItem('appUpdated');
  });
}

export function capacitorUpdaterInitialize(): void {
  if (!Capacitor.isNativePlatform()) {
    store.commit('setLoadingProgress', undefined);
    return;
  }

  CapacitorUpdater.addListener('appReady', async (appInfo) => {
    await SplashScreen.hide({ fadeOutDuration: 0 });
  });

  CapacitorUpdater.notifyAppReady();

  CapacitorApp.addListener('appStateChange', async (state: any) => {
    if (state.isActive) capacitorUpdaterCheckUpdate();
  });

  CapacitorUpdater.addListener('download', async (progress) => {
    store.commit('setLoadingProgress', progress.percent);
  });

  capacitorUpdaterCheckUpdate();

  Browser.addListener('browserFinished', () => {
    store.commit('setLoadingView', false);
  });
}
