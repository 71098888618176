var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "avatarRef", staticClass: "starling-avatar" }, [
    _vm.showSvg
      ? _c("div", {
          staticClass: "avatar-payload",
          domProps: { innerHTML: _vm._s(_vm.svgContent) }
        })
      : _vm._e(),
    !_vm.showSvg
      ? _c("div", { staticClass: "avatar-payload-placeholder" })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }