<template>
  <div class="registration-agreement">
    <v-layout row px-2 gap-xs-2 align-baseline v-show="loadedAgreement">
      <v-flex>
        <v-btn
            class="ma-0" fab
            :label="'Label'"
            :outline="!internalValue"
            :color="internalValue?'checked_checkbox':'normal'"
            @click="internalValue = !internalValue">
          <v-icon v-if="internalValue">mdi-check</v-icon>
        </v-btn>
      </v-flex>
      <v-flex>
        <p class="starling-body">
                {{ $t('public.registration.account.fields.agreement.label') }}
                <a ref="termsOfUseLink">{{ $t('common.actions.termsOfUse') }}</a>,
                <a ref="privacyPolicyLink">{{ $t('common.actions.privacyPolicy') }}</a>
                {{ $t('public.registration.account.fields.agreement.conjunction') }}
                <a ref="communityGuidelinesLink">{{ $t('common.actions.communityGuidelines') }}</a>
              </p>
      </v-flex>
    </v-layout>

    <v-dialog v-if="loadedAgreement && loadedAgreement.termsOfUse" v-model="displayTermsOfUse"
              :max-width="$vuetify.breakpoint.xsOnly? '90%':'800px'"
              content-class="radius-15">
      <v-card class="white" elevation="0">
        <v-card-title>
          <starling-logo-colors id="tou-logo" class="mt-2 mr-2" width="130px" height="40px"></starling-logo-colors>
          <h1 class="starling-h1">{{ $t('common.actions.termsOfUse') }}</h1>
          <v-spacer></v-spacer>
          <v-btn flat icon @click="displayTermsOfUse = false" class="">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-2">
          <div v-html="loadedAgreement.termsOfUse"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="loadedAgreement && loadedAgreement.privacyPolicy" v-model="displayPrivacyPolicy"
              :max-width="$vuetify.breakpoint.xsOnly? '90%':'800px'"
              content-class="radius-15">
      <v-card class="white" elevation="0">
        <v-card-title>
          <starling-logo-colors id="pp-logo" class="mt-2 mr-2" width="130px" height="40px"></starling-logo-colors>
          <h1 class="starling-h1">{{ $t('common.actions.privacyPolicy') }}</h1>
          <v-spacer></v-spacer>
          <v-btn flat icon @click="displayPrivacyPolicy = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-2">
          <div v-html="loadedAgreement.privacyPolicy"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="loadedAgreement && loadedAgreement.communityGuidelines" v-model="displayCommunityGuidelines"
              :max-width="$vuetify.breakpoint.xsOnly? '90%':'800px'"
              content-class="radius-15">
      <v-card class="white" elevation="0">
        <v-card-title>
          <starling-logo-colors id="cg-logo" class="mt-2 mr-2" width="130px" height="40px"></starling-logo-colors>
          <h1 class="starling-h1">{{ $t('common.actions.communityGuidelines') }}</h1>
          <v-spacer></v-spacer>
          <v-btn flat icon @click="displayCommunityGuidelines = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-2">
          <div v-html="loadedAgreement.communityGuidelines"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StarlingLogoColors from '@/views/images/starling-logo-colors.vue';

export default {
  name: 'registration-field-agreement',
  components: {
    StarlingLogoColors,
  },
  props: {
    value: Boolean,
    accessCode: {
      type: String,
      required: false,
    },
    accessCodeImprint: {
      type: String,
      required: false,
    },
    referralKey: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
      displayTermsOfUse: false,
      displayPrivacyPolicy: false,
      displayCommunityGuidelines: false,
      loadedAgreement: null,
    };
  },
  watch: {
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
      }
    },
    internalValue(newValue) {
      this.$emit('input', newValue);
      this.$emit('form-validate');
    },
  },
  mounted() {
    let apiCall = null;
    if (this.referralKey) {
      apiCall = this.$store.dispatch('generateTermsOfUseAndPrivacyPolicyWithReferralKey', this.referralKey);
    } else if (this.accessCodeImprint) {
      apiCall = this.$store.dispatch('generateTermsOfUseAndPrivacyPolicyWithAccessCodeImprint', this.accessCodeImprint);
    } else if (this.accessCode) {
      apiCall = this.$store.dispatch('generateTermsOfUseAndPrivacyPolicyWithAccessCode', this.accessCode);
    } else {
      apiCall = new Promise((resolve) => {
        resolve({
          termsOfUse: null,
          privacyPolicy: null,
          communityGuidelines: null,
        });
      });
    }

    apiCall.then(res => {
      this.loadedAgreement = res;

      this.$refs.termsOfUseLink.onclick = () => {
        if (this.loadedAgreement.termsOfUse) {
          this.displayTermsOfUse = true;
        } else {
          this.capacitorBrowserOpen(this.$t('public.registration.account.fields.agreement.termsOfUseLink'));
        }
      };
      this.$refs.privacyPolicyLink.onclick = () => {
        if (this.loadedAgreement.privacyPolicy) {
          this.displayPrivacyPolicy = true;
        } else {
          this.capacitorBrowserOpen(this.$t('public.registration.account.fields.agreement.privacyPolicyLink'));
        }
      };
      this.$refs.communityGuidelinesLink.onclick = () => {
        if (this.loadedAgreement.communityGuidelines) {
          this.displayCommunityGuidelines = true;
        } else {
          this.capacitorBrowserOpen(this.$t('public.registration.account.fields.agreement.communityGuidelinesLink'));
        }
      };
    });
  },
};
</script>

<style scoped>
.registration-agreement .v-btn.checked_checkbox {
  background-color: var(--StarlingSuccess) !important;
  color: white;
}
</style>
