export default {
  header_title: 'Favorites',
  title: 'Favorites',
  content: 'Anytime you star something it will show up here.',
  callout: {
    title: 'Your Favorites',
    description: 'Look for the star to favorite any video and rewatch them here.',
  },
  empty_content: {
    text: 'You can find videos in any Training Session, Exercise, Webinar, or use the {0} page to jump right in!',
    link: 'Videos',
  },
  types: {
    VIDEO: 'Video',
    AUDIO: 'Audio',
  },
};
