<template>
<svg style="display:inline; margin: 0; padding: 0; width: auto;"
     class="tools-icon"
     viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M.938 25.977V12.5c0-.599.208-1.107.625-1.523a2.073 2.073 0 0 1 1.523-.625h4.883V7.148c0-.599.208-1.106.625-1.523A2.073 2.073 0 0 1 10.117 5h9.766c.599 0 1.107.208 1.523.625.417.417.625.924.625 1.523v3.204h4.883c.599 0 1.107.208 1.523.625.417.416.625.924.625 1.523v13.477H.938Zm1.21-1.211h25.704V18.75h-4.454v1.563h-1.171V18.75H7.773v1.563H6.602V18.75H2.148v6.016Zm0-12.266v5.078h4.454v-1.562h1.171v1.562h14.454v-1.562h1.171v1.562h4.454V12.5c0-.234-.098-.45-.293-.645-.196-.195-.41-.293-.645-.293H3.086c-.234 0-.45.098-.645.293-.195.196-.293.41-.293.645Zm7.032-2.148h11.64V7.148c0-.234-.097-.449-.293-.644-.195-.195-.41-.293-.644-.293h-9.766c-.234 0-.449.098-.644.293-.196.195-.293.41-.293.644v3.204Z"/>
  <path class="icon-content" d="M.938 25.977V18.75h5.664v1.563h1.171V18.75h14.454v1.563h1.171V18.75h5.665v7.227H.938Zm0-13.477c0-.573.214-1.074.644-1.504.43-.43.931-.644 1.504-.644h4.883V7.148c0-.572.215-1.074.644-1.503.43-.43.931-.645 1.504-.645h9.766c.573 0 1.074.215 1.504.645.43.43.644.93.644 1.503v3.204h4.883c.573 0 1.074.214 1.504.644.43.43.645.931.645 1.504v5.234h-5.665v-1.718h-1.171v1.718H7.773v-1.718H6.602v1.718H.937V12.5Zm8.242-2.148h11.64V7.148a.904.904 0 0 0-.273-.664.904.904 0 0 0-.664-.273h-9.766a.904.904 0 0 0-.664.273.904.904 0 0 0-.273.664v3.204Z"/>
</svg>
</template>

<script>
export default {
  name: 'tools-icon',
};
</script>

<style scoped lang="css">
.tools-icon {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0.3;
}

.tools-icon .icon-content {
  fill: transparent;
  stroke: transparent;
}

.tools-icon.activated, .router-link-active .v-icon.tools-icon {
  fill: transparent;
  stroke: transparent;
}

.tools-icon.activated .icon-content, .router-link-active .v-icon.tools-icon .icon-content {
  fill: currentColor;
  stroke: transparent;
}

</style>
