






















import Vue, { PropType } from 'vue';
import { WidgetShape, WidgetType } from '@/views/avatar/enums';
import { Widget } from '@/views/avatar/types';
import { NONE } from '@/views/avatar/constant';

export default Vue.extend({
  name: 'starling-avatar-selector-shape-carousel',
  props: {
    widgetType: {
      type: String as PropType<WidgetType>,
    },
    value: {
      type: Object as PropType<Widget<WidgetShape>>,
    },
    available: {
      type: Array as PropType<Array<WidgetShape>>,
    },
  },
  data() {
    return {
      innerValue: this.value?.shape === NONE ? this.available[0] : this.value.shape,
    };
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
  },
  watch: {
    'value.shape'(val: WidgetShape) {
      this.innerValue = val;
    },
    innerValue(val: WidgetShape) {
      this.$emit('input', { ...this.value, shape: val } as Widget<WidgetShape>);
    },
  },
  methods: {
    onNext() {
      this.innerValue = this.available[(this.available.indexOf(this.innerValue) + this.available.length + 1) % this.available.length];
    },
    onPrevious() {
      this.innerValue = this.available[(this.available.indexOf(this.innerValue) + this.available.length - 1) % this.available.length];
    },
  },
});
