import publicPages from './public';
import app from './app';
import common from './common';
import status from './status';
import en from '../en';
import { mergeDeep } from '@/scripts/mergeDeep';

const merged = mergeDeep({}, en, {
  public: publicPages,
  app,
  common,
  status,
});

export default merged;
