<template>
  <v-text-field
      ref="field"
      autocomplete="new-password"
      :label="$t('public.registration.accessCode.fields.memberCode.label')"
      name="member_id"
      :rules="rules"
      v-model="internalValue"
      :hint="$t('public.registration.accessCode.fields.memberCode.hint')"
      persistent-hint
      :maxlength="this.configuration.max"
      :type="'text'">
  </v-text-field>
</template>

<script>
export default {
  name: 'registration-field-member-id',
  props: {
    value: String,
    configuration: Object,
  },
  data() {
    return {
      internalValue: this.value,
      loading: false,
      postValidateError: null,
      timeout: null,
      rules: [
        v => !!v || this.$t('common.errors.required'),
        v => (v && v.length >= this.configuration.min) || this.$t('common.errors.min_param', { min: this.configuration.min }),
      ],
    };
  },
  watch: {
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
      }
    },
    internalValue(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>

<style scoped>

</style>
