<template>
  <v-text-field
      ref="field"
      autocomplete="new-password"
      :label="$t('public.registration.profile.fields.firstname.label')"
      :placeholder="$t('public.registration.profile.fields.firstname.placeholder')"
      :hint="$t('public.registration.profile.fields.firstname.hint')"
      name="firstname"
      :rules="rules"
      v-model="internalValue"
      persistent-hint
      :type="'text'">
  </v-text-field>
</template>

<script>
export default {
  name: 'registration-field-firstname',
  props: {
    value: String,
  },
  data() {
    return {
      internalValue: this.value,
      loading: false,
      postValidateError: null,
      timeout: null,
      rules: [
        v => !!v || this.$t('common.errors.required'),
        v => (v && v.toString().trim().length > 0) || this.$t('common.errors.nonEmpty'),
        v => (v && v.length <= 50) || this.$t('common.errors.max50', { maxLength: 50 }),
      ],
    };
  },
  watch: {
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
      }
    },
    internalValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  mounted() {
    this.$refs.field.focus();
  },
};
</script>

<style scoped>

</style>
