// Lazy loaded routes
import registration from './routes/registration';
import app from './routes/app';

const PreviewView = resolve => {
  require.ensure([ '@/views/Preview.vue' ], () => {
    resolve(require('@/views/Preview.vue'));
  }, 'appContent');
};

const ErrorPageView = resolve => {
  require.ensure([ '@/views/ErrorPage.vue' ], () => {
    resolve(require('@/views/ErrorPage.vue'));
  }, 'appContent');
};

const routes = [
  {
    path: '/',
    redirect: '/app',
  },
  {
    name: 'preview',
    path: '/preview',
    component: PreviewView,
    meta: { public: true },
  },
  {
    name: 'error',
    path: '/error',
    component: ErrorPageView,
    meta: { public: true },
    props: true,
  },
  registration,
  app,
  { path: '*', redirect: '/' }, // catch all use case
];

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'devaws') {
  const TestingView = resolve => {
    require.ensure([ '@/views/Testing.vue' ], () => {
      resolve(require('@/views/Testing.vue'));
    }, 'appContent');
  };

  const AvatarTestingView = resolve => {
    require.ensure([ '@/views/AvatarTesting.vue' ], () => {
      resolve(require('@/views/AvatarTesting.vue'));
    }, 'AvatarTesting');
  };

  const UserTestingView = resolve => {
    require.ensure([ '@/views/UserTesting.vue' ], () => {
      resolve(require('@/views/UserTesting.vue'));
    }, 'appContent');
  };
  const UserTesting3View = resolve => {
    require.ensure([ '@/views/UserTesting3.vue' ], () => {
      resolve(require('@/views/UserTesting3.vue'));
    }, 'appContent');
  };

  routes.push({
    name: 'testing',
    path: '/testing',
    component: TestingView,
    meta: { public: true },
  });
  routes.push({
    name: 'avatar-testing',
    path: '/avatar_testing',
    component: AvatarTestingView,
    meta: { public: true },
  });
  routes.push({
    name: 'user-testing',
    path: '/user-testing',
    component: UserTestingView,
    meta: { public: true },
  });
  routes.push({
    name: 'user-testing-3',
    path: '/user-testing-3',
    component: UserTesting3View,
    meta: { public: true },
  });
}

export default routes;
