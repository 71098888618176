<template>
<svg width="260" height="303" viewBox="0 0 260 303" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M150.466 8.88351C137.142 6.51156 132.934 38.0266 127.776 42.6005C122.631 47.1283 112.162 45.9497 110.092 58.4531C108.014 70.9543 117.764 73.3086 116.036 83.6872C114.302 94.0636 93.0118 86.5434 90.317 102.939C87.6145 119.333 102.938 128.93 101.296 138.809C99.6565 148.68 90.3095 150.063 86.6915 171.914C83.0735 193.765 98.6858 202.937 123.329 212.002C147.973 221.067 165.636 218.413 169.731 194.9C173.823 171.395 146.908 164.385 147.197 150.922C147.472 137.504 166.344 135.572 168.955 120.484C171.558 105.394 145.909 98.7639 147.959 86.7046C150.01 74.6453 171.773 73.9834 173.931 61.4636C176.089 48.9438 171.545 45.6842 163.197 38.7612C154.81 31.9184 163.791 11.2555 150.466 8.88351Z" fill="url(#paint0_linear)"/>
    <path d="M124.091 200.431C124.574 200.752 125.147 200.525 125.359 199.928C125.58 199.334 125.361 198.588 124.878 198.267C113.256 190.655 108.623 180.65 110.759 167.916C110.869 167.273 110.532 166.619 110.008 166.443C109.484 166.268 108.975 166.647 108.866 167.29C106.545 181.162 111.699 192.281 124.091 200.431Z" fill="#98BBE9"/>
    <path d="M131.26 147.701C131.804 147.807 132.259 147.354 132.281 146.694C132.306 146.027 131.884 145.407 131.34 145.301C122.572 143.561 116.757 136.257 113.769 123.185C113.623 122.536 113.074 122.095 112.552 122.204C112.03 122.312 111.729 122.925 111.878 123.567C115.093 137.586 121.582 145.766 131.26 147.701Z" fill="#98BBE9"/>
    <path d="M133.222 75.1767C133.599 75.2266 133.915 74.9091 133.924 74.4705C133.943 74.0264 133.648 73.6336 133.271 73.5838C128.739 72.9836 125.747 69.5364 124.194 63.0794C124.092 62.6506 123.713 62.3754 123.354 62.4641C122.996 62.5528 122.783 62.9747 122.885 63.4036C124.592 70.4874 128.068 74.4814 133.222 75.1767Z" fill="#98BBE9"/>
    <path d="M136.006 67.2408C135.641 67.1196 135.45 66.6818 135.587 66.2631C135.714 65.8498 136.114 65.615 136.479 65.7362C140.862 67.2345 144.762 65.3637 148.315 59.9714C148.552 59.6066 148.994 59.5169 149.299 59.7624C149.603 60.0078 149.662 60.4993 149.424 60.8641C145.509 66.7936 141.001 68.9562 136.006 67.2408Z" fill="#98BBE9"/>
    <path d="M131.981 131.538C131.458 131.33 131.189 130.67 131.389 130.061C131.581 129.45 132.165 129.126 132.688 129.335C140.426 132.403 147.387 129.237 153.79 119.566C154.142 119.034 154.776 118.916 155.215 119.3C155.654 119.684 155.724 120.429 155.372 120.96C148.447 131.406 140.588 134.972 131.981 131.538Z" fill="#98BBE9"/>
    <path d="M131.703 189.391C131.186 189.16 130.927 188.469 131.126 187.86C131.318 187.249 131.898 186.941 132.414 187.172C140.042 190.625 146.928 187.721 153.295 178.173C153.645 177.649 154.272 177.554 154.703 177.969C155.136 178.376 155.201 179.136 154.851 179.66C147.963 189.974 140.186 193.256 131.703 189.391Z" fill="#98BBE9"/>
    <path d="M115.9 243.775C115.752 244.641 116.197 245.559 116.89 245.841C117.583 246.123 119.203 246.021 119.351 245.156C119.242 239.497 119.973 232.013 121.555 222.692C123.914 208.714 134.645 187.754 135.95 171.792C137.238 155.859 128.919 122.617 129.408 98.5535C129.781 79.0774 137.83 63.4344 141.499 52.6134C145.187 41.7562 146.563 28.1297 146.325 28.0866C146.042 28.0303 144.563 41.9854 140.77 52.4544C136.988 62.885 130.165 76.1402 128.132 92.3177C124.328 122.483 135.596 153.282 132.232 173.057C128.865 192.841 121.378 203.274 117.623 220.374C115.108 231.759 114.538 239.555 115.9 243.775Z" fill="#8FAFDA"/>
    <path d="M48.3875 22.6835C61.2613 18.7476 69.8731 50.529 75.6786 54.7632C81.4918 58.9951 91.8713 57.0018 95.7721 69.7608C99.6752 82.5274 90.0926 85.4571 93.3054 95.9658C96.5183 106.474 117.265 98.0141 122.439 114.937C127.613 131.859 113.061 141.748 116.131 151.79C119.201 161.832 129.18 163.401 136.031 185.811C142.883 208.222 127.484 216.545 102.44 224.202C77.3971 231.858 58.4558 228.677 51.6043 206.267C44.7527 183.857 71.9164 177.058 69.9899 163.913C68.0711 150.766 48.3929 149.309 43.9446 134.759C39.4985 120.216 64.8327 112.471 61.1987 100.584C57.5646 88.6981 35.5045 89.5506 31.7979 77.4269C28.0914 65.3033 32.2281 61.67 39.6884 54.0161C47.1635 46.3828 35.5138 26.6194 48.3875 22.6835Z" fill="url(#paint1_linear)"/>
    <path d="M100.219 212.829C99.7482 213.124 99.1213 212.88 98.8146 212.288C98.508 211.695 98.6475 210.975 99.1182 210.68C110.44 203.612 114.042 193.823 110.132 181.033C109.936 180.39 110.205 179.739 110.726 179.58C111.254 179.418 111.834 179.81 112.03 180.453C116.306 194.411 112.302 205.294 100.219 212.829Z" fill="#5ACE87"/>
    <path d="M85.6602 161.131C85.1075 161.267 84.5992 160.836 84.5169 160.183C84.4321 159.523 84.8152 158.887 85.3679 158.751C94.2707 156.582 99.6185 148.971 101.493 135.61C101.59 134.944 102.106 134.469 102.656 134.543C103.206 134.618 103.576 135.224 103.478 135.89C101.458 150.225 95.4902 158.737 85.6602 161.131Z" fill="#5ACE87"/>
    <path d="M74.3967 88.4564C74.0223 88.5457 73.675 88.2585 73.6152 87.8165C73.563 87.3721 73.8168 86.943 74.1912 86.8537C78.7147 85.772 81.423 81.9562 82.3643 75.2073C82.4286 74.7608 82.7811 74.4355 83.157 74.4879C83.5329 74.5404 83.7834 74.9492 83.7191 75.3957C82.6855 82.8005 79.5594 87.2211 74.3967 88.4564Z" fill="#5ACE87"/>
    <path d="M71.1087 80.4395C66.1455 82.3001 61.0743 80.3856 56.069 74.8271C55.7636 74.4853 55.7451 74.0139 56.0274 73.7602C56.3097 73.5065 56.7838 73.5792 57.0869 73.9134C61.6487 78.9797 66.0202 80.6311 70.3752 78.9983C70.7356 78.863 71.1936 79.0745 71.3911 79.4744C71.5964 79.872 71.4691 80.3042 71.1087 80.4395Z" fill="#5ACE87"/>
    <path d="M83.919 144.732C75.208 147.94 66.2688 144.488 57.3471 134.578C56.8937 134.08 56.8608 133.371 57.2675 133.004C57.6742 132.637 58.3678 132.743 58.8211 133.24C67.0828 142.414 75.0041 145.466 82.8207 142.591C83.3471 142.396 84.0226 142.717 84.3193 143.304C84.616 143.892 84.4531 144.536 83.919 144.732Z" fill="#5ACE87"/>
    <path d="M91.441 201.829C82.7299 205.036 73.7931 201.592 64.869 191.675C64.4157 191.177 64.3828 190.467 64.7895 190.1C65.1962 189.733 65.8897 189.839 66.3431 190.337C74.6047 199.511 82.5261 202.563 90.3427 199.688C90.8691 199.493 91.5446 199.814 91.8413 200.401C92.138 200.988 91.9697 201.642 91.441 201.829Z" fill="#5ACE87"/>
    <path d="M114.215 255.761C114.475 256.611 114.113 257.483 113.402 257.7C112.69 257.918 110.941 257.7 110.679 256.842C110.118 251.339 108.439 243.986 105.631 234.776C101.425 220.964 87.5533 200.063 84.2467 184.403C80.9378 168.735 85.3379 135.687 81.6681 111.584C78.6835 91.9664 68.4157 76.6681 63.2618 66.0247C58.108 55.3813 54.8872 41.7533 55.1168 41.6831C55.3924 41.5988 58.7567 55.5596 63.9736 65.8071C69.1906 76.0546 77.8891 89.0122 82.1408 105.246C90.0576 135.466 82.3358 166.19 88.2981 185.692C94.2628 205.201 103.46 215.738 109.551 232.732C113.609 244.062 115.169 251.737 114.215 255.761Z" fill="#42B16D"/>
    <path d="M215.324 94.5702C203.438 88.2501 191.178 113.557 185.066 115.864C178.947 118.167 169.217 114.081 163.867 124.144C158.516 134.207 167.364 138.912 162.96 147.194C158.556 155.476 139.666 143.474 132.572 156.816C125.478 170.158 138.236 181.963 134.024 189.885C129.816 197.8 120.025 196.864 110.636 214.523C101.246 232.183 115.01 242.847 138.132 255.141C161.255 267.435 179.865 269.037 189.255 251.377C198.645 233.717 173.379 221.67 176.876 210.83C180.367 199.986 199.473 203.229 205.568 191.767C211.662 180.305 188.271 167.868 193.254 158.497C198.232 149.134 219.341 154.901 224.421 145.346C229.501 135.792 225.98 131.726 219.764 123.454C213.544 115.188 227.217 100.894 215.324 94.5702Z" fill="url(#paint2_linear)"/>
    <path d="M141.701 245.889C142.118 246.247 142.75 246.184 143.119 245.746C143.488 245.307 143.447 244.651 143.03 244.293C133.028 235.64 130.789 226.418 136.147 216.34C136.418 215.832 136.24 215.211 135.759 214.956C135.272 214.697 134.665 214.9 134.395 215.409C128.534 226.397 131.03 236.653 141.701 245.889Z" fill="#BFD8E6"/>
    <path d="M161.578 204.091C162.095 204.294 162.671 204.029 162.874 203.494C163.078 202.958 162.817 202.357 162.303 202.148C153.947 198.82 150.091 191.53 150.694 180.031C150.727 179.458 150.298 178.968 149.751 178.94C149.197 178.908 148.724 179.355 148.699 179.93C148.043 192.263 152.35 200.409 161.578 204.091Z" fill="#BFD8E6"/>
    <path d="M181.77 144.668C182.121 144.809 182.52 144.622 182.658 144.261C182.796 143.899 182.623 143.49 182.269 143.356C178.021 141.678 176.065 138.027 176.364 132.213C176.388 131.826 176.092 131.497 175.713 131.477C175.334 131.456 175.012 131.756 174.995 132.146C174.667 138.525 176.931 142.757 181.77 144.668Z" fill="#BFD8E6"/>
    <path d="M186.764 138.975C191.055 141.918 195.837 141.433 200.935 137.59C201.248 137.358 201.309 136.919 201.088 136.62C200.859 136.317 200.424 136.267 200.114 136.492C195.468 139.995 191.347 140.415 187.574 137.828C187.26 137.616 186.829 137.695 186.606 138.012C186.379 138.335 186.451 138.763 186.764 138.975Z" fill="#BFD8E6"/>
    <path d="M166.787 191.243C174.321 196.355 182.754 195.427 191.845 188.577C192.301 188.23 192.41 187.599 192.074 187.157C191.745 186.72 191.106 186.643 190.642 186.985C182.224 193.323 174.752 194.146 167.989 189.562C167.528 189.253 166.891 189.377 166.56 189.844C166.227 190.302 166.325 190.934 166.787 191.243Z" fill="#BFD8E6"/>
    <path d="M152.409 238.52C159.943 243.632 168.376 242.705 177.467 235.854C177.923 235.507 178.032 234.876 177.696 234.435C177.366 233.997 176.727 233.92 176.264 234.263C167.845 240.6 160.374 241.423 153.611 236.839C153.149 236.53 152.513 236.654 152.182 237.122C151.856 237.583 151.954 238.215 152.409 238.52Z" fill="#BFD8E6"/>
    <path d="M122.87 279.532C122.513 280.203 122.754 281.029 123.411 281.378C124.069 281.728 125.778 281.939 126.135 281.268C127.364 276.672 129.901 270.742 133.749 263.488C139.521 252.598 155.473 237.838 160.613 225.152C165.754 212.467 165.659 183.101 172.203 163.258C177.527 147.108 189.312 136.332 195.601 128.373C201.887 120.421 206.691 109.46 206.479 109.347C206.225 109.212 201.243 120.422 194.944 128.023C188.646 135.624 178.66 144.755 172.539 157.717C161.147 181.836 164.728 209.964 156.552 225.341C148.376 240.718 138.216 247.653 130.231 260.847C124.92 269.626 122.458 275.858 122.87 279.532Z" fill="#BFD8E6"/>
    <defs>
        <linearGradient id="paint0_linear" x1="227.141" y1="-189.513" x2="-41.1749" y2="72.2676" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="#BCE4FE"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="59.0673" y1="92.7262" x2="75.3411" y2="267.584" gradientUnits="userSpaceOnUse">
            <stop stop-color="#84F0A8"/>
            <stop offset="1" stop-color="#48B676"/>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="212.435" y1="92.2976" x2="81.164" y2="115.577" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="1" stop-color="#E5EEFF"/>
        </linearGradient>
    </defs>
</svg>

</template>

<script>
export default {
  name: 'registration-trees-image',
};
</script>
