<template>
    <v-text-field readonly class="no-border" type="text" :rules="rules"
                  :placeholder="$t('public.registration.profile.fields.codename.placeholder')"
                  v-model="internalValue"/>
</template>

<script>

export default {
  name: 'registration-field-codename',
  props: {
    value: String,
  },
  data() {
    return {
      internalValue: this.value,
      loading: false,
      postValidateError: null,
      rules: [
        v => !!v || this.$t('common.errors.required'),
      ],
    };
  },
  watch: {
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
      }
    },
    internalValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  mounted() {
    if (!this.internalValue) {
      this.$emit('required-codename');
    }
  },
};
</script>

<style>
.no-border .v-input__slot::before {
  border-color: transparent !important;
}

.no-border .v-input__slot::after {
  border-color: transparent !important;
}

.no-border input {
  min-height: 50px;
  padding: 20px 10px;
  text-align: center;
  font-size: 1.6em;
  font-weight: 500;
  line-height: 40px;
}

.no-border input::-webkit-input-placeholder {
  color: var(--StarlingLightGrey);
  font-size: 1em !important;
}

.no-border input::-moz-placeholder {
  color: var(--StarlingLightGrey) !important;
  font-size: 1em !important;
}

.no-border input::placeholder {
  color: var(--StarlingLightGrey) !important;
  font-size: 1em !important;
}
</style>
