export default {
  title: 'Reset my password',
  content: {
    default: 'To reset your password, please follow the steps below. ',
    required: 'It looks like you haven’t verified your email address yet. To keep your account secure, please reset your password by following the steps below.',
    common: [
      'A confirmation code is on the way to: <strong>{email}</strong>',
      'You have 1 hour to enter the code before it expires. If you have multiple codes, please make sure to use the most recent one.',
      'Didn’t get a code?<br/><br/>' +
      '<ol><li>Verify the above email address is correct. If not, <a href="/landing/forgotten_password">click here</a></li><li>Check your spam or junk folder in your email.</li><li>If the code is not there, please email <a href="mailto:members@starlingminds.com?subject=Reset%20password%20code%20not%20received">members@starlingminds.com</a> for technical support.</li></ol>',
    ],
  },
  buttons: {
    set_password: 'Reset',
    new_reset_email: 'Send new Reset Password email',
  },
  fields: {
    code: {
      title: 'Enter the confirmation code. ',
    },
    password: {
      title: 'Enter a new password for your account.',
    },
  },
};
