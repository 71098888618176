export default {
  gender: {
    MALE: 'Hombre',
    FEMALE: 'Mujer',
    NON_BINARY: 'No binario',
    SELF_DESCRIBE: 'No listado',
  },
  durations: {
    none: 'duración incorrecta',
    seconds: { multiple: 'segundos', single: 'segundo' },
    minutes: { multiple: 'minutos', single: 'minuto' },
    hours: { multiple: 'horas', single: 'hora' },
    days: { multiple: 'días', single: 'día' },
    months: { multiple: 'meses', single: 'mes' },
    years: { multiple: 'años', single: 'año' },
  },
};
