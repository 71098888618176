import { Module } from 'vuex';
import { RootState } from '../../types';
import { HomeState, HomeStepTypeEnum, ICallout, IHome, IHomeDailyProgress, IHomeProduct, ITherapies } from './types';

const POPOVER_CONFIG = 'popoverConfigB';

const initialState: HomeState = {
  productHome: {
    products: [],
  },
  therapies: { recommended: [], discover: [], completed: [] },
  dailyProgress: undefined,
  callout: undefined,
  popoverConfig: undefined,
};

export const defaultState: HomeState = {
  productHome: {
    products: [],
  },
  therapies: { recommended: [], discover: [], completed: [] },
  dailyProgress: undefined,
  callout: undefined,
  popoverConfig: undefined,
};

export const home: Module<HomeState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    resetProductState(state) {
      Object.assign(state, initialState);
    },
    setProductHome(state, content) {
      state.productHome = content;
    },
    setTherapies(state, content) {
      state.therapies = content;
    },
    setDailyProgress(state, content) {
      state.dailyProgress = content;
    },
    setCallout(state, content) {
      state.callout = content;
    },
    setPopoverConfig(state, payload) {
      localStorage.setItem(payload.key, JSON.stringify(Array.from(new Set(payload.identifiers))));
      state.popoverConfig = payload;
    },
  },
  actions: {
    getCallout({ commit, dispatch, rootGetters }) {
      const callback = (res: ICallout) => {
        commit('setCallout', res);
        return res;
      };
      return dispatch('$apiRequest', [ '/home/callout', callback ], { root: true });
    },
    updateChosenCheckupDate({ commit, dispatch, rootGetters }, chosenCheckupDate: string) {
      const callback = (res: any) => {
        return res;
      };
      return dispatch('$apiRequest', [ '/home/chosenCheckupDate', callback, 'post', { date: chosenCheckupDate } ], { root: true });
    },
    getDailyProgress({ commit, dispatch, rootGetters }) {
      const callback = (res: IHomeDailyProgress) => {
        commit('setDailyProgress', res);
        if (res?.steps) {
          res.steps[res.steps.length - 1].isLast = true;
        }
        return res;
      };
      return dispatch('$apiRequest', [ '/home/progress', callback ], { root: true });
    },
    completeDailyStep({ commit, dispatch, rootGetters }, type: HomeStepTypeEnum) {
      const callback = () => {
        dispatch('getDailyProgress');
      };
      return dispatch('$apiRequest', [ `/home/progress`, callback, 'put', { type } ], { root: true });
    },
    getProductHome({ commit, dispatch, rootGetters }) {
      const callback = (res: IHome) => {
        commit('setProductHome', res);
        return res;
      };
      return dispatch('$apiRequest', [ '/home', callback ], { root: true });
    },
    getTherapies({ commit, dispatch }) {
      const callback = (res: ITherapies) => {
        commit('setTherapies', res);
        return res;
      };
      return dispatch('$apiRequest', [ '/home/therapies', callback ], { root: true });
    },
    getTherapyInfo({ commit, dispatch, rootGetters }, productId) {
      const callback = (res: IHomeProduct) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/home/therapy/${productId}`, callback ], { root: true });
    },
    getIntroduction({ commit, dispatch, rootGetters }) {
      const callback = (res: any) => {
        return res;
      };
      return dispatch('$apiRequest', [ '/home/introduction', callback ], { root: true });
    },
    getPopoverConfig({ commit }, userId) {
      if (!userId) return;
      const key = `${userId}_${POPOVER_CONFIG}`;
      const config = localStorage.getItem(key);
      const identifiers = config ? JSON.parse(config) : [];
      commit('setPopoverConfig', { key, identifiers });
    },
    addPopoverConfig({ commit, state }, identifier) {
      if (!state.popoverConfig) return;
      const key = state.popoverConfig.key;
      const identifiers = state.popoverConfig.identifiers || [];
      identifiers.push(identifier);
      if (identifiers.length > 50) identifiers.shift();
      commit('setPopoverConfig', { key, identifiers });
    },
  },
  getters: {
    productHome(state) {
      return state.productHome;
    },
    dailyProgress(state) {
      return state.dailyProgress;
    },
    callout(state) {
      return state.callout;
    },
    visibleCallout(state) {
      if (!state.popoverConfig?.identifiers || !state.callout) return;
      return state.popoverConfig.identifiers.includes(state.callout.identifier) ? undefined : state.callout;
    },
  },
};
