import { Module } from 'vuex';
import { RootState } from '@/scripts/store/types';
import { JournalState, IJournal, IJournalPage } from './types';

const initialState: JournalState = {
  journals: [],
  totalJournals: 0,
  isPreviewMode: false,
};
export const journals: Module<JournalState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetJournalsState(state) {
      state.journals = [];
      state.totalJournals = 0;
      state.isPreviewMode = false;
    },
    setJournals(state, journalList: Array<IJournal>) {
      state.journals = journalList;
    },
    addMoreJournals(state, newJournals: Array<IJournal>) {
      state.journals = [ ...state.journals, ...newJournals ];
    },
    setTotalJournals(state, totalJournals: number) {
      state.totalJournals = totalJournals;
    },
    updateJournalInStore(state, updatedJournal: IJournal) {
      state.journals = state.journals.map(journal => {
        if (journal.id === updatedJournal.id) {
          return updatedJournal;
        }
        return journal;
      });
    },
    removeJournalCount(state) {
      state.totalJournals = state.totalJournals >= 0 ? state.totalJournals - 1 : 0;
    },
    setPreviewMode(state, isPreviewMode) {
      state.isPreviewMode = isPreviewMode;
    },
  },
  actions: {
    getJournals({ state, commit, dispatch }, { page = 0, sort = 'date', dir = 'ASC' }) {
      const callback = (res: IJournalPage) => {
        commit('addMoreJournals', res.content);
        commit('setTotalJournals', res.totalElements);
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/journals?page=${page}&size=10&sort=${sort}%2C${dir}`, callback ], { root: true });
    },
    getJournal({ commit, dispatch }, id: string) {
      const callback = (res: IJournal) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/journals/${id}`, callback ], { root: true });
    },
    createJournal({ commit, state, dispatch, rootGetters }, journal: IJournal) {
      const callback = (res: IJournal) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/journals`, callback, 'post', journal ], { root: true });
    },
    updateJournal({ commit, state, dispatch, rootGetters }, journal: IJournal) {
      const callback = (res: IJournal) => {
        commit('updateJournalInStore', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/journals/${journal.id}`, callback, 'put', journal ], { root: true });
    },
    deleteJournal({ commit, state, getters, dispatch }, id: string) {
      const callback = () => {
        state.journals = state.journals.filter(journal => journal.id !== id);
        commit('removeJournalCount');
      };
      return dispatch('$apiRequest', [ `/tools/journals/${id}`, callback, 'delete' ], { root: true });
    },
    resetJournals({ state, commit, dispatch }) {
      commit('resetJournalsState');
    },
  },
  getters: {
    journals(state): IJournal[] {
      return state.journals;
    },
    totalJournals(state): number {
      return state.totalJournals;
    },
    isPreviewMode(state): boolean {
      return state.isPreviewMode;
    },
  },
};
