var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "webinar-icon",
      staticStyle: {
        display: "inline",
        margin: "0",
        padding: "0",
        width: "auto"
      },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 30 30",
        fill: "none"
      }
    },
    [
      _c("path", {
        staticClass: "icon-content",
        attrs: {
          "fill-rule": "evenodd",
          d:
            "M1.172 26.182c.469.454 1.03.682 1.683.682H21.99c.653 0 1.207-.228 1.662-.682a2.262 2.262 0 0 0 .681-1.662v-8.65l5.2 5.156V8.796l-5.2 5.156V5.344c0-.654-.227-1.208-.681-1.662A2.262 2.262 0 0 0 21.989 3H2.855c-.653 0-1.214.227-1.683.682a2.227 2.227 0 0 0-.703 1.662V24.52c0 .653.234 1.207.703 1.662ZM12.4 14.165c.738 0 1.349-.249 1.832-.746.483-.497.724-1.1.724-1.811 0-.739-.241-1.35-.724-1.832-.483-.483-1.094-.725-1.833-.725-.738 0-1.349.242-1.832.725-.483.482-.724 1.093-.724 1.832 0 .71.241 1.314.724 1.811.483.497 1.094.746 1.832.746Zm-5.753 6.477h11.548v-.298c0-.938-.533-1.69-1.598-2.259-1.065-.568-2.464-.852-4.197-.852-1.705 0-3.09.284-4.155.852-1.066.568-1.598 1.321-1.598 2.259v.298Z",
          "clip-rule": "evenodd"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M6.648 20.642h11.548v-.298c0-.938-.533-1.69-1.598-2.259-1.065-.568-2.464-.852-4.197-.852-1.705 0-3.09.284-4.155.852-1.066.568-1.598 1.321-1.598 2.259v.298Zm5.753-6.477c.738 0 1.349-.249 1.832-.746.483-.497.724-1.1.724-1.811 0-.739-.241-1.35-.724-1.832-.483-.483-1.094-.725-1.832-.725-.74 0-1.35.242-1.833.725-.483.482-.724 1.093-.724 1.832 0 .71.241 1.314.724 1.811.483.497 1.094.746 1.833.746ZM2.855 26.864a2.333 2.333 0 0 1-1.683-.682 2.227 2.227 0 0 1-.703-1.662V5.344c0-.654.234-1.208.703-1.662A2.333 2.333 0 0 1 2.855 3H21.99c.653 0 1.207.227 1.662.682.454.454.681 1.008.681 1.662v8.608l5.2-5.157v12.23l-5.2-5.156v8.65c0 .654-.227 1.208-.681 1.663a2.262 2.262 0 0 1-1.662.682H2.855Zm0-1.321H21.99c.312 0 .56-.1.745-.299.185-.198.277-.44.277-.724V5.344c0-.284-.092-.526-.277-.725-.184-.199-.433-.298-.745-.298H2.855c-.312 0-.568.1-.767.298a.986.986 0 0 0-.298.725V24.52c0 .284.1.526.298.724.199.2.455.299.767.299Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }