var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "align-center": "" } },
    [
      _c(
        "v-flex",
        { staticClass: "text-center", attrs: { xs3: "" } },
        [
          _c(
            "v-btn",
            { attrs: { icon: "", large: "" }, on: { click: _vm.onPrevious } },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v("mdi-chevron-left")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { staticClass: "text-center", attrs: { xs6: "" } },
        [
          _vm.hasSlot
            ? _vm._t("default")
            : [
                _vm.$te("app.avatar.widget." + _vm.widgetType + ".icon")
                  ? _c("v-icon", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.avatar.widget." + _vm.widgetType + ".icon"
                          )
                        )
                      )
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "starling-body" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "app.avatar." +
                          _vm.widgetType +
                          "." +
                          _vm.innerValue +
                          ".name"
                      )
                    )
                  )
                ])
              ]
        ],
        2
      ),
      _c(
        "v-flex",
        { staticClass: "text-center", attrs: { xs3: "" } },
        [
          _c(
            "v-btn",
            { attrs: { icon: "", large: "" }, on: { click: _vm.onNext } },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v("mdi-chevron-right")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }