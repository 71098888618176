export default {

  widget: {
    background: {
      name: 'Arrière-plan',
    },
    body: {
      name: 'Corps',
    },
    eyes: {
      name: 'Yeux',
      icon: 'mdi-eye',
    },
    beak: {
      name: 'Bec',
      icon: 'mdi-eye',
    },
  },
  body: {
    crow: {
      name: 'Corbeau',
    },
    duck: {
      name: 'Canard',
    },
    eagle: {
      name: 'Aigle',
    },
    heron: {
      name: 'Héron',
    },
    owl: {
      name: 'Chouette',
    },
    plover: {
      name: 'Pluvier',
    },
    sparrow: {
      name: 'Moineau',
    },
    toucan: {
      name: 'Toucan',
    },
  },
  eyes: {
    standard: {
      name: 'En face',
    },
    left: {
      name: 'À gauche ',
    },
    right: {
      name: 'À droite',
    },
    closed: {
      name: 'Fermés',
    },
  },
  customize: {
    title: 'Personnalisez votre avatar',
    tagline: 'Ces informations vous représenterons dans la communauté.',
  },
  unsaved: {
    title: 'Modifications non sauvegardées',
    content: 'Si vous continuez, les modifications faites sur votre avatar ne seront pas sauvegardées.',
  },
};
