export default {
  enter_starling: 'Entrar en Starling',
  ok: 'OK',
  cancel: 'Cancelar',
  save: 'Guardar',
  start: 'Iniciar',
  edit: 'Editar',
  delete: 'Eliminar',
  close: 'Cerrar',
  skip: 'Saltar',
  next: 'Siguiente',
  back: 'Volver',
  send: 'Enviar',
  search: 'Buscar',
  complete: 'Completar',
  pause: 'Pausar',
  resume: 'Continuar',
  duplicate: 'Créer une copie',
  pin: 'Anclar',
  unpin: 'Desanclar',
  logout: 'Cerrar la sesión',
  open_transcript: 'Abrir la transcripción',
  close_transcript: 'Cerrar la transcripción',
  language: 'Idioma',
  load_more: 'Cargar más',
  report: 'Denunciar',
  reply: 'Responder',
  post: 'Publicar',
  edit_response: 'Editar respuesta',
  change_moods: 'Cambiar la selección del estado de ánimo',
  termsOfUse: 'Condiciones de Uso',
  privacyPolicy: 'Política de Privacidad',
  communityGuidelines: 'Directrices de la comunidad',
  great: '¡Genial!',
  saving: 'Guardando en progreso...',
  optional: 'Opcional',
  add_situation: 'Añade tu situación',
  create_email: 'Crear correo electrónico',
  or: 'o',
  turn_off: 'Desactivar',
  continue: 'Continuar',
  hide: 'Ocultar',
  block: 'Bloquear',
  yes: 'Sí',
  no: 'No',
  done: 'Terminado',
  recording: 'Grabación en progreso...',
};
