var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    ref: "field",
    class: { hidden: _vm.migrationEmail },
    attrs: {
      autocomplete: "off",
      label: _vm.$t("public.registration.accessCode.fields.accessCode.label"),
      name: "access_code",
      rules: _vm.rules,
      "error-messages": _vm.loading
        ? _vm.$t("common.errors.loading")
        : _vm.postValidateError,
      hint: _vm.$t("public.registration.accessCode.fields.accessCode.hint"),
      "persistent-hint": "",
      color: _vm.loading ? "warning" : "primary",
      loading: _vm.loading,
      type: "text"
    },
    on: { input: _vm.validateForm },
    model: {
      value: _vm.internalValue,
      callback: function($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }