import { render, staticRenderFns } from "./logout-icon.vue?vue&type=template&id=de8e1586&scoped=true&"
import script from "./logout-icon.vue?vue&type=script&lang=js&"
export * from "./logout-icon.vue?vue&type=script&lang=js&"
import style0 from "./logout-icon.vue?vue&type=style&index=0&id=de8e1586&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de8e1586",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-dev-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de8e1586')) {
      api.createRecord('de8e1586', component.options)
    } else {
      api.reload('de8e1586', component.options)
    }
    module.hot.accept("./logout-icon.vue?vue&type=template&id=de8e1586&scoped=true&", function () {
      api.rerender('de8e1586', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/icons/logout-icon.vue"
export default component.exports