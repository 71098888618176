<template>
  <div class="fixed-maintenance-bar" style="z-index: 5"
    :class="{
      'with-bottom-nav': $vuetify.breakpoint.smAndDown && !$route.meta.public,
      'mobile': $vuetify.breakpoint.smAndDown
    }">
    <v-alert v-if="$route.name!=='maintenance'"
             class="ma-0 mx-1"
             color="warning"
             :value="true"
             :class="{
               'radius-15': rounded,
               'pa-2': !$vuetify.breakpoint.smAndDown,
               'px-2 py-1': $vuetify.breakpoint.smAndDown
             }">
      <v-icon v-if="$vuetify.breakpoint.smAndDown" style="cursor: pointer;float:right;" @click="showContent=!showContent">
        {{ showContent ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
      </v-icon>
      <p class="starling-explanatory font-weight-bold StarlingDarkGrey--text mb-1">
        {{ $t('common.maintenance.notify.title') }}
      </p>
      <p class="starling-citation StarlingDarkGrey--text" :key="key" v-show="showContent">
        <span v-html="$t('common.maintenance.notify.message', {countdown, duration })"></span>
      </p>
    </v-alert>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'maintenanceBar',
  props: {
    rounded: Boolean,
  },
  data() {
    return { now: this.$moment(), key: 0, interval: null, polling: null, showContent: true };
  },
  computed: {
    maintenance() {
      return this.$store.getters.maintenance;
    },
    countdown() {
      if (!this.maintenance) return '';
      return Vue.filter('durationFormat')(this.$moment(this.maintenance.start).diff(this.now));
    },
    duration() {
      if (!this.maintenance) return '';
      return Vue.filter('durationFormat')(this.$moment.duration(this.maintenance.duration));
    },
  },
  watch: {
    maintenance() {
      this.now = this.$moment();
      this.$forceUpdate();
      this.key++;
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.now = this.$moment();
      this.$forceUpdate();
      this.key++;
      if (this.maintenance && this.now.isSame(this.$moment(this.maintenance.start), 'second')) this.ping();
    }, 1000);

    this.polling = setInterval(() => {
      this.ping();
    }, 60000);
    this.ping();
  },
  mounted() {

  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.polling);
  },
  methods: {
    ping() {
      this.$store.dispatch('ping').then(res => {
        this.pingOK = res.status === 204;
      });
    },
  },
};
</script>

<style lang="scss">
.fixed-maintenance-bar {
  position: fixed;
  right: 0;
  bottom: 10px;

  &:not(.mobile) {
    max-width: 65%;
  }

  .v-alert {
    border: none;
    display: block;
  }
}
</style>
