import { Module } from 'vuex';
import { RootState } from '../../types';
import { WebinarRegistrant, WebinarState } from './types';

// Default state
export const defaultState: WebinarState = {
  webinars: [],
  currentWebinar: null,
};

export const webinar: Module<WebinarState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    SET_WEBINARS(state, webinars: WebinarRegistrant[]) {
      state.webinars = webinars;
    },
    SET_CURRENT_WEBINAR(state, webinar: WebinarRegistrant | null) {
      state.currentWebinar = webinar;
    },
  },
  actions: {
    getAvailableWebinars({ commit, dispatch }) {
      const callback = (res: WebinarRegistrant[]) => {
        commit('SET_WEBINARS', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/webinar`, callback ], { root: true });
    },
    checkRegistrationWebinar({ commit, dispatch }, webinarId: string) {
      const callback = (res: WebinarRegistrant) => {
        commit('SET_CURRENT_WEBINAR', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/webinar/${webinarId}`, callback ], { root: true });
    },
    registerWebinar({ commit, dispatch }, webinarId: string) {
      const callback = (res: WebinarRegistrant) => {
        commit('SET_CURRENT_WEBINAR', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/webinar/${webinarId}`, callback, 'post' ], { root: true });
    },
    unregisterWebinar({ commit, dispatch }, webinarId: string) {
      return dispatch('$apiRequest', [ `/webinar/${webinarId}`, null, 'delete' ], { root: true });
    },
    getBanneredWebinar({ commit, dispatch }) {
      const callback = (res: WebinarRegistrant[]) => {
        commit('SET_CURRENT_WEBINAR', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/webinar/_bannered_webinar`, callback ], { root: true });
    },
  },
  getters: {
    webinars: (state) => state.webinars,
    currentWebinar: (state) => state.currentWebinar,
  },
};
