import { Module } from 'vuex';
import { RootState } from '@/scripts/store/types';
import { GoalState, IGoalGroup, IGoalSetter } from './types';

const intialState: GoalState = {
  goals: {},
};

export const defaultState: GoalState = {
  ...intialState,
};

export const goals: Module<GoalState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    resetGoalsState(state) {
      Object.assign(state, intialState);
    },
    goals(state, goalGroup: IGoalGroup) {
      state.goals = goalGroup;
    },
  },
  actions: {
    // API
    getGoals({ commit, state, dispatch }) {
      const callback = (res: IGoalGroup) => {
        commit('goals', res);
        return res;
      };
      return dispatch('$apiRequest', [ '/tools/goals', callback ], { root: true });
    },
    getGoal({ commit, dispatch }, id: string) {
      const callback = (res: IGoalSetter) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/goals/${id}`, callback ], { root: true });
    },
    getPinnedGoal({ commit, dispatch }) {
      const callback = (res: IGoalSetter) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/goals/pinned`, callback ], { root: true });
    },
    createGoal({ commit, state, dispatch, rootGetters }, goal: IGoalSetter) {
      const callback = (res: IGoalSetter) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/goals`, callback, 'post', goal ], { root: true });
    },
    editGoal({ commit, state, dispatch, rootGetters }, goal: IGoalSetter) {
      const callback = (res: IGoalSetter) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/goals/${goal.id}`, callback, 'put', goal ], { root: true });
    },
    pinGoal({ commit, state, dispatch }, goal: IGoalSetter) {
      const callback = (res: IGoalSetter) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/goals/${goal.id}/_pin`, callback, 'put' ], { root: true });
    },
    unpinGoal({ commit, state, dispatch }, goal: IGoalSetter) {
      const callback = (res: IGoalSetter) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/goals/${goal.id}/_unpin`, callback, 'put' ], { root: true });
    },
    pauseGoal({ commit, state, dispatch }, goal: IGoalSetter) {
      const callback = (res: IGoalSetter) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/goals/${goal.id}/_pause`, callback, 'put' ], { root: true });
    },
    resumeGoal({ commit, state, dispatch }, goal: IGoalSetter) {
      const callback = (res: IGoalSetter) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/goals/${goal.id}/_resume`, callback, 'put' ], { root: true });
    },
    completeGoal({ commit, state, dispatch }, goal: IGoalSetter) {
      const callback = (res: IGoalSetter) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/goals/${goal.id}/_complete`, callback, 'put' ], { root: true });
    },
    deleteGoal({ commit, state, getters, dispatch }, id: string) {
      const callback = () => {};
      return dispatch('$apiRequest', [ `/tools/goals/${id}`, callback, 'delete' ], { root: true });
    },
  },
  getters: {
    goals(state): IGoalGroup {
      return state.goals;
    },
  },
};
