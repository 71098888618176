<template>
<svg width="206" height="68" viewBox="0 0 206 68" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.88521 12.2552C8.88521 9.33067 11.9596 8.13931 14.7118 8.13931C17.0701 8.13931 19.4744 9.11756 21.0065 10.4219C21.1344 10.5297 21.3236 10.5092 21.4183 10.3731L24.8559 5.44335C24.9429 5.31753 24.9147 5.14037 24.7894 5.0505C21.3057 2.524 17.8144 1.64075 14.0647 1.64075C7.80584 1.64075 1.60325 5.54091 1.60325 12.3091C1.60325 16.5867 3.97686 19.1851 6.94387 20.9182C12.0134 23.8966 19.0268 24.4923 19.0268 29.3656C19.0268 32.1257 16.7606 34.131 13.525 34.131C10.1769 34.131 6.98223 32.4518 4.82348 30.1769C4.7135 30.0614 4.53189 30.0588 4.41935 30.1744L0.0788218 34.7318C-0.0311621 34.8474 -0.0260466 35.0322 0.0941684 35.1401C3.93593 38.596 8.55015 40.6296 13.5276 40.6296C20.3235 40.6296 26.3113 35.972 26.3113 29.2577C26.3087 16.4788 8.88521 18.3737 8.88521 12.2552Z"
        :fill="'url(#'+id+'paint0_linear)'"/>
  <path d="M45.0676 34.6677C44.932 34.3647 44.6225 34.1824 44.2951 34.226C43.671 34.3108 42.8142 34.3878 42.1057 34.3878C40.3792 34.3878 39.2461 33.8999 38.5453 33.0886C37.9519 32.3851 37.6833 31.4094 37.6833 29.7867V19.7731H43.2516C43.5508 19.7731 43.8168 19.5933 43.9422 19.3186C44.7351 17.5547 45.7889 15.9371 47.055 14.5095C47.2954 14.2399 47.1061 13.8163 46.748 13.8163H37.6833V8.23947C37.6833 7.75676 37.205 7.42298 36.7548 7.5873L32.0332 9.32042C31.2147 9.62083 30.6699 10.4014 30.6699 11.2769V14.5583V20.5151V28.9702C30.6699 32.706 31.2633 35.0348 32.6113 36.8219C34.3915 39.2046 37.4659 40.6142 41.6734 40.6142C43.0239 40.6142 44.3079 40.4293 46.0421 40.0724C46.6381 39.9492 47.1905 39.8336 47.6816 39.7104C47.9323 39.6462 48.0218 39.3432 47.8555 39.1455C46.7301 37.7975 45.7889 36.2929 45.0676 34.6677Z"
        :fill="'url(#'+id+'paint1_linear)'"/>
  <path d="M124.34 8.92505C126.348 8.6041 127.934 6.9326 128.105 4.89907C128.358 1.88729 125.762 -0.420976 122.734 0.0642986C120.723 0.385247 119.138 2.05418 118.966 4.09028C118.713 7.09949 121.312 9.41032 124.34 8.92505Z"
        :fill="'url(#'+id+'paint2_linear)'"/>
  <path d="M147.59 13.8291H146.47C141.991 13.8291 138.809 15.4544 136.651 18.0528C135.032 19.9836 134.159 22.4126 134.057 26.4154H134.047V28.2153C134.006 30.5852 134.014 32.2028 133.883 33.6149C133.804 34.4956 131.811 34.3313 129.937 34.0566C128.993 33.9179 127.645 33.4943 126.576 33.0835H126.565V32.1232V14.5018C126.565 14.1192 126.256 13.8086 125.875 13.8086H120.245C119.864 13.8086 119.555 14.1192 119.555 14.5018V33.086C114.181 34.6009 111.569 34.3852 111.569 34.3852C109.843 34.3852 108.71 33.8974 108.009 33.086C107.415 32.3825 107.147 31.4068 107.147 29.7841V6.55513V2.67807C107.147 2.19537 106.669 1.85901 106.216 2.02591L101.492 3.76673C100.676 4.06714 100.131 4.84769 100.131 5.72067V6.55513V28.9702C100.131 32.706 100.724 35.0348 102.072 36.8219C103.852 39.2046 106.927 40.6142 111.134 40.6142C111.134 40.6142 111.457 40.6527 112.441 40.5551C112.886 40.5166 113.344 40.4601 113.828 40.3831C115.628 40.1212 118.534 39.582 123.107 38.4805C129.829 40.9043 133.175 40.627 133.175 40.627C137.71 40.627 140.636 39.22 140.799 34.3647C140.799 34.3287 140.93 31.548 140.966 29.7559V29.7507C140.971 29.4606 140.976 29.1936 140.976 28.9702H140.966V26.9829C140.966 24.3845 141.559 22.7669 142.528 21.7938C143.447 20.8746 144.848 20.3328 146.467 20.3328H147.588C149.207 20.3328 150.608 20.8746 151.527 21.7938C152.496 22.7695 153.092 24.1226 153.092 26.721V39.9441C153.092 40.3266 153.401 40.6373 153.783 40.6373H159.412C159.793 40.6373 160.103 40.3266 160.103 39.9441V27.2627C160.103 22.7669 159.131 20.1146 157.404 18.0579C155.251 15.4544 152.066 13.8291 147.59 13.8291Z"
        :fill="'url(#'+id+'paint3_linear)'"/>
  <path d="M205.973 11.9984C205.973 11.9984 204.441 12.6634 202.362 12.9458C201.108 13.1153 197.64 13.5749 195.709 13.8137C192.113 14.2604 189.882 15.6803 187.414 18.6331C184.444 15.5263 180.874 13.8291 177.237 13.8291C175.362 13.8291 173.492 14.2682 171.684 15.1334C165.323 18.1786 162.655 24.215 164.361 31.6892L164.384 31.7765C164.422 31.8972 165.325 34.7806 167.024 36.4341C167.868 37.2558 169.74 38.9632 171.456 40.514C171.07 40.835 170.681 41.1688 170.295 41.518C168.745 42.9122 167.205 44.5349 165.972 46.4631C163.264 50.7022 162.709 55.5627 164.448 59.7992C166.085 63.7841 169.571 66.6392 174.014 67.6303C175.119 67.8768 176.249 68.0026 177.375 68.0026C183.49 68.0026 188.79 64.3746 190.56 58.9775C192.297 53.6806 191.11 48.6892 187.03 44.1472C184.797 41.6592 182.14 39.6975 179.567 37.7975C179.071 37.4304 178.574 37.0632 178.081 36.6935C175.876 35.0399 173.797 33.4788 172.318 31.3041C170.668 28.8726 170.638 26.0046 172.236 23.6296C173.75 21.3804 176.208 20.4817 178.723 20.8951C185.652 22.0325 187.414 29.2038 187.414 29.2038C189.21 21.9735 196.024 20.8746 196.024 20.8746C203.011 19.2801 204.774 15.2644 205.231 13.7444C205.439 13.0537 206.139 11.9856 205.973 11.9984ZM175.927 45.7981C176.367 45.4541 176.835 45.1151 177.334 44.7814C179.958 46.6223 182.35 48.5865 183.557 51.5367C184.785 54.5356 183.999 57.9274 181.649 59.7863C180.495 60.6978 179.017 61.2011 177.485 61.2011C176.019 61.2011 174.597 60.7595 173.374 59.925C170.717 58.1097 169.904 54.4406 171.438 51.2003C172.4 49.1745 173.85 47.4208 175.927 45.7981Z"
        :fill="'url(#'+id+'paint4_linear)'"/>
  <path d="M95.8878 12.8714C92.3171 12.9381 89.4703 13.8445 87.2578 15.0333C83.3087 17.1515 80.8788 21.3187 80.8788 25.812V29.8354C80.8583 31.3683 80.9453 31.489 80.843 32.5519C80.843 32.5519 80.5897 34.6266 76.9194 33.3556C74.7248 32.5442 74.6711 31.6379 74.5918 30.3849C74.4971 28.87 74.5867 28.2666 74.5764 27.1728C74.5764 27.1266 74.5841 27.0804 74.5841 27.0342C74.5841 18.1375 66.1026 11.2307 56.8153 14.0114C52.6027 15.2721 49.275 18.6151 48.0166 22.8413C45.244 32.1668 52.1269 40.6784 60.9896 40.6784C64.5474 40.6784 66.609 40.2136 70.0211 37.2095C71.8678 39.202 75.111 40.6296 79.8736 40.6296C79.8736 40.6296 81.7152 40.6912 83.6974 40.2419C87.9024 39.2893 87.7208 35.3506 87.7336 34.4211C87.7336 34.3801 87.7847 31.1808 87.7924 29.4529C87.7924 29.4426 87.7924 29.4298 87.7924 29.4195V26.5181C87.7924 23.8966 89.3961 21.5242 91.8465 20.6152C93.0282 20.1762 94.4043 19.8527 95.8954 19.8116C96.1231 19.8064 96.3047 19.6241 96.3047 19.3956V13.2848C96.3098 13.0562 96.118 12.8662 95.8878 12.8714ZM68.1846 27.7737C67.8495 31.1706 65.1153 33.9179 61.7313 34.2543C57.158 34.7113 53.347 30.8856 53.7997 26.2947C54.1348 22.8978 56.8716 20.1505 60.2529 19.8141C64.8237 19.3597 68.6347 23.1828 68.1846 27.7737Z"
        :fill="'url(#'+id+'paint5_linear)'"/>
  <defs>
    <linearGradient :id="id+'paint0_linear'" x1="0.934318" y1="21.1354" x2="205.066" y2="21.1354" gradientUnits="userSpaceOnUse">
      <stop stop-color="#009BFF"/>
      <stop offset="1" stop-color="#00CCFF"/>
    </linearGradient>
    <linearGradient :id="id+'paint1_linear'" x1="0.934542" y1="24.0796" x2="205.066" y2="24.0796" gradientUnits="userSpaceOnUse">
      <stop stop-color="#009BFF"/>
      <stop offset="1" stop-color="#00CCFF"/>
    </linearGradient>
    <linearGradient :id="id+'paint2_linear'" x1="0.933708" y1="4.49457" x2="205.066" y2="4.49457" gradientUnits="userSpaceOnUse">
      <stop stop-color="#009BFF"/>
      <stop offset="1" stop-color="#00CCFF"/>
    </linearGradient>
    <linearGradient :id="id+'paint3_linear'" x1="0.93415" y1="21.3087" x2="205.066" y2="21.3087" gradientUnits="userSpaceOnUse">
      <stop stop-color="#009BFF"/>
      <stop offset="1" stop-color="#00CCFF"/>
    </linearGradient>
    <linearGradient :id="id+'paint4_linear'" x1="0.933563" y1="39.9984" x2="205.065" y2="39.9984" gradientUnits="userSpaceOnUse">
      <stop stop-color="#009BFF"/>
      <stop offset="1" stop-color="#00CCFF"/>
    </linearGradient>
    <linearGradient :id="id+'paint5_linear'" x1="0.934388" y1="26.7759" x2="205.066" y2="26.7759" gradientUnits="userSpaceOnUse">
      <stop stop-color="#009BFF"/>
      <stop offset="1" stop-color="#00CCFF"/>
    </linearGradient>
  </defs>
</svg>

</template>

<script>
export default {
  name: 'starling-logo-colors',
  props: {
    id: {
      type: String,
      required: false,
      default: 'starling-logo',
    },
  },
};
</script>
