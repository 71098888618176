var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { on: { click: _vm.doPredefined } },
        [
          _c("v-select", {
            ref: "field",
            attrs: {
              label: _vm.$t("public.registration.profile.fields.gender.label"),
              placeholder: _vm.$t(
                "public.registration.profile.fields.gender.placeholder"
              ),
              disabled:
                !_vm.itemList ||
                !_vm.itemList.length ||
                _vm.internalValue.type === "skipped",
              "persistent-hint": "",
              items: _vm.itemList,
              "error-messages":
                !_vm.itemList || !_vm.itemList.length ? "empty hint" : null,
              "item-text": "text",
              "item-value": "value",
              rules: _vm.internalValue.type === "skipped" ? [] : _vm.rules
            },
            on: { change: _vm.onChange },
            model: {
              value: _vm.internalValue.value,
              callback: function($$v) {
                _vm.$set(_vm.internalValue, "value", $$v)
              },
              expression: "internalValue.value"
            }
          })
        ],
        1
      ),
      _vm.internalValue.type === "custom"
        ? _c("v-text-field", {
            attrs: {
              label: _vm.$t(
                "public.registration.profile.fields.gender.custom.label"
              ),
              placeholder: _vm.$t(
                "public.registration.profile.fields.gender.custom.placeholder"
              ),
              hint: _vm.$t(
                "public.registration.profile.fields.gender.custom.hint"
              ),
              name: "self_describe",
              rules: _vm.rules,
              "persistent-hint": "",
              type: "text"
            },
            model: {
              value: _vm.internalValue.custom,
              callback: function($$v) {
                _vm.$set(_vm.internalValue, "custom", $$v)
              },
              expression: "internalValue.custom"
            }
          })
        : _vm._e(),
      _c(
        "a",
        {
          staticClass: "starling-text",
          class: {
            "text--disabled": _vm.internalValue.type !== "skipped",
            "font-weight-bold": _vm.internalValue.type === "skipped"
          },
          staticStyle: { display: "block" },
          on: { click: _vm.doSkip }
        },
        [
          _vm._v(
            _vm._s(
              _vm.$t("public.registration.profile.fields.gender.skip.label")
            )
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }