var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      directives: [
        {
          name: "touch",
          rawName: "v-touch",
          value: { right: _vm.swipeLeftRight },
          expression: "{ right: swipeLeftRight }"
        }
      ],
      staticClass: "app-main",
      attrs: { id: "app" }
    },
    [
      _vm.isLoadingProgress
        ? _c("starling-loading")
        : [
            _c("router-view"),
            _vm.enableThemeEditor
              ? [
                  _c(
                    "primary-button",
                    {
                      staticClass: "white--text font-weight-bold",
                      attrs: {
                        small: "",
                        color: "primary--gradient",
                        fab: "",
                        fixed: "",
                        top: "",
                        right: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.openThemeEditor = true
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-palette")])],
                    1
                  ),
                  _c("theme-editor", {
                    model: {
                      value: _vm.openThemeEditor,
                      callback: function($$v) {
                        _vm.openThemeEditor = $$v
                      },
                      expression: "openThemeEditor"
                    }
                  })
                ]
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }