var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        ref: "field",
        attrs: {
          autocomplete: "new-password",
          label: _vm.label
            ? _vm.label
            : _vm.$t("public.registration.account.fields.password.label"),
          placeholder: _vm.label
            ? ""
            : _vm.$t("public.registration.account.fields.password.placeholder"),
          hint: _vm.$t("public.registration.account.fields.password.hint"),
          name: _vm.name,
          rules: _vm.rules,
          "persistent-hint": "",
          "append-icon": _vm.show
            ? "$vuetify.icons.starling_eye_opened"
            : "$vuetify.icons.starling_eye_closed",
          type: _vm.show ? "text" : "password",
          disabled: _vm.disabled
        },
        on: {
          "click:append": function($event) {
            _vm.show = !_vm.show
          }
        },
        model: {
          value: _vm.internalValue,
          callback: function($$v) {
            _vm.internalValue = $$v
          },
          expression: "internalValue"
        }
      }),
      _c(
        "ul",
        { staticStyle: { "list-style-type": "none", "padding-left": "0" } },
        _vm._l(_vm.passwordRules, function(rule, index) {
          return _c(
            "li",
            { key: index },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-baseline": "", "gap-xs-1": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "", "d-flex": "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "rule",
                          class: { active: _vm.isValid(rule.pattern) },
                          attrs: { size: "18" }
                        },
                        [_vm._v("mdi-check-circle")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticStyle: { "line-height": "130%" },
                      attrs: { grow: "" }
                    },
                    [_c("small", [_vm._v(_vm._s(_vm.$t(rule.label)))])]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }