<template>
  <div v-if="!firstLoad">
    <div v-for="filterItem in filterItems" :key="filterItem.id">
      <p v-if="filterItem.question" class="starling-text mt-2 font-weight-medium">
        {{ filterItem.question }}
      </p>
      <v-autocomplete
        :class="!filterItem.question ? 'mt-2' : 'mt-0'"
        clearable
        item-text="text"
        item-value="value"
        persistent-hint
        :hint="filterItem.required ? $t('public.registration.profile.fields.businessUnit.hint') : ''"
        :items="filterItem.sublevels"
        :label="$t('public.registration.profile.fields.businessUnit.label')"
        :loading="loadingFilters"
        :no-data-text="$t('public.registration.profile.fields.businessUnit.errors.search')"
        :error-messages="(!filterItem.sublevels || !filterItem.sublevels.length)? $t('public.registration.profile.fields.businessUnit.errors.loading') : null"
        :placeholder="filterItem.label"
        :rules="filterItem.required ? rules : []"
        @change="onSelectFilter(filterItem, $event)">
        <template v-slot:item="{ parent, item }">
          <template>
            <v-list-tile-content>
              <v-list-tile-title v-if="item.value === notListedOption.value" class="grey--text">{{ item.text }}</v-list-tile-title>
              <v-list-tile-title v-else><div v-html="`${parent.genFilteredText(item.text)}`"></div></v-list-tile-title>
            </v-list-tile-content>
          </template>
        </template>
      </v-autocomplete>
    </div>
    <div @click="doPredefined">
      <v-autocomplete
        ref="businessUnit"
        v-model="internalValue.value"
        class="mt-0"
        clearable
        item-text="text"
        item-value="value"
        persistent-hint
        :disabled="!itemList || !itemList.length || internalValue.type === 'skipped'"
        :error-messages="(!itemList || !itemList.length)? $t('public.registration.profile.fields.businessUnit.errors.loading') : null"
        :filter="customSearch"
        :hint="internalValue.type === 'skipped' ? '' : $t('public.registration.profile.fields.businessUnit.hint')"
        :items="itemList"
        :label="$t('public.registration.profile.fields.businessUnit.label')"
        :loading="loading"
        :no-data-text="$t('public.registration.profile.fields.businessUnit.errors.search')"
        :placeholder="label ? label : $t('public.registration.profile.fields.businessUnit.placeholder')"
        :rules="internalValue.type === 'skipped' ? [] : rules"
        :search-input.sync="search"
        @change="onChange">
        <template v-slot:item="{ parent, item }">
          <template>
            <v-list-tile-content>
              <v-list-tile-title v-if="item.value === notListedOption.value" class="grey--text">{{ item.text }}</v-list-tile-title>
              <v-list-tile-title v-else><div v-html="`${parent.genFilteredText(item.text)}`"></div></v-list-tile-title>
            </v-list-tile-content>
          </template>
        </template>
        <template v-slot:append-item>
          <v-list-tile v-if="!isLastPage" @click="loadMore">
            <v-list-tile-content>
              <v-list-tile-title class="grey--text">{{ $t('common.actions.load_more') }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-autocomplete>
    </div>

    <v-text-field v-if="internalValue.type === 'custom'"
                  v-model="internalValue.custom"
                  name="notListed"
                  class="mt-0"
                  persistent-hint
                  :hint="$t('public.registration.profile.fields.businessUnit.hint')"
                  :placeholder="$t('public.registration.profile.fields.businessUnit.notListedPlaceholder', { param: label })"
                  :rules="rules"
                  :type="'text'"/>

    <a v-if="enableNotListed" style="display:block;" class="starling-text" :class="internalValue.type === 'skipped' ? 'font-weight-bold' : 'text--disabled'" @click="doSkip">
      {{ $t('public.registration.profile.fields.businessUnit.skip.label') }}
    </a>
  </div>
  <v-progress-circular v-else :size="30" color="primary" indeterminate></v-progress-circular>
</template>

<script>
const PREDEFINED = 'predefined';
const CUSTOM = 'custom';
const SKIPPED = 'skipped';

export default {
  name: 'registration-field-business-unit',
  props: {
    value: Object,
    label: {
      type: String,
      required: false,
    },
    accessCode: {
      type: String,
      required: false,
    },
    accessCodeImprint: {
      type: String,
      required: false,
    },
    referralKey: {
      type: String,
      required: false,
    },
    enableNotListed: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      internalValue: this.value || { value: null, type: PREDEFINED, custom: null, filters: null },
      firstLoad: true,
      loading: true,
      itemList: [],
      loadingFilters: true,
      filterItems: null,
      selectedFilters: null,
      page: 0,
      isLastPage: false,
      search: null,
      timeoutId: null,
      rules: [
        v => !!v || this.$t('common.errors.required'),
      ],
      notListedOption: { value: 'NOT_LISTED', text: this.$t('public.registration.profile.fields.businessUnit.notListed') },
    };
  },
  watch: {
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
      }
    },
    internalValue: {
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue);
      },
    },
    search(searchValue) {
      this.querySelections(searchValue);
    },
  },
  mounted() {
    if (this.internalValue.type === CUSTOM || this.internalValue.custom) {
      if (!this.enableNotListed) {
        this.internalValue.value = null;
        this.internalValue.type = PREDEFINED;
        this.internalValue.custom = null;
      } else {
        this.itemList = [ this.notListedOption ];
        this.internalValue.value = this.notListedOption.value;
        this.internalValue.type = CUSTOM;
        this.isLastPage = true;
        this.firstLoad = false;
        this.loading = false;
        return;
      }
    }
    if (this.internalValue.value) {
      this.getBusinessUnits(null, this.internalValue.value);
    } else {
      this.getBusinessUnitFilters().then(() => {
        this.getBusinessUnits();
      });
    }
  },
  methods: {
    doSkip() {
      this.internalValue = { value: null, type: SKIPPED, custom: null, filters: this.selectedFilters ? Array.from(this.selectedFilters.values()) : null };
    },
    doPredefined() {
      if (this.internalValue.type === SKIPPED) {
        this.internalValue = { value: null, type: PREDEFINED, custom: null, filters: this.selectedFilters ? Array.from(this.selectedFilters.values()) : null };
      }
      this.$nextTick(() => {
        this.$refs.businessUnit.onClick();
      });
    },
    onChange(selected) {
      if (this.internalValue.type === CUSTOM && !selected) {
        this.getBusinessUnitFilters();
      }
      this.internalValue.type = selected === this.notListedOption.value ? CUSTOM : PREDEFINED;
      this.internalValue.custom = null;
    },
    customSearch (item, queryText, itemText) {
      const text = itemText.toLowerCase();
      const searchText = queryText.toLowerCase();

      return text.indexOf(searchText) > -1 || item.value === this.notListedOption.value;
    },
    querySelections (value) {
      if (value === this.notListedOption.text) return;
      if (!value) this.getBusinessUnitFilters();
      this.page = 0;
      this.getBusinessUnits(value);
    },
    loadMore() {
      if (this.isLastPage) return;
      this.page++;
      this.getBusinessUnits(this.search);
    },
    getBusinessUnits(searchValue, selected) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }

      this.loading = true;

      const sublevelFilters = this.selectedFilters ? Array.from(this.selectedFilters.values()).join(',') : null;

      this.timeoutId = setTimeout(() => {
        let apiCall = null;
        if (this.referralKey) {
          apiCall = this.$store.dispatch('generateBusinessUnitsWithReferralKey', { referralKey: this.referralKey, search: searchValue, page: this.page, sublevels: sublevelFilters, businessUnitId: selected });
        } else if (this.accessCodeImprint) {
          apiCall = this.$store.dispatch('generateBusinessUnitsWithAccessCodeImprint', { accessCodeImprint: this.accessCodeImprint, search: searchValue, page: this.page, sublevels: sublevelFilters, businessUnitId: selected });
        } else if (this.accessCode) {
          apiCall = this.$store.dispatch('generateBusinessUnitsWithAccessCode', { accessCode: this.accessCode, search: searchValue, page: this.page, sublevels: sublevelFilters, businessUnitId: selected });
        } else {
          return;
        }

        apiCall.then(res => {
          this.isLastPage = res.last;
          const items = this.page === 0 ? res.content : this.itemList.concat(res.content);
          if (this.enableNotListed && res.last) items.push(this.notListedOption);
          this.itemList = items;
        }).finally(() => {
          this.firstLoad = false;
          this.loading = false;
        });
      }, 500);
    },
    async getBusinessUnitFilters() {
      this.loadingFilters = true;

      const sublevelFilters = this.selectedFilters ? Array.from(this.selectedFilters.values()).join(',') : null;

      let apiResponse = null;
      if (this.referralKey) {
        apiResponse = await this.$store.dispatch('generateBusinessUnitFiltersWithReferralKey', { referralKey: this.referralKey, sublevels: sublevelFilters });
      } else if (this.accessCodeImprint) {
        apiResponse = await this.$store.dispatch('generateBusinessUnitFiltersWithAccessCodeImprint', { accessCodeImprint: this.accessCodeImprint, sublevels: sublevelFilters });
      } else if (this.accessCode) {
        apiResponse = await this.$store.dispatch('generateBusinessUnitFiltersWithAccessCode', { accessCode: this.accessCode, sublevels: sublevelFilters });
      } else {
        return;
      }
      this.filterItems = [ ...apiResponse ].map(level => {
        if (level.enableNotListed) {
          level.sublevels.push(this.notListedOption);
        }
        return level;
      });
      this.loadingFilters = false;
    },
    onSelectFilter(filterItem, selection) {
      if (!this.selectedFilters) {
        this.selectedFilters = new Map();
      }

      if (selection === undefined || selection === this.notListedOption.value) {
        this.selectedFilters.delete(filterItem.id);
      } else {
        this.selectedFilters.set(filterItem.id, selection);
      }

      this.$nextTick(() => {
        this.internalValue = { value: null, type: PREDEFINED, custom: null, filters: Array.from(this.selectedFilters.values()) };
        this.getBusinessUnitFilters().then(() => {
          this.getBusinessUnits();
        });
      });
    },
  },
};
</script>

<style scoped>

</style>
