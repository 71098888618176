export default {
  enter_starling: 'Entrer dans Starling',
  ok: 'D\'accord',
  cancel: 'Annuler',
  save: 'Sauvegarder',
  start: 'Commencer',
  edit: 'Modifier',
  delete: 'Supprimer',
  close: 'Fermer',
  skip: 'Sauter',
  next: 'Suivant',
  back: 'Retour',
  send: 'Envoyer',
  search: 'Rechercher',
  complete: 'Compléter',
  pause: 'Arrêter',
  resume: 'Continuer',
  duplicate: 'Dupliquer',
  pin: 'Épingler',
  unpin: 'Désépingler',
  logout: 'Fermer la session',
  open_transcript: 'Ouvrir la transcription',
  close_transcript: 'Fermer la transcription',
  language: 'Langue',
  load_more: 'Voir plus',
  report: 'Rapporter',
  reply: 'Répondre',
  post: 'Publier',
  edit_response: 'Modifier la réponse',
  change_moods: 'Modifer le choix de l\'humeur',
  termsOfUse: 'Conditions d\'utilisation',
  privacyPolicy: 'Politique de confidentialité',
  communityGuidelines: 'Règles de la communauté',
  great: 'Génial !',
  saving: 'Sauvegarde en cours...',
  optional: 'Facultatif',
  add_situation: 'Ajoutez votre situation',
  create_email: 'Générer l\'email',
  or: 'ou',
  turn_off: 'Désactiver',
  continue: 'Continuer',
  hide: 'Masquer',
  show: 'Montrer',
  block: 'Bloquer',
  yes: 'Oui',
  no: 'Non',
  done: 'Terminé',
  recording: 'Enregistrement en cours...',
};
