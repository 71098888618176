import { TopNavDesktopView, TopNavMobileView } from './common';
import tools from './tools';
import profile from './profile';
import favorites from './favorites';
import content from './content';
import products from './products';
import exercises from './exercises';
import community from './community';
import webinars from './webinars';
import store from '../store';
// import { REFERRAL_STORAGE_KEY } from '@/views/landing/setup_referral/SetupReferral';

const AppContentView = resolve => {
  require.ensure([ '@/views/AppContent.vue' ], () => {
    resolve(require('@/views/AppContent.vue'));
  }, 'appContent');
};
const HomeView = resolve => {
  require.ensure([ '@/views/home/Home.vue' ], () => {
    resolve(require('@/views/home/Home.vue'));
  }, 'home');
};
const ReferralAcceptanceView = resolve => {
  require.ensure([ '@/views/home/components/referral-acceptance.vue' ], () => {
    resolve(require('@/views/home/components/referral-acceptance.vue'));
  }, 'referral');
};

export default {
  path: '/app',
  meta: {
    public: false,
  },
  component: AppContentView,
  beforeEnter: async (to, from, next) => {
    if (store.state.identity.userIdentity === null) {
      await store.dispatch('identity/logout');
      next({ name: 'presentation' });
      return;
    }

    const refIdentity = await store.dispatch('identity/checkReferral', { email: store.state.identity.userIdentity.username });

    if (store.state.identity.userIdentity.location && store.state.identity.userIdentity.location['@type'] && store.state.identity.userIdentity.location['@type'] === 'nowhere') {
      if (refIdentity) {
        const referral = await store.dispatch('prepareReferralRegister', refIdentity.referralKey);
        if (typeof referral !== 'string' && (!referral.status || referral.status === 200)) {
          next({ name: 'referral_register', query: { referral_key: refIdentity.referralKey } });
          return;
        }
      }

      next({ name: 'setup_access_code' });
      return;
    }

    if (refIdentity && to.name !== 'referral') {
      const referral = await store.dispatch('prepareReferralRegister', refIdentity.referralKey);
      if (typeof referral !== 'string' && (!referral.status || referral.status === 200)) {
        next({ name: 'referral', query: { referral_key: refIdentity.referralKey } });
        return;
      }
    }
    if (refIdentity && to.name === 'referral') {
      const referral = await store.dispatch('prepareReferralRegister', refIdentity.referralKey);
      if (!(typeof referral !== 'string' && (!referral.status || referral.status === 200))) {
        next({ name: 'home' });
        return;
      }
    }
    await store.dispatch('getUser');
    await store.dispatch('favorites/getAllFavorites');
    await store.dispatch('comments/getCommentsStatusesByUser');
    if (to.path === '/app' || to.path === '/app/') {
      next({ name: 'home' });
    } else {
      next();
    }
  },
  children: [
    {
      path: '',
      redirect: 'home',
    },
    {
      path: 'home',
      name: 'home',
      components: {
        topNavDesktop: TopNavDesktopView,
        topNavMobile: TopNavMobileView,
        default: HomeView,
      },
      beforeEnter(to, from, next) {
        store.commit('navigation/displayLogo', true);
        store.commit('navigation/topDisableBackButton');
        store.commit('navigation/topHeader', 'app.home.header_title');
        next();
      },
      children: [
        {
          path: 'therapy/:uen',
          redirect: to => ({
            name: 'products-run',
            params: to.params,
          }),
        },
      ],
    },
    {
      path: 'referral',
      name: 'referral',
      components: {
        topNavDesktop: TopNavDesktopView,
        topNavMobile: TopNavMobileView,
        default: ReferralAcceptanceView,
      },
      props: {
        signIn: true,
      },
      beforeEnter(to, from, next) {
        store.commit('navigation/displayLogo', true);
        store.commit('navigation/topEnableBackButton');
        store.commit('navigation/topHeader', 'app.home.referral_acceptance.title');
        next();
      },
    },
    products,
    exercises,
    community,
    tools,
    content,
    profile,
    webinars,
    favorites,
  ],
};
