import Vue from 'vue';

const content = {
  actions: {
    // API
    getCategories({ dispatch }, id) {
      const callback = res => {
        Vue.$log.debug('Loaded content categories', res);
        return res;
      };

      return dispatch('$apiRequest', [ '/content/categories', callback ]);
    },
    getCategory({ dispatch }, id) {
      const callback = res => {
        Vue.$log.debug('Loaded category for id', id, res);
        return res;
      };
      return dispatch('$apiRequest', [ `/content/categories/${id}`, callback ]);
    },
    getCategoryVideos({ dispatch }, id) {
      const callback = res => {
        Vue.$log.debug('Loaded category videos for id', id, res);
        return res;
      };
      return dispatch('$apiRequest', [ `/content/categories/${id}/videos`, callback ]);
    },
    getVideo({ dispatch }, id) {
      const callback = res => {
        Vue.$log.debug('Loaded category videos for id', id, res);
        return res;
      };
      return dispatch('$apiRequest', [ `/content/videos/${id}`, callback ]);
    },
    getVideoByVimeoId({ dispatch }, vimeoId) {
      const callback = res => {
        Vue.$log.debug('Loaded videos from vimeoId', vimeoId, res);
        return res;
      };
      return dispatch('$apiRequest', [ `/content/videos/_findByVimeoId?vimeoId=${vimeoId}`, callback ]);
    },
    getVideoByIdentifier({ dispatch }, identifier) {
      const callback = res => {
        Vue.$log.debug('Loaded videos from identifier', identifier, res);
        return res;
      };
      return dispatch('$apiRequest', [ `/content/videos/_findByIdentifier?identifier=${identifier}`, callback ]);
    },
  },
};

export default content;
