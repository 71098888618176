export default {
  enter_starling: 'Enter Starling',
  ok: 'OK',
  cancel: 'Cancel',
  save: 'Save',
  start: 'Start',
  edit: 'Edit',
  delete: 'Delete',
  close: 'Close',
  skip: 'Skip',
  next: 'Next',
  back: 'Back',
  send: 'Send',
  search: 'Search',
  complete: 'Complete',
  pause: 'Pause',
  resume: 'Resume',
  duplicate: 'Duplicate',
  pin: 'Pin',
  unpin: 'Unpin',
  logout: 'Logout',
  open_transcript: 'Open transcript',
  close_transcript: 'Close transcript',
  language: 'Language',
  load_more: 'Load more',
  report: 'Report',
  reply: 'Reply',
  post: 'Post',
  edit_response: 'Edit response',
  change_moods: 'Change mood selection',
  termsOfUse: 'Terms of Use',
  privacyPolicy: 'Privacy Policy',
  communityGuidelines: 'Community Guidelines',
  great: 'Great!',
  saving: 'Saving in progress...',
  optional: 'Optional',
  add_situation: 'Add your situation',
  create_email: 'Create email',
  or: 'or',
  turn_off: 'Turn off',
  continue: 'Continue',
  hide: 'Hide',
  show: 'Show',
  block: 'Block',
  yes: 'Yes',
  no: 'No',
  done: 'Done',
  recording: 'Recording in progress...',
};
