var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    _vm._b(
      {
        staticClass:
          "starling-button-primary font-weight-bold white--text ma-0",
        attrs: { color: "primary--gradient", round: "" },
        on: {
          click: function($event) {
            return _vm.$emit("click", $event)
          }
        }
      },
      "v-btn",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }