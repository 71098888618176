import { BoilerplateView, TopNavDesktopView, TopNavMobileView } from '../common';
import store from '../../store';

const TherapiesView = resolve => {
  require.ensure([ '@/views/products/Therapies.vue' ], () => {
    resolve(require('@/views/products/Therapies.vue'));
  }, 'therapies');
};
const ProductPlayerView = resolve => {
  require.ensure([ '@/views/products/ProductPlayer.vue' ], () => {
    resolve(require('@/views/products/ProductPlayer.vue'));
  }, 'product');
};

export default {
  path: 'training',
  alias: 'therapies',
  components: {
    topNavDesktop: TopNavDesktopView,
    topNavMobile: TopNavMobileView,
    default: BoilerplateView,
  },
  beforeEnter(to, from, next) {
    store.commit('navigation/displayLogo', false);
    store.commit('navigation/topEnableBackButton');
    store.commit('navigation/topHeader', 'app.products.header_title');
    next();
  },
  children: [
    {
      name: 'therapies',
      path: '',
      component: TherapiesView,
      beforeEnter(to, from, next) {
        store.commit('navigation/displayLogo', false);
        store.commit('navigation/topDisableBackButton');
        store.commit('navigation/topHeader', 'app.products.header_title');
        next();
      },
    },
    {
      path: 'session',
      component: BoilerplateView,
      children: [
        {
          name: 'introduction',
          path: '',
          redirect: { name: 'products-run', params: { uen: process.env.VUE_APP_INTRO_UEN } },
        },
        {
          name: 'first_checkup',
          path: '',
          redirect: { name: 'products-run', params: { uen: process.env.VUE_APP_CHECK_UP_UEN } },
        },
        {
          name: 'checkup',
          path: '',
          redirect: { name: 'products-run', params: { uen: process.env.VUE_APP_CHECK_UP_UEN } },
        },
        {
          name: 'checkup-results',
          path: '',
          redirect: { name: 'products-run', params: { uen: process.env.VUE_APP_CHECK_UP_RESULT_UEN } },
        },
        {
          name: 'products-run',
          path: ':uen',
          component: ProductPlayerView,
          props: true,
          beforeEnter(to, from, next) {
            store.commit('navigation/topEnableBackButton');
            next();
          },
        },
      ],
    },
  ],
};
