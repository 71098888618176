export default {
  end: {
    loading: {
      title: 'Chargement',
      content: 'Veuillez patienter.',
    },
    success: {
      title: 'Connecté',
      content: 'Vous serez redirigé dans un instant.',
    },
    error: {
      title: 'Erreur',
      content: 'Une erreur inattendue s\'est produite. Veuillez réessayer. Vous serez redirigé dans un moment.',
    },
  },
  sign_out: {
    success: {
      title: 'Succès',
      content: 'Vous serez redirigé dans un instant.',
    },
  },
};
