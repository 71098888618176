<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    style="display: inline"
    viewBox="0 0 24 24"
    class="care-icon"
  >
    <rect class="icon-content" height="11" width="4" x="1" y="11" />
    <path
      class="icon-content"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16,3.25C16.65,2.49,17.66,2,18.7,2C20.55,2,22,3.45,22,5.3c0,2.27-2.91,4.9-6,7.7c-3.09-2.81-6-5.44-6-7.7 C10,3.45,11.45,2,13.3,2C14.34,2,15.35,2.49,16,3.25z"
    />
    <path
      class="icon-content"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20,17h-7l-2.09-0.73l0.33-0.94L13,16h2.82c0.65,0,1.18-0.53,1.18-1.18v0c0-0.49-0.31-0.93-0.77-1.11L8.97,11H7v9.02 L14,22l8.01-3v0C22,17.9,21.11,17,20,17z"
    />
    <path
      d="M16,13c3.09-2.81,6-5.44,6-7.7C22,3.45,20.55,2,18.7,2c-1.04,0-2.05,0.49-2.7,1.25C15.34,2.49,14.34,2,13.3,2 C11.45,2,10,3.45,10,5.3C10,7.56,12.91,10.19,16,13z M13.3,4c0.44,0,0.89,0.21,1.18,0.55L16,6.34l1.52-1.79 C17.81,4.21,18.26,4,18.7,4C19.44,4,20,4.56,20,5.3c0,1.12-2.04,3.17-4,4.99c-1.96-1.82-4-3.88-4-4.99C12,4.56,12.56,4,13.3,4z"
    />
    <path
      d="M19,16h-2c0-1.2-0.75-2.28-1.87-2.7L8.97,11H1v11h6v-1.44l7,1.94l8-2.5v-1C22,17.34,20.66,16,19,16z M3,20v-7h2v7H3z M13.97,20.41L7,18.48V13h1.61l5.82,2.17C14.77,15.3,15,15.63,15,16c0,0-1.99-0.05-2.3-0.15l-2.38-0.79l-0.63,1.9l2.38,0.79 c0.51,0.17,1.04,0.26,1.58,0.26H19c0.39,0,0.74,0.23,0.9,0.56L13.97,20.41z"
    />
  </svg>
</template>

<script>
export default {
  name: 'care-icon',
};
</script>

  <style scoped>
.care-icon {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0.3;
}

.care-icon .icon-content {
  fill: transparent;
  stroke: transparent;
}

.care-icon.activated,
.router-link-active .v-icon.care-icon {
  fill: transparent;
  stroke: transparent;
}

.care-icon.activated .icon-content,
.router-link-active .v-icon.care-icon .icon-content {
  fill: currentColor;
  stroke: transparent;
}
</style>
