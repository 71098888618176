import store from '@/scripts/store/index';

export default {
  header_title: 'Tools',
  title: 'What would you like to do today?',
  home_title: 'CBT Tools',
  home_subtitle: 'Once you\'ve learned the basics, use the CBT tools to refine your skills.',
  content: [
    {
      id: 'checkup',
      icon: '$vuetify.icons.starling_checkup',
      title: 'Check-Up',
      subtitle: 'Take a new Check-Up to track any changes in your mental fitness.',
      route: { name: 'products-run', params: { uen: process.env.VUE_APP_CHECK_UP_UEN } },
    },
    {
      id: 'checkup_results',
      title: 'My Progress',
      subtitle: 'Review your progress to uncover your patterns.',
      route: { name: 'products-run', params: { uen: process.env.VUE_APP_CHECK_UP_RESULT_UEN } },
      iconComponent: {
        name: 'checkup-results-radarchart',
        isValid: (props) => {
          return props && props.values && Object.keys(props.values).map(k => props.values[k].simplifiedValue).every(v => v !== 'NOT_EVALUATED');
        },
        propsLoader: () => {
          return store
            .dispatch('assessment/getMostRecentAssessments')
            .then((res) => {
              if (res.status >= 400) {
                return { values: {}, interpretations: {} };
              }
              return { values: res, interpretations: {} };
            });
        },
      },
    },
    {
      id: 'thought_balancer',
      icon: '$vuetify.icons.starling_thought_balancer',
      title: 'Thought Balancer',
      subtitle: 'Create balanced positions to manage worrisome or repetitive thoughts.',
      route: {
        name: 'thoughts-list',
      },
      routeLabel: 'View Thoughts',
      cardImage: 'thought_balancer.svg',
    },
    {
      id: 'goal_setter',
      icon: '$vuetify.icons.starling_goal_setter',
      title: 'Goal Setter',
      subtitle: 'Set SMART achievable goals and celebrate your successes.',
      route: {
        name: 'goals-list',
      },
      routeLabel: 'View Goals',
      cardImage: 'goal_setter.svg',
    },
    {
      id: 'journal',
      icon: '$vuetify.icons.starling_workbook',
      title: 'My Journal',
      subtitle: 'Keep a record of your reflections, ideas, and thoughts during your training.',
      route: {
        name: 'journals-list',
      },
      routeLabel: 'View Journal',
      cardImage: 'my_journal.svg',
    },
    {
      id: 'mood_tracker',
      icon: '$vuetify.icons.starling_mood_tracker',
      title: 'Mood Tracker',
      subtitle: 'Break unhelpful patterns by tracking your common triggers, moods, and reactions.',
      route: {
        name: 'moods-list',
      },
      routeLabel: 'View Moods',
      cardImage: 'mood_tracker.svg',
    },
  ],

  goal_setter: {
    header_title: 'Goal Setter',
    title_first: 'Set your first goal, track your progress, and make lasting changes.',
    title_other: 'Set better goals, track your progress, and make lasting changes.',
    actions: {
      save: 'Save Goal',
      create: 'Create Goal',
    },
    fields: {
      title: {
        label: 'My goal:',
        placeholder: 'Add a title',
        copy: 'Copy: ',
      },
      task: {
        title: 'Task <em>(optional)</em>:',
        placeholder: 'Add a task',
        guideContent: 'Nice goal! If you want to keep breaking it down, try making another goal.',
      },
      startDate: {
        title: 'Start time:',
      },
      reminder: {
        title: 'Reminder:',
        label: 'before my goal starts.',
        intervalOptions: [
          { value: 30, text: '30 minutes' },
          { value: 60, text: '1 hour' },
          { value: 1440, text: '1 day' },
        ],
        madal_title: 'Reminders',
        modal_description: 'By setting a reminder for this goal, your notification settings for the goal setter will be switched on in your <strong>Profile</strong>.',
      },
      repeat: {
        title: 'Repeat:',
        label: 'Every',
        frequency: [
          { value: 'DAY', text: 'day' },
          { value: 'WEEK', text: 'week' },
        ],
        weekDays: [
          { value: 'MONDAY', text: 'M', label: 'Monday' },
          { value: 'TUESDAY', text: 'T', label: 'Tuesday' },
          { value: 'WEDNESDAY', text: 'W', label: 'Wednesday' },
          { value: 'THURSDAY', text: 'T', label: 'Thursday' },
          { value: 'FRIDAY', text: 'F', label: 'Friday' },
          { value: 'SATURDAY', text: 'S', label: 'Saturday' },
          { value: 'SUNDAY', text: 'S', label: 'Sunday' },
        ],
      },
      pinned: {
        title: 'Pin to homepage:',
      },
    },
    sections: {
      started: 'Started goals',
      upcoming: 'Upcoming goals',
      paused: 'Paused goals',
      completed: 'Completed goals',
    },
    goal_card: {
      motivational: [
        'Every small step builds momentum - keep going!',
        'Welcome back! How did it go?',
        'You\'re doing great, keep it up!',
        'Progress is progress no matter how small it seems. Keep striving, you can do it!',
        'You got this! Keep working on your goal.',
        'Finding what works requires trial and error. It\'s not a failure to figure out it\'s not for you.',
        'It\'s not how much you do, it\'s how consistent you are in doing it that leads to bigger changes.',
        'Each step moves you in the right direction - keep going!',
      ],
      edit: 'Would you like to edit your goal?',
      delete: 'No problem! Please confirm to delete this goal.',
      pause_pt1: 'No problem! We\'ll stop any reminders for this goal and remove it from your homepage.',
      pause_pt2: 'Resume this goal any time from your <strong>Goal Setter</strong> in <strong>Tools</strong>.',
      complete: 'Would you like to mark this goal as completed?',
      duplicate: 'Great! We\'ll create a duplicate for you to continue editing.',
      pin_first: 'Your pinned goal will be on your homepage until you complete it, pin a different goal, pause it, or unpin it.',
      pin_replace: [
        'Pinning this goal will unpin your previous.',
        'You can always find your goals in <strong>Tools</strong>.',
      ],
      unpin: 'We\'ll remove this goal from your homepage. You can find it anytime in <strong>Tools</strong>.',
      content: {
        progress: 'Progress:',
        slider_progress: 'Progress',
        start: 'Start:',
        before: '{interval} before',
        reminder: 'Reminder:',
        repeat: 'Repeat:',
        daily: 'Daily',
        weekly: 'Weekly every {days}.',
      },
    },
    notifications: {
      errored: 'An error occured.',
    },
  },
  thought_balancer: {
    header_title: 'Thought Balancer',
    actions: {
      save: 'Save Balanced Thought',
    },
    tabs: {
      tool: {
        title: 'Balance thoughts',
        motivational: 'Take control of unhelpful thoughts by creating more balanced positions.',
        fields: {
          initial: {
            title: 'What is your thought?',
            subtitle: 'If your thought is a question, rewrite it as a statement.',
            label: 'Initial Thought',
            helperText: 'E.g., I should be doing more.',
          },
          factsFor: {
            title: 'What are the facts for?',
            subtitle: 'Only add things that are 100% true.',
            label: 'Supporting Facts',
            helperText: 'E.g., I’m behind on my work.',
          },
          factsAgainst: {
            title: 'What are the facts against?',
            subtitle: 'Think creatively to make new connections in your brain.',
            label: 'Facts Against',
            helperText: 'E.g., My colleagues tell me that I’m a very efficient person.',
          },
          balanced: {
            title: 'Create your balanced position:',
            subtitle: 'Write a more balanced position considering both the facts for and the facts against.',
            label: 'Balanced Thought',
            helperText: 'E.g., I’m as efficient as I can be. I can’t do any more without sacrificing my health.',
          },
        },
        buttons: {
          add_another: '+ add another',
        },
      },
      history: {
        title: 'Thoughts history',
        motivational: 'Here are your balanced positions:',
        empty: {
          title: 'You don\'t have any thoughts entered yet.',
          subtitle: 'Select the Balance Thoughts tab to get started.',
        },
      },
    },
    thought_card: {
      balanced: 'Balanced thought',
      initial: 'Initial thought',
      facts_for: 'Facts for',
      facts_against: 'Facts against',
      edit: 'Would you like to edit your balanced thought?',
      delete: 'Are you sure you want to delete this balanced thought?',
      see_more: 'See more',
      see_less: 'See less',
    },
    notifications: {
      created: 'Balanced thought created',
      updated: 'Balanced thought updated',
      deleted: 'Balanced thought deleted',
    },
  },
  mood_tracker: {
    header_title: 'Mood Tracker',
    actions: {
      save_mood: 'Select',
      save_shift: 'Save Mood Shift',
    },
    tabs: {
      tool: {
        title: 'Track moods',
        motivational: 'Track your moods and triggers to identify common patterns.',
        fields: {
          emotions: {
            title: 'Select one or more moods to track:',
            worried: 'Worried',
            sad: 'Sad',
            anxious: 'Anxious',
            stressed: 'Stressed',
            guilt: 'Guilty',
            angry: 'Angry',
            relaxed: 'Relaxed',
            confident: 'Confident',
            happy: 'Happy',
          },
          intensities: {
            title: 'Rate your mood intensity:',
            mild: 'Mild',
            moderate: 'Moderate',
            intense: 'Intense',
            reason: {
              title: 'Add a description:',
              subtitle: 'What was happening, how did you react?',
            },
          },
          date: {
            title: 'Add when it happened:',
            label: 'Date',
          },
          time: {
            label: 'Time',
          },
        },
      },
      daily: {
        title: 'Daily',
        motivational: 'Track your moods and triggers to identify common patterns.',
        empty: {
          title: 'You don\'t have any moods entered yet.',
          subtitle: 'Select the Track Moods tab to get started.',
        },
        chart: {
          label: {
            xAxis: 'Time of day',
            yAxis: 'Intensity of emotions',
          },
        },
      },
      history: {
        title: 'Mood history',
        motivational: 'Look for patterns such as common events, times of day, or thoughts that result in intense moods.',
        empty: {
          title: 'You don\'t have any moods entered yet.',
          subtitle: 'Select the Track Moods tab to get started.',
        },
        chart: {
          label: {
            xAxis: 'Time',
            yAxis: 'Intensity of emotions',
          },
        },
      },
    },
    mood_card: {
      edit: 'Would you like to edit your mood?',
      delete: 'Are you sure you want to delete this mood?',
    },
    moods: [
      { value: 'worried', label: 'Worried' },
      { value: 'sad', label: 'Sad' },
      { value: 'anxious', label: 'Anxious' },
      { value: 'stressed', label: 'Stressed' },
      { value: 'guilt', label: 'Guilt' },
      { value: 'angry', label: 'Angry' },
      { value: 'relaxed', label: 'Relaxed' },
      { value: 'confident', label: 'Confident' },
      { value: 'happy', label: 'Happy' },
    ],
    notifications: {
      created: 'Moods tracked',
      updated: 'Moods updated',
      deleted: 'Moods deleted',
    },
  },
  workbook: {
    header_title: 'My Journal',
    motivational: 'These are your journal entries, so far, from your training. Review any time to reflect and add to your ideas.',
    program: {
      toolkit: 'My Mental Fitness Toolkit',
      stress: 'Work Stress Management Plan',
    },
    empty: {
      title: 'Journal entries written during your training will appear here. You don\'t have any yet.',
      subtitle: '',
    },
    entry_card: {
      edit: 'Would you like to edit this journal entry?',
    },
    notifications: {
      updated: 'Journal entry updated',
    },
  },
  journal: {
    header_title: 'My Journal',
    page_title: 'Journal',
    page_sub_title: 'Record your thoughts',
    page_instruction: 'Keep a record of your personal insights, learnings, and milestones.',
    actions: {
      create: 'New Entry',
    },
    fields: {
      title: {
        placeholder: 'Title',
      },
      description: {
        placeholder: 'Add your thoughts',
      },
    },
    sections: {
      title: 'Journal Entries',
    },
    notifications: {
      errored: 'An error occured.',
    },
    sorts: [ 'Sort by newest', 'Sort by oldest' ],
    journal_card: {
      delete: 'No problem! Please confirm to delete this journal entry.',
    },
  },
  note: {
    note: 'Note',
  },
};
