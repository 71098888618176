/* eslint-disable no-unused-vars */

export enum WidgetType {
  Body = 'body',
  Eyes = 'eyes',
  Beak = 'beak',
}

export enum WrapperShape {
  Circle = 'circle',
  Square = 'square',
  Squircle = 'squircle',
}

/**
 * WidgetShape
 *
 * All enumeration values of `WidgetShape` correspond to the file name.
 */

export enum BodyShape {
  Crow = 'crow',
  Duck = 'duck',
  Eagle = 'eagle',
  Heron = 'heron',
  Owl = 'owl',
  Plover = 'plover',
  Sparrow = 'sparrow',
  Toucan = 'toucan',
}

export enum EyesShape {
  Standard = 'standard',
  Left = 'left',
  Right = 'right',
  Closed = 'closed',
}

export enum BeakShape {
  Standard = 'standard',
}

export type WidgetShape =
  | BodyShape
  | EyesShape
  | BeakShape
