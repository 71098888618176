import { RootState } from '@/scripts/store/types';
import { format } from 'date-fns';
import { Module } from 'vuex';
import { IMoodHistory, IMoodTracker, MoodTrackerState } from './types';

const defaultFormData: IMoodTracker = {
  id: '',
  reasonText: '',
  favourite: false,
  deleted: false,
  date: undefined,
  timestamp: '',
  moodIntensities: undefined,
};

export const defaultState: MoodTrackerState = {
  moods: [],
  moodHistory: [],
  moodFormData: {
    ...defaultFormData,
  },
};

export const moods: Module<MoodTrackerState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    resetMoodsState(state) {
      Object.assign(state, defaultFormData);
    },
    moods(state, moodList) {
      state.moods = moodList;
    },
    moodHistory(state, moodHistory) {
      state.moodHistory = moodHistory;
    },
    moodFormData(state, moodData) {
      state.moodFormData = moodData;
    },
    resetMoodFormData(state) {
      state.moodFormData = { ...defaultFormData };
    },
  },
  actions: {
    getMoods({ commit, state, dispatch }) {
      const callbackMoods = (res: Array<IMoodTracker>) => {
        res.sort((a, b) => {
          return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        });

        commit('moods', res);
        return res;
      };

      const callBackMoodHistory = (res: Array<IMoodHistory>) => {
        res.sort((a, b) => {
          return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        });

        res.forEach(mood => {
          const date = new Date(mood.timestamp);
          mood.timestamp = date.toISOString();
          return mood;
        });
        commit('moodHistory', res);
        return res;
      };

      return dispatch('$apiRequest', [ '/tools/moods/history', callBackMoodHistory ], { root: true }).then(
        res => dispatch('$apiRequest', [ '/tools/moods', callbackMoods ], { root: true }),
      );
    },
    getMood({ commit, state, dispatch, rootGetters }, moodId: string) {
      const callback = (res: IMoodTracker) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/moods/${moodId}`, callback, 'get' ], { root: true });
    },
    postMood({ commit, state, dispatch, rootGetters }, moodFormData: IMoodTracker) {
      const mood = { ...moodFormData };
      if (mood.time) delete mood.time;
      if (mood.moodDate) delete mood.moodDate;
      const callback = (res: IMoodTracker) => {
        return dispatch('getMoods').then(() => res);
      };
      return dispatch('$apiRequest', [ `/tools/moods`, callback, 'post', mood ], { root: true });
    },
    editMood({ commit, state, getters, dispatch, rootGetters }, moodFormData: IMoodTracker) {
      if (Array.isArray(moodFormData.moodIntensities)) {
        moodFormData.moodIntensities = moodFormData.moodIntensities.reduce((obj, emotion) => {
          obj[emotion.label] = emotion.intensity;
          return obj;
        }, {});
      }
      const mood = { ...getters.getMoodById(moodFormData.id), ...moodFormData };
      const callback = (res: IMoodTracker) => {
        return dispatch('getMoods').then(() => res);
      };
      return dispatch('$apiRequest', [ `/tools/moods/${moodFormData.id}`, callback, 'put', mood ], { root: true });
    },
    deleteMood({ commit, getters, dispatch }, moodId: string) {
      const callback = () => {
        return dispatch('getMoods');
      };
      return dispatch('$apiRequest', [ `/tools/moods/${moodId}`, callback, 'delete' ], { root: true });
    },
    setFavouriteMood({ commit, dispatch, getters, state }, mood: IMoodTracker) {
      if (!mood) return;
      mood.favourite = !mood.favourite;
      const callback = () => {
        return dispatch('getMoods');
      };
      return dispatch('$apiRequest', [ `/tools/moods/${mood.id}`, callback, 'put', mood ], { root: true });
    },
  },
  getters: {
    moods(state) {
      return state.moods;
    },
    moodFormData(state) {
      return state.moodFormData;
    },
    getMoodById: state => (id: string) => {
      if (state.moods.length) {
        return state.moods.find(mood => mood.id == id);
      }
      return false;
    },
    getMoodsByTimestamp: state => (timestamp: string) => {
      if (state.moods.length) {
        return state.moods.filter(mood => {
          return format(new Date(mood.timestamp), 'MM-dd-yy') === timestamp;
        });
      }
      return [];
    },
    moodHistory(state) {
      return state.moodHistory;
    },
  },
};
