var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    { staticClass: "pa-0" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "pa-0": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "justify-center": "" } },
            [
              _c("v-flex", { attrs: { xs12: "", sm11: "", md10: "" } }, [
                _c("h1", { staticClass: "starling-h1" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "public.signup.setup_referral.account_logged.title",
                          { name: _vm.partnerName }
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("p", {
                    staticClass: "starling-body",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "public.signup.setup_referral.account_logged.content",
                          { caseManager: _vm.referral.caseManager }
                        )
                      )
                    }
                  })
                ]
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("blockquote", {
                    staticClass: "starling-body",
                    domProps: { innerHTML: _vm._s(_vm.referral.message) }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "pa-0 pt-3" },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-center": "",
                    "align-center": "",
                    "gap-xs-3": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-center": "" } },
                    [
                      _c(
                        "primary-button",
                        { attrs: { large: "" }, on: { click: _vm.homepage } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "public.signup.setup_referral.account_logged.button"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }