export default {
  menu: [
    {
      id: 'home',
      label: 'Accueil',
      path: '/app/home',
      icon: '$vuetify.icons.starling_home',
      desktop: true,
      mobile: true,
    },
    {
      id: 'training',
      label: 'Entraînement',
      path: '/app/training',
      icon: '$vuetify.icons.starling_therapy',
      desktop: true,
      mobile: true,
    },
    {
      id: 'exercises',
      label: 'Exercices',
      path: '/app/exercises',
      icon: '$vuetify.icons.starling_exercises',
      desktop: true,
      mobile: true,
    },
    {
      id: 'webinars',
      label: 'Webinaires',
      path: '/app/webinars',
      icon: '$vuetify.icons.starling_webinar',
      desktop: true,
      mobile: true,
      popover: {
        badge: 'Nouveau',
        title: 'Le sommeil en pleine conscience',
        description: 'Un atelier complet sur la maîtrise du sommeil',
      },
    },
    {
      id: 'videos',
      label: 'Vidéos',
      path: '/app/videos',
      icon: '$vuetify.icons.starling_videos',
      desktop: true,
      mobile: true,
    },
    {
      id: 'tools',
      label: 'Outils',
      path: '/app/tools',
      icon: '$vuetify.icons.starling_tools',
      desktop: true,
      mobile: true,
    },
    {
      id: 'community',
      label: 'Communauté',
      path: '/app/community',
      icon: '$vuetify.icons.starling_community',
      desktop: true,
      mobile: true,
    },
    {
      id: 'profile',
      label: 'Profil',
      path: '/app/profile',
      icon: '$vuetify.icons.starling_profile',
      desktop: true,
      mobile: true,
    },
    {
      id: 'favorites',
      label: 'Favoris',
      path: '/app/favorites',
      icon: '$vuetify.icons.starling_favorite',
      desktop: true,
      mobile: true,
    },
  ],
  more_button: 'Plus',
  favorites_button: 'Favoris',
  profile_button: 'Profil',
};
