














import Vue from 'vue';
import therapyEventBus, { EventType } from '@/scripts/eventbus/therapyEventBus.js';

// eslint-disable-next-line security/detect-unsafe-regex
export const DYNAMIC_TEXT_REGEX = /<dynamic-text(?: uen="(.*?)")?>(.*?)<\/dynamic-text>/i;

export default Vue.extend({
  name: 'sm-dynamic-text',
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      internalValue: undefined as string | undefined,
      uen: undefined as string | undefined,
      editMode: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.internalValue = newValue?.replace(DYNAMIC_TEXT_REGEX, '$2');
        const matches = newValue?.match(DYNAMIC_TEXT_REGEX);
        this.uen = matches?.[1];
      },
    },
  },
  created() {
    therapyEventBus.$on(EventType.SAVE_THERAPY.toString(), () => {
      this.$log.debug('Therapy bus event to save dynamic-text. Edit mode is: ', this.editMode);
      if (this.editMode) {
        this.onEnter();
      }
    });
  },
  beforeDestroy() {
    therapyEventBus.$off(EventType.SAVE_THERAPY.toString());
  },
  methods: {
    onEdit() {
      if (this.editMode) {
        this.onEnter();
      } else {
        this.editMode = true;
      }
    },
    onEnter() {
      this.internalValue = (this.$refs.editableField as HTMLElement).innerText.trim();
      this.$store.dispatch('therapy/updateWorkbookAnswer', { uen: this.uen, userAnswer: this.internalValue }).then(res => {
        if (res.status && res.status > 300) {
          throw Error();
        }
        this.editMode = false;
        this.$emit('input', this.internalValue);
      }).catch(() => {});
    },
    onCancel() {
      this.internalValue = this.value?.replace(DYNAMIC_TEXT_REGEX, '$2');
      this.editMode = false;
    },
    onPaste(event: any) {
      const text = event.clipboardData?.getData('text/plain');
      const selectedRange = window.getSelection()?.getRangeAt(0);
      if (!selectedRange || !text) {
        return;
      }

      selectedRange.deleteContents();
      selectedRange.insertNode(document.createTextNode(text));
      selectedRange.setStart(selectedRange.endContainer, selectedRange.endOffset);
    },
  },
});
