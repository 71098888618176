var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    [
      _c(
        "v-card",
        {
          staticClass: "setup_profile-card flexcard",
          class: _vm.$vuetify.breakpoint.smAndUp ? "pa-3" : "pa-0",
          style: _vm.cardStyles,
          attrs: {
            flat: "",
            elevation: _vm.elevation,
            "min-height": _vm.cardHeight
          }
        },
        [
          this.loading ? _c("v-card-loader") : _vm._e(),
          _vm.loaded
            ? _c(
                "v-form",
                {
                  ref: "form",
                  attrs: {
                    autocomplete: "off",
                    transition: "slide-y-reverse-transition"
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "", "justify-center": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm11: "", md10: "" } },
                            [
                              _c("h1", { staticClass: "starling-h1" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "public.signup.setup_profile.title",
                                        { name: _vm.partnerName }
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm11: "",
                                md10: "",
                                "mt-3": ""
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "starling-body starling-content"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "public.signup.setup_profile.content",
                                          { name: _vm.partnerName }
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm11: "",
                                md10: "",
                                "mt-3": ""
                              }
                            },
                            [
                              _c("h3", { staticClass: "starling-h3" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "public.signup.setup_profile.profile.title",
                                        { name: _vm.partnerName }
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm11: "",
                                md10: "",
                                "mt-3": ""
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "starling-body starling-content"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "public.signup.setup_profile.profile.content." +
                                            _vm.accessCodeStep.program +
                                            "." +
                                            (_vm.privacyDisclaimer
                                              ? "privacy_clinician"
                                              : "privacy"),
                                          { name: _vm.partnerName }
                                        )
                                      )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm11: "",
                                md10: "",
                                "mt-3": ""
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "starling-body font-weight-medium"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "public.signup.setup_profile.profile.fields.firstname.title",
                                          { name: _vm.partnerName }
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _c("registration-field-firstname", {
                                staticClass: "mt-2",
                                model: {
                                  value: _vm.form.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name"
                                }
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "starling-body starling-content mt-3"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "public.signup.setup_profile.profile.fields.firstname.content",
                                          { name: _vm.partnerName }
                                        )
                                      )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          !_vm.defaultYearOfBirth
                            ? _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm11: "",
                                    md10: "",
                                    "mt-3": ""
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "starling-body font-weight-medium"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "public.signup.setup_profile.profile.fields.birthYear.title",
                                              { name: _vm.partnerName }
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  ),
                                  _c("registration-field-birth-year", {
                                    staticClass: "mt-2",
                                    model: {
                                      value: _vm.form.birthYear,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "birthYear", $$v)
                                      },
                                      expression: "form.birthYear"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.defaultGender
                            ? _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm11: "",
                                    md10: "",
                                    "mt-3": ""
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "starling-body font-weight-medium"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "public.signup.setup_profile.profile.fields.gender.title",
                                              { name: _vm.partnerName }
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  ),
                                  _c("registration-field-gender", {
                                    staticClass: "mt-2",
                                    model: {
                                      value: _vm.form.gender,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "gender", $$v)
                                      },
                                      expression: "form.gender"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm11: "",
                                md10: "",
                                "mt-3": ""
                              }
                            },
                            [
                              _c("h3", { staticClass: "starling-h3" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "public.signup.setup_profile.community.title",
                                        { name: _vm.partnerName }
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm11: "",
                                md10: "",
                                "mt-3": ""
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "starling-body starling-content"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "public.signup.setup_profile.community.content",
                                          { name: _vm.partnerName }
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    row: "",
                                    wrap: "",
                                    "align-center": "",
                                    "justify-center": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs8: "",
                                        sm6: "",
                                        md4: "",
                                        lg3: "",
                                        "pa-2": "",
                                        "text-center": ""
                                      }
                                    },
                                    [
                                      _c("starling-avatar-input", {
                                        attrs: {
                                          value: _vm.form.avatar
                                            ? JSON.parse(_vm.form.avatar)
                                            : null
                                        },
                                        on: { saved: _vm.onUpdateAvatar }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        sm6: "",
                                        md8: "",
                                        lg9: "",
                                        "align-center": "",
                                        "justify-center": "",
                                        "text-center": ""
                                      }
                                    },
                                    [
                                      _c("registration-field-codename", {
                                        staticClass: "mt-2",
                                        on: {
                                          "required-codename":
                                            _vm.provideCodename
                                        },
                                        model: {
                                          value: _vm.form.codename,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "codename", $$v)
                                          },
                                          expression: "form.codename"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "primary--text font-weigth-bold",
                                          attrs: {
                                            flat: "",
                                            block: "",
                                            large: ""
                                          },
                                          on: { click: _vm.provideCodename }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              staticStyle: { width: "24px" },
                                              attrs: { left: "" }
                                            },
                                            [
                                              _vm._v(
                                                "$vuetify.icons.starling_randomize"
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "public.registration.profile.fields.codename.action"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm11: "",
                                md10: "",
                                "mt-3": ""
                              }
                            },
                            [
                              _c("registration-field-marketing", {
                                model: {
                                  value: _vm.form.newsletter,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "newsletter", $$v)
                                  },
                                  expression: "form.newsletter"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-0 pt-3" },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "justify-center": "",
                            "align-center": "",
                            "gap-xs-3": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: { xs12: "", "text-center": "", "pt-3": "" }
                            },
                            [
                              _c(
                                "primary-button",
                                {
                                  attrs: {
                                    id: "btn-do-register",
                                    type: "button",
                                    large: "",
                                    disabled: !_vm.valid || _vm.loading,
                                    loading: _vm.loading
                                  },
                                  on: { click: _vm.setupProfile }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "public.signup.setup_profile.buttons.register"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "text-center": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    id: "btn-cancel",
                                    flat: "",
                                    round: "",
                                    disabled: _vm.loading
                                  },
                                  on: { click: _vm.toSetupAccessCode }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.actions.back")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "text-center": "" } },
                            [_c("span", [_vm._v("2 / 2")])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }