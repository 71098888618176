var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "community-icon",
      staticStyle: {
        display: "inline",
        margin: "0",
        padding: "0",
        width: "auto"
      },
      attrs: {
        viewBox: "0 0 31 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M2,11.5C2,6.3,6.8,2,13,2c5,0,10,4.3,10,9.5c0,5.2-3.9,9.5-10,9.5c-0.9,0-2.8-0.1-3.6-0.3L6,23v-3.5 C3.6,17.5,2,14.8,2,11.5L2,11.5z M9.6,22.7c0.8,0.1,2.5,0.3,3.4,0.3c7.2,0,12-5.3,12-11.6S19,0,13,0C5.8,0,0,5.1,0,11.4 c0,3.6,1.4,6.7,4,9V26L9.6,22.7L9.6,22.7z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M26.8,9.1c0,0.3,0.1,0.6,0.1,0.9c0,0.6-0.1,1.3-0.2,1.9c1.3,1.6,2.2,3.5,2.2,5.6c0,3.3-2,6.3-5,8V29l-3.4-2.3 c-0.8,0.2-1.7,0.3-2.6,0.3c-2.6,0-4-0.8-5.8-2.1c-0.5,0-1.1,0.1-1.7,0.1c-0.4,0-0.8,0-1.2-0.1c2.4,2.3,4.8,3.8,8.7,3.8 c0.8,0,1.6-0.1,2.4-0.2l5.6,3.5v-5.7c3-2.1,5-5.3,5-9C30.8,14.1,29.3,11.2,26.8,9.1L26.8,9.1z"
        }
      }),
      _c("path", {
        staticClass: "icon-content",
        attrs: {
          d:
            "M6.5,10C7.3,10,8,10.7,8,11.5C8,12.3,7.3,13,6.5,13S5,12.3,5,11.5C5,10.7,5.7,10,6.5,10L6.5,10z M12.5,10 c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5S11,12.3,11,11.5C11,10.7,11.7,10,12.5,10L12.5,10z M18.5,10c0.8,0,1.5,0.7,1.5,1.5 c0,0.8-0.7,1.5-1.5,1.5S17,12.3,17,11.5C17,10.7,17.7,10,18.5,10L18.5,10z M9.6,22.7c0.8,0.1,2.5,0.3,3.4,0.3c7.2,0,12-5.3,12-11.6 S19,0,13,0C5.8,0,0,5.1,0,11.4c0,3.6,1.4,6.7,4,9V26L9.6,22.7L9.6,22.7z"
        }
      }),
      _c("path", {
        staticClass: "icon-content",
        attrs: {
          d:
            "M26.8,9.1l0.1,0.9c0,0.8-0.1,1.1-0.2,1.9C26.2,19.3,20.8,25,12,24.9c-1.1,0-2.8,0-2.8,0 c2.4,2.3,4.8,3.8,8.7,3.8c0.8,0,1.6-0.1,2.4-0.2l5.6,3.5v-5.7c3-2.1,5-5.3,5-9C30.8,14.1,29.3,11.2,26.8,9.1L26.8,9.1z"
        }
      }),
      _c("path", {
        staticClass: "icon-points",
        attrs: {
          d:
            "M18.5,13c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5S17,10.7,17,11.5C17,12.3,17.7,13,18.5,13L18.5,13z M6.5,13 C7.3,13,8,12.3,8,11.5C8,10.7,7.3,10,6.5,10S5,10.7,5,11.5C5,12.3,5.7,13,6.5,13L6.5,13z M12.5,13c0.8,0,1.5-0.7,1.5-1.5 c0-0.8-0.7-1.5-1.5-1.5S11,10.7,11,11.5C11,12.3,11.7,13,12.5,13L12.5,13z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }