export default {
  title: 'Vérification de l\'adresse courriel',
  subtitle: 'Veuillez entrer un nouveau mot de passe pour votre compte.',
  content: [
    'Un code de confirmation vous a été envoyé par courriel.',
    'Veuillez entrer le code avant son expiration dans les 10 prochaines minutes.',
    'Si vous n’avez pas reçu de code, veuillez suivre les étapes suivantes pour résoudre le problème :',
    '1. Vérifiez votre dossier de courriels indésirables.',
    '2. Si le courriel contenant le code de confirmation ne s’y trouve pas, envoyez un courriel à <a target="_blank" href="mailto:membres@starlingminds.com">membres@starlingminds.com</a> pour obtenir du soutien technique',
  ],
  buttons: {
    verify: 'Vérifiez mon code',
    generate_token: 'Renvoyer le code',
    log_in_again: 'Ouvrez une nouvelle session',
  },
  success: {
    generate_token: 'Un nouveau code a été envoyé à votre adresse courriel.',
  },
  error: {
    EXPIRED: 'Ce code est expiré.',
    WRONG_CODE: 'Ce code est invalide.',
    MISMATCH: 'Ce code est invalide.',
    TOO_MANY_TRIES: 'Vous avez utilisé des codes invalides à trop de reprises. Veuillez ouvrir une nouvelle session.',
    LIMIT_EXCEEDED: 'Vous avez utilisé des codes invalides à trop de reprises. Veuillez réessayer plus tard.',
    CANNOT_GENERATE: 'Un nouveau code ne peut pas être généré. Veuillez réessayer plus tard.',
    default: 'Une erreur s\'est produite.',
  },
};
