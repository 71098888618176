
<template>
<svg style="display:inline; margin: 0; padding: 0; width: auto;"
    class="therapy-icon"
    viewBox="0 0 30 30"
    fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="icon-content" fill-rule="evenodd" clip-rule="evenodd" d="M10.568 24.188V30h8.906v-8.063c2.157-1.03 4.032-2.437 5.532-4.312 1.5-1.875 2.25-4.031 2.25-6.469 0-3.094-1.125-5.719-3.282-7.875C21.818 1.125 19.1 0 16.006 0c-2.813 0-5.25.844-7.313 2.719C6.631 4.594 5.318 6.938 4.85 9.75l-2.625 4.594c-.28.469-.28.937-.093 1.406.187.469.562.656 1.03.656h1.5v6.282c0 .375.188.75.47 1.03.28.282.656.47 1.03.47h4.407Zm4.688-4.782c0 .469.375.75.75.75s.656-.375.562-.562c0-3.469 1.125-4.032 2.813-4.594 2.437-.844 3.656-2.25 3.656-4.313 0-1.968-1.5-3.28-3-3.562-.563-1.781-2.438-2.625-4.219-2.625-1.687 0-3.469.938-3.844 2.719-.656.093-1.312.468-1.78.937-.563.563-.938 1.313-.938 2.156 0 1.5 1.03 2.532 2.812 2.813 1.031.094 2.531.188 3.656-.188 1.688-.562 2.157-2.156 2.157-2.25.093-.374-.094-.75-.47-.843-.374-.094-.75.094-.843.469 0 0-.281.937-1.219 1.312-.656.281-1.78.281-3 .094-1.125-.188-1.687-.656-1.687-1.5 0-.469.187-.938.562-1.219.375-.375.844-.563 1.5-.563.188 0 .375 0 .657-.28a.716.716 0 0 0 .187-.47c0-1.312 1.219-1.968 2.438-1.968 1.312 0 2.718.656 2.906 1.875a.64.64 0 0 0 .656.656c.844 0 2.156.656 2.156 2.25 0 1.5-.843 2.438-2.718 3-2.438.75-3.75 1.969-3.75 5.906Z"/>
  <path d="M10.568 30v-5.813H6.162c-.375 0-.75-.187-1.031-.468-.282-.282-.47-.657-.47-1.032v-6.28h-1.5c-.468 0-.843-.188-1.03-.657-.188-.469-.188-.938.093-1.406L4.85 9.75c.47-2.813 1.782-5.156 3.844-7.031C10.756.844 13.193 0 16.006 0c3.093 0 5.812 1.125 7.968 3.281 2.157 2.156 3.282 4.781 3.282 7.875 0 2.438-.75 4.594-2.25 6.469s-3.375 3.281-5.532 4.313V30h-8.906Zm7.5-1.406V21l.75-.375c1.969-.938 3.656-2.156 4.969-3.75 1.312-1.594 1.969-3.469 1.969-5.625 0-2.719-.938-5.063-2.813-6.938C21.068 2.438 18.724 1.5 16.006 1.5c-2.532 0-4.782.844-6.657 2.438-1.875 1.593-2.906 3.75-3.093 6.28L3.536 15h2.626v7.688h5.812v5.906h6.094Z"/>
  <path d="M16.006 20.156a.74.74 0 0 1-.75-.75c0-3.937 1.312-5.156 3.75-5.906 1.875-.563 2.718-1.5 2.718-3 0-1.594-1.312-2.25-2.156-2.25a.64.64 0 0 1-.656-.656c-.188-1.219-1.594-1.875-2.906-1.875-1.22 0-2.438.656-2.438 1.968a.716.716 0 0 1-.187.47c-.282.28-.47.28-.657.28-.656 0-1.125.188-1.5.563-.375.281-.562.75-.562 1.219 0 .844.562 1.312 1.687 1.5 1.22.187 2.344.187 3-.094.938-.375 1.22-1.313 1.22-1.313.093-.374.468-.562.843-.468.375.094.562.469.469.844 0 .093-.47 1.687-2.157 2.25-1.125.374-2.625.28-3.656.187-1.781-.281-2.812-1.313-2.812-2.813 0-.843.375-1.593.937-2.156.469-.469 1.125-.844 1.781-.937.375-1.782 2.157-2.719 3.844-2.719 1.781 0 3.656.844 4.219 2.625 1.5.281 3 1.594 3 3.563 0 2.062-1.219 3.468-3.656 4.312-1.688.563-2.813 1.125-2.813 4.594.094.187-.187.562-.562.562Z"/>
</svg>
</template>

<script>
export default {
  name: 'therapy-icon',
};
</script>

<style scoped>
.therapy-icon {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0.2;
}

.therapy-icon .icon-content {
  fill: transparent;
  stroke: transparent;
}

.therapy-icon.activated,
.router-link-active .v-icon.therapy-icon {
  fill: transparent;
  stroke: transparent;
}

.therapy-icon.activated .icon-content,
.router-link-active .v-icon.therapy-icon .icon-content {
  fill: currentColor;
  stroke: transparent;
}
</style>
