var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: {
                autocomplete: "off",
                transition: "slide-y-reverse-transition"
              },
              nativeOn: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleEnterKey($event)
                }
              },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "justify-center": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm11: "", md10: "" } }, [
                    _c("h1", { staticClass: "starling-h1" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "public.signup.setup_referral.claim_account.title",
                              { name: _vm.partnerName }
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._l(
                    _vm.$t(
                      "public.signup.setup_referral.claim_account.content",
                      { name: _vm.partnerName }
                    ),
                    function(text) {
                      return _c(
                        "v-flex",
                        {
                          key: text,
                          attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" }
                        },
                        [
                          _c("p", { staticClass: "starling-body" }, [
                            _vm._v(
                              "\n            " + _vm._s(text) + "\n          "
                            )
                          ])
                        ]
                      )
                    }
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                    [
                      _c("h4", { staticClass: "starling-h3" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "public.signup.setup_referral.claim_account.account_cta"
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                    [
                      _c(
                        "p",
                        { staticClass: "starling-body font-weight-medium" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "public.signup.setup_referral.claim_account.fields.email.title"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm11: "", md10: "" } },
                    [
                      _c("registration-field-email", {
                        attrs: {
                          value: _vm.referral.email,
                          disabled: "",
                          "should-focus": false
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                    [
                      _c(
                        "p",
                        { staticClass: "starling-body font-weight-medium" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "public.signup.setup_referral.claim_account.fields.password.title"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm11: "", md10: "" } },
                    [
                      _c("registration-field-password", {
                        attrs: { confirmation: _vm.form.passwordConfirmation },
                        on: { "form-validate": _vm.onFormValidate },
                        model: {
                          value: _vm.form.password,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm11: "", md10: "" } },
                    [
                      _c("registration-field-password-confirmation", {
                        attrs: { password: _vm.form.password },
                        on: { "form-validate": _vm.onFormValidate },
                        model: {
                          value: _vm.form.passwordConfirmation,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "passwordConfirmation", $$v)
                          },
                          expression: "form.passwordConfirmation"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pa-0 pt-3" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                wrap: "",
                "justify-center": "",
                "align-center": "",
                "gap-xs-3": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", "text-center": "", "pt-3": "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: _vm.$vuetify.breakpoint.xsOnly,
                        "justify-center": "",
                        "gap-xs-2": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "" } },
                        [
                          _c(
                            "primary-button",
                            {
                              attrs: {
                                id: "btn-do-reset-password",
                                disabled:
                                  !_vm.valid || _vm.loading || !_vm.referral,
                                large: ""
                              },
                              on: { click: _vm.claimAccount }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.actions.next")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }