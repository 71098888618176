

















import Vue from 'vue';

export default Vue.extend({
  name: 'starling-loading',
  computed: {
    loadingProgress(): Number {
      return this.$store.state.loadingProgress || 0;
    },
  },
});
