var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    ref: "field",
    attrs: {
      autocomplete: "new-password",
      label: _vm.$t(
        "public.registration.account.fields.passwordConfirmation.label"
      ),
      placeholder: _vm.$t(
        "public.registration.account.fields.passwordConfirmation.placeholder"
      ),
      name: "password-confirmation",
      rules: _vm.rules,
      hint: _vm.$t(
        "public.registration.account.fields.passwordConfirmation.hint"
      ),
      "persistent-hint": "",
      "append-icon": _vm.show
        ? "$vuetify.icons.starling_eye_opened"
        : "$vuetify.icons.starling_eye_closed",
      type: _vm.show ? "text" : "password",
      disabled: _vm.disabled
    },
    on: {
      "click:append": function($event) {
        _vm.show = !_vm.show
      }
    },
    model: {
      value: _vm.internalValue,
      callback: function($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }