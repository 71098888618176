<template>
  <v-text-field
      ref="field"
      :class="{hidden: migrationEmail}"
      autocomplete="off"
      :label="$t('public.registration.accessCode.fields.accessCode.label')"
      name="access_code"
      :rules="rules"
      :error-messages="loading ? $t('common.errors.loading') : postValidateError"
      v-model="internalValue"
      :hint="$t('public.registration.accessCode.fields.accessCode.hint')"
      persistent-hint
      @input="validateForm"
      :color="loading ? 'warning' : 'primary'"
      :loading="loading"
      :type="'text'">
  </v-text-field>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'registration-field-access-code',
  props: {
    value: String,
    migrationEmail: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
      loading: false,
      postValidateError: null,
      debounceInput: undefined,
      rules: [
        v => !!v || this.$t('common.errors.required'),
        v => (v && v.toString().trim().length > 0) || this.$t('common.errors.nonEmpty'),
        v => (v && v.length >= 6) || this.$t('common.errors.min6'),
      ],
    };
  },
  watch: {
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
        this.validateForm();
      }
    },
  },
  created () {
    const that = this;
    this.debounceInput = _.debounce(() => {
      this.$emit('input', this.internalValue);
      that.postValidate();
    }, 1000);
  },
  mounted() {
    if (this.value) {
      this.validateForm();
    }
    this.$refs.field.focus();
  },
  methods: {
    validateForm() {
      this.loading = true;
      this.postValidateError = null;

      if (!this.$refs.field.validate(true)) {
        this.$emit('input', this.internalValue);
        this.loading = false;
        return;
      }

      this.debounceInput();
    },
    postValidate() {
      this.$store.dispatch('identity/findAccessCode', this.internalValue).then(res => {
        return this.$store.dispatch('accessCode/validate', res.accessCode);
      }).then(validateRes => {
        if (validateRes.status && validateRes.status >= 400) {
          throw validateRes;
        }

        this.$emit('access-code-validated', {
          accessCode: this.internalValue,
          organization: validateRes.accessCode,
          memberId: validateRes,
        });
      }).catch(err => {
        this.$emit('access-code-invalidated');
        if (typeof err !== 'string' && err?.status && this.$te(`public.registration.accessCode.fields.accessCode.errors.${err.status}`)) {
          this.postValidateError = this.$t(`public.registration.accessCode.fields.accessCode.errors.${err.status}`);
        } else {
          this.postValidateError = this.$t(`public.registration.accessCode.fields.accessCode.errors.generic`);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>
