import { Module } from 'vuex';
import { RootState } from '../../types';
import {
  AssessmentState,
  IAssessment,
  IAssessmentHistoryChart,
  IAssessmentResult, IAssessmentScale,
  IUserAssessment,
  IUserAssessmentNote,
} from './types';

const initialState: AssessmentState = {
  assessments: [],
};

export const defaultState: AssessmentState = {
  assessments: [],
};

export const assessment: Module<AssessmentState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    resetAssessmentState(state) {
      Object.assign(state, initialState);
    },
    assessments(state, assessments) {
      if (assessments.length) {
        state.assessments = assessments;
      } else {
        state.assessments = [ assessments ];
      }
    },
  },
  actions: {
    getAssessments({ commit, dispatch }) {
      const callback = (res: IAssessment[]) => {
        commit('assessments', res);
        return res;
      };
      return dispatch('$apiRequest', [ '/assessments', callback ], { root: true });
    },
    getAssessment({ commit, dispatch }, { name, uen }) {
      const params = `uen=${uen}`;
      const callback = (res: IAssessment) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/assessments/byName/${name}?${params}`, callback ], { root: true });
    },
    saveAssessment({ commit, dispatch, getters }, assessmentData: IUserAssessment) {
      const callback = (res: IAssessment) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/assessments/next`, callback, 'post', assessmentData ], { root: true });
    },
    addAssessmentNotes({ commit, dispatch, getters }, assessmentNote: IUserAssessmentNote) {
      const callback = (res: IAssessment) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/assessments/${assessmentNote.userAssessmentId}/notes`, callback, 'post', assessmentNote ], { root: true });
    },
    getAssessmentHistoryChart({ commit, dispatch }, assessmentName) {
      const callback = (res: IAssessmentHistoryChart[]) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/assessments/historyChart?assessmentName=${assessmentName || ''}`, callback ], { root: true });
    },
    getAssessmentScale({ commit, dispatch }, assessmentName) {
      const callback = (res: IAssessmentScale[]) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/assessments/byName/${assessmentName}/scale`, callback ], { root: true });
    },
    getMostRecentAssessments({ dispatch }) {
      const callback = (res: { [name: string]: IAssessmentResult }) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/assessments/mostRecent`, callback ], { root: true });
    },
    getInterpretation({ dispatch }, assessmentName) {
      const callback = (res:any) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/assessments/interpretation/${assessmentName}`, callback ], { root: true });
    },
  },
  getters: {
    assessments(state) {
      return state.assessments;
    },
  },
};
