var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        { staticClass: "text-left", attrs: { "justify-start": "" } },
        [
          _vm.title
            ? _c(
                "v-layout",
                [
                  _c("v-flex", [
                    _c("h4", { staticClass: "starling-h4" }, [
                      _vm._v(_vm._s(_vm.title))
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-layout",
            { attrs: { wrap: "", "text-left": "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", md10: "" } },
                [
                  _vm._l(_vm.available, function(item, index) {
                    return [
                      _vm.previews[item]
                        ? _c("div", {
                            key: index,
                            staticClass: "shape-selector-item",
                            class: {
                              selected: _vm.innerValue === item,
                              mobile: _vm.$vuetify.breakpoint.smAndDown
                            },
                            domProps: { innerHTML: _vm._s(_vm.previews[item]) },
                            on: {
                              click: function($event) {
                                return _vm.onSelect(index)
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }