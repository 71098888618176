var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "registration-marketing",
      attrs: { row: "", "px-2": "", "gap-xs-2": "", "align-baseline": "" }
    },
    [
      _c(
        "v-flex",
        [
          _c(
            "v-btn",
            {
              staticClass: "ma-0",
              attrs: {
                fab: "",
                label: "Label",
                outline: !_vm.internalValue,
                color: _vm.internalValue ? "checked_checkbox" : "normal"
              },
              on: {
                click: function($event) {
                  _vm.internalValue = !_vm.internalValue
                }
              }
            },
            [
              _vm.internalValue ? _c("v-icon", [_vm._v("mdi-check")]) : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("v-flex", [
        _c("p", { staticClass: "starling-body" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("public.registration.account.fields.marketing.label")
              ) +
              "\n    "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }