export default {
  title: 'Restablecer mi contraseña',
  subtitle: {
    provider: 'Si te registraste utilizando un socio de inicio de sesión, utiliza el enlace correspondiente a continuación para acceder a tu cuenta.',
    email: 'Si te registraste con tu dirección de correo electrónico, introdúcela a continuación, y te enviaremos los detalles sobre cómo restablecer tu contraseña.',
  },
  success: {
    redirect: 'Por favor, revisa tu correo electrónico para obtener más detalles sobre cómo restablecer tu contraseña.',
    action: 'Iniciar sesión',
  },
  fields: {
    email: 'Correo electrónico',
  },
  buttons: {
    reset_password: 'Restablecer contraseña',
  },
};
