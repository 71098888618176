export default {
  header_title: 'Mon profil',
  navigation: [
    {
      id: 'account',
      label: 'Compte',
      path: 'profile-account',
    },
  ],
  header: {
    fields: {
      name: {
        title: 'Nom',
        question: 'Comment devrions-nous vous appeler?',
        label: 'Prénom',
      },
    },
  },
  account: {
    title: 'Profil',
    joined: 'Inscrit le ',
    fields: {
      codeName: {
        title: 'Nom de code de la communauté',
        question: 'Choisir un nom code pour la communauté',
        label: 'Nom de code de la communauté',
        hint: 'Veuillez cliquer sur le bouton ci-dessous pour générer un nouveau nom de code',
        provider: {
          title: 'Randomiser',
        },
      },
      yearOfBirth: {
        title: 'Année de naissance',
        question: 'Peut-on connaître votre année de naissance?',
        label: 'Année de naissance',
      },
      profession: {
        title: 'Emploi',
        question: 'Pouvons-nous connaître votre emploi?',
        label: 'Emploi',
      },
      businessUnit: {
        title: 'Secteur d\'activité',
        question: 'Pouvons-nous connaître votre secteur d\'activité?',
        label: 'Secteur d\'activité',
      },
      gender: {
        title: 'Sexe',
        question: 'Comment souhaitez-vous qu\'on s\'adresse à vous?',
        label: 'Sexe',
        custom_label: 'Je me décris comme',
      },
      password: {
        title: 'Mot de passe',
        question: 'Changer votre mot de passe',
        current_password: 'Mot de passe actuel',
        new_password: 'Nouveau mot de passe',
        confirm_password: 'Confirmer le mot de passe',
      },
      email: {
        title: 'Adresse courriel',
      },
      organization: {
        title: 'Organisme',
      },
    },
    delete_account: {
      title: 'Supprimer le compte',
      description: 'Veuillez confirmer la suppression du compte. Cette action est irréversible et supprimera définitivement toutes vos données associées à ce compte.',
      confirmation: {
        label: 'Pour confirmer, saisissez <strong><i>supprimer le compte</i></strong> dans le champ de saisie de texte.',
        safe_word: 'supprimer le compte',
      },
      button: 'Supprimer le compte',
      confirm_button: 'Supprimer le compte',
    },
  },
  community: {
    title: 'Paramètres de la communauté',
    tagline: 'Ces informations vous représenterons dans la communauté.',
  },
  settings: {
    title: 'Paramètres globaux',
    fields: {
      language: {
        title: 'Langue',
        question: 'Quelle langue parlez-vous?',
        label: 'Langue',
      },
      enable: {
        title: 'Activer la traduction',
      },
      timezone: {
        title: 'Fuseau horaire',
        question: 'Mettre à jour le fuseau horaire par défaut de votre compte',
        label: 'Fuseau horaire',
      },
    },
  },
  communication: {
    title: 'Paramètres de notification',
    email: 'Courriel',
    app: 'Appli',
    comments: {
      title: 'Commentaires',
      reactions: 'Réactions',
      replies: 'Réponses',
    },
    progress: {
      title: 'Progrès',
      session: 'Session',
      checkup: 'Bilan de santé',
      goal_reminder: 'Rappels d\'objectifs',
      webinar_reminder: 'Rappels de webinaires',
    },
    completion: {
      title: 'Complet',
      session: 'Session',
      exercise: 'Exercices',
    },
    product_updates: {
      updates: 'Mises à jour du produit',
    },
    goal_reminder_modal: {
      title: 'Attention.',
      description: 'La désactivation des rappels d\'objectifs met en pause les notifications pour tous les objectifs que vous avez établis.<br><br> Vous pouvez désactiver les rappels pour un objectif précis dans l\'outil <strong>d\'établissement d\'objectifs</strong> dans <strong>Outils.</strong>',
    },
    warning_push_disabled: {
      title: 'Attention.',
      description: 'Votre appareil n\'est pas configuré pour recevoir des notifications poussées. Vous pouvez activer cette fonction dans les paramètres de votre téléphone.',
    },
  },
  feedback_block: {
    title: 'Rétroaction',
    greeting: 'Comment nous en sortons-nous?',
    buttons: {
      write: 'Écrivez-nous',
    },
  },
  misc: {
    no_disclosure: 'Je préfère garder cette information secrète',
    self_describe: 'Je me décris comme {value}',
    no_match: 'Aucun résultat trouvé',
  },
  notifications: {
    updated: 'Profil mis à jour',
    password_changed: 'Mot de passe modifié',
    password_not_changed: 'Votre mot de passe actuel est erroné, veuillez réessayer.',
  },
  feedback: {
    mailto: 'mailto:membres@starlingminds.com?subject=Retours%20et%20questions',
  },
  webinars: {
    title: 'Paramètres pour les webinaires',
    header_webinars: 'Webinaires',
    header_registered: 'Inscrit',
    no_items: 'Aucun webinaire à venir. Activez l\'option de mises à jour des produits dans les paramètres de notification pour rester au fait des nouveaux webinaires.',
  },
};
