var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "v-overlay v-overlay--active",
      staticStyle: { "z-index": "201" },
      style: _vm.absolute ? "position:absolute;" : ""
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "loading-bounce",
        staticStyle: {
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          margin: "auto"
        }
      },
      [
        _c("div", { staticClass: "double-bounce1" }),
        _c("div", { staticClass: "double-bounce2" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }