/* eslint-disable no-unused-vars */

import type { AvatarOption, Color } from './types';

import { SETTINGS } from './constant';
import { BeakShape, BodyShape, EyesShape, WrapperShape } from '@/views/avatar/enums';

/**
 * Get a random value from an array.
 */
function getRandomValue<Item = unknown>(
  arr: Item[],
  {
    avoid = [],
    usually = [],
  }: { avoid?: unknown[]; usually?: (Item | 'none')[] } = {},
): Item {
  const avoidValues = avoid.filter(Boolean);
  const filteredArr = arr.filter((it) => !avoidValues.includes(it));

  const usuallyValues = usually
    .filter(Boolean)
    .reduce<Item[]>((acc, cur) => acc.concat(new Array(15).fill(cur)), []);

  const finalArr = filteredArr.concat(usuallyValues);

  const randomIdx = Math.floor(Math.random() * finalArr.length);
  const randomValue = finalArr[randomIdx];

  return randomValue;
}

export function getRandomFillColor(colors: Color[]) {
  return colors[Math.floor(Math.random() * colors.length)];
}

export function getDefault(): AvatarOption {
  return {
    wrapperShape: WrapperShape.Circle,

    background: {
      color: SETTINGS.backgroundColors[0],
    },

    widgets: {
      body: {
        shape: BodyShape.Sparrow,
        color: SETTINGS.widgetColors.body[0],
      },
      eyes: {
        shape: EyesShape.Standard,
        color: SETTINGS.widgetColors.eyes[0],
      },
      beak: {
        shape: BeakShape.Standard,
        color: SETTINGS.widgetColors.beak[2],
      },
    },
  };
}

export function getRandomAvatarOption(
  presetOption: Partial<AvatarOption> = {},
  useOption: Partial<AvatarOption> = {},
): AvatarOption {
  const avatarOption: AvatarOption = {
    wrapperShape: WrapperShape.Circle,

    background: {
      color: getRandomValue(SETTINGS.backgroundColors),
    },

    widgets: {
      body: {
        shape: getRandomValue(SETTINGS.bodyShape),
        color: getRandomFillColor(SETTINGS.widgetColors.body),
      },
      eyes: {
        shape: getRandomValue(SETTINGS.eyesShape),
        color: getRandomFillColor(SETTINGS.widgetColors.eyes),
      },
      beak: {
        shape: getRandomValue(SETTINGS.beakShape),
        color: getRandomFillColor(SETTINGS.widgetColors.beak),
      },
    },
  };

  return avatarOption;
}

export function translateXMLIntoCSS(input: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'image/svg+xml');

  return Array.from(doc.querySelectorAll('linearGradient'))
    .map(lg => {
      const angle = getGradientAngle(lg);
      const stops = getGradientStops(lg);

      return {
        angle,
        stops: stops.filter(s => {
          return !!s && defined(s) && defined(s.offset) && defined(s.color);
        }),
      };
    });
}

function getGradientAngle(lg: SVGLinearGradientElement): number {
  const x1Attr = removeUnit((lg.getAttribute('x1') || '').trim());
  const x2Attr = removeUnit((lg.getAttribute('x2') || '').trim());
  const y1Attr = removeUnit((lg.getAttribute('y1') || '').trim());
  const y2Attr = removeUnit((lg.getAttribute('y2') || '').trim());

  // Defaults: https://developer.mozilla.org/en-US/docs/Web/SVG/Element/linearGradient
  // thanks @nVitius :)
  const x1 = toNumber(x1Attr, 0);
  const x2 = toNumber(x2Attr, 100);
  const y1 = toNumber(y1Attr, 0);
  const y2 = toNumber(y2Attr, 0);

  const x = x2 - x1;
  const y = y2 - y1;

  // Use 90deg for gradients only in the x-direction
  if (y === 0) {
    return 90;
  }

  const angleRad = Math.atan(y / x);

  return angleRad * 180 / Math.PI;
}

function removeUnit(value: string) {
  if (value.includes('%')) {
    return value.replace(/%/g, '');
  }

  return value;
}

function toNumber(value: string, defaultValue: number): number {
  const n = Number(value);
  return Number.isNaN(n) ? defaultValue : n;
}

function getGradientStops(lg: SVGLinearGradientElement) {
  return Array.from(lg.querySelectorAll('stop'))
    .map(stop => {
      let offsetStr = stop.getAttribute('offset');

      if (offsetStr === null) {
        return undefined;
      }

      offsetStr = offsetStr.trim();

      let offset: number;
      if (offsetStr.endsWith('%')) {
        offset = Number(offsetStr.replace('%', ''));
      } else {
        // Assume 0-1 as percentage when offset has no %
        offset = Number(offsetStr) * 100;
      }

      let color;
      let opacity;

      // Try to find the color using `stop-color` and `stop-opacity`
      if (stop.hasAttribute('stop-color')) {
        color = stop.getAttribute('stop-color');
        opacity = stop.getAttribute('stop-opacity');

        if (color && opacity) {
          const rgb = hexToRgb(color);

          if (rgb) {
            const { r, g, b } = rgb;
            color = `rgba(${r}, ${g}, ${b}, ${opacity})`;
          }
        }
      }

      // Try to find the color using `style`
      if (!color && stop.hasAttribute('style')) {
        let stylesAttr = stop.getAttribute('style');
        if (!stylesAttr) stylesAttr = '';
        const styles: CSSStyleDeclaration = getStyles(stylesAttr);
        color = styles.getPropertyValue('stop-color');
      }

      return { offset, color };
    });
}

function hexToRgb(hex: string): { r: number, g: number, b: number } | undefined {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : undefined;
}

function defined(value: any): boolean {
  return value !== undefined;
}

function getStyles(string: string): CSSStyleDeclaration {
  const el = document.createElement('div');
  el.setAttribute('style', string);
  return el.style;
}
