export default {
  loading: 'Vérification de la validité...',
  email: 'Veuillez entrer une adresse courriel valide',
  password: '*Ce champ doit contenir au minimum de 12 caractères avec au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère.',
  passwordMatch: 'Les mots de passe ne correspondent pas.',
  passwordSimilar: 'Le nouveau mot de passe ne doit pas être le même que l\'actuel.',
  required: 'Ce champ est requis',
  nonEmpty: 'Ce champ ne peut être vide',
  accessCode: 'Votre code d\'accès n\'est pas valide',
  frequency: 'Veuillez entrer un chiffre entre 1 et 100',
  date: 'Veuillez sélectionner une date valide',
  generic: 'Aucun utilisateur n\'a été trouvé.',
  video: 'Il y a eu une erreur lors du chargement de votre vidéo.',
  min_param: 'Doit contenir au moins {min} caractères',
  max_param: 'Doit contenir moins {max} caractères',
  min6: 'Doit contenir au moins 6 caractères',
  min8: 'Doit contenir au moins 8 caractères',
  max50: 'Doit contenir moins 50 caractères',
  max60: 'Doit contenir moins de 60 caractères',
  max100: 'Doit contenir moins de 100 caractères',
  max254: 'Doit contenir moins de 254 caractères',
  max255: 'Doit contenir moins de 255 caractères',
  max256: 'Doit contenir moins de 256 caractères',
  max1024: 'Doit contenir moins de 1 024 caractères',
  audio: 'Il y a eu une erreur lors du chargement de la piste audio.',
};
