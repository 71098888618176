var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("i18n", { attrs: { path: _vm.path + ".text", tag: "div" } }, [
        _c("a", { ref: "link", on: { click: _vm.onClick } }, [
          _vm._v(_vm._s(_vm.emailBuilder.mailto.label))
        ])
      ]),
      _vm.emailBuilder.additionalProperties &&
      _vm.emailBuilder.additionalProperties.fields &&
      _vm.emailBuilder.additionalProperties.fields.length
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.xsOnly ? "98%" : "800px",
                "content-class": "email-builder-dialog radius-15"
              },
              model: {
                value: _vm.prompt,
                callback: function($$v) {
                  _vm.prompt = $$v
                },
                expression: "prompt"
              }
            },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "", autocomplete: "off" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onAccept($event)
                    }
                  },
                  model: {
                    value: _vm.promptValid,
                    callback: function($$v) {
                      _vm.promptValid = $$v
                    },
                    expression: "promptValid"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("h3", { staticClass: "starling-h3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("common.member_support.contact.title")
                              )
                            )
                          ]),
                          _vm.emailBuilder.additionalProperties.explanation
                            ? _c("p", {
                                staticClass: "starling-body mb-4",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.emailBuilder.additionalProperties
                                      .explanation
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._l(
                            _vm.emailBuilder.additionalProperties.fields,
                            function(property) {
                              return _c(
                                "v-layout",
                                {
                                  key: property.id,
                                  attrs: { row: "", wrap: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      property.type === "input"
                                        ? _c("v-text-field", {
                                            attrs: {
                                              id: property.id,
                                              type: "text",
                                              label: property.label,
                                              placeholder: property.placeholder,
                                              rules: [
                                                function(v) {
                                                  return (
                                                    !v ||
                                                    v.length <= 255 ||
                                                    _vm.$t(
                                                      "common.errors.max255"
                                                    )
                                                  )
                                                }
                                              ],
                                              outline: "",
                                              counter: 255
                                            },
                                            model: {
                                              value:
                                                _vm.promptValues[property.id],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.promptValues,
                                                  property.id,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "promptValues[property.id]"
                                            }
                                          })
                                        : _vm._e(),
                                      property.type === "textarea"
                                        ? _c("v-textarea", {
                                            attrs: {
                                              id: property.id,
                                              label: property.label,
                                              placeholder: property.placeholder,
                                              "auto-grow": "",
                                              rules: [
                                                function(v) {
                                                  return (
                                                    !v ||
                                                    v.length <= 1024 ||
                                                    _vm.$t(
                                                      "common.errors.max1024"
                                                    )
                                                  )
                                                }
                                              ],
                                              outline: "",
                                              counter: 1024
                                            },
                                            model: {
                                              value:
                                                _vm.promptValues[property.id],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.promptValues,
                                                  property.id,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "promptValues[property.id]"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "v-layout",
                            {
                              class: {
                                "py-4": _vm.$vuetify.breakpoint.lgAndUp,
                                "py-2": _vm.$vuetify.breakpoint.mdAndDown
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-right",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "button",
                                        flat: "",
                                        small: _vm.$vuetify.breakpoint.mdAndDown
                                      },
                                      on: { click: _vm.onCancel }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("common.actions.cancel")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "primary-button",
                                    {
                                      attrs: {
                                        large: "",
                                        disabled: !_vm.promptValid
                                      },
                                      on: { click: _vm.onAccept }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "common.actions.create_email"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }