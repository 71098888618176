import Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    testEnvironment: boolean
    handleGenericError(data: object, message?: string): void
  }
}

export default Vue.extend({
  computed: {
    testEnvironment(): boolean {
      return (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'devaws' || process.env.NODE_ENV == 'staging' || this.$store.getters.email?.includes('@starlingminds.com'));
    },
  },
  methods: {
    handleGenericError(data: object, message?: string) {
      this.$log.warn('An error has happened in the application: ', data);

      if (!this.testEnvironment) {
        this.$router.push('/').catch((err) => {
          this.$log.warn('Error redirecting to default path: ', err);
        });
      } else {
        this.$router.push({ name: 'error', params: { errorData: JSON.stringify(data, undefined, 2), errorMessage: message || '' } });
      }
    },
  },
});
