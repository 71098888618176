









import Vue from 'vue';
import SmDynamicText, { DYNAMIC_TEXT_REGEX } from '@/views/components/sm-dynamic-text.vue';

export default Vue.extend({
  name: 'sm-markup-text',
  components: {
    SmDynamicText,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    internalValue(): String[] | undefined {
      return this.markup(this.value);
    },
    valueParts(): String[] {
      return this.internalValue?.filter(part => part !== undefined && part !== '') || [];
    },
  },
  methods: {
    isDynamicText(part: String | undefined) {
      return !!part && DYNAMIC_TEXT_REGEX.test(part.toString());
    },
  },
});
