import { Module } from 'vuex';
import { NavigationState } from './types';
import { RootState } from '../../types';

const defaultState: NavigationState = {
  top: {
    display: true,
    logo: false,
    title: '',
    subtitle: '',
    showBackButton: true,
    extension: {
      tabs: undefined,
      progress: undefined,
    },
  },
};

export const navigation: Module<NavigationState, RootState> = {
  namespaced: true,
  state: defaultState,
  getters: {
    tabs(state) {
      return state.top.extension.tabs?.length ? state.top.extension.tabs : undefined;
    },
    progress(state) {
      return state.top.extension.progress;
    },
  },
  mutations: {
    topHeader(state, value) {
      if (typeof value === 'object') {
        state.top.title = value.title;
        state.top.subtitle = value.subtitle;
      } else {
        state.top.title = value;
        state.top.subtitle = '';
      }
    },
    displayLogo(state, value) {
      state.top.logo = value;
    },
    setExtensionProgress(state, value) {
      state.top.extension.progress = value;
    },
    setExtensionTabs(state, value) {
      state.top.extension.tabs = value;
    },
    removeExtensionProgress(state) {
      state.top.extension.progress = undefined;
    },
    removeExtensionTabs(state) {
      state.top.extension.tabs = undefined;
    },
    topDisableBackButton(state) {
      state.top.showBackButton = false;
    },
    topEnableBackButton(state) {
      state.top.showBackButton = true;
    },
  },
};

export default navigation;
