var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "audio-icon",
      attrs: {
        width: "30",
        height: "30",
        viewBox: "0 0 35 30",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M18.3333 29.5417V26.9583C21.0278 26.1806 23.2292 24.6875 24.9375 22.4792C26.6458 20.2708 27.5 17.7639 27.5 14.9583C27.5 12.1528 26.6528 9.63889 24.9583 7.41667C23.2639 5.19444 21.0556 3.70833 18.3333 2.95833V0.375C21.7778 1.15278 24.5833 2.89583 26.75 5.60417C28.9167 8.3125 30 11.4306 30 14.9583C30 18.4861 28.9167 21.6042 26.75 24.3125C24.5833 27.0208 21.7778 28.7639 18.3333 29.5417ZM0 20V10H6.66667L15 1.66667V28.3333L6.66667 20H0ZM17.5 22V7.95833C19.0278 8.43056 20.2431 9.31944 21.1458 10.625C22.0486 11.9306 22.5 13.3889 22.5 15C22.5 16.5833 22.0417 18.0278 21.125 19.3333C20.2083 20.6389 19 21.5278 17.5 22Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }