export default {

  widget: {
    background: {
      name: 'Fondo',
    },
    body: {
      name: 'Cuerpo',
    },
    eyes: {
      name: 'Ojos',
      icon: 'mdi-eye',
    },
    beak: {
      name: 'Pico',
      icon: 'mdi-eye',
    },
  },
  body: {
    crow: {
      name: 'Cuervo',
    },
    duck: {
      name: 'Pata',
    },
    eagle: {
      name: 'Águila',
    },
    heron: {
      name: 'Garza',
    },
    owl: {
      name: 'Búho',
    },
    plover: {
      name: 'Chorlito',
    },
    sparrow: {
      name: 'Gorrión',
    },
    toucan: {
      name: 'Tucán',
    },
  },
  eyes: {
    standard: {
      name: 'Delanter',
    },
    left: {
      name: 'Izquierda',
    },
    right: {
      name: 'Derecha',
    },
    closed: {
      name: 'Cerrados',
    },
  },
  customize: {
    title: 'Personaliza tu avatar',
    tagline: 'Esto te representará en la comunidad.',
  },
  unsaved: {
    title: 'Tiene cambios sin guardar',
    content: 'Si continúas, perderás todos los cambios no guardados en tu avatar.',
  },
};
