







import Vue, { PropType } from 'vue';
import { WidgetType } from '@/views/avatar/enums';
import { AvatarOption, CompositeColorRenderer } from '@/views/avatar/types';
import { AVATAR_LAYER, NONE } from '@/views/avatar/constant';
import { WidgetData, widgetData } from '@/views/avatar/dynamic-data';

export default Vue.extend({
  name: 'starling-avatar-display',
  props: {
    value: {
      type: Object as PropType<AvatarOption>,
      required: false,
    },
    showSvg: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      avatarOptions: this.value as AvatarOption,
      svgContent: '',
    };
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.avatarOptions = val;
      },
    },
    avatarOptions: {
      deep: true,
      immediate: true,
      async handler(avatarOpt: AvatarOption) {
        const sortedList = Object.entries(avatarOpt.widgets).sort(
          ([ prevShape, prev ], [ nextShape, next ]) => {
            const ix = prev?.zIndex ?? AVATAR_LAYER[prevShape as WidgetType]?.zIndex ?? 0;
            const iix = next?.zIndex ?? AVATAR_LAYER[nextShape as WidgetType]?.zIndex ?? 0;
            return ix - iix;
          },
        );

        const promises: Promise<WidgetData | undefined>[] = sortedList.map(
          async ([ widgetType, opt ]) => {
            if (opt && opt.shape !== NONE && widgetData?.[widgetType as WidgetType]?.[opt.shape]) {
              const loadedWidgetData = (await widgetData[widgetType as WidgetType][opt.shape](avatarOpt));
              return loadedWidgetData;
            }
            return undefined;
          },
        );

        const svgRawList = await Promise.all(promises).then((raw) => {
          return raw.map((widgetData, i) => {
            const [ widgetType, widget ] = sortedList[i];
            if (!widgetData || !widgetData.content || !widgetData.content.length) return '';
            const rawContent = widgetData.content;
            const content = rawContent.slice(rawContent.indexOf('>', rawContent.indexOf('<svg')) + 1).replace('</svg>', '');
            const xmlColor: string = widget && widget.color ? new CompositeColorRenderer().toXML(widget.color, widgetType + '-color', widgetData) : '';
            return `
            <g id="starling-avatar-${sortedList[i][0]}">
              <defs>${xmlColor}</defs>
              ${content}
            </g>
          `;
          });
        });

        this.svgContent = `   <svg
                                width="100%"
                                height="100%"
                                viewBox="0 0 200 200"
                                preserveAspectRatio="xMidYMax meet"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                               <defs>
                               ${new CompositeColorRenderer().toXML(avatarOpt.background.color, 'background-color')}
                               </defs>
                                <rect fill="url(#background-color)" stroke-width="0" x="0" y="0" width="100%" height="100%" />
                                <g>
                                  ${svgRawList.join('')}
                                </g>
                              </svg>
                            `;

        this.$nextTick(() => {
          this.$emit('rendered');
        });
      },
    },
  },
});
