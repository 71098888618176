export default {
  1005: 'Nous ne sommes pas en mesure de nous connecter en ce moment.',
  1205: 'Aucun compte ne correspond à cette adresse courriel',
  1215: 'Le mot de passe que vous avez entré est incorrect.',
  1301: 'Votre clé de réinitialisation du mot de passe a été envoyée',
  1305: 'Aucun compte ne correspond à cette adresse courriel',
  1311: 'Votre mot de passe a été réinitialisé avec succès',
  1315: 'La clé de réinitialisation du mot de passe est invalide',
  1401: 'Votre compte a été créé',
  1405: 'Cette adresse courriel est déjà utilisée',
  1415: 'Votre code d’accès n\'est pas valide',
  2102: 'Votre profil a été mis à jour',
  2112: 'Votre langue a été définie',
  2103: 'Votre compte a été supprimé',
  3101: 'Votre objectif a été ajouté',
  3102: 'Votre objectif a été mis à jour',
  3104: 'Objectif complété',
  3112: 'Votre objectif a été rétabli',
  3122: 'Objectif noté',
  3103: 'Votre objectif a été supprimé',
  3201: 'Votre pensée a été ajoutée',
  3202: 'Votre pensée a été mise à jour',
  3212: 'Votre pensée a été rétablie',
  3222: 'Pensée notée',
  3203: 'Votre pensée a été supprimée',
  3301: 'Votre humeur a été ajoutée',
  3302: 'Votre humeur a été mise à jour',
  3312: 'Votre humeur a été rétablie',
  3322: 'Humeur notée',
  3303: 'Votre humeur a été supprimée',
  3401: 'Évaluation complétée',
  4201: 'Votre réponse a été ajoutée',
  4202: 'Votre réponse a été mise à jour',
  4203: 'Votre réponse a été supprimée',
  4301: 'Votre signet a été ajouté',
  4302: 'Votre signet a été retiré',
  5101: 'Votre commentaire a été ajouté',
  5102: 'Votre commentaire a été mis à jour',
  5112: 'Commentaire jugé utile',
  5103: 'Votre commentaire a été supprimé',
  6102: 'Préférences de notification mises à jour',
};
