var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "my-progress-icon",
      staticStyle: {
        display: "inline",
        margin: "0",
        padding: "0",
        width: "auto"
      },
      attrs: {
        viewBox: "0 0 46 51",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("rect", {
        attrs: {
          x: "1",
          y: "14.7812",
          width: "9.10345",
          height: "35.2188",
          rx: "1",
          "stroke-width": "2"
        }
      }),
      _c("rect", {
        attrs: {
          x: "19.2069",
          y: "1",
          width: "9.10345",
          height: "49",
          rx: "1",
          "stroke-width": "2"
        }
      }),
      _c("rect", {
        attrs: {
          x: "35.8965",
          y: "25.5",
          width: "9.10345",
          height: "24.5",
          rx: "1",
          "stroke-width": "2"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }