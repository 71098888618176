export default {
  title: 'Restablecer mi contraseña',
  content: {
    default: 'Para restablecer su contraseña, siga los pasos a continuación.',
    required: 'Parece que aún no has verificado tu dirección de correo electrónico. Para mantener su cuenta segura, restablezca su contraseña siguiendo los pasos a continuación.',
    common: [
      'Se ha enviado un código de confirmación a: <strong>{email}</strong>.',
      '<br/>Tienes <strong>1 hora</strong> para introducir el código. Si tiene varios códigos, asegúrese de utilizar el más reciente.<br/><br/>',
      '¿No has recibido un código?',
      '<ol><li>Comprueba que la dirección de correo electrónico anterior es válida. Si no, haz <a href="/landing/forgotten_password">clic aquí</a> para volver a intentar con el correo electrónico correcto. </li><li>Revisa tu carpeta de correo no deseado.</li><li>Si no encuentras el correo electrónico que contiene el código de confirmación en esta carpeta, envía un correo electrónico a <a href="mailto:members@starlingminds.com?subject=Restablecer%20contraseña%20código%20no%20recibido">members@starlingminds.com</a> para solicitar asistencia técnica.</li></ol>',
    ],
  },
  buttons: {
    set_password: 'Restablecer',
    new_reset_email: 'Enviar nuevo correo electrónico para restablecer la contraseña',
  },
  fields: {
    code: {
      title: 'Ingrese el código de confirmación.',
    },
    password: {
      title: 'Ingrese una nueva contraseña para su cuenta.',
    },
  },
};
