<template>
<svg
        style="display:inline; margin: 0; padding: 0; width: auto;"
        class="my-progress-icon"
        viewBox="0 0 46 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="14.7812" width="9.10345" height="35.2188" rx="1" stroke-width="2"/>
  <rect x="19.2069" y="1" width="9.10345" height="49" rx="1" stroke-width="2"/>
  <rect x="35.8965" y="25.5" width="9.10345" height="24.5" rx="1" stroke-width="2"/>
</svg>
</template>

<script>
export default {
  name: 'mood-tracker-icon',
};
</script>

<style scoped>
.my-progress-icon {
  fill: currentColor;
  stroke: transparent;
}
</style>
