import Vue from 'vue';
import { Auth } from 'aws-amplify';
import store from '@/scripts/store';
import router from '@/scripts/router';
import moment from 'moment';

export const baseConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: 'include',
};

/* eslint-disable no-useless-escape */
/* eslint-disable security/detect-unsafe-regex */
export const VALIDATION_REGEX = {
  EMAIL: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&,:;<>~_=\*\.\$\^\(\)\{\}\[\]\?\+\-\|\/\\]).{12,60}/,
};

/* eslint-enable no-useless-escape */

export function resetAuthorization() {

}

async function $makeApiRequest(path, method, data, formData) {
  if (!store.state.identity.configuration || !store.state.identity.configuration.backendUrl) throw new Error(`cannot call API without a configured backend : ${method} ${path}`);
  const url = store.state.identity.configuration.backendUrl + path;
  const config = {
    ...baseConfig,
    method,
  };

  if (data) config.body = JSON.stringify(data);

  const response = await fetch(url, config);
  const result = {
    body: false,
    formData: false,
    status: response.status,
    headers: response.headers,
    err: false,
  };

  if (!response.ok) {
    try {
      result.err = await response.json();
    } catch (ignored) {
      // do nothing
    }
    throw result;
  }

  if (response.status !== 204) formData ? result.formData = await response.formData() : result.body = await response.json();
  return result;
}

export const dealWithMaintenance = (response) => {
  if (response.status === 503) {
    router.push({ name: 'maintenance' });
  }
  const MAINTENANCE_NOTIFY_HEADER = 'X-SM-Maintenance-Notify';
  const MAINTENANCE_REDIRECT_HEADER = 'X-SM-Maintenance-Redirect';

  const parseHeader = (headerValue) => {
    const maintenanceStr = headerValue;
    const maintenanceParsed = maintenanceStr.split(';');
    const maintenanceProperties = maintenanceParsed.map(p => p.split('='));
    const duration = maintenanceProperties.find(p => p[0] === 'duration');
    const start = maintenanceProperties.find(p => p[0] === 'start');
    const maintenance = {
      duration: duration ? moment.duration(duration[1]) : null,
      start: moment(Number(start[1])),
    };
    return maintenance;
  };

  if (response.headers.has(MAINTENANCE_NOTIFY_HEADER) && !!response.headers.get(MAINTENANCE_NOTIFY_HEADER)) {
    store.commit('setMaintenance', parseHeader(response.headers.get(MAINTENANCE_NOTIFY_HEADER)));
  }

  if (response.headers.has(MAINTENANCE_REDIRECT_HEADER) && !!response.headers.get(MAINTENANCE_REDIRECT_HEADER)) {
    store.commit('setMaintenance', parseHeader(response.headers.get(MAINTENANCE_REDIRECT_HEADER)));
    if (router.currentRoute.name !== 'maintenance') {
      router.replace({ name: 'maintenance' });
    }
  }
  if (!response.headers.has(MAINTENANCE_NOTIFY_HEADER) && !response.headers.get(MAINTENANCE_REDIRECT_HEADER)) {
    store.commit('setMaintenance', null);
  }
};

let previousAccessToken = null;

export const api = {
  $apiRequest({ commit, dispatch }, [ path, callback, method = 'get', data = false, formData = false ]) {
    const session = Auth.currentSession();
    return session.then(tokens => {
      if (!tokens.isValid() || previousAccessToken === tokens.getAccessToken().getJwtToken()) return Promise.resolve();
      previousAccessToken = tokens.getAccessToken().getJwtToken();
      return this.dispatch('identity/updateAccessToken', tokens.getAccessToken().getJwtToken());
    }).catch((err) => {
      Vue.$log.debug('Error getting authenticated user session', err);
      return Promise.resolve();
    }).then(() => {
      return $makeApiRequest(path, method, data, formData);
    }).then(response => {
      Vue.$log.debug('Api request response', response);
      return response.body ? response.body : response;
    }).then(apiData => {
      Vue.$log.debug('Api request returning body', apiData);
      if (callback) return callback(apiData);
    }).catch(error => {
      Vue.$log.debug(error);
      commit('setLoadingView', false);
      commit('setLoadingSpinner', false);
      if (error.status === 503) {
        router.push({ name: 'maintenance' });
      } else if (error.status === 401 && !path.includes('/authenticate')) {
        // log user off in this case
        this.dispatch('identity/signOutCognito').then(() => location.reload());
      }
      return error;
    });
  },
};
