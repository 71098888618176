export default {
  end: {
    loading: {
      title: 'Loading',
      content: 'Please wait.',
    },
    success: {
      title: 'Logged In',
      content: 'You’ll be redirected in a few moments.',
    },
    error: {
      title: 'Error',
      content: 'An unexpected error occurred. Please try again. You’ll be redirected in a few moments.',
    },
  },
  sign_out: {
    success: {
      title: 'Success',
      content: 'You’ll be redirected in a few moments.',
    },
  },
};
