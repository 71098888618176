<template>
  <v-container fluid pa-0 fill-height>
    <v-layout row wrap fill-height align-center justify-center class="central-layout">
      <v-flex xs12 md8 lg5>
        <v-container class="central-container">
          <v-layout row wrap>
            <v-flex xs12>
              <slot></slot>
            </v-flex>
            <v-flex xs12>
              <v-footer v-if="$route.meta.footer === 'simple'" class="py-3" color="white">
                <div class="caption text--disabled text-center">
                  <span>© {{ new Date().getFullYear() }} Starling Minds™ Inc.</span>
                </div>
              </v-footer>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
    <maintenance-bar v-if="maintenance" rounded></maintenance-bar>
  </v-container>
</template>

<script>
import MaintenanceBar from '@/views/components/utilities/maintenance-bar';

export default {
  name: 'landing-central-card',
  components: { MaintenanceBar },
  props: {
    showMaintenance: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    maintenance() {
      return this.$store.getters.maintenance;
    },
  },
};
</script>

<style scoped>
.central-layout {
  background: url('/svg/landscape-top-background.svg') no-repeat;
  background-size: 100%;
}
</style>
