export default {
  title: 'Email Verification',
  subtitle: 'Please enter a new password for your account.',
  content: [
    'A confirmation code has been emailed to you.',
    'Please enter the code before it expires within the next hour.',
    'If you didn’t receive a code, please follow these steps to troubleshoot:',
    '1. Check your spam or junk folder in your email.',
    '2. If the code is not there, please email <a target="_blank" href="mailto:members@starlingminds.com">members@starlingminds.com</a> for technical support.',

  ],
  buttons: {
    verify: 'Verify my code',
    generate_token: 'Resend Code',
    log_in_again: 'Sign in again',
  },
  success: {
    generate_token: 'A new code has been sent to your email address.',
  },
  error: {
    EXPIRED: 'This code has expired.',
    WRONG_CODE: 'The code is invalid.',
    MISMATCH: 'The code is invalid.',
    TOO_MANY_TRIES: 'You have used invalid codes too many times. Please sign in again.',
    LIMIT_EXCEEDED: 'You have used invalid codes too many times. Please try again after some time.',
    CANNOT_GENERATE: 'No se puede generar un código nuevo. Please try again after some time.',
    default: 'An error occured.',
  },
};
