export default {
  'CD-RISC2': {
    name: 'CD-RISC2',
    label: 'Resilience',
    action_label: 'Add a resilience note',
    color: '#30CEFA',
    order: 0,
  },
  ENERGY: {
    name: 'ENERGY',
    label: 'Energy',
    action_label: 'Add an energy note',
    color: '#08E9cf',
    order: 1,
  },
  GAD7: {
    name: 'GAD7',
    label: 'Anxiety',
    action_label: 'Add an anxiety note',
    color: '#FF777D',
    order: 2,
  },
  PHQ9: {
    name: 'PHQ9',
    label: 'Depression',
    action_label: 'Add a depression note',
    color: '#4c75aa',
    order: 3,
  },
  STRESS: {
    name: 'STRESS',
    label: 'Stress',
    action_label: 'Add a stress note',
    color: '#ffb844',
    order: 4,
  },
  SLEEP: {
    name: 'SLEEP',
    label: 'Sleep',
    action_label: 'Add a sleep note',
    color: '#08E9cf',
  },
  PANIC: {
    name: 'PANIC',
    label: 'Panic',
    action_label: 'Add a panic note',
    color: '#08E9cf',
  },
  LDQ: {
    name: 'LDQ',
    label: 'Subtance use',
    action_label: 'Add a substance use note',
    color: '#058189',
  },
  MEBS: {
    name: 'MEBS',
    label: 'Eating habits',
    action_label: 'Add an eating habits note',
    color: '#08E9cf',
  },
  ADHD: {
    name: 'ADHD',
    label: 'Attention deficit hyperactivity disorder (ADHD)',
    action_label: 'Add an Attention deficit hyperactivity disorder (ADHD) note',
    color: '#08E9cf',
  },
  RTHCONFIDENCE: {
    name: 'RTHCONFIDENCE',
    label: 'Work readiness',
    action_label: 'Add a Work readiness note',
    color: '#058189',
  },
  drilldown: {
    unavailable: 'No interpretation of this particular assessment is available yet.',
  },
  scoreScale: [
    {
      value: 1,
      name: 'HH',
      labels: {
        long: 'Healthy: High Average',
        short: 'Healthy +',
      },
    },
    {
      value: 2,
      name: 'HA',
      labels: {
        long: 'Healthy: Average',
        short: 'Healthy',
      },
    },
    {
      value: 3,
      name: 'HL',
      labels: {
        long: 'Healthy: Low Average',
        short: 'Healthy -',
      },
    },
    {
      value: 4,
      name: 'BL',
      labels: {
        long: 'Borderline',
        short: 'Borderline',
      },
    },
    {
      value: 5,
      name: 'ML',
      labels: {
        long: 'Mild',
        short: 'Mild',
      },
    },
    {
      value: 6,
      name: 'MO',
      labels: {
        long: 'Moderate',
        short: 'Moderate',
      },
    },
    {
      value: 7,
      name: 'SE',
      labels: {
        long: 'Severe',
        short: 'Severe',
      },
    },
  ],
  simplifiedScoreScale: [
    {
      value: 3,
      name: 'HI',
      label: 'High',
    },
    {
      value: 2,
      name: 'MD',
      label: 'Moderate',
    },
    {
      value: 1,
      name: 'LO',
      label: 'Low',
    },
    {
      value: 0,
      name: 'VL',
      label: 'Very low',
    },
    {
      value: -1,
      name: 'NOT_EVALUATED',
      label: 'Not evaluated',
      hideInScale: true,
    },
  ],
};
