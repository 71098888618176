import { BoilerplateView } from '../common';
import store from '../../store';

const SleepTrackView = resolve => {
  require.ensure([ '@/views/tools/sleep/SleepTracker.vue' ], () => {
    resolve(require('@/views/tools/sleep/SleepTracker.vue'));
  }, 'sleep');
};
const EditSleepTrackView = resolve => {
  require.ensure([ '@/views/tools/sleep/EditSleepTrack.vue' ], () => {
    resolve(require('@/views/tools/sleep/EditSleepTrack.vue'));
  }, 'edit-sleep-track');
};

export default {
  path: 'sleep',
  component: BoilerplateView,
  beforeEnter(to, from, next) {
    store.commit('navigation/topEnableBackButton');
    store.commit('navigation/topHeader', 'app.tools.sleep_tracker.header_title');
    next();
  },
  children: [
    {
      name: 'sleep-track-list',
      path: '',
      component: SleepTrackView,
    },
    {
      name: 'sleep-track-create',
      path: 'create',
      component: EditSleepTrackView,
      props: route => {
        return {
          sleep: Promise.resolve({}),
        };
      },
    },
  ],
};
