var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fixed-maintenance-bar",
      class: {
        "with-bottom-nav":
          _vm.$vuetify.breakpoint.smAndDown && !_vm.$route.meta.public,
        mobile: _vm.$vuetify.breakpoint.smAndDown
      },
      staticStyle: { "z-index": "5" }
    },
    [
      _vm.$route.name !== "maintenance"
        ? _c(
            "v-alert",
            {
              staticClass: "ma-0 mx-1",
              class: {
                "radius-15": _vm.rounded,
                "pa-2": !_vm.$vuetify.breakpoint.smAndDown,
                "px-2 py-1": _vm.$vuetify.breakpoint.smAndDown
              },
              attrs: { color: "warning", value: true }
            },
            [
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-icon",
                    {
                      staticStyle: { cursor: "pointer", float: "right" },
                      on: {
                        click: function($event) {
                          _vm.showContent = !_vm.showContent
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.showContent
                              ? "mdi-chevron-down"
                              : "mdi-chevron-up"
                          ) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "p",
                {
                  staticClass:
                    "starling-explanatory font-weight-bold StarlingDarkGrey--text mb-1"
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("common.maintenance.notify.title")) +
                      "\n    "
                  )
                ]
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showContent,
                      expression: "showContent"
                    }
                  ],
                  key: _vm.key,
                  staticClass: "starling-citation StarlingDarkGrey--text"
                },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("common.maintenance.notify.message", {
                          countdown: _vm.countdown,
                          duration: _vm.duration
                        })
                      )
                    }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }