var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      key: "edit-" + _vm.editMode,
      staticClass: "dynamic-text editable pl-2",
      class: _vm.editMode ? "edit-mode" : "read-mode"
    },
    [
      _c(
        "span",
        {
          ref: "editableField",
          staticClass: "input-text",
          attrs: { contenteditable: _vm.editMode },
          on: {
            keydown: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.onEnter($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "escape",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                return _vm.onCancel($event)
              }
            ],
            paste: function($event) {
              $event.preventDefault()
              return _vm.onPaste($event)
            }
          }
        },
        [_vm._v(_vm._s(_vm.internalValue))]
      ),
      _c("span", { staticClass: "edit-button" }, [
        _c(
          "i",
          {
            staticClass: "v-icon material-icons cursor-pointer",
            attrs: { "aria-hidden": "true" },
            on: { click: _vm.onEdit }
          },
          [_vm._v("edit")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }