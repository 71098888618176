


























import Vue, { PropType } from 'vue';
import { WidgetShape, WidgetType } from '@/views/avatar/enums';
import { previewData } from '@/views/avatar/dynamic-data';

export default Vue.extend({
  name: 'starling-avatar-selector-shape-list',
  props: {
    title: {
      type: String,
    },
    widgetType: {
      type: String as PropType<WidgetType>,
    },
    value: {
      type: String as PropType<WidgetShape>,
    },
    available: {
      type: Array as PropType<Array<WidgetShape>>,
    },
  },
  data() {
    return {
      innerValue: this.value,
      previews: {} as { [key: string]: string },
    };
  },
  watch: {
    'value'(val: WidgetShape) {
      this.innerValue = val;
    },
    innerValue(val: WidgetShape) {
      this.$emit('input', val);
    },
  },
  mounted() {
    this.available.forEach(shape => {
      previewData[this.widgetType][shape]().then(p => {
        this.$set(this.previews, shape, p);
      });
    });
  },
  methods: {
    onSelect(index: number) {
      this.innerValue = this.available[index];
    },
    getPreviewImage(item: WidgetShape) {

    },
  },
});
