<template>
<div class="v-overlay v-overlay--active"
     style="z-index: 201;"
     :style="absolute? 'position:absolute;':''"
>
  <div class="loading-bounce" style="position: absolute;
  top: 0;
  bottom: 0;
  left:0;
  right:0;
  margin: auto;
">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>
</template>

<script>
export default {
  name: 'v-card-loader',
  props: [ 'absolute' ],
};
</script>
