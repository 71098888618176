import Vue from 'vue';

const auth = {
  state: {
    email_verification: {
      username: null,
      password: null,
      uuid: null,
    },
  },
  actions: {
    selfRegister({ dispatch, commit, state }, formData) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ '/registration/self', callback, 'post', formData ]);
    },
    externalUserRegister({ dispatch, commit, state }, formData) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ '/registration/external_user', callback, 'post', formData ]);
    },
    prepareReferralRegister({ dispatch, commit, state }, referralKey) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/referral?referralKey=${referralKey}`, callback, 'get' ]);
    },
    claimReferral({ dispatch, commit, state }, referralKey) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/referral/_claim?referralKey=${referralKey}`, callback, 'get' ]);
    },
    prepareReferralAcceptance({ dispatch, commit, state }, referralKey) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/referral/_prepare?referralKey=${referralKey}`, callback, 'get' ]);
    },
    getDefaultFields({ dispatch, commit, state }, accessCode) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/default_fields?accessCode=${accessCode}`, callback, 'get' ]);
    },
    getDefaultFieldsByImprint({ dispatch, commit, state }, accessCodeImprint) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/default_fields?accessCodeImprint=${encodeURIComponent(accessCodeImprint)}`, callback, 'get' ]);
    },
    getBusinessUnitLabel({ dispatch, commit, state }, accessCode) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/business_unit_label?accessCode=${accessCode}&lang=${localStorage.getItem('currLang')}`, callback, 'get' ]);
    },
    getBusinessUnitLabelByImprint({ dispatch, commit, state }, accessCodeImprint) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/business_unit_label?accessCodeImprint=${encodeURIComponent(accessCodeImprint)}&lang=${localStorage.getItem('currLang')}`, callback, 'get' ]);
    },
    getBusinessUnitLabelByReferralKey({ dispatch, commit, state }, referralKey) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/referral/business_unit_label_by_referral_key?referralKey=${referralKey}&lang=${localStorage.getItem('currLang')}`, callback, 'get' ]);
    },
    getCaseManagerName({ dispatch, commit, state }, referralKey) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/referral/case_manager?referralKey=${referralKey}`, callback, 'get' ]);
    },
    referralRegister({ dispatch, commit, state }, formData) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ '/registration/referral', callback, 'post', formData ]);
    },
    referralAccept({ dispatch, commit, state }, formData) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ '/registration/referral/_accept', callback, 'post', formData ]);
    },
    findExternalUser({ dispatch, commit, state }, idp) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/external_user/find_user?idp=` + idp, callback, 'get' ]);
    },
    findSp4User({ dispatch, commit, state }, data) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/migrated_member_by_email`, callback, 'post', data ]);
    },
    loginExternalUser({ dispatch, commit, state }, auth) {
      Vue.$log.debug('User is authenticated', auth.user.storage[auth.key]);
      commit('setIsAuthorized', true);
    },
    generateToken({ commit, dispatch, state }) {
      const data = {
        code: '0000',
        username: state.email_verification.username,
        password: state.email_verification.password,
      };
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ '/authenticate/_generateToken', callback, 'post', data ]);
    },
  },
};

export default auth;
