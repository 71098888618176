


















































































































import Vue, { PropType } from 'vue';
import { AvatarOption } from '@/views/avatar/types';
import { SETTINGS } from '@/views/avatar/constant';
import StarlingAvatarDisplay from '@/views/avatar/starling-avatar-display.vue';
import StarlingAvatarSelectorShapeCarousel from '@/views/avatar/builder/starling-avatar-selector-shape-carousel.vue';
import StarlingAvatarSelectorShapeList from '@/views/avatar/builder/starling-avatar-selector-shape-list.vue';
import StarlingAvatarSelectorColorList from '@/views/avatar/builder/starling-avatar-selector-color-list.vue';
import { WidgetType } from '@/views/avatar/enums';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import SecondaryButton from '@/views/components/button/secondary-button.vue';

export default Vue.extend({
  name: 'starling-avatar-builder',
  components: {
    SecondaryButton,
    StarlingAvatarSelectorShapeList,
    StarlingAvatarSelectorColorList,
    StarlingAvatarSelectorShapeCarousel,
    PrimaryButton,
    StarlingAvatarDisplay,
  },
  props: {
    value: {
      type: Object as PropType<AvatarOption>,
      required: false,
    },
    opened: {
      type: Boolean,
    },
  },
  data() {
    return {
      open: this.opened,
      cancelDialog: false,
      innerValue: this.value as AvatarOption,
      modified: false,
    };
  },
  computed: {
    WidgetType() {
      return WidgetType;
    },
    SETTINGS() {
      return SETTINGS;
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.innerValue = val;
        this.modified = false;
      },
    },
    innerValue: {
      deep: true,
      handler() {
        this.modified = true;
      },
    },
    opened(val) {
      this.open = val;
      this.modified = false;
    },
  },
  methods: {
    onRendered() {
      const avatarDisplay: Vue = this.$refs['avatarDisplay'] as Vue;
      if (avatarDisplay) {
        const avatarRef = avatarDisplay.$refs['avatarRef'] as HTMLElement;
        const avatarPayload = avatarRef.querySelector('.avatar-payload') as HTMLElement;

        this.$emit('rendered', avatarPayload.innerHTML);
      }
    },
    onStartCancel() {
      if (this.modified) {
        this.cancelDialog = true;
      } else {
        this.onConfirmCancel();
      }
    },
    onConfirmCancel() {
      this.$emit('cancel');
    },
    onSelect() {
      const avatarDisplay: Vue = this.$refs['avatarDisplay'] as Vue;
      if (avatarDisplay) {
        const avatarRef = avatarDisplay.$refs['avatarRef'] as HTMLElement;
        const avatarPayload = avatarRef.querySelector('.avatar-payload') as HTMLElement;

        this.$emit('save', {
          image: avatarPayload.innerHTML,
          options: this.innerValue,
        });
      }
    },
  },
});
