/* eslint-disable no-unused-vars */
import { BeakShape, BodyShape, EyesShape, WidgetType } from './enums';
import { AvatarOption } from '@/views/avatar/types';
import store from '@/scripts/store/index';

export type WidgetData = Readonly<{
  content: string,
  gradientCoordinates?: {
    x1: number,
    y1: number,
    x2: number,
    y2: number,
  }
}>
/** @internal */
type Data = Readonly<{
  [key in `${WidgetType}`]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key in string]: (options: AvatarOption) => Promise<WidgetData>
  }
}>

type PreviewData = Readonly<{
  [key in `${WidgetType}`]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key in string]: () => Promise<string>
  }
}>

const widgetData: Data = {
  [WidgetType.Body]: {
    [BodyShape.Crow]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/body/crow.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
            gradientCoordinates: {
              x1: 136.14,
              y1: 50.74,
              x2: 136.14,
              y2: 196.79,
            },
          };
        });
      });
    },
    [BodyShape.Duck]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/body/duck.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
            gradientCoordinates: {
              x1: 120.56,
              y1: 36.01,
              x2: 120.56,
              y2: 199.62,
            },
          };
        });
      });
    },
    [BodyShape.Eagle]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/body/eagle.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
            gradientCoordinates: {
              x1: 109.72,
              y1: 32.94,
              x2: 109.72,
              y2: 195.36,
            },
          };
        });
      });
    },
    [BodyShape.Heron]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/body/heron.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
            gradientCoordinates: {
              x1: 127.12,
              y1: 35.2,
              x2: 127.12,
              y2: 199.62,
            },
          };
        });
      });
    },
    [BodyShape.Owl]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/body/owl.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
            gradientCoordinates: {
              x1: 119.38,
              y1: 42.05,
              x2: 119.38,
              y2: 196.12,
            },
          };
        });
      });
    },
    [BodyShape.Plover]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/body/plover.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
            gradientCoordinates: {
              x1: 127.01,
              y1: 45.03,
              x2: 127.01,
              y2: 196.84,
            },
          };
        });
      });
    },
    [BodyShape.Sparrow]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/body/sparrow.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
            gradientCoordinates: {
              x1: 125.68,
              y1: 26.42,
              x2: 125.68,
              y2: 196.27,
            },
          };
        });
      });
    },
    [BodyShape.Toucan]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/body/toucan.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
            gradientCoordinates: {
              x1: 82.04,
              y1: 128.85,
              x2: 180.86,
              y2: 128.85,
            },
          };
        });
      });
    },
  },

  [WidgetType.Eyes]: {
    [EyesShape.Standard]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/eyes/${options.widgets.body?.shape}/standard.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
          };
        });
      });
    },
    [EyesShape.Right]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/eyes/${options.widgets.body?.shape}/right.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
          };
        });
      });
    },
    [EyesShape.Left]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/eyes/${options.widgets.body?.shape}/left.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
          };
        });
      });
    },
    [EyesShape.Closed]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/eyes/${options.widgets.body?.shape}/closed.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
          };
        });
      });
    },
  },

  [WidgetType.Beak]: {
    [BeakShape.Standard]: (options: AvatarOption) => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/widgets/beaks/${options.widgets.body?.shape}/standard.svg`, { mode: 'cors' }).then(response => {
        return response.text().then(txt => {
          return {
            content: txt,
          };
        });
      });
    },
  },
};

const previewData: PreviewData = {
  [WidgetType.Body]: {
    [BodyShape.Crow]: () => Promise.resolve(''),
    [BodyShape.Duck]: () => Promise.resolve(''),
    [BodyShape.Eagle]: () => Promise.resolve(''),
    [BodyShape.Heron]: () => Promise.resolve(''),
    [BodyShape.Owl]: () => Promise.resolve(''),
    [BodyShape.Plover]: () => Promise.resolve(''),
    [BodyShape.Sparrow]: () => Promise.resolve(''),
    [BodyShape.Toucan]: () => Promise.resolve(''),
  },
  [WidgetType.Beak]: {
    [BeakShape.Standard]: () => Promise.resolve(''),
  },
  [WidgetType.Eyes]: {
    [EyesShape.Standard]: async () => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/preview/eyes/standard.svg`, { mode: 'cors' }).then(response => response.text());
    },
    [EyesShape.Right]: () => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/preview/eyes/right.svg`, { mode: 'cors' }).then(response => response.text());
    },
    [EyesShape.Left]: () => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/preview/eyes/left.svg`, { mode: 'cors' }).then(response => response.text());
    },
    [EyesShape.Closed]: () => {
      return fetch(`${(((store.state as any).identity as any).configuration.avatarUrl)}/assets/preview/eyes/closed.svg`, { mode: 'cors' }).then(response => response.text());
    },
  },

};

export { widgetData, previewData };
