import { BoilerplateView } from '../common';
import store from '../../store';

const GoalsView = resolve => {
  require.ensure([ '@/views/tools/goals/Goals.vue' ], () => {
    resolve(require('@/views/tools/goals/Goals.vue'));
  }, 'goals');
};
const EditGoalView = resolve => {
  require.ensure([ '@/views/tools/goals/EditGoal.vue' ], () => {
    resolve(require('@/views/tools/goals/EditGoal.vue'));
  }, 'edit-goals');
};

export default {
  path: 'goals',
  component: BoilerplateView,
  beforeEnter(to, from, next) {
    store.commit('navigation/topEnableBackButton');
    store.commit('navigation/topHeader', 'app.tools.goal_setter.header_title');
    next();
  },
  children: [
    {
      name: 'goals-list',
      path: '',
      component: GoalsView,
    },
    {
      name: 'goal-create',
      path: 'create',
      component: EditGoalView,
      props: route => {
        return {
          goal: Promise.resolve({}),
        };
      },
    },
    {
      name: 'goal-duplicate',
      path: 'duplicate',
      component: EditGoalView,
      props: route => {
        return {
          goal: Promise.resolve(JSON.parse(route.params.goal)),
        };
      },
    },
    {
      name: 'goal-edit',
      path: ':id',
      component: EditGoalView,
      props: route => {
        return {
          goal: store.dispatch('goals/getGoal', route.params.id),
        };
      },
    },
  ],
};
