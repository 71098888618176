export enum Program {
  /* eslint-disable no-unused-vars */
  MENTAL_FITNESS = 'MENTAL_FITNESS',
  RTH_MENTAL = 'RTH_MENTAL',
  RTH_PHYSICAL = 'RTH_PHYSICAL',
  RTH_VETERANS = 'RTH_VETERANS'
}

export interface IdentityState {
  type: null | 'BASIC' | 'COBRANDED' | 'COLABELLED';
  program: null | Program;
  userIdentity: any;
  cognitoUser: any;
  configuration: any;
  organization: any;
  logo: any;
  theme: any;
  content: any;
  subdomain: any;
}

export interface AuthenticationUsernamePassword {
  username: string;
  password: string;
}
