<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"
    fill="none" style="display:inline; margin: 0; padding: 0; width: auto;"
    class="exercises-icon">
  <path class="icon-content" d="M18.042 0h-1.667L6.125 17.083a.625.625 0 0 0 0 .75c.167.25.333.417.667.417h6.083L11.208 30h1.667l10.167-17.083c.166-.25.166-.5 0-.834-.167-.25-.417-.416-.667-.416h-6L18.042 0Z"/>
  <path d="M16.458 2.333 15.125 11.5l-.167 1.417h6.667l-8.833 14.75 1.333-9.25.167-1.417H7.625l8.833-14.667ZM18.042 0h-1.667L6.125 17.083a.625.625 0 0 0 0 .75c.167.25.333.417.667.417h6.083L11.208 30h1.667l10.167-17.083c.166-.25.166-.5 0-.834-.167-.25-.417-.416-.667-.416h-6L18.042 0Z"/>
</svg>
</template>

<script>
export default {
  name: 'exercises-icon',
};
</script>

<style scoped>
.exercises-icon {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0.3;
}

.exercises-icon .icon-content {
  fill: transparent;
  stroke: transparent;
}

.exercises-icon.activated .icon-content,
.router-link-active .v-icon.exercises-icon .icon-content {
  fill: currentColor;
  stroke: transparent;
}
</style>
