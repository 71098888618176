import { Module } from 'vuex';
import { RootState } from '../../types';
import { IJournalCategory, IJournalEntry, JournalState } from './types';

export const journal: Module<JournalState, RootState> = {
  namespaced: true,
  actions: {
    getJournalCategories({ commit, state, dispatch }) {
      const callback = (res: Array<IJournalCategory>) => {
        return res;
      };
      return dispatch('$apiRequest', [ '/tools/journal/categories', callback ], { root: true });
    },
    getJournalEntries({ commit, state, dispatch }, { productId, isToolkit, isStress }) {
      let path = '';
      if (isToolkit) path = 'toolkit';
      if (isStress) path = 'stress';
      if (productId) path = 'products/' + productId;
      const callback = (res: Array<IJournalEntry>) => {
        return res;
      };
      return dispatch('$apiRequest', [ '/tools/journal/' + path, callback ], { root: true });
    },
    getJournalEntry({ commit, state, dispatch }, entryId: number) {
      const callback = (res: IJournalEntry) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/journal/entries/${entryId}`, callback ], { root: true });
    },
    editJournalEntry({ commit, dispatch }, entry: IJournalEntry) {
      const callback = (res: IJournalEntry) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/journal/entries/${entry.id}`, callback, 'put', entry ], { root: true });
    },
  },
};
