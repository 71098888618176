export default {
  title: 'Establecer una nueva contraseña segura',
  content: {
    default: 'Parece que inició sesión con una contraseña temporal y debe establecer una nueva contraseña permanente para mantener su cuenta segura.',
  },
  buttons: {
    set_password: 'Set',
  },
  fields: {
    password: {
      title: 'Ingrese una nueva contraseña para su cuenta.',
    },
  },
  error: {
    message: 'Ocurrió un error. Inténtalo de nuevo.',
  },
};
