var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "goal-setter-icon",
      staticStyle: {
        display: "inline",
        margin: "0",
        padding: "0",
        width: "auto",
        height: "auto"
      },
      attrs: {
        viewBox: "0 0 95 95",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("rect", { attrs: { width: "95", height: "95", fill: "white" } }),
      _c("circle", {
        attrs: {
          cx: "59.3146",
          cy: "38.5197",
          r: "18.9828",
          transform: "rotate(2.61635 59.3146 38.5197)",
          fill: "url(#gs-paint0)",
          "fill-opacity": "0.8"
        }
      }),
      _c("path", {
        staticClass: "icon-colorized",
        attrs: {
          d:
            "M69.5916 64.0592L50.1387 38.2022C50.0265 38.0358 49.8584 37.8693 49.6902 37.7583V22.9988C49.6902 22.4439 49.2417 22 48.6811 22H35.3948C34.2736 22 33.3206 22.8878 33.3206 24.053V30.4895C33.3206 31.5993 34.2736 32.5425 35.3948 32.5425H47.672V37.7583C47.5599 37.8138 47.3917 37.9248 47.2796 38.0358L34.9463 50.2429L31.9191 47.3576C31.5266 46.9692 30.966 46.8027 30.4615 46.8582C29.9009 46.9137 29.3963 47.1911 29.116 47.635L17.3434 64.0592C16.9509 64.6141 16.8949 65.3909 17.1752 66.0012C17.5116 66.6116 18.1282 67 18.857 67H68.1901C68.9189 67 69.5355 66.6116 69.8719 65.9457C70.0961 65.3909 70.0401 64.6141 69.5916 64.0592ZM35.3948 30.4895C35.3948 30.4895 35.3387 30.4895 35.3948 30.4895L35.3387 24.053C35.3387 24.053 35.3387 23.9975 35.3948 23.9975H47.672V30.4895H35.3948ZM19.0252 65.058L30.5736 48.9667L33.6009 51.7965C34.3296 52.5179 35.5069 52.4624 36.2357 51.7411L48.569 39.5339L67.7977 65.0025L19.0252 65.058Z"
        }
      }),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "gs-paint0",
                x1: "22.0934",
                y1: "38.1475",
                x2: "59.3146",
                y2: "57.5025",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", {
                attrs: { "stop-color": "white", "stop-opacity": "0" }
              }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#FFDC75" } })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }