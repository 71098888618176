export default {
  accessCode: {
    title: 'Quel est votre code d\'accès?',
    tips: {
      text: 'Vous pouvez trouver cette information auprès de votre organisme parrain. Si vous n\'avez pas de code ou d\'organisme parrain, {0}.',
      mailto: {
        label: 'nous pouvons vous aider',
        to: 'membres@starlingminds.com',
        subject: 'Quel est mon code d\'accès?',
        body: 'Nom de l\'organisation ou du syndicat: ###organization### \nProfession: ###profession###\n\nVotre message:\n###freeText###',
      },
      additionalProperties: {
        explanation: 'Pour aider le service de soutien aux membres à trouver rapidement votre code d’accès, veuillez remplir le formulaire ci-dessous. En cas de doute, laissez les champs vides; le service de soutien aux membres fera un suivi auprès de vous.',
        fields: [
          {
            type: 'input',
            id: 'organization',
            label: 'Nom de l’organisation ou du syndicat',
            placeholder: '',
          },
          {
            type: 'input',
            id: 'profession',
            label: 'Profession',
            placeholder: '',
          },
          {
            type: 'textarea',
            id: 'freeText',
            label: 'Toute autre information que vous souhaitez nous communiquer',
            placeholder: '',
          },
        ],
      },
    },
    fields: {
      accessCode: {
        label: 'Entrez le CODE D\'ACCÈS de votre organisation parrainante',
        hint: '* Champ obligatoire',
        errors: {
          generic: 'Une erreur s\'est produite.',
          404: 'Votre code d’accès n\'est pas valide.',
          400: 'Votre code d’accès est expiré.',
        },
      },
      memberCode: {
        label: 'Identifiant de membre',
        hint: '* Champ obligatoire',
      },
      migratedUser: {
        title: 'Bienvenue dans votre nouveau Starling!',
        introduction: 'Ici, vous trouverez le tout nouveau bilan qui fournit des informations plus approfondies sur votre état de santé, un entraînement personnalisé qui s\'adapte à vos besoins actuels, des outils pour soutenir votre forme mentale et des communautés de pairs qui vous soutiennent.',
        setup: 'Pour configurer votre compte, vous devrez répondre à quelques questions et créer un nouveau mot de passe.',
        start: 'Commençons…',
      },
    },
  },
  account: {
    title: 'Bienvenue à {name}!',
    MENTAL_FITNESS: {
      introduction: [ 'Nous sommes content de vous voir.', 'Nous pouvons vous aider à acquérir de nouvelles compétences de gestion du stress, de l\'anxiété et de la dépression.' ],
      privacy: [ 'Votre activité au sein de Starling est 100% confidentielle. Nous ne partageons ni de vendons pas vos informations personnelles de santé mentale à qui que ce soit', 'Si vous avez des questions, veuillez s\'il vous plait vous référer aux Conditions d\'Utilisation et à la Politique de Confidentialité.' ],
      privacy_clinician: [ 'Votre activité au sein de Starling est hautement confidentielle. Pour favoriser votre retour à la santé, nous ne partagerons que ce qui est strictement nécessaire avec votre clinicien et votre prestataire de soins de santé', `Si vous avez des questions, veuillez s'il vous plait vous référer aux Conditions d'Utilisation et à la Politique de Confidentialité. ` ],
    },
    RTH_MENTAL: {
      introduction: [ 'Nous sommes content de vous voir.', 'Être en arrêt de travail peut être une période incertaine, stressante et émotionnelle. Nous sommes ici pour vous soutenir pendant que vous la traversez.' ],
      privacy: [ 'Votre activité au sein de Starling est hautement confidentielle. Nous ne partagerons avec votre fournisseur que le strict nécessaire pour vous aider à retourner au travail.', 'Si vous avez des questions, veuillez s\'il vous plait vous référer aux Conditions d\'Utilisation et à la Politique de Confidentialité.' ],
      privacy_clinician: [ 'Votre activité au sein de Starling est hautement confidentielle. Pour favoriser votre retour à la santé, nous ne partagerons que ce qui est strictement nécessaire avec votre clinicien et votre prestataire de soins de santé', `Si vous avez des questions, veuillez s'il vous plait vous référer aux Conditions d'Utilisation et à la Politique de Confidentialité. ` ],
    },
    RTH_PHYSICAL: {
      introduction: [ 'Nous sommes content de vous voir.', 'Être en arrêt de travail peut être une période incertaine, stressante et émotionnelle. Nous sommes ici pour vous soutenir pendant que vous la traversez.' ],
      privacy: [ 'Votre activité au sein de Starling est hautement confidentielle. Nous ne partagerons avec votre fournisseur que le strict nécessaire pour vous aider à retourner au travail.', 'Si vous avez des questions, veuillez s\'il vous plait vous référer aux Conditions d\'Utilisation et à la Politique de Confidentialité.' ],
      privacy_clinician: [ 'Votre activité au sein de Starling est hautement confidentielle. Pour favoriser votre retour à la santé, nous ne partagerons que ce qui est strictement nécessaire avec votre clinicien et votre prestataire de soins de santé', `Si vous avez des questions, veuillez s'il vous plait vous référer aux Conditions d'Utilisation et à la Politique de Confidentialité. ` ],
    },
    RTH_VETERANS: {
      introduction: [ 'Nous sommes heureux de vous compter parmi nous.', 'Nous sommes là pour vous aider à renforcer votre forme mentale et à trouver des moyens de vous sentir mieux.' ],
      privacy: [ 'Votre activité au sein de Starling est hautement confidentielle. Nous ne partagerons avec votre fournisseur que le strict nécessaire pour vous aider à retourner au travail.', 'Si vous avez des questions, veuillez s\'il vous plait vous référer aux Conditions d\'Utilisation et à la Politique de Confidentialité.' ],
      privacy_clinician: [ 'Votre activité au sein de Starling est hautement confidentielle. Pour favoriser votre retour à la santé, nous ne partagerons que ce qui est strictement nécessaire avec votre clinicien et votre prestataire de soins de santé', `Si vous avez des questions, veuillez s'il vous plait vous référer aux Conditions d'Utilisation et à la Politique de Confidentialité. ` ],
    },
    account_subtitle: 'Commençons par créer votre compte',
    privacy_subtitle: 'Votre vie privée est importante',
    fields: {
      email: {
        title: 'Quel est votre adresse courriel?',
        label: '',
        placeholder: 'Votre adresse courriel',
        hint: '* Champ obligatoire',
      },
      password: {
        title: 'Créez votre mot de passe securisé',
        label: '',
        placeholder: 'Your mot de passe',
        hint: '* Champ obligatoire',
        rules: {
          special_char: 'Au moins un caractère spécial',
          lowercase_char: 'Au moins un caractère minuscule',
          uppercase_char: 'Au moins un caractère majuscule',
          numerical_char: 'Au moins un chiffre',
          size: '12 caractères minimum',
        },
        errors: {
          generic: 'Chacune des conditions ci-dessous doit être remplie.',
        },
      },
      passwordConfirmation: {
        title: 'Veuillez confirmer votre mot de passe',
        label: '',
        placeholder: 'Votre confirmation de mot de passe',
        hint: '* Champ obligatoire',
      },
      agreement: {
        label: 'J\'ai lu et j\'accepte les ',
        conjunction: ' et ',
        age: ' et déclare avoir 16 ans ou plus',
        termsOfUseLink: 'https://www.starlingminds.com/terms-of-use-french/',
        privacyPolicyLink: 'https://www.starlingminds.com/privacy-policy-french/',
        communityGuidelinesLink: 'https://www.starlingminds.com/community-guidelines-french/',
      },
      marketing: {
        label: 'Faites-moi savoir lorsque de nouveaux webinaires, de nouvelles fonctionnalités et du nouveau contenu sont disponibles.',
      },
    },
  },
  profile: {
    title: 'Fait pour vous.',
    content: 'Maintenant que votre compte est prêt, apprenez-nous en un peu sur vous.',
    name_subtitle: 'Votre nom',
    name_content: 'Seule l\'application Starling vous appellera ainsi. Votre prénom ou alias ne sera pas rendu public.',
    profile_subtitle: 'Votre profil',
    profile_content: 'Starling vous fournira du contenu personnalisé et vous inclura dans une communauté en fonction de ces réponses.',
    codename_subtitle: 'Nom de code de la communauté',
    codename_content: 'Ce sera la façon dont vous serez identifié(e) dans notre communauté Starling anonyme.',
    finalisation_content: [ 'Tout est beau! Vous pourrez changer ces informations à tout moment à partir de votre profil.', 'Parfait! Regardons maintenant comment vous allez!' ],
    registerButton: 'C\'est parti!',
    fields: {
      firstname: {
        title: 'Comment voulez-vous qu\'on vous appelle?',
        label: '',
        placeholder: 'Votre prénom',
        hint: '* Champ obligatoire',
      },
      businessUnit: {
        title: 'Où travaillez-vous?',
        label: '',
        placeholder: 'Sélectionnez votre lieu de travail.',
        hint: '* Champ obligatoire',
        notListed: 'Non listé',
        notListedPlaceholder: 'Ajoutez votre {param}',
        skip: {
          label: 'Je ne suis pas sûr',
        },
        errors: {
          loading: 'Les lieux ne sont pas disponibles pour l\'instant.',
          search: 'Aucun résultat trouvé',
        },
      },
      profession: {
        title: 'Quel est votre emploi?',
        label: '',
        placeholder: 'Sélectionnez votre emploi',
        hint: '* Champ obligatoire',
        errors: {
          loading: 'Les emplois ne sont pas disponibles pour l\'instant.',
          search: 'Aucun résultat trouvé',
        },
      },
      birthYear: {
        title: 'Peut-on connaître votre année de naissance?',
        label: '',
        placeholder: 'Sélectionnez votre année de naissance',
        hint: '',
        skip: {
          label: 'Je préfère garder cette information secrète',
        },
        errors: {
          search: 'Aucun résultat trouvé',
        },
      },
      gender: {
        title: 'Comment souhaitez-vous qu\'on s\'adresse à vous?',
        placeholder: 'Sélectionnez votre genre',
        label: '',
        hint: '',
        skip: {
          label: 'Je préfère garder cette information secrète',
        },
        custom: {
          placeholder: 'Ajoutez votre auto-identification de genre',
          label: '',
          hint: '',
        },
      },
      codename: {
        placeholder: 'Aucun généré',
        action: 'Génerer aléatoirement',
      },
    },
  },
  referral: {
    title: 'Référence',
    content: [
      { value: 'En acceptant cette recommandation, vous franchissez la première étape vers l\'amélioration de votre résilience!' },
      { value: 'Acquérez des compétences pour mieux gérer le stress, l\'anxiété et la dépression grâce à une thérapie guidée personnalisée.' },
      { value: 'Découvrez de nouvelles stratégies, améliorez vos observations et branchez-vous à une communauté d\'entraide de manière confidentielle.' },
      {
        cssClass: 'caption text-center mt-4',
        value: 'Des questions? N\'hésitez pas à <a target="_blank" href="mailto:membres@starlingminds.com?subject=Retours%20and%20questions">nous appeler</a> en tout temps.',
      },
    ],
    expired: {
      title: 'Référence expirée',
      content: 'Ce lien de référence est expiré. Veuillez contacter {firstName} {lastName} afin de recevoir un nouveau lien.',
    },
    invalid: {
      title: 'Lien invalide',
      content: 'Vous avez cliqué sur un lien invalide.<br/><br/> Pour une assistance additionnelle, veuillez communiquer avec nous à <a href="mailto:membres@starlingminds.com?subject=R%C3%A9f%C3%A9rence%20-%20Lien%20invalide">membres@starlingminds.com</a>.',
    },
    cancelled: {
      title: 'Référence annulée',
      content: 'Veuillez utiliser le plus récent courriel que nous vous avons envoyé pour accepter le renvoi de référence.<br/><br/> Pour une assistance additionnelle, veuillez communiquer avec nous à <a href="mailto:membres@starlingminds.com?subject=R%C3%A9f%C3%A9rence%20-%20Lien%20annul%C3%A9">membres@starlingminds.com</a>.',
    },
    empty: {
      title: 'Lien invalide',
      content: 'Vous avez cliqué sur un lien invalide.<br/><br/> Pour une assistance additionnelle, veuillez communiquer avec nous à <a href="mailto:membres@starlingminds.com?subject=R%C3%A9f%C3%A9rence%20-%20Lien%20invalide">membres@starlingminds.com</a>.',
    },
    simulated: {
      title: 'Référence simulée',
      content: 'Vous avez utilisé un lien de simulation.<br/>Un lien réel permettrait ici d\'accéder à une inscription pré-remplie.<br/><br/> Pour une assistance additionnelle, veuillez communiquer avec nous à <a href="mailto:membres@starlingminds.com?subject=R%C3%A9f%C3%A9rence%20-%20Lien%20simul%C3%A9">membres@starlingminds.com</a>.',
    },
  },
};
