export default {
  1005: 'No podemos conectarnos en este momento.',
  1205: 'No se ha encontrado ninguna cuenta para este correo electrónico.',
  1215: 'La contraseña que has introducido es incorrecta.',
  1301: 'Se ha enviado tu clave de restablecimiento de contraseña.',
  1305: 'No se ha encontrado ninguna cuenta para este correo electrónico.',
  1311: 'La contraseña se ha restablecido correctamente.',
  1315: 'La clave de restablecimiento de la contraseña no es válida.',
  1401: 'Tu cuenta ha sido creada.',
  1405: 'Este correo electrónico ya está en uso.',
  1415: 'Tu código de acceso no es válido.',
  2102: 'Tu perfil ha sido actualizado.',
  2103: 'Tu cuenta ha sido eliminada.',
  2112: 'Tu idioma ha sido establecido.',
  3101: 'Se ha añadido tu objetivo.',
  3102: 'Se ha actualizado tu objetivo.',
  3103: 'Se ha eliminado tu objetivo.',
  3104: 'Objetivo cumplido',
  3112: 'Se ha restablecido tu objetivo.',
  3122: 'Objetivo anclado',
  3201: 'Se ha añadido tu pensamiento.',
  3202: 'Se ha actualizado tu pensamiento.',
  3203: 'Se ha borrado tu pensamiento.',
  3212: 'Se ha restablecido tu pensamiento.',
  3222: 'Pensamiento anclado',
  3301: 'Se ha añadido tu estado de ánimo.',
  3302: 'Se ha actualizado tu estado de ánimo.',
  3303: 'Se ha borrado tu estado de ánimo.',
  3312: 'Se ha restablecido tu estado de ánimo.',
  3322: 'Estado de ánimo anclado',
  3401: 'Evaluación completada',
  4201: 'Se ha añadido tu respuesta.',
  4202: 'Se ha actualizado tu respuesta.',
  4203: 'Se ha borrado tu respuesta.',
  4301: 'Se ha añadido tu marcador.',
  4302: 'Se ha eliminado tu marcador.',
  5101: 'Se ha añadido tu comentario.',
  5102: 'Se ha actualizado tu comentario.',
  5103: 'Se ha borrado tu comentario.',
  5112: 'Comentario marcado “me gusta”',
  6102: 'Preferencias de notificación actualizadas',
};
