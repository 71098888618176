<template>
  <v-form autocomplete="off"
          transition="slide-y-reverse-transition"
          ref="form"
          v-model="valid">

    <v-card-text class="pa-0">
      <v-layout row wrap justify-center>
        <v-flex xs12 sm11 md10>
          <h1 class="starling-h1">
            {{ $t('public.signup.setup_referral.accept_referral.title', { name: partnerName }) }}
          </h1>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-3>
          <p class="starling-body starling-content">
            {{ $t('public.signup.setup_referral.accept_referral.content', { name: partnerName }) }}
          </p>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-3>
          <h3 class="starling-h3">
            {{ $t('public.signup.setup_referral.accept_referral.name.title', { name: partnerName }) }}
          </h3>
        </v-flex>
        <v-flex xs12 sm11 md10 mt-1>
          <p class="starling-body starling-content">
            {{ $t(`public.signup.setup_referral.accept_referral.name.content`, { name: partnerName }) }}
          </p>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-3>
          <p class="starling-body font-weight-medium">
            {{ $t('public.signup.setup_referral.accept_referral.name.fields.firstname.title', { name: partnerName }) }}
          </p>
          <registration-field-firstname v-model="form.name" class="mt-2"/>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-3>
          <h3 class="starling-h3">
            {{ $t('public.signup.setup_referral.accept_referral.profile.title', { name: partnerName }) }}
          </h3>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-1>
          <p class="starling-body starling-content">
            {{ $t(`public.signup.setup_referral.accept_referral.profile.content`, { name: partnerName }) }}
          </p>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-3>
          <template v-if="referral.memberCodeConfiguration && referral.memberCodeConfiguration.active">
              <p class="starling-body font-weight-medium">
                {{ $t('public.signup.setup_referral.accept_referral.profile.fields.memberCode.title') }}
              </p>
              <registration-field-member-id class="w-full"
                                            v-model="form.memberCode"
                                            :configuration="referral.memberCodeConfiguration"/>
          </template>

          <p class="starling-body mt-3 font-weight-medium">
            {{
              businessUnitLabels.question ? businessUnitLabels.question : $t('public.registration.profile.fields.businessUnit.title')
            }}
          </p>
          <registration-field-business-unit v-model="form.businessUnit"
                                            :label="businessUnitLabels.label"
                                            :referral-key="referralKey"
                                            :enable-not-listed="businessUnitLabels.allowOther">
          </registration-field-business-unit>

          <p class="starling-body mt-3 font-weight-medium">
            {{ $t('public.registration.profile.fields.profession.title') }}
          </p>
          <registration-field-profession
              class="pt-2"
              v-model="form.professionId"
              :referral-key="referralKey">
          </registration-field-profession>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-3>
          <p class="starling-body font-weight-medium">
            {{ $t('public.signup.setup_referral.accept_referral.profile.fields.birthYear.title', { name: partnerName }) }}
          </p>
          <registration-field-birth-year v-model="form.birthYear" class="mt-2"/>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-3>
          <p class="starling-body font-weight-medium">
            {{ $t('public.signup.setup_referral.accept_referral.profile.fields.gender.title', { name: partnerName }) }}
          </p>
          <registration-field-gender v-model="form.gender" class="mt-2"/>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-3>
          <h3 class="starling-h3">
            {{ $t('public.signup.setup_referral.accept_referral.community.title', { name: partnerName }) }}
          </h3>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-2>
          <p class="starling-body starling-content">
            {{ $t('public.signup.setup_referral.accept_referral.community.content', { name: partnerName }) }}
          </p>
          <v-layout row wrap align-center justify-center>
            <v-flex xs8 sm6 md4 lg3 pa-2 text-center>
              <starling-avatar-input :value="form.avatar ? JSON.parse(form.avatar):null" @saved="onUpdateAvatar"/>
            </v-flex>
            <v-flex xs12 sm6 md8 lg9 align-center justify-center text-center>
              <registration-field-codename class="mt-2" v-model="form.codename"
                                           @required-codename="provideCodename"/>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-btn
                  class="primary--text font-weigth-bold"
                  @click="provideCodename"
                  flat block large>
                <v-icon left class="mr-2" style="width:24px">$vuetify.icons.starling_randomize</v-icon>
                {{ $t('public.registration.profile.fields.codename.action') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-3>
          <h3 class="starling-h3">
            {{ $t('public.signup.setup_referral.accept_referral.privacy.title', { name: partnerName }) }}
          </h3>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-1>
          <p class="starling-body starling-content" v-html="$t(`public.signup.setup_referral.accept_referral.privacy.content.${referral.memberCodeConfiguration ? referral.memberCodeConfiguration.program : 'MENTAL_FITNESS'}.${privacyDisclaimer ? 'privacy_clinician' : 'privacy'}`, { name: partnerName })"/>
        </v-flex>
        <v-flex xs12 sm11 md10 mt-3>
          <registration-field-agreement v-model="form.agreement" :referral-key="referralKey"/>
        </v-flex>

        <v-flex xs12 sm11 md10 mt-3>
          <registration-field-marketing v-model="form.newsletter"/>
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-card-actions class="pa-0 pt-3">
      <v-layout row wrap justify-center align-center gap-xs-3>
        <v-flex xs12 text-center pt-3>
          <primary-button
              id="btn-do-register"
              type="button"
              large
              :disabled="!valid || !form.agreement || loading"
              :loading="loading"
              @click="doRegister">
            {{ $t('public.signup.setup_referral.accept_referral.buttons.register') }}
          </primary-button>
        </v-flex>

        <v-flex xs12 text-center>
          <v-btn
              id="btn-cancel"
              flat round
              :disabled="loading"
              @click="cancel">
            {{ $t('common.actions.cancel') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-form>
</template>

<script>
import PrimaryButton from '@/views/components/button/primary-button.vue';
import RegistrationFieldBirthYear from '@/views/landing/register/components/registration-field-birth-year.vue';
import RegistrationFieldBusinessUnit from '@/views/landing/register/components/registration-field-business-unit.vue';
import RegistrationFieldCodename from '@/views/landing/register/components/registration-field-codename.vue';
import RegistrationFieldFirstname from '@/views/landing/register/components/registration-field-firstname.vue';
import RegistrationFieldGender from '@/views/landing/register/components/registration-field-gender.vue';
import RegistrationFieldMarketing from '@/views/landing/register/components/registration-field-marketing.vue';
import RegistrationFieldProfession from '@/views/landing/register/components/registration-field-profession.vue';
import { mapState } from 'vuex';
import RegistrationFieldMemberId from '@/views/landing/register/components/registration-field-member-id.vue';
import { Auth } from 'aws-amplify';
import RegistrationFieldAgreement from '@/views/landing/register/components/registration-field-agreement.vue';

export default {
  name: 'accept-referral',
  components: {
    RegistrationFieldAgreement,
    RegistrationFieldMemberId,
    PrimaryButton,
    RegistrationFieldBirthYear,
    RegistrationFieldBusinessUnit,
    RegistrationFieldCodename,
    RegistrationFieldFirstname,
    RegistrationFieldGender,
    RegistrationFieldMarketing,
    RegistrationFieldProfession,
    StarlingAvatarInput: () => import('@/views/avatar/starling-avatar-input.vue'),
  },
  props: {
    referralKey: {
      type: String,
      required: true,
    },
    referral: {
      type: Object,
      required: true,
    },
    userIdentity: {
      type: Object,
      required: true,
    },
  },
  data() {
    const initialForm = {
      agreement: false,
      name: null,
      memberCode: null,
      businessUnit: {
        value: null,
        type: null,
        custom: null,
      },
      professionId: null,
      birthYear: null,
      gender: null,
      genderValue: null,
      professionKey: null,
      newsletter: false,
      codename: null,
      avatar: null,
      image: null,
    };

    return {
      loading: false,
      valid: false,
      referralLoaded: false,
      businessUnitLabels: {
        label: null,
        question: null,
        allowOther: null,
      },
      form: initialForm,
      privacyDisclaimer: false,
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
    partnerName() {
      return this.partner && this.partner.content && this.partner.content['NAME'] && this.partner.content['NAME'][this.locale] ? this.partner.content['NAME'][this.locale] : 'Starling';
    },
  },
  mounted() {
    const promises = [];
    promises.push(this.$store.dispatch('getBusinessUnitLabelByReferralKey', this.referralKey).then(res => {
      this.businessUnitLabels.label = res.label;
      this.businessUnitLabels.question = res.question;
      this.businessUnitLabels.allowOther = res.allowOther;
    }));

    Object.assign(this.form, {
      name: this.referral.firstname,
      professionId: this.referral.professionId,
      memberCode: this.referral.memberCodeConfiguration?.memberId,
      businessUnit: {
        value: this.referral.businessUnitId,
        type: this.referral.businessUnitId ? 'predefined' : (this.referral.businessUnitOther ? 'custom' : 'skipped'),
        custom: this.referral.businessUnitOther || null,
      },
    });

    Promise.all(promises).finally(() => {
      this.referralLoaded = true;
    });
  },
  methods: {
    cancel() {
      this.$store.dispatch('identity/logout').then(() => {
        this.$router.push({ name: 'home' });
      });
    },
    doRegister() {
      this.loading = true;

      Auth.currentSession().then(session => {
        this.form.gender = this.form.gender ? this.form.gender : {
          value: null,
          type: 'skipped',
          custom: null,
        };
        this.form.birthYear = this.form.birthYear ? this.form.birthYear : {
          value: null,
          type: 'skipped',
          custom: null,
        };
        this.form.businessUnit = this.form.businessUnit ? this.form.businessUnit : {
          value: null,
          type: 'predefined',
          custom: null,
        };

        const toRegister = {};
        toRegister.referralKey = this.referralKey;
        toRegister.memberCode = this.form.memberCode;
        toRegister.email = session.getIdToken().payload.email;
        toRegister.userName = session.getIdToken().payload['cognito:username'];
        toRegister.agreement = this.form.agreement;
        toRegister.newsletter = this.form.newsletter;
        toRegister.name = this.form.name;
        toRegister.codename = this.form.codename;
        toRegister.avatar = this.form.avatar;
        toRegister.image = this.form.image;
        toRegister.gender = this.form.gender.type === 'custom' ? this.form.gender.custom : this.form.gender.value;
        toRegister.genderSelectionType = this.form.gender.type;
        toRegister.birthYear = this.form.birthYear.value;
        toRegister.birthYearSelectionType = this.form.birthYear.type;
        toRegister.professionId = this.form.professionId;
        toRegister.businessUnitId = this.form.businessUnit.type === 'custom' ? null : this.form.businessUnit.value;
        toRegister.businessUnitOther = this.form.businessUnit.type === 'custom' ? this.form.businessUnit.custom : null;
        toRegister.businessUnitSelectionType = this.form.businessUnit.type;
        toRegister.businessUnitSublevels = this.form.businessUnit.filters;
        toRegister.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Vancouver';
        toRegister.locale = localStorage.getItem('currLang') || 'en';

        return this.$store.dispatch('referralRegister', toRegister).then(res => {
          if (res.status && res.status !== 204) {
            this.$log.error('An error occured', res);
          } else {
            this.$emit('accepted');
            return this.$store.dispatch('identity/configureAuth').then(res => {
              setTimeout(() => this.$router.push({ name: 'home' }), 500);
            });
          }
        }).finally(() => {
          this.loading = false;
        });
      });
    },
    onUpdateAvatar(event) {
      this.form.avatar = JSON.stringify(event.options);
      this.form.image = event.image;
    },
    provideCodename() {
      return this.$store.dispatch('generateRegistrationCodename').then(res => {
        this.form.codename = res.codename;
      });
    },
  },
};
</script>

<style scoped>
.setup_access_code-card {
  border-radius: 15px;
}
</style>
