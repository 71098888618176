export default {
  header_title: 'Favoritos',
  title: 'Favoritos',
  content: 'Cada vez que marques algo con una estrella, se mostrará aquí. ',
  callout: {
    title: 'Tus favoritos',
    description: 'Haz clic en la estrella para añadir cualquier video a tus favoritos y poder volver a verlo aquí.',
  },
  empty_content: {
    text: 'Puedes encontrar los vídeos en las sesiones de capacitación, los ejercicios y los seminarios web, o utilizar la página “{0}” para acceder a ellos directamente.',
    link: 'Videos',
  },
  types: {
    VIDEO: 'Video',
    AUDIO: 'Audio',
  },
};
