var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { on: { click: _vm.doPredefined } },
      [
        _c("v-autocomplete", {
          ref: "field",
          attrs: {
            clearable: "",
            "item-text": "text",
            "item-value": "value",
            "persistent-hint": "",
            disabled:
              !_vm.itemList ||
              !_vm.itemList.length ||
              _vm.internalValue.type === "skipped",
            "error-messages":
              !_vm.itemList || !_vm.itemList.length ? "empty hint" : null,
            hint: _vm.$t("public.registration.profile.fields.birthYear.hint"),
            items: _vm.itemList,
            label: _vm.$t("public.registration.profile.fields.birthYear.label"),
            "no-data-text": _vm.$t(
              "public.registration.profile.fields.birthYear.errors.search"
            ),
            placeholder: _vm.$t(
              "public.registration.profile.fields.birthYear.placeholder"
            ),
            rules: _vm.internalValue.type === "skipped" ? [] : _vm.rules
          },
          model: {
            value: _vm.internalValue.value,
            callback: function($$v) {
              _vm.$set(_vm.internalValue, "value", $$v)
            },
            expression: "internalValue.value"
          }
        })
      ],
      1
    ),
    _c(
      "a",
      {
        staticClass: "starling-text",
        class: {
          "text--disabled": _vm.internalValue.type !== "skipped",
          "font-weight-bold": _vm.internalValue.type === "skipped"
        },
        staticStyle: { display: "block" },
        on: { click: _vm.doSkip }
      },
      [
        _vm._v(
          _vm._s(
            _vm.$t("public.registration.profile.fields.birthYear.skip.label")
          )
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }