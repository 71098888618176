export default {
  end: {
    loading: {
      title: 'Cargando',
      content: 'Espere por favor.',
    },
    success: {
      title: 'Conectado',
      content: 'Serás redirigido en unos momentos.',
    },
    error: {
      title: 'Error',
      content: 'Ocurrió un error inesperado. Inténtalo de nuevo. Serás redirigido en unos momentos.',
    },
  },
  sign_out: {
    success: {
      title: 'Éxito',
      content: 'Serás redirigido en unos momentos.',
    },
  },
};
