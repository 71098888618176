/* eslint-disable no-unused-vars */

import { NONE } from './constant';
import { BeakShape, BodyShape, EyesShape, WidgetType, WrapperShape } from './enums';
import { WidgetData } from '@/views/avatar/dynamic-data';
import { translateXMLIntoCSS } from '@/views/avatar/utils';

export type None = typeof NONE

export interface Color {
}

export interface ColorRendered<T extends Color> {
  toXML(color: T, id: string, properties?: WidgetData): string;

  toCSS(color: T, properties?: WidgetData): string;

  isEqual(color: T, other: T): boolean;
}

export interface Widget<Shape> {
  shape: Shape | None;
  zIndex?: number;
  color?: Color;
}

export interface GradientColorBreakpoint {
  offset: string,
  color: string
}

export interface SolidColor extends Color {
  color: string;
}

export interface GradientColor extends Color {
  stops: GradientColorBreakpoint[];
  gradientUnits?: string;
}

type AvatarWidgets = {
  body: Widget<BodyShape>
  eyes: Widget<EyesShape>
  beak: Widget<BeakShape>
}

export class GradientColorRenderer implements ColorRendered<GradientColor> {
  toXML(color: GradientColor, id: string, properties?: WidgetData): string {
    const svgns = 'http://www.w3.org/2000/svg';
    const gradient = document.createElementNS(svgns, 'linearGradient');

    color.stops.forEach(stop => {
      const el = document.createElementNS(svgns, 'stop');
      el.setAttribute('offset', stop.offset);
      el.setAttribute('stop-color', stop.color);

      // Add the `<stop>` element to `<linearGradient>`.
      gradient.appendChild(el);
    });
    gradient.id = id;
    if (properties && properties.gradientCoordinates) {
      if (properties.gradientCoordinates.x1) gradient.setAttribute('x1', properties.gradientCoordinates.x1.toString());
      if (properties.gradientCoordinates.y1) gradient.setAttribute('y1', properties.gradientCoordinates.y1.toString());
      if (properties.gradientCoordinates.x2) gradient.setAttribute('x2', properties.gradientCoordinates.x2.toString());
      if (properties.gradientCoordinates.y2) gradient.setAttribute('y2', properties.gradientCoordinates.y2.toString());
    }
    if (color.gradientUnits) gradient.setAttribute('gradientUnits', color.gradientUnits);

    return gradient.outerHTML;
  }

  toCSS(color: GradientColor, properties?: WidgetData): string {
    const result = translateXMLIntoCSS(this.toXML(color, 'any-id', properties))
      .map(gradient => {
        if (gradient.angle && gradient.stops.length > 0) {
          const round = (value: number): number => {
            return Math.round(value * 100) / 100;
          };
          const angle = round(Number(gradient.angle));
          return `linear-gradient(${angle}deg, ${
            gradient.stops
              .map(stop => stop && stop.color && stop.offset ? `${stop.color} ${stop.offset}%` : '')
              .filter(stopStr => stopStr && stopStr.length)
              .join(', ')
          })`;
        }

        return undefined;
      })
      .filter(gradient => {
        return gradient != undefined;
      })
      .join(',\r\n');

    return `background-image:${result}`;
  }

  isEqual(color: GradientColor, other: GradientColor): boolean {
    return color.gradientUnits === other.gradientUnits &&
      color.stops.length === other.stops.length &&
      color.stops.every((stop, idx) => stop.color === other.stops[idx].color && stop.offset === other.stops[idx].offset);
  }
}

export class SolidColorRenderer implements ColorRendered<SolidColor> {
  toXML(color: SolidColor, id: string, properties?: WidgetData): string {
    return `
    <linearGradient id="${id}">
      <stop stop-color="${color.color}"/>
    </linearGradient>
    `;
  }

  toCSS(color: SolidColor, properties?: WidgetData): string {
    return `background-color: ${color.color}`;
  }

  isEqual(color: SolidColor, other: SolidColor): boolean {
    return color.color === other.color;
  }
}

export class CompositeColorRenderer implements ColorRendered<Color> {
  toXML(color: Color, id: string, properties?: WidgetData): string {
    if (this.isSolid(color)) {
      return new SolidColorRenderer().toXML((<SolidColor>color), id, properties);
    } else if (this.isGradient(color)) {
      return new GradientColorRenderer().toXML((<GradientColor>color), id, properties);
    }
    return '';
  }

  toCSS(color: Color, properties?: WidgetData): string {
    if (this.isSolid(color)) {
      return new SolidColorRenderer().toCSS((<SolidColor>color), properties);
    } else if (this.isGradient(color)) {
      return new GradientColorRenderer().toCSS((<GradientColor>color), properties);
    }
    return '';
  }

  private isSolid(color: Color): color is SolidColor {
    return (<SolidColor>color).color !== undefined;
  }

  private isGradient(color: Color): color is GradientColor {
    return (<GradientColor>color).stops !== undefined;
  }

  isEqual(color?: Color, other?: Color): boolean {
    if (!color && !other) return true;
    if ((color && !other) || (!color && other)) return false;
    if (color && other && this.isSolid(color) && this.isSolid(other)) {
      return new SolidColorRenderer().isEqual((<SolidColor>color), (<SolidColor>other));
    }
    if (color && other && this.isGradient(color) && this.isGradient(other)) {
      return new GradientColorRenderer().isEqual((<GradientColor>color), (<GradientColor>other));
    }
    return false;
  }
}

export interface AvatarOption {
  wrapperShape?: `${WrapperShape}`;

  background: {
    color: Color
  };

  widgets: Partial<AvatarWidgets>;
}

type Colors = Readonly<{
  [key in `${WidgetType}`]: Array<Color>
}>;

export interface AvatarSettings {
  wrapperShape: WrapperShape[];
  bodyShape: BodyShape[];
  eyesShape: EyesShape[];
  beakShape: BeakShape[];

  backgroundColors: Color[];
  widgetColors: Colors;
}
