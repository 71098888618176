<template>
  <landing-central-card>
    <v-card flat class="setup_access_code-card" :elevation="elevation" :min-height="cardHeight" :style="cardStyles" :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'pa-0'">
      <v-card-loader v-if="this.loading"></v-card-loader>

      <v-card-text class="pa-0" v-if="accessCodeDetermined">
        <v-form
            ref="form"
            v-model="valid"
            autocomplete="off"
            transition="slide-y-reverse-transition"
            @submit.prevent="handleEnterKey">

          <v-layout row wrap justify-center>
            <v-flex xs12 sm11 md10>
              <h1 class="starling-h1">
                {{ $t('public.signup.setup_access_code.title', { name: partnerName }) }}
              </h1>
            </v-flex>
            <v-flex xs12 sm11 md10 mt-3>
              <p class="starling-body">
                {{ $t('public.signup.setup_access_code.content', { name: partnerName }) }}
              </p>
            </v-flex>
            <v-flex xs12 sm11 md10 mt-3>
              <p class="starling-body font-weight-medium">
                {{ $t('public.signup.setup_access_code.account_cta') }}
              </p>
            </v-flex>
            <template v-if="!form.accessCodeImprint">
              <v-flex xs12 sm11 md10 mt-3>
                <p class="starling-body font-weight-medium">
                  {{ $t('public.signup.setup_access_code.fields.access_code.title') }}
                </p>
              </v-flex>
              <v-flex xs12 sm11 md10 mt-2>
                <registration-field-access-code v-model="form.accessCode"
                                                @access-code-validated="onAccessCodeValidated"
                                                @access-code-invalidated="onAccessCodeInvalidated"/>
              </v-flex>
              <v-flex xs12 sm11 md10 mt-3>
                <v-layout row justify-center>
                  <v-flex shrink align-top mr-1>
                    <v-icon color="primary" large>mdi-information</v-icon>
                  </v-flex>
                  <v-flex align-top>
                    <email-builder :path="'public.signup.setup_access_code.fields.access_code.tips'"/>
                  </v-flex>
                </v-layout>
              </v-flex>
            </template>
            <template v-else>
            </template>

            <template v-if="(form.accessCode || form.accessCodeImprint) && form.validated && accessCodeLoaded">
              <template v-if="memberId && memberId.active">
                <v-flex xs12 sm11 md10 mt-3>
                  <p class="starling-body font-weight-medium">
                    {{ $t('public.signup.setup_access_code.fields.memberCode.title') }}
                  </p>
                </v-flex>
                <v-flex xs12 sm11 md10 mt-2>
                  <registration-field-member-id v-model="form.memberCode"
                                                :configuration="memberId"/>
                </v-flex>
              </template>

              <template v-if="!defaultProfession">
                <v-flex xs12 sm11 md10 mt-3>
                  <p class="starling-body font-weight-medium">
                    {{ $t('public.signup.setup_access_code.fields.profession.title') }}
                  </p>
                </v-flex>
                <v-flex xs12 sm11 md10 mt-2>
                  <registration-field-profession
                      v-model="form.professionId"
                      :access-code="form.accessCode"
                      :access-code-imprint="form.accessCodeImprint"/>
                </v-flex>
              </template>

              <template v-if="!defaultBusinessUnit">
                <v-flex xs12 sm11 md10 mt-3>
                  <p class="starling-body font-weight-medium">
                    {{ businessUnitLabels.question ? businessUnitLabels.question : $t('public.signup.setup_access_code.fields.businessUnit.title') }}
                  </p>
                </v-flex>
                <v-flex xs12 sm11 md10>
                  <registration-field-business-unit v-model="form.businessUnit"
                                                    :label="businessUnitLabels.label"
                                                    :access-code="form.accessCode"
                                                    :access-code-imprint="form.accessCodeImprint"
                                                    :enable-not-listed="businessUnitLabels.allowOther"/>
                </v-flex>
              </template>
            </template>
          </v-layout>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-0 pt-3">
        <v-layout row wrap justify-center align-center gap-xs-3>
          <v-flex xs12 text-center pt-3>
            <primary-button
                id="btn-do-reset-password"
                :disabled="!valid || loading || !accessCodeLoaded"
                @click="toNextStep"
                large>
              {{ $t('common.actions.next') }}
            </primary-button>
          </v-flex>

          <v-flex xs12 text-center>
            <v-btn
                :disabled="loading"
                @click="cancel"
                flat
                round>
              {{ $t('common.actions.logout') }}
            </v-btn>
          </v-flex>

          <v-flex xs12 text-center>
            <span>1&nbsp;/&nbsp;2</span>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </landing-central-card>
</template>

<script>
import LandingCentralCard from '@/views/landing/landing-central-card.vue';
import vCardLoader from '@/views/components/loader/v-card-loader.vue';
import EmailBuilder from '@/views/landing/register/components/email-builder.vue';
import RegistrationFieldAccessCode from '@/views/landing/register/components/registration-field-access-code.vue';
import RegistrationFieldMemberId from '@/views/landing/register/components/registration-field-member-id.vue';
import { SETUP_PROFILE_STORAGE_KEY } from './SetupProfile.vue';
import { Auth } from 'aws-amplify';
import RegistrationFieldProfession from '@/views/landing/register/components/registration-field-profession.vue';
import RegistrationFieldBusinessUnit from '@/views/landing/register/components/registration-field-business-unit.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import { mapState } from 'vuex';

export const SETUP_ACCESS_CODE_STORAGE_KEY = 'SETUP_ACCESS_CODE';
export const SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY = 'SETUP_ACCESS_CODE_IMPRINT';

export default {
  components: {
    PrimaryButton,
    RegistrationFieldBusinessUnit,
    RegistrationFieldProfession,
    EmailBuilder,
    LandingCentralCard,
    RegistrationFieldMemberId,
    RegistrationFieldAccessCode,
    vCardLoader,
  },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    const initialForm = {
      accessCodeImprint: null,
      accessCode: null,
      memberCode: null,
      program: 'MENTAL_FITNESS',
      validated: false,
      professionId: null,
      businessUnit: null,
    };

    this.$log.debug('form', initialForm);
    return {
      loading: false,
      valid: false,
      form: initialForm,
      memberId: null,
      migratedUserData: null,
      businessUnitLabels: {
        label: null,
        question: null,
        allowOther: null,
      },
      defaultBusinessUnit: null,
      defaultProfession: null,
      accessCodeLoaded: false,
      accessCodeDetermined: false,
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
    partnerName() {
      return this.partner && this.partner.content && this.partner.content['NAME'] && this.partner.content['NAME'][this.locale] ? this.partner.content['NAME'][this.locale] : 'Starling';
    },
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        this.$log.debug('Form \'Access Code\' changed : saving to local storage', value);
        localStorage.setItem(SETUP_ACCESS_CODE_STORAGE_KEY, JSON.stringify(value));
      },
    },
    'form.accessCode'(value) {
      this.$log.debug('\'Access Code\' changed : removing other values', value);
      localStorage.removeItem(SETUP_PROFILE_STORAGE_KEY);
      this.form.validated = false;
      this.accessCodeLoaded = false;
    },
  },
  beforeCreate() {
    this.$recaptchaInstance.showBadge();
    Auth.currentSession().then(() => {
      if (!(this.$store.state.identity.userIdentity.location && this.$store.state.identity.userIdentity.location['@type'] && this.$store.state.identity.userIdentity.location['@type'] === 'nowhere')) {
        this.$log.debug('User is not @type=nowhere, redirecting to home');
        this.$router.push({ name: 'home' });
      }
    }, () => {
      this.$log.debug('User is not authenticated, go back to sign-in');
      this.$router.push({ name: 'sign_in' });
    });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
  async beforeMount() {
    // Check if a profile exists in the localStorage
    const accessCodeStorageString = localStorage.getItem(SETUP_ACCESS_CODE_STORAGE_KEY);
    if (accessCodeStorageString) {
      this.$log.debug('accessCodeStorageString', accessCodeStorageString);
      this.form = JSON.parse(accessCodeStorageString);
      this.accessCodeDetermined = true;
      this.$log.debug('Access code FORM found in localStorage', this.form);
    }

    // Else check if an AC imprint has been stored
    if (localStorage.getItem(SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY)) {
      this.form.accessCodeImprint = localStorage.getItem(SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY);
      this.form.accessCode = null;
      this.accessCodeDetermined = true;
      this.$log.debug('Access code IMPRINT found in localStorage', this.form);
    }

    // Else check if the member deserves to receives the default access code
    if (!this.form.accessCodeImprint && !this.form.accessCode && !this.form.validated && !!this.partner?.organization?.subdomain) {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha(process.env.VUE_APP_RECAPTCHA_V3_ACTION || 'PROVIDE_AC');
      const providedImprint = await fetch(process.env.VUE_APP_IDENTITY_API + '/_magicLink/v3', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ captchaToken: token }),
      }).then(res => {
        return res.json();
      });
      if (providedImprint.imprint) {
        this.$log.debug('Access code IMPRINT received from default configuration', this.form);
        this.form.accessCodeImprint = providedImprint.imprint;
        this.accessCodeDetermined = true;
      }
    }
    // oad accessCodeImprint if it exists
    if (this.form.accessCodeImprint) {
      // Check validation identity app
      this.$store.dispatch('identity/findAccessCodeByImprint', this.form.accessCodeImprint).then(res => {
        return this.$store.dispatch('accessCode/validateByImprint', this.form.accessCodeImprint);
      }).then(validateRes => {
        if (validateRes.status && validateRes.status >= 400) {
          localStorage.removeItem(SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY);
          throw validateRes;
        }
        this.form = {
          accessCodeImprint: this.form.accessCodeImprint,
          accessCode: null,
          memberCode: this.form.memberCode || null,
          program: validateRes?.program || 'MENTAL_FITNESS',
          validated: true,
          professionId: this.form.professionId,
          businessUnit: this.form.businessUnit,
        };
        this.memberId = validateRes;

        const promises = [];
        promises.push(this.$store.dispatch('getBusinessUnitLabelByImprint', this.form.accessCodeImprint).then(res => {
          this.businessUnitLabels.label = res.label;
          this.businessUnitLabels.question = res.question;
          this.businessUnitLabels.allowOther = res.allowOther;
          return res;
        }));
        promises.push(this.$store.dispatch('getDefaultFieldsByImprint', this.form.accessCodeImprint).then(res => {
          this.defaultBusinessUnit = res.businessUnit;
          this.defaultProfession = res.profession;
          return res;
        }));
        Promise.all(promises)
          .then(() => {
            this.accessCodeLoaded = true;
          });
      }).catch(e => {
        localStorage.removeItem(SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY);
        this.form = {
          accessCodeImprint: null,
          accessCode: null,
          memberCode: null,
          program: 'MENTAL_FITNESS',
          validated: false,
          professionId: null,
          businessUnit: null,
        };
      });
    }

    this.$log.debug('No Access code found anywhere, default behavior', this.form);
    this.accessCodeDetermined = true;
  },
  mounted() {
    this.$log.debug('Mounted', this.form);
  },
  methods: {
    onFormValidate(fieldname) {
      if (this.$refs.form) {
        if (fieldname) {
          const field = this.$refs.form.$data.inputs.find(i => i.$attrs.name === fieldname);
          if (field) {
            field.validate();
          }
        } else {
          this.$refs.form.validate();
        }
      }
    },
    onAccessCodeValidated(values) {
      this.form = {
        accessCodeImprint: null,
        accessCode: values.accessCode,
        memberCode: this.form.memberCode || null,
        program: values?.memberId?.program || 'MENTAL_FITNESS',
        validated: true,
        professionId: this.form.professionId,
        businessUnit: this.form.businessUnit,
      };
      this.memberId = values.memberId;

      const promises = [];
      promises.push(this.$store.dispatch('getBusinessUnitLabel', this.form.accessCode).then(res => {
        this.businessUnitLabels.label = res.label;
        this.businessUnitLabels.question = res.question;
        this.businessUnitLabels.allowOther = res.allowOther;
        return res;
      }));
      promises.push(this.$store.dispatch('getDefaultFields', this.form.accessCode).then(res => {
        this.defaultBusinessUnit = res.businessUnit;
        this.defaultProfession = res.profession;
        return res;
      }));
      Promise.all(promises)
        .then(() => {
          this.accessCodeLoaded = true;
        });
    },
    onAccessCodeInvalidated() {
      this.form = {
        accessCodeImprint: null,
        accessCode: this.form.accessCode,
        validated: false,
        memberCode: null,
        professionId: null,
        businessUnit: null,
      };
      this.memberId = null;
      this.$forceUpdate();
    },
    clearFormData() {
      localStorage.removeItem(SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY);
      localStorage.removeItem(SETUP_ACCESS_CODE_STORAGE_KEY);
      localStorage.removeItem(SETUP_PROFILE_STORAGE_KEY);
    },
    cancel() {
      this.clearFormData();
      this.$store.dispatch('identity/logout').then(() => {
        this.$router.push({ name: 'presentation' });
      });
    },
    toNextStep() {
      if (this.valid) {
        this.$router.push({ name: 'setup_profile' });
      }
    },
    handleEnterKey() {
      if (this.valid) {
        this.toNextStep();
      }
    },
  },
};
</script>

<style scoped>
.setup_access_code-card {
  border-radius: 15px;
}
</style>
