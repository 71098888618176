export default {
  title: 'Réinitialiser mon mot de passe',
  subtitle: {
    provider: 'Si vous avez effectué votre inscription à l’aide de l’un de nos partenaires de connexion, veuillez utiliser le lien correspondant ci-dessous pour accéder à votre compte.',
    email: 'Si vous avez effectué votre inscription à l’aide de votre adresse courriel, veuillez la saisir ci-dessous, et nous vous enverrons les détails sur la façon de réinitialiser votre mot de passe.',
  },
  success: {
    redirect: 'Veuillez vérifier votre boîte de courriels pour savoir comment réinitialiser votre mot de passe.',
    action: 'Ouvrir une session',
  },
  fields: {
    email: 'Adresse courriel',
  },
  buttons: {
    reset_password: 'Réinitialisation du mot de passe',
  },
};
