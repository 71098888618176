export default {
  'CD-RISC2': {
    name: 'CD-RISC2',
    label: 'Resiliencia',
    action_label: 'Añadir una nota resiliencia',
    color: '#30CEFA',
    order: 0,
  },
  ENERGY: {
    name: 'ENERGY',
    label: 'Energía',
    action_label: 'Añade una nota energía',
    color: '#08E9cf',
    order: 1,
  },
  GAD7: {
    name: 'GAD7',
    label: 'Ansiedad',
    action_label: 'Añadir una nota de ansiedad',
    color: '#FF777D',
    order: 2,
  },
  PHQ9: {
    name: 'PHQ9',
    label: 'Depresión',
    action_label: 'Añadir una nota de depresión',
    color: '#4c75aa',
    order: 3,
  },
  STRESS: {
    name: 'STRESS',
    label: 'Estrés',
    action_label: 'Añadir una nota de estrés',
    color: '#ffb844',
    order: 4,
  },
  SLEEP: {
    name: 'SLEEP',
    label: 'Calidad del sueño, evaluación del insomnio',
    action_label: 'Añade una nota Calidad del sueño, evaluación del insomnio',
    color: '#08E9cf',
  },
  PANIC: {
    name: 'PANIC',
    label: 'Pánico',
    action_label: 'Añade una nota Pánico',
    color: '#08E9cf',
  },
  LDQ: {
    name: 'LDQ',
    label: 'Consumo de sustancias',
    action_label: 'Añade una nota Consumo de sustancias',
    color: '#058189',
  },
  MEBS: {
    name: 'MEBS',
    label: 'Hábitos alimentarios',
    action_label: 'Añade una nota Hábitos alimentarios',
    color: '#08E9cf',
  },
  ADHD: {
    name: 'ADHD',
    label: 'Trastorno por déficit de atención con hiperactividad (TDAH)',
    action_label: 'Añade una nota Trastorno por déficit de atención con hiperactividad (TDAH)',
    color: '#08E9cf',
  },

  drilldown: {
    unavailable: 'Todavía no hay ninguna interpretación para esta evaluación.',
  },
  scoreScale: [
    {
      value: 1,
      name: 'HH',
      labels: {
        long: 'Saludable: Medio alto',
        short: 'Saludable +',
      },
    },
    {
      value: 2,
      name: 'HA',
      labels: {
        long: 'Saludable: Medio',
        short: 'Saludable',
      },
    },
    {
      value: 3,
      name: 'HL',
      labels: {
        long: 'Saludable: Medio bajo',
        short: 'Saludable -',
      },
    },
    {
      value: 4,
      name: 'BL',
      labels: {
        long: 'Límite',
        short: 'Límite',
      },
    },
    {
      value: 5,
      name: 'ML',
      labels: {
        long: 'Leve',
        short: 'Leve',
      },
    },
    {
      value: 6,
      name: 'MO',
      labels: {
        long: 'Moderado',
        short: 'Moderado',
      },
    },
    {
      value: 7,
      name: 'SE',
      labels: {
        long: 'Grave',
        short: 'Grave',
      },
    },
  ],
  simplifiedScoreScale: [
    {
      value: 3,
      name: 'HI',
      label: 'Alto',
    },
    {
      value: 2,
      name: 'MD',
      label: 'Moderado',
    },
    {
      value: 1,
      name: 'LO',
      label: 'Bajo',
    },
    {
      value: 0,
      name: 'VL',
      label: 'Muy bajo',
    },
    {
      value: -1,
      name: 'NOT_EVALUATED',
      label: 'No evaluado',
      hideInScale: true,
    },
  ],
};
