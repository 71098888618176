/* eslint-disable no-unused-vars */
import { AvatarSettings, GradientColor, SolidColor } from './types';

import { BeakShape, BodyShape, EyesShape, WidgetType, WrapperShape } from './enums';

export const AVATAR_LAYER: Readonly<{
  [key in `${WidgetType}`]: { zIndex: number }
}> = {
  [WidgetType.Body]: {
    zIndex: 20,
  },
  [WidgetType.Beak]: {
    zIndex: 30,
  },
  [WidgetType.Eyes]: {
    zIndex: 40,
  },
};

export const SETTINGS: Readonly<AvatarSettings> = {
  wrapperShape: Object.values(WrapperShape),
  bodyShape: Object.values(BodyShape),
  eyesShape: Object.values(EyesShape),
  beakShape: Object.values(BeakShape),

  widgetColors: {
    body: [
       { stops: [ { color: '#7ACBEB', offset: '0.02' }, { color: '#06A0DD', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
       { stops: [ { color: '#49C8E5', offset: '0.02' }, { color: '#18B5D9', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
       { stops: [ { color: '#F8DA71', offset: '0.02' }, { color: '#F2CC44', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
       { stops: [ { color: '#FBC078', offset: '0.02' }, { color: '#F2A444', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
       { stops: [ { color: '#F78779', offset: '0.02' }, { color: '#F26452', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
       { stops: [ { color: '#AF80C7', offset: '0.02' }, { color: '#A15FC2', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
       { stops: [ { color: '#7BDDB5', offset: '0.02' }, { color: '#4BD99F', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
       { stops: [ { color: '#64C3A8', offset: '0.02' }, { color: '#37A183', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
    ],
    beak: [
      { color: '#178FBE' } as SolidColor,
      { color: '#139AB9' } as SolidColor,
      { color: '#F5C30E' } as SolidColor,
      { color: '#F08B0F' } as SolidColor,
      { color: '#D23E2C' } as SolidColor,
      { color: '#8F50AE' } as SolidColor,
      { color: '#2AC082' } as SolidColor,
      { color: '#1D8E6E' } as SolidColor,
    ],
    eyes: [
       { color: '#393338' } as SolidColor,
    ],
  },

  backgroundColors: [
     { stops: [ { color: '#DEF4FD', offset: '0.02' }, { color: '#A4DFF6', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
     { stops: [ { color: '#C7FFFB', offset: '0.02' }, { color: '#90EAFF', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
     { stops: [ { color: '#FDF5D8', offset: '0.02' }, { color: '#F4E5AE', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
     { stops: [ { color: '#FCEED3', offset: '0.02' }, { color: '#F7D8B2', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
     { stops: [ { color: '#FFE8DD', offset: '0.02' }, { color: '#FBC3BC', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
     { stops: [ { color: '#F3E0FD', offset: '0.02' }, { color: '#E7C2FA', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
     { stops: [ { color: '#C9FDE8', offset: '0.02' }, { color: '#9FF6D2', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
     { stops: [ { color: '#BAF9DB', offset: '0.02' }, { color: '#78E5AA', offset: '1' } ], gradientUnits: 'userSpaceOnUse' } as GradientColor,
  ],
};

export const NONE = 'none';
