var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { autocomplete: "off", transition: "slide-y-reverse-transition" },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "justify-center": "" } },
            [
              _c("v-flex", { attrs: { xs12: "", sm11: "", md10: "" } }, [
                _c("h1", { staticClass: "starling-h1" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "public.signup.setup_referral.accept_referral.title",
                          { name: _vm.partnerName }
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("p", { staticClass: "starling-body starling-content" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "public.signup.setup_referral.accept_referral.content",
                            { name: _vm.partnerName }
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ]
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("h3", { staticClass: "starling-h3" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "public.signup.setup_referral.accept_referral.name.title",
                            { name: _vm.partnerName }
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ]
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-1": "" } },
                [
                  _c("p", { staticClass: "starling-body starling-content" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "public.signup.setup_referral.accept_referral.name.content",
                            { name: _vm.partnerName }
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ]
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("p", { staticClass: "starling-body font-weight-medium" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "public.signup.setup_referral.accept_referral.name.fields.firstname.title",
                            { name: _vm.partnerName }
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("registration-field-firstname", {
                    staticClass: "mt-2",
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("h3", { staticClass: "starling-h3" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "public.signup.setup_referral.accept_referral.profile.title",
                            { name: _vm.partnerName }
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ]
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-1": "" } },
                [
                  _c("p", { staticClass: "starling-body starling-content" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "public.signup.setup_referral.accept_referral.profile.content",
                            { name: _vm.partnerName }
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ]
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _vm.referral.memberCodeConfiguration &&
                  _vm.referral.memberCodeConfiguration.active
                    ? [
                        _c(
                          "p",
                          { staticClass: "starling-body font-weight-medium" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "public.signup.setup_referral.accept_referral.profile.fields.memberCode.title"
                                  )
                                ) +
                                "\n            "
                            )
                          ]
                        ),
                        _c("registration-field-member-id", {
                          staticClass: "w-full",
                          attrs: {
                            configuration: _vm.referral.memberCodeConfiguration
                          },
                          model: {
                            value: _vm.form.memberCode,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "memberCode", $$v)
                            },
                            expression: "form.memberCode"
                          }
                        })
                      ]
                    : _vm._e(),
                  _c(
                    "p",
                    { staticClass: "starling-body mt-3 font-weight-medium" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.businessUnitLabels.question
                              ? _vm.businessUnitLabels.question
                              : _vm.$t(
                                  "public.registration.profile.fields.businessUnit.title"
                                )
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("registration-field-business-unit", {
                    attrs: {
                      label: _vm.businessUnitLabels.label,
                      "referral-key": _vm.referralKey,
                      "enable-not-listed": _vm.businessUnitLabels.allowOther
                    },
                    model: {
                      value: _vm.form.businessUnit,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "businessUnit", $$v)
                      },
                      expression: "form.businessUnit"
                    }
                  }),
                  _c(
                    "p",
                    { staticClass: "starling-body mt-3 font-weight-medium" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "public.registration.profile.fields.profession.title"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("registration-field-profession", {
                    staticClass: "pt-2",
                    attrs: { "referral-key": _vm.referralKey },
                    model: {
                      value: _vm.form.professionId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "professionId", $$v)
                      },
                      expression: "form.professionId"
                    }
                  })
                ],
                2
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("p", { staticClass: "starling-body font-weight-medium" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "public.signup.setup_referral.accept_referral.profile.fields.birthYear.title",
                            { name: _vm.partnerName }
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("registration-field-birth-year", {
                    staticClass: "mt-2",
                    model: {
                      value: _vm.form.birthYear,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "birthYear", $$v)
                      },
                      expression: "form.birthYear"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("p", { staticClass: "starling-body font-weight-medium" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "public.signup.setup_referral.accept_referral.profile.fields.gender.title",
                            { name: _vm.partnerName }
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("registration-field-gender", {
                    staticClass: "mt-2",
                    model: {
                      value: _vm.form.gender,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "gender", $$v)
                      },
                      expression: "form.gender"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("h3", { staticClass: "starling-h3" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "public.signup.setup_referral.accept_referral.community.title",
                            { name: _vm.partnerName }
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ]
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-2": "" } },
                [
                  _c("p", { staticClass: "starling-body starling-content" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "public.signup.setup_referral.accept_referral.community.content",
                            { name: _vm.partnerName }
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "align-center": "",
                        "justify-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs8: "",
                            sm6: "",
                            md4: "",
                            lg3: "",
                            "pa-2": "",
                            "text-center": ""
                          }
                        },
                        [
                          _c("starling-avatar-input", {
                            attrs: {
                              value: _vm.form.avatar
                                ? JSON.parse(_vm.form.avatar)
                                : null
                            },
                            on: { saved: _vm.onUpdateAvatar }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm6: "",
                            md8: "",
                            lg9: "",
                            "align-center": "",
                            "justify-center": "",
                            "text-center": ""
                          }
                        },
                        [
                          _c("registration-field-codename", {
                            staticClass: "mt-2",
                            on: { "required-codename": _vm.provideCodename },
                            model: {
                              value: _vm.form.codename,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "codename", $$v)
                              },
                              expression: "form.codename"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary--text font-weigth-bold",
                              attrs: { flat: "", block: "", large: "" },
                              on: { click: _vm.provideCodename }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  staticStyle: { width: "24px" },
                                  attrs: { left: "" }
                                },
                                [_vm._v("$vuetify.icons.starling_randomize")]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "public.registration.profile.fields.codename.action"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("h3", { staticClass: "starling-h3" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "public.signup.setup_referral.accept_referral.privacy.title",
                            { name: _vm.partnerName }
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ]
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-1": "" } },
                [
                  _c("p", {
                    staticClass: "starling-body starling-content",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "public.signup.setup_referral.accept_referral.privacy.content." +
                            (_vm.referral.memberCodeConfiguration
                              ? _vm.referral.memberCodeConfiguration.program
                              : "MENTAL_FITNESS") +
                            "." +
                            (_vm.privacyDisclaimer
                              ? "privacy_clinician"
                              : "privacy"),
                          { name: _vm.partnerName }
                        )
                      )
                    }
                  })
                ]
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("registration-field-agreement", {
                    attrs: { "referral-key": _vm.referralKey },
                    model: {
                      value: _vm.form.agreement,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "agreement", $$v)
                      },
                      expression: "form.agreement"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm11: "", md10: "", "mt-3": "" } },
                [
                  _c("registration-field-marketing", {
                    model: {
                      value: _vm.form.newsletter,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newsletter", $$v)
                      },
                      expression: "form.newsletter"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pa-0 pt-3" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                wrap: "",
                "justify-center": "",
                "align-center": "",
                "gap-xs-3": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", "text-center": "", "pt-3": "" } },
                [
                  _c(
                    "primary-button",
                    {
                      attrs: {
                        id: "btn-do-register",
                        type: "button",
                        large: "",
                        disabled:
                          !_vm.valid || !_vm.form.agreement || _vm.loading,
                        loading: _vm.loading
                      },
                      on: { click: _vm.doRegister }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "public.signup.setup_referral.accept_referral.buttons.register"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", "text-center": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "btn-cancel",
                        flat: "",
                        round: "",
                        disabled: _vm.loading
                      },
                      on: { click: _vm.cancel }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.actions.cancel")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }