import { BoilerplateView } from '../common';
import store from '../../store';

const MoodsView = resolve => {
  require.ensure([ '@/views/tools/moods/Moods.vue' ], () => {
    resolve(require('@/views/tools/moods/Moods.vue'));
  }, 'moods-list');
};
const EditMoodView = resolve => {
  require.ensure([ '@/views/tools/moods/EditMood.vue' ], () => {
    resolve(require('@/views/tools/moods/EditMood.vue'));
  }, 'mood-edit');
};

export default {
  path: 'moods',
  component: BoilerplateView,
  beforeEnter(to, from, next) {
    store.commit('navigation/topEnableBackButton');
    store.commit('navigation/topHeader', 'app.tools.mood_tracker.header_title');
    next();
  },
  children: [
    {
      path: '',
      name: 'moods-list',
      component: MoodsView,
    },
    {
      name: 'mood-edit',
      path: ':id/edit',
      component: EditMoodView,
      props: route => {
        return {
          mood: store.dispatch('moods/getMood', route.params.id),
        };
      },
    },
  ],
};
