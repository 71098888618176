import { BoilerplateView } from '../common';
import store from '../../store';

const JournalsView = resolve => {
  require.ensure([ '@/views/tools/journal/Journals.vue' ], () => {
    resolve(require('@/views/tools/journal/Journals.vue'));
  }, 'journals');
};
const EditJournalView = resolve => {
  require.ensure([ '@/views/tools/journal/EditJournal.vue' ], () => {
    resolve(require('@/views/tools/journal/EditJournal.vue'));
  }, 'edit-journals');
};

export default {
  path: 'journals',
  component: BoilerplateView,
  beforeEnter(to, from, next) {
    store.commit('navigation/topEnableBackButton');
    store.commit('navigation/topHeader', 'app.tools.journal.header_title');
    next();
  },
  children: [
    {
      name: 'journals-list',
      path: '',
      component: JournalsView,
    },
    {
      name: 'journal-create',
      path: 'create',
      component: EditJournalView,
      props: route => {
        return {
          journal: Promise.resolve({}),
        };
      },
    },
    {
      name: 'journal-edit',
      path: ':id',
      component: EditJournalView,
      props: route => {
        return {
          journal: store.dispatch('journals/getJournal', route.params.id),
        };
      },
    },
  ],
};
