<template>
  <v-text-field
      ref="field"
      autocomplete="new-password"
      :label="$t('public.registration.account.fields.passwordConfirmation.label')"
      :placeholder="$t('public.registration.account.fields.passwordConfirmation.placeholder')"
      name="password-confirmation"
      :rules="rules"
      v-model="internalValue"
      :hint="$t('public.registration.account.fields.passwordConfirmation.hint')"
      persistent-hint
      :append-icon="show?'$vuetify.icons.starling_eye_opened':'$vuetify.icons.starling_eye_closed'"
      @click:append="show=!show"
      :type="show?'text':'password'"
  :disabled="disabled">
  </v-text-field>
</template>

<script>

export default {
  name: 'registration-field-password-confirmation',
  props: {
    value: String,
    password: String,
    disabled: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
      show: false,
      rules: [
        v => !!v || this.$t('common.errors.required'),
        v => !this.password || v === this.password || this.$t('common.errors.passwordMatch'),
      ],
    };
  },
  watch: {
    password() {
      this.$refs.field.validate();
    },
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
      }
    },
    internalValue(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>

<style scoped>

</style>
