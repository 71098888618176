export default {
  account: {
    title: 'S\'inscrire',
    fields: {
      email: {
        title: 'Quel est votre adresse courriel?',
      },
      password: {
        title: 'Créez votre mot de passe securisé',
      },
      passwordConfirmation: {
        title: 'Veuillez confirmer votre mot de passe',
      },
    },
    buttons: {
      signup: 'S\'inscrire',
    },
    error: {
      message: 'Une erreur s\'est produite lors de la création de votre compte,',
    },
  },
  verify: {
    start: {
      title: 'Vérification de votre email',
      content: [
        'Un lien de confirmation a été envoyé à l’adresse: <strong>{email}</strong>.',
        'Vous avez <strong>1 heure</strong> pour cliquer sur le lien.',
        'Vous n’avez pas reçu de lien?',
        '<ol><li>Vérifiez que l’adresse courriel ci-dessus est bien valide. Si ce n’est pas le cas, <a href="/landing/sign_up">cliquez ici</a> pour vous inscrire avec la bonne adresse email. </li><li>Vérifiez votre dossier de courriels indésirables.</li><li>Si vous n’avez pas reçu de lien, envoyez un courriel à \n' +
        'l’adresse <a href="mailto:membres@starlingminds.com?subject=Code%20de%20v%E9rification%20du%20compte%20non%20re%E7u">membres@starlingminds.com</a> pour obtenir du soutien technique.</li></ol>',
      ],
    },
    end: {
      valid: {
        title: 'Courriel vérifié',
        content: [
          'Merci d’avoir vérifié votre adresse courriel. Poursuivez la configuration de votre compte dans les étapes suivantes.',
        ],
        button: 'Définir mon mot de passe',
      },
      invalid: {
        title: 'Lien invalide',
        content: [
          'Le lien sur lequel vous avez cliqué est invalide ou vous avez rechargé la page.',
        ],
      },
      error: {
        title: 'Une erreur s\'est produite',
        content: [
          'Une erreur s\'est produite.',
        ],
      },
      confirmed: {
        title: 'Compte vérifié',
        content: [
          'Votre compte <strong>{email}</strong> est vérifié, vous pouvez désormais ouvrir une session.',
        ],
        button: 'Ouvrir une session',
      },
      expired: {
        title: 'Lien expiré',
        content: [
          'Le lien a expiré. Cliquez sur le bouton ci-dessous pour recevoir un nouveau lien afin de poursuivre la configuration de votre compte.',
        ],
        button: 'Renvoyer le lien',
      },
    },
  },
  setup_referral: {
    claim_account: {
      title: 'Bienvenue à {name}!',
      content: [ 'Nous sommes content de vous voir.', 'Être en arrêt de travail peut être une période incertaine, stressante et émotionnelle. Nous sommes ici pour vous soutenir pendant que vous la traversez.' ],
      account_cta: 'Commençons par créer votre compte.',
      fields: {
        email: {
          title: 'Quelle est votre adresse courriel?',
        },
        password: {
          title: 'Créez votre mot de passe securisé',
        },
      },
    },
    accept_referral: {
      title: 'Fait pour vous.',
      content: 'Maintenant que votre compte est prêt, apprenez-nous en un peu sur vous.',
      name: {
        title: 'Votre nom',
        content: 'Seule l\'application Starling vous appellera ainsi. Votre prénom ou alias ne sera pas rendu public.',
        fields: {
          firstname: {
            title: 'Comment voulez-vous qu\'on vous appelle?',
          },
        },
      },
      profile: {
        title: 'Votre profil',
        content: 'Starling vous fournira du contenu personnalisé et vous inclura dans une communauté en fonction de ces réponses.',
        fields: {
          birthYear: {
            title: 'Peut-on connaître votre année de naissance?',
          },
          gender: {
            title: 'Comment souhaitez-vous qu\'on s\'adresse à vous?',
          },
          memberCode: {
            title: 'Quel est votre identifiant de membre ?',
          },
        },
      },
      community: {
        title: 'Paramètres de la communauté',
        content: 'Personnalisez votre avatar et votre nom de code utilisés pour interagir dans notre communauté anonyme.',
      },
      privacy: {
        title: 'Votre vie privée est importante',
        content: {
          MENTAL_FITNESS: {
            privacy: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Tout ce que vous faites ici est confidentiel à 100%. Nous ne vendons ni de partageons aucune de vos données personnelles à quiconque.',
            privacy_clinician: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Votre activité au sein de {name} est hautement confidentielle. Afin de soutenir votre retour en santé, nous ne partagerons avec votre Clinicien et votre Fournisseur que le strict nécessaire.',
          },
          RTH_MENTAL: {
            privacy: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Tout ce que vous faites ici est confidentiel à 100%. Nous ne vendons ni de partageons aucune de vos données personnelles à quiconque.',
            privacy_clinician: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Votre activité au sein de {name} est hautement confidentielle. Afin de soutenir votre retour en santé, nous ne partagerons avec votre Clinicien et votre Fournisseur que le strict nécessaire.',
          },
          RTH_PHYSICAL: {
            privacy: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Tout ce que vous faites ici est confidentiel à 100%. Nous ne vendons ni de partageons aucune de vos données personnelles à quiconque.',
            privacy_clinician: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Votre activité au sein de {name} est hautement confidentielle. Afin de soutenir votre retour en santé, nous ne partagerons avec votre Clinicien et votre Fournisseur que le strict nécessaire.',
          },
          RTH_VETERANS: {
            privacy: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Tout ce que vous faites ici est confidentiel à 100%. Nous ne vendons ni de partageons aucune de vos données personnelles à quiconque.',
            privacy_clinician: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Votre activité au sein de {name} est hautement confidentielle. Afin de soutenir votre retour en santé, nous ne partagerons avec votre Clinicien et votre Fournisseur que le strict nécessaire.',
          },
        },
      },
      buttons: {
        register: 'C\'est parti!',
      },
    },
    account_login: {
      title: 'Compte existant',
      content: 'Il semble que vous ayez déjà créé un compte avec l’adresse courriel <strong>{email}</strong>.',
      signin_cta: 'Pour accepter l’invitation, ouvrez une session ci-dessous. ',
      button: 'Me connecter',
    },
    incorrect_account: {
      title: 'Compte incorrect',
      content: 'Le compte que vous utilisez actuellement ne correspond pas à l\'adresse courriel de l\'invitation.',
      signin_cta: 'Veuillez fermer et ouvrir une session avec l\'adresse courriel <strong>{email}</strong>.',
      button: 'Me déconnecter',
    },
    account_logged: {
      title: 'Acceptez votre invitation',
      content: '<strong>{caseManager}</strong> pense que le programme Recouvrer la santé pourrait vous être bénéfique :',
      button: 'Accepter',
    },
  },
  setup_access_code: {
    title: 'Bienvenue à {name}!',
    content: 'Prenez soin de votre santé mentale et découvrez des stratégies de gestion du stress, de l\'anxiété et de la dépression.',
    account_cta: 'Commençons par créer votre compte',
    fields: {
      access_code: {
        title: 'Quel est votre code d\'accès?',
        tips: {
          text: 'Vous pouvez trouver cette information auprès de votre organisme parrain. Si vous n\'avez pas de code ou d\'organisme parrain, {0}.',
          mailto: {
            label: 'nous pouvons vous aider',
            to: 'membres@starlingminds.com',
            subject: 'Quel est mon code d\'accès?',
            body: 'Nom de l\'organisation ou du syndicat: ###organization### \nProfession: ###profession###\n\nVotre message:\n###freeText###',
          },
          additionalProperties: {
            explanation: 'Pour aider le service de soutien aux membres à trouver rapidement votre code d’accès, veuillez remplir le formulaire ci-dessous. En cas de doute, laissez les champs vides; le service de soutien aux membres fera un suivi auprès de vous.',
            fields: [
              {
                type: 'input',
                id: 'organization',
                label: 'Nom de l’organisation ou du syndicat',
                placeholder: '',
              },
              {
                type: 'input',
                id: 'profession',
                label: 'Profession',
                placeholder: '',
              },
              {
                type: 'textarea',
                id: 'freeText',
                label: 'Toute autre information que vous souhaitez nous communiquer',
                placeholder: '',
              },
            ],
          },
        },
      },
      memberCode: {
        title: 'Quel est votre identifiant de member?',
      },
      profession: {
        title: 'Quel est votre emploi?',
      },
      businessUnit: {
        title: 'Où travaillez-vous?',
      },
    },
  },
  setup_profile: {
    title: 'Fait pour vous.',
    content: 'Maintenant que votre compte est prêt, apprenez-nous en un peu sur vous.',
    profile: {
      title: 'Votre profil',
      content: {
        MENTAL_FITNESS: {
          privacy: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Tout ce que vous faites ici est confidentiel à 100%. Nous ne vendons ni de partageons aucune de vos données personnelles à quiconque.',
          privacy_clinician: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Votre activité au sein de {name} est hautement confidentielle. Afin de soutenir votre retour en santé, nous ne partagerons avec votre Clinicien et votre Fournisseur que le strict nécessaire.',
        },
        RTH_MENTAL: {
          privacy: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Tout ce que vous faites ici est confidentiel à 100%. Nous ne vendons ni de partageons aucune de vos données personnelles à quiconque.',
          privacy_clinician: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Votre activité au sein de {name} est hautement confidentielle. Afin de soutenir votre retour en santé, nous ne partagerons avec votre Clinicien et votre Fournisseur que le strict nécessaire.',
        },
        RTH_PHYSICAL: {
          privacy: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Tout ce que vous faites ici est confidentiel à 100%. Nous ne vendons ni de partageons aucune de vos données personnelles à quiconque.',
          privacy_clinician: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Votre activité au sein de {name} est hautement confidentielle. Afin de soutenir votre retour en santé, nous ne partagerons avec votre Clinicien et votre Fournisseur que le strict nécessaire.',
        },
        RTH_VETERANS: {
          privacy: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Tout ce que vous faites ici est confidentiel à 100%. Nous ne vendons ni de partageons aucune de vos données personnelles à quiconque.',
          privacy_clinician: '{name} vous donne accès à du contenu et à une communauté personnalisés en fonction de vos réponses. Votre activité au sein de {name} est hautement confidentielle. Afin de soutenir votre retour en santé, nous ne partagerons avec votre Clinicien et votre Fournisseur que le strict nécessaire.',
        },
      },
      fields: {
        firstname: {
          title: 'Comment voulez-vous qu\'on vous appelle?',
          content: 'Seule l\'application {name} vous appellera ainsi. Votre prénom ou alias ne sera pas rendu public.',
        },
        birthYear: {
          title: 'Peut-on connaître votre année de naissance?',
        },
        gender: {
          title: 'Comment souhaitez-vous qu\'on s\'adresse à vous?',
        },
      },
    },
    community: {
      title: 'Paramètres de la communauté',
      content: 'Personnalisez votre avatar et votre nom de code utilisés pour interagir dans notre communauté anonyme.',
    },
    buttons: {
      register: 'C\'est parti!',
    },
  },
};
