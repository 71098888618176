var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    ref: "field",
    attrs: {
      autocomplete: "new-password",
      label: _vm.$t("public.registration.profile.fields.firstname.label"),
      placeholder: _vm.$t(
        "public.registration.profile.fields.firstname.placeholder"
      ),
      hint: _vm.$t("public.registration.profile.fields.firstname.hint"),
      name: "firstname",
      rules: _vm.rules,
      "persistent-hint": "",
      type: "text"
    },
    model: {
      value: _vm.internalValue,
      callback: function($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }