

















































































import Vue from 'vue';
import RegistrationFieldPassword from '@/views/landing/register/components/registration-field-password.vue';
import RegistrationFieldPasswordConfirmation
  from '@/views/landing/register/components/registration-field-password-confirmation.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import { mapState } from 'vuex';
import { RootState } from '@/scripts/store/types';
import RegistrationFieldEmail from '@/views/landing/register/components/registration-field-email.vue';

export default Vue.extend({
  name: 'claim-account',
  components: {
    RegistrationFieldEmail,
    PrimaryButton,
    RegistrationFieldPasswordConfirmation,
    RegistrationFieldPassword,
  },
  props: {
    referralKey: {
      type: String,
      required: true,
    },
    referral: {
      type: Object as any,
      required: true,
    },
    userIdentity: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      error: null as null | string,
      form: {
        password: null,
        passwordConfirmation: null,
      },
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return (state as RootState).identity;
      },
      locale(): string {
        return localStorage.getItem('currLang') as string;
      },
    }),
    partnerName(): string {
      return this.partner && this.partner.content && this.partner.content['NAME'] && this.partner.content['NAME'][this.locale] ? this.partner.content['NAME'][this.locale] : 'Starling';
    },
  },
  methods: {
    onFormValidate(fieldname: string) {
      if (this.$refs.form) {
        if (fieldname) {
          const field = (this.$refs.form as any).$data.inputs.find((i: any) => i.$attrs.name === fieldname);
          if (field) {
            field.validate(true);
          }
        } else {
          (this.$refs.form as any).validate();
        }
      }
    },
    claimAccount() {
      this.loading = true;
      this.$store.dispatch('identity/claimReferral', {
        referralKey: this.referralKey,
        password: this.form.password,
      }).then(res => {
        return this.$store.dispatch('identity/login', {
          username: (this.referral as any).email,
          password: this.form.password,
        }).then(() => {
          this.$emit('claimed');
        });
      }, () => {
        this.error = this.$tc('public.referral.claim.error.message');
      }).finally(() => {
        this.loading = false;
      });
    },
    handleEnterKey() {
      if (this.valid) {
        this.claimAccount();
      }
    },
  },
});

