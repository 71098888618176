<template>
  <div>
    <v-text-field
        ref="field"
        autocomplete="new-password"
        :label="label ? label : $t('public.registration.account.fields.password.label')"
        :placeholder="label ? '' : $t('public.registration.account.fields.password.placeholder')"
        :hint="$t('public.registration.account.fields.password.hint')"
        :name="name"
        :rules="rules"
        v-model="internalValue"
        persistent-hint
        :append-icon="show?'$vuetify.icons.starling_eye_opened':'$vuetify.icons.starling_eye_closed'"
        @click:append="show=!show"
        :type="show?'text':'password'"
        :disabled="disabled">
    </v-text-field>
    <ul style="list-style-type:none; padding-left:0;">
      <li v-for="(rule,index) in passwordRules" :key="index">
        <v-layout row align-baseline gap-xs-1>
          <v-flex shrink d-flex>
            <v-icon class="rule" size="18" :class="{'active': isValid(rule.pattern)}">mdi-check-circle</v-icon>
          </v-flex>
          <v-flex grow style="line-height: 130%;">
            <small>{{ $t(rule.label) }}</small>
          </v-flex>
        </v-layout>
      </li>
    </ul>
  </div>
</template>

<script>
import { VALIDATION_REGEX } from '@/scripts/api';

export default {
  name: 'registration-field-password',
  props: {
    value: String,
    confirmation: String,
    label: String,
    name: {
      type: String,
      defaultValue: 'password',
    },
    disabled: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
      show: false,
      rules: [
        v => !!v || this.$t('common.errors.required'),
        v => VALIDATION_REGEX.PASSWORD.test(v) || this.$t('public.registration.account.fields.password.errors.generic'),
        v => (v && v.length <= 60) || this.$t('common.errors.max60'),
        v => !this.confirmation || v === this.confirmation || this.$t('common.errors.passwordMatch'),
      ],
    };
  },
  computed: {
    passwordRules() {
      return [
        { label: 'public.registration.account.fields.password.rules.size', pattern: /.{12,60}/ },
        { label: 'public.registration.account.fields.password.rules.lowercase_char', pattern: /[a-z]/ },
        { label: 'public.registration.account.fields.password.rules.uppercase_char', pattern: /[A-Z]/ },
        { label: 'public.registration.account.fields.password.rules.numerical_char', pattern: /[0-9]/ },
        { label: 'public.registration.account.fields.password.rules.special_char', pattern: /[!@#%&,:;<>~_=*.$^(){}[\]?+\-|/\\]/ },
      ];
    },
  },
  watch: {
    password() {
      this.$refs.field.validate();
    },
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
      }
    },
    internalValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    isValid(pattern) {
      return !!this.internalValue && pattern.test(this.internalValue);
    },
  },
};
</script>

<style scoped>
.rule.active {
  color: var(--StarlingSuccess) !important;
}
</style>
