
















import Vue, { PropType } from 'vue';
import StarlingAvatarBuilder from '@/views/avatar/starling-avatar-builder.vue';
import { getDefault } from '@/views/avatar/utils';
import { AvatarOption } from '@/views/avatar/types';
import SecondaryButton from '@/views/components/button/secondary-button.vue';

export default Vue.extend({
  name: 'starling-avatar-input',
  components: { SecondaryButton, StarlingAvatarBuilder },
  props: {
    value: {
      type: Object as PropType<AvatarOption | null>,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    const val: AvatarOption = JSON.parse(JSON.stringify(this.value ? this.value : getDefault()));
    return {
      innerValue: val as AvatarOption,
      edit: false,
      key: 0,
    };
  },
  watch: {
    value(val) {
      const img = this.$refs.image as HTMLImageElement;
      img.src = '';
      this.innerValue = JSON.parse(JSON.stringify(val || getDefault()));
    },
  },
  methods: {
    applyImage(image: string) {
      const blob = new Blob([ image ], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(blob);
      const img = this.$refs.image as HTMLImageElement;
      img.addEventListener('load', () => URL.revokeObjectURL(url), { once: true });
      img.src = url;
    },
    onRendered(evt: string) {
      const img = this.$refs.image as HTMLImageElement;
      if (img.getAttribute('src') === '' || !img.hasAttribute('src')) {
        this.applyImage(evt);
      }
    },
    onSave(evt: { image: string, options: AvatarOption }) {
      this.applyImage(evt.image);
      this.innerValue = JSON.parse(JSON.stringify(evt.options));
      this.edit = false;
      this.$emit('saved', evt);
    },
    onCancel() {
      this.edit = false;
      this.innerValue = JSON.parse(JSON.stringify(this.value ? this.value : getDefault()));
      this.key++;
    },
  },
});
