












































































import Vue from 'vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export interface MailTo {
  label: string;
  to: string;
  subject?: string;
  body?: string;
}

export interface AdditionalProperty {
  type: 'input' | 'textarea';
  id: string;
  label: string;
  placeholder: string;
}

export interface AdditionalProperties {
  explanation?: string;
  fields?: Array<AdditionalProperty>;
}

export interface EmailBuilder {
  text: string;
  mailto: MailTo;
  additionalProperties?: AdditionalProperties;
}

export default Vue.extend({
  name: 'registration-tips-access-code',
  components: { PrimaryButton },
  props: {
    path: String,
  },
  data() {
    return {
      prompt: false,
      promptValid: false,
      promptValues: {} as { [key: string]: string },
    };
  },
  computed: {
    emailBuilder(): EmailBuilder {
      return (this.$t(this.path) as any) as EmailBuilder;
    },
    form(): Vue & { resetValidation: () => void, validate: () => boolean } {
      return this.$refs.form as Vue & { resetValidation: () => void, validate: () => boolean };
    },
  },
  methods: {
    onPrompt() {
      this.prompt = true;
      this.promptValues = {};
      this.form.resetValidation();
    },
    onAccept() {
      if (this.form.validate()) {
        this.prepareEmail();
        this.prompt = false;
        this.form.resetValidation();
      }
    },
    onCancel() {
      this.promptValues = {};
      this.prompt = false;
      this.form.resetValidation();
    },
    onClick() {
      if (this.emailBuilder.additionalProperties && this.emailBuilder.additionalProperties.fields && this.emailBuilder.additionalProperties.fields.length) {
        this.onPrompt();
      } else {
        this.prepareEmail();
      }
    },
    prepareEmail() {
      const parts = [];
      if (this.emailBuilder.mailto.subject) {
        parts.push('subject=' + encodeURIComponent(this.emailBuilder.mailto.subject));
      }
      if (this.emailBuilder.mailto.body) {
        let body = this.emailBuilder.mailto.body;

        if (this.emailBuilder.additionalProperties?.fields && this.emailBuilder.additionalProperties?.fields.length) {
          for (const field of this.emailBuilder.additionalProperties?.fields) {
            body = body.replaceAll('###' + field.id + '###', this.promptValues && this.promptValues[field.id] ? this.promptValues[field.id] : '');
          }
        }
        parts.push('body=' + encodeURIComponent(body));
      }

      let value = 'mailto:';
      value += this.emailBuilder.mailto.to || 'members@starlingminds.com';
      if (parts.length) {
        value += '?';
        parts.forEach(part => {
          value += part + '&';
        });
      }
      window.open(value, '_blank');
    },
  },
});
