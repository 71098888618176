<template>
<svg style="display:inline; margin: 0; padding: 0; width: auto;"
     class="eye-opened-icon"
     viewBox="0 0 26 16"
     fill="transparent"
     xmlns="http://www.w3.org/2000/svg">
    <path d="M24.8436 7.33691C24.6212 7.03632 19.3 0 12.5022 0C5.7044 0 0.383172 7.03632 0.158665 7.33691C-0.0528885 7.62056 -0.0528885 8.00583 0.158665 8.2916C0.381013 8.59219 5.7044 15.6285 12.5 15.6285C19.2957 15.6285 24.619 8.59219 24.8414 8.2916C25.0551 8.00583 25.0551 7.62056 24.8436 7.33691ZM12.5022 14.0091C7.49613 14.0091 3.15928 9.33943 1.87484 7.81108C3.15712 6.28273 7.48318 1.61514 12.5022 1.61514C17.5082 1.61514 21.8451 6.28485 23.1295 7.81319C21.8473 9.34154 17.519 14.0091 12.5022 14.0091Z"/>
    <path d="M12.5032 2.9635C9.77677 2.9635 7.55762 5.13959 7.55762 7.81314C7.55762 10.4867 9.77677 12.6628 12.5032 12.6628C15.2297 12.6628 17.4488 10.4867 17.4488 7.81314C17.4488 5.13959 15.2297 2.9635 12.5032 2.9635ZM12.5032 11.0455C10.6856 11.0455 9.20687 9.59551 9.20687 7.81314C9.20687 6.03078 10.6856 4.58075 12.5032 4.58075C14.3209 4.58075 15.7996 6.03078 15.7996 7.81314C15.7996 9.59551 14.3209 11.0455 12.5032 11.0455Z"/>
</svg>

</template>

<script>
export default {
  name: 'eye-opened-icon',
};
</script>

<style scoped lang="css">
.eye-opened-icon  path{
    fill: currentColor;
    stroke: transparent;
}
</style>
