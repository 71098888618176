var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("v-autocomplete", {
        attrs: {
          clearable: "",
          "item-text": "text",
          "item-value": "value",
          "persistent-hint": "",
          disabled: !_vm.itemList || !_vm.itemList.length,
          "error-messages":
            !_vm.itemList || !_vm.itemList.length
              ? _vm.$t(
                  "public.registration.profile.fields.profession.errors.loading"
                )
              : null,
          hint: _vm.$t("public.registration.profile.fields.profession.hint"),
          items: _vm.itemList,
          label: _vm.$t("public.registration.profile.fields.profession.label"),
          "no-data-text": _vm.$t(
            "public.registration.profile.fields.profession.errors.search"
          ),
          placeholder: _vm.$t(
            "public.registration.profile.fields.profession.placeholder"
          ),
          rules: _vm.rules
        },
        model: {
          value: _vm.internalValue,
          callback: function($$v) {
            _vm.internalValue = $$v
          },
          expression: "internalValue"
        }
      })
    : _c("v-progress-circular", {
        attrs: { size: 30, color: "primary", indeterminate: "" }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }