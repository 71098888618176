export default {
  title: 'Sign In',
  fields: {
    username: 'Email',
    password: 'Password',
  },
  buttons: {
    signin: 'Sign in',
    not_registered: 'Not registered yet?',
    having_questions: 'Have questions? Feel free to <a target="_blank" href="mailto:members@starlingminds.com?subject=Feedback%20and%20questions">contact us</a> any time',
    forgotten_password: 'Forgotten password?',
  },
  error: {
    message: 'Invalid username or password',
    disabled: 'Your account has not been activated or temporarily disabled. Please contact us.',
    expired: 'Your account is expired. Please contact us.',
    locked: 'Your account is temporarily locked. Please try again in an hour.',
  },
};
