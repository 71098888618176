import publicPages from './public';
import app from './app';
import common from './common';
import status from './status';

export default {
  public: publicPages,
  app,
  common,
  status,
};
