export default {
  title: 'Réinitialiser mon mot de passe',
  content: {
    default: 'Pour réinitialiser votre mot de passe, veuillez suivre les étapes ci-dessous. ',
    required: 'Il semblerait que vous n\'ayez pas encore vérifié votre adresse courriel. Afin de garder votre compte sécurisé, veuillez réinitialiser votre mot de passe en suivant les étapes ci-dessous.',
    common: [
      'Un code de confirmation a été envoyé à l’adresse: <strong>{email}</strong>.',
      'Vous avez <strong>1 heure</strong> pour saisir le code avant son expiration. Si vous avez reçu plusieurs codes, veuillez s’il vous plait utiliser le plus récent.',
      'Vous n’avez rien reçu?',
      '<ol><li>Vérifiez que l’adresse courriel ci-dessus est bien valide. Si ce n’est pas le cas, <a href="/landing/forgotten_password">cliquez ici</a> pour recommencer avec la bonne adresse email. </li><li>Vérifiez votre dossier de courriels indésirables.</li><li>Si le courriel contenant le code de confirmation ne s’y trouve pas, envoyez un courriel à \n' +
      'l’adresse <a href="mailto:membres@starlingminds.com?subject=Code%20de%20r%E9initialisation%20de%20mot%20de%20passe%20non%20re%E7u">membres@starlingminds.com</a> pour obtenir du soutien technique.</li></ol>',
    ],
  },
  buttons: {
    set_password: 'Réinitialiser',
    new_reset_email: 'Envoyer un nouveau code par courriel',
  },
  fields: {
    code: {
      title: 'Entrez votre code de confirmation',
    },
    password: {
      title: 'Entrez un nouveau mot de passe pour votre compte.',
    },
  },
};
