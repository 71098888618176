import { dealWithMaintenance } from '@/scripts/api';

const ping = {
  state: {},
  mutations: {},
  actions: {
    ping({ commit, dispatch, state, rootState }) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/ping', {
        method: 'get',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(res => {
        dealWithMaintenance(res);
        return res;
      });
    },
  },
};

export default ping;
