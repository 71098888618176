export default {
  title: 'Iniciar sesión',
  fields: {
    username: 'Correo electrónico',
    password: 'Contraseña',
  },
  buttons: {
    signin: 'Iniciar sesión',
    not_registered: '¿Todavía no has creado una cuenta?',
    having_questions: '¿Tienes preguntas? Puedes <a target="_blank" href="mailto:members@starlingminds.com?subject=Comentarios%20y%20preguntas">ponte en contacto con nosotros</a> en cualquier momento',
    forgotten_password: '¿Has olvidado tu contraseña?',
  },
  error: {
    message: 'Nombre de usuario o contraseña no válido',
    disabled: 'Tu cuenta no ha sido activada o está desactivada temporalmente. Ponte en contacto con nosotros.',
    expired: 'Tu cuenta ha caducado. Ponte en contacto con nosotros.',
    locked: 'Tu cuenta está bloqueada temporalmente. Inténtalo de nuevo en una hora.',
  },
};
