var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.firstLoad
    ? _c(
        "div",
        [
          _vm._l(_vm.filterItems, function(filterItem) {
            return _c(
              "div",
              { key: filterItem.id },
              [
                filterItem.question
                  ? _c(
                      "p",
                      { staticClass: "starling-text mt-2 font-weight-medium" },
                      [
                        _vm._v(
                          "\n      " + _vm._s(filterItem.question) + "\n    "
                        )
                      ]
                    )
                  : _vm._e(),
                _c("v-autocomplete", {
                  class: !filterItem.question ? "mt-2" : "mt-0",
                  attrs: {
                    clearable: "",
                    "item-text": "text",
                    "item-value": "value",
                    "persistent-hint": "",
                    hint: filterItem.required
                      ? _vm.$t(
                          "public.registration.profile.fields.businessUnit.hint"
                        )
                      : "",
                    items: filterItem.sublevels,
                    label: _vm.$t(
                      "public.registration.profile.fields.businessUnit.label"
                    ),
                    loading: _vm.loadingFilters,
                    "no-data-text": _vm.$t(
                      "public.registration.profile.fields.businessUnit.errors.search"
                    ),
                    "error-messages":
                      !filterItem.sublevels || !filterItem.sublevels.length
                        ? _vm.$t(
                            "public.registration.profile.fields.businessUnit.errors.loading"
                          )
                        : null,
                    placeholder: filterItem.label,
                    rules: filterItem.required ? _vm.rules : []
                  },
                  on: {
                    change: function($event) {
                      return _vm.onSelectFilter(filterItem, $event)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item",
                        fn: function(ref) {
                          var parent = ref.parent
                          var item = ref.item
                          return [
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  item.value === _vm.notListedOption.value
                                    ? _c(
                                        "v-list-tile-title",
                                        { staticClass: "grey--text" },
                                        [_vm._v(_vm._s(item.text))]
                                      )
                                    : _c("v-list-tile-title", [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              "" +
                                                parent.genFilteredText(
                                                  item.text
                                                )
                                            )
                                          }
                                        })
                                      ])
                                ],
                                1
                              )
                            ]
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          }),
          _c(
            "div",
            { on: { click: _vm.doPredefined } },
            [
              _c("v-autocomplete", {
                ref: "businessUnit",
                staticClass: "mt-0",
                attrs: {
                  clearable: "",
                  "item-text": "text",
                  "item-value": "value",
                  "persistent-hint": "",
                  disabled:
                    !_vm.itemList ||
                    !_vm.itemList.length ||
                    _vm.internalValue.type === "skipped",
                  "error-messages":
                    !_vm.itemList || !_vm.itemList.length
                      ? _vm.$t(
                          "public.registration.profile.fields.businessUnit.errors.loading"
                        )
                      : null,
                  filter: _vm.customSearch,
                  hint:
                    _vm.internalValue.type === "skipped"
                      ? ""
                      : _vm.$t(
                          "public.registration.profile.fields.businessUnit.hint"
                        ),
                  items: _vm.itemList,
                  label: _vm.$t(
                    "public.registration.profile.fields.businessUnit.label"
                  ),
                  loading: _vm.loading,
                  "no-data-text": _vm.$t(
                    "public.registration.profile.fields.businessUnit.errors.search"
                  ),
                  placeholder: _vm.label
                    ? _vm.label
                    : _vm.$t(
                        "public.registration.profile.fields.businessUnit.placeholder"
                      ),
                  rules: _vm.internalValue.type === "skipped" ? [] : _vm.rules,
                  "search-input": _vm.search
                },
                on: {
                  "update:searchInput": function($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function($event) {
                    _vm.search = $event
                  },
                  change: _vm.onChange
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function(ref) {
                        var parent = ref.parent
                        var item = ref.item
                        return [
                          [
                            _c(
                              "v-list-tile-content",
                              [
                                item.value === _vm.notListedOption.value
                                  ? _c(
                                      "v-list-tile-title",
                                      { staticClass: "grey--text" },
                                      [_vm._v(_vm._s(item.text))]
                                    )
                                  : _c("v-list-tile-title", [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            "" +
                                              parent.genFilteredText(item.text)
                                          )
                                        }
                                      })
                                    ])
                              ],
                              1
                            )
                          ]
                        ]
                      }
                    },
                    {
                      key: "append-item",
                      fn: function() {
                        return [
                          !_vm.isLastPage
                            ? _c(
                                "v-list-tile",
                                { on: { click: _vm.loadMore } },
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c(
                                        "v-list-tile-title",
                                        { staticClass: "grey--text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("common.actions.load_more")
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  820510743
                ),
                model: {
                  value: _vm.internalValue.value,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "value", $$v)
                  },
                  expression: "internalValue.value"
                }
              })
            ],
            1
          ),
          _vm.internalValue.type === "custom"
            ? _c("v-text-field", {
                staticClass: "mt-0",
                attrs: {
                  name: "notListed",
                  "persistent-hint": "",
                  hint: _vm.$t(
                    "public.registration.profile.fields.businessUnit.hint"
                  ),
                  placeholder: _vm.$t(
                    "public.registration.profile.fields.businessUnit.notListedPlaceholder",
                    { param: _vm.label }
                  ),
                  rules: _vm.rules,
                  type: "text"
                },
                model: {
                  value: _vm.internalValue.custom,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "custom", $$v)
                  },
                  expression: "internalValue.custom"
                }
              })
            : _vm._e(),
          _vm.enableNotListed
            ? _c(
                "a",
                {
                  staticClass: "starling-text",
                  class:
                    _vm.internalValue.type === "skipped"
                      ? "font-weight-bold"
                      : "text--disabled",
                  staticStyle: { display: "block" },
                  on: { click: _vm.doSkip }
                },
                [
                  _vm._v(
                    "\n    " +
                      _vm._s(
                        _vm.$t(
                          "public.registration.profile.fields.businessUnit.skip.label"
                        )
                      ) +
                      "\n  "
                  )
                ]
              )
            : _vm._e()
        ],
        2
      )
    : _c("v-progress-circular", {
        attrs: { size: 30, color: "primary", indeterminate: "" }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }