var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "markup-text" },
    [
      _vm._l(_vm.valueParts, function(part, index) {
        return [
          !_vm.isDynamicText(part)
            ? _c("span", {
                key: "static-" + index,
                domProps: { innerHTML: _vm._s(part) }
              })
            : _c("sm-dynamic-text", {
                key: "dynamic-" + index,
                attrs: { value: part }
              })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }