var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    [
      _c(
        "v-card",
        {
          staticClass: "setup_access_code-card",
          class: _vm.$vuetify.breakpoint.smAndUp ? "pa-3" : "pa-0",
          style: _vm.cardStyles,
          attrs: {
            flat: "",
            elevation: _vm.elevation,
            "min-height": _vm.cardHeight
          }
        },
        [
          this.loading ? _c("v-card-loader") : _vm._e(),
          _vm.accessCodeDetermined
            ? _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: {
                        autocomplete: "off",
                        transition: "slide-y-reverse-transition"
                      },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.handleEnterKey($event)
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "", "justify-center": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm11: "", md10: "" } },
                            [
                              _c("h1", { staticClass: "starling-h1" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "public.signup.setup_access_code.title",
                                        { name: _vm.partnerName }
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm11: "",
                                md10: "",
                                "mt-3": ""
                              }
                            },
                            [
                              _c("p", { staticClass: "starling-body" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "public.signup.setup_access_code.content",
                                        { name: _vm.partnerName }
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm11: "",
                                md10: "",
                                "mt-3": ""
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "starling-body font-weight-medium"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "public.signup.setup_access_code.account_cta"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          !_vm.form.accessCodeImprint
                            ? [
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      xs12: "",
                                      sm11: "",
                                      md10: "",
                                      "mt-3": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "starling-body font-weight-medium"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "public.signup.setup_access_code.fields.access_code.title"
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      xs12: "",
                                      sm11: "",
                                      md10: "",
                                      "mt-2": ""
                                    }
                                  },
                                  [
                                    _c("registration-field-access-code", {
                                      on: {
                                        "access-code-validated":
                                          _vm.onAccessCodeValidated,
                                        "access-code-invalidated":
                                          _vm.onAccessCodeInvalidated
                                      },
                                      model: {
                                        value: _vm.form.accessCode,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "accessCode", $$v)
                                        },
                                        expression: "form.accessCode"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      xs12: "",
                                      sm11: "",
                                      md10: "",
                                      "mt-3": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        attrs: { row: "", "justify-center": "" }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: {
                                              shrink: "",
                                              "align-top": "",
                                              "mr-1": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  large: ""
                                                }
                                              },
                                              [_vm._v("mdi-information")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { "align-top": "" } },
                                          [
                                            _c("email-builder", {
                                              attrs: {
                                                path:
                                                  "public.signup.setup_access_code.fields.access_code.tips"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : void 0,
                          (_vm.form.accessCode || _vm.form.accessCodeImprint) &&
                          _vm.form.validated &&
                          _vm.accessCodeLoaded
                            ? [
                                _vm.memberId && _vm.memberId.active
                                  ? [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm11: "",
                                            md10: "",
                                            "mt-3": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "starling-body font-weight-medium"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "public.signup.setup_access_code.fields.memberCode.title"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm11: "",
                                            md10: "",
                                            "mt-2": ""
                                          }
                                        },
                                        [
                                          _c("registration-field-member-id", {
                                            attrs: {
                                              configuration: _vm.memberId
                                            },
                                            model: {
                                              value: _vm.form.memberCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "memberCode",
                                                  $$v
                                                )
                                              },
                                              expression: "form.memberCode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                !_vm.defaultProfession
                                  ? [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm11: "",
                                            md10: "",
                                            "mt-3": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "starling-body font-weight-medium"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "public.signup.setup_access_code.fields.profession.title"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm11: "",
                                            md10: "",
                                            "mt-2": ""
                                          }
                                        },
                                        [
                                          _c("registration-field-profession", {
                                            attrs: {
                                              "access-code":
                                                _vm.form.accessCode,
                                              "access-code-imprint":
                                                _vm.form.accessCodeImprint
                                            },
                                            model: {
                                              value: _vm.form.professionId,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "professionId",
                                                  $$v
                                                )
                                              },
                                              expression: "form.professionId"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                !_vm.defaultBusinessUnit
                                  ? [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm11: "",
                                            md10: "",
                                            "mt-3": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "starling-body font-weight-medium"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.businessUnitLabels
                                                      .question
                                                      ? _vm.businessUnitLabels
                                                          .question
                                                      : _vm.$t(
                                                          "public.signup.setup_access_code.fields.businessUnit.title"
                                                        )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm11: "",
                                            md10: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "registration-field-business-unit",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.businessUnitLabels.label,
                                                "access-code":
                                                  _vm.form.accessCode,
                                                "access-code-imprint":
                                                  _vm.form.accessCodeImprint,
                                                "enable-not-listed":
                                                  _vm.businessUnitLabels
                                                    .allowOther
                                              },
                                              model: {
                                                value: _vm.form.businessUnit,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "businessUnit",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.businessUnit"
                                              }
                                            }
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            { staticClass: "pa-0 pt-3" },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-center": "",
                    "align-center": "",
                    "gap-xs-3": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-center": "", "pt-3": "" } },
                    [
                      _c(
                        "primary-button",
                        {
                          attrs: {
                            id: "btn-do-reset-password",
                            disabled:
                              !_vm.valid ||
                              _vm.loading ||
                              !_vm.accessCodeLoaded,
                            large: ""
                          },
                          on: { click: _vm.toNextStep }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.actions.next")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.loading, flat: "", round: "" },
                          on: { click: _vm.cancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.actions.logout")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "", "text-center": "" } }, [
                    _c("span", [_vm._v("1 / 2")])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }