import { ActionContext, Module } from 'vuex';
import { RootState } from '../../types';
import { AuthenticationUsernamePassword, IdentityState, Program } from '@/scripts/store/modules/identity/types';
import { Auth } from 'aws-amplify';
import router from '@/scripts/router';
import Vue from 'vue';
import i18n from '@/scripts/lang';
import { mergeDeep } from '@/scripts/mergeDeep';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Capacitor, CapacitorCookies } from '@capacitor/core';
import { SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY } from '@/views/landing/setup_profile/SetupAccessCode.vue';

const defaultTheme = {
  fonts: {
    'Open Sans': 'https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,500,600,700,800',
  },
  variables: {
    '--StarlingDefaultFont': { type: 'text', value: 'Open Sans' },
    '--StarlingPrimary1': { type: 'color', value: '#2194dd' },
    '--StarlingPrimary2': { type: 'color', value: '#19aef6' },
    '--StarlingPrimary3': { type: 'color', value: '#2fcefb' },
    '--StarlingSecondary': { type: 'color', value: '#23cdfd' },
    '--StarlingAccent': { type: 'color', value: '#22a71b' },
    '--StarlingError': { type: 'color', value: '#ff4850' },
    '--StarlingSuccess': { type: 'color', value: '#08eacf' },
    '--StarlingWarning': { type: 'color', value: '#ffdc75' },
    '--StarlingInfo': { type: 'color', value: '#4f4f4f' },

    '--button-gradient-1': { type: 'color', value: '#23cdfd' },
    '--button-gradient-2': { type: 'color', value: '#019cff' },
    '--button-gradient-3': { type: 'color', value: '#18befe' },
    '--button-radius': { type: 'text', value: '28px' },
    '--button-hover': { type: 'text', value: 'currentColor' },
    '--button-hover-opacity': { type: 'numeric', value: '.12' },

    '--secondary-button-color': { type: 'color', value: '#ffffff' },
    '--secondary-button-text-color': { type: 'color', value: 'var(--StarlingPrimary2)' },
    '--secondary-button-hover': { type: 'text', value: 'currentColor' },
    '--secondary-button-hover-opacity': { type: 'numeric', value: '.12' },

    '--StarlingHyperlink': { type: 'color', value: '#4c75aa' },

    '--StarlingLighterGrey': { type: 'color', value: '#ededed' },
    '--StarlingMediumGrey': { type: 'color', value: '#b7b7b7' },
    '--StarlingDarkGrey': { type: 'color', value: '#4f4f4f' },
    '--StarlingAlmostBlack': { type: 'color', value: '#333333' },
    '--StarlingGreen': { type: 'color', value: '#08eacf' },
    '--StarlingGrey': { type: 'color', value: '#4f4f4f' },
    '--StarlingLightGrey': { type: 'color', value: '#c4c4c4' },
    '--StarlingPaleRed': { type: 'color', value: '#ff777e' },
    '--StarlingPaleYellow': { type: 'color', value: '#ffdc75' },
    '--StarlingDarkblue': { type: 'color', value: '#3a5981' },
    '--StarlingDarkGreen': { type: 'color', value: '#058189' },
    '--StarlingLighterBlue': { type: 'color', value: '#f4fbff' },
    '--StarlingGanonBlue': { type: 'color', value: '#a3e7fa' },
  },
  images: {
    SIDE_NAV: {
      imageUrl: '/svg/bg-sidenav.svg',
      linkUrl: null,
    },
    BOTTOM_NAV: {
      imageUrl: '/svg/footer-background.svg',
      linkUrl: null,
    },
    MEMBER: {
      imageUrl: '/svg/starling-logo-colors.svg',
      linkUrl: null,
    },
    MEMBER_DEFAULT: {
      imageUrl: '/svg/starling-logo-colors.svg',
      linkUrl: null,
      default: true,
    },
  },
};

const rthTheme = mergeDeep({}, defaultTheme, {
  variables: {
    '--StarlingPrimary1': { type: 'color', value: '#09646A' },
    '--StarlingPrimary2': { type: 'color', value: '#058189' },
    '--StarlingPrimary3': { type: 'color', value: '#3AA1A5' },
    '--StarlingSecondary': { type: 'color', value: '#94c8ca' },
    '--StarlingDarkGreen': { type: 'color', value: '#2194DD' },
    '--button-gradient-1': { type: 'color', value: '#058189' },
    '--button-gradient-2': { type: 'color', value: '#09646A' },
    '--button-gradient-3': { type: 'color', value: '#3AA1A5' },
  },
  images: {
    SIDE_NAV: {
      imageUrl: '/svg/bg-sidenav-rth.svg',
      linkUrl: null,
    },
    BOTTOM_NAV: {
      imageUrl: '/svg/footer-background-rth.svg',
      linkUrl: null,
    },
    MEMBER: {
      imageUrl: '/svg/starling-logo-colors-rth.svg',
      linkUrl: null,
    },
    MEMBER_DEFAULT: {
      imageUrl: '/svg/starling-logo-colors-rth.svg',
      linkUrl: null,
      default: true,
    },
  },
});

/* eslint-disable no-unused-vars */
const defaultThemesByProgram: { [key in Program]: any } = {
  [Program.MENTAL_FITNESS]: mergeDeep({}, defaultTheme),
  [Program.RTH_MENTAL]: mergeDeep({}, rthTheme),
  [Program.RTH_PHYSICAL]: mergeDeep({}, rthTheme),
  [Program.RTH_VETERANS]: mergeDeep({}, rthTheme),
};

const transparentVariants = {
  Transparent10: 'E6',
  Transparent20: 'CC',
  Transparent50: '80',
  Transparent80: '33',
  Transparent90: '1A',
} as any;

export const defaultState: IdentityState = {
  type: null,
  program: null,
  userIdentity: null,
  cognitoUser: null,
  configuration: null,
  organization: null,
  subdomain: null,
  logo: null,
  theme: null,
  content: null,
};

export const identity: Module<IdentityState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    emptyCognitoUser(state) {
      state.cognitoUser = null;
    },
    setCognitoUser(state, cognitoUser) {
      state.cognitoUser = cognitoUser;
    },
    emptyBackend(state) {
      state.organization = null;
      state.configuration = null;
      state.subdomain = null;
    },
    configureBackend(state, payload) {
      state.organization = payload.organization;
      state.configuration = payload.configuration;
      state.subdomain = payload.subdomain;
    },
    emptyLogo(state) {
      state.logo = null;
    },
    configureLogo(state, payload) {
      state.logo = payload;
    },
    emptyContent(state) {
      state.content = null;
    },
    configureContent(state, payload) {
      state.content = payload.content;
    },
    emptyTheme(state) {
      state.type = null;
      state.theme = null;
    },
    configureTheme(state, payload) {
      state.type = payload.type;
      state.theme = payload.theme;
    },
  },
  actions: {
    applyContent(context, payload) {
      const content = payload.content;
      if (!content) {
        context.commit('emptyContent', payload);
        return;
      }
      document.title = content.PAGE_TITLE && content.PAGE_TITLE[i18n.locale] ? content.PAGE_TITLE[i18n.locale] : i18n.tc('common.title.value');
      document.documentElement.lang = i18n.locale;

      document.head.querySelector("meta[name='']");

      context.commit('configureContent', payload);
      return Promise.resolve(true);
    },
    applyTheme(context, payload) {
      const theme = payload.theme;

      if (theme.variables) {
        new Vue().$vuetify.theme = {
          primary: theme.variables['--StarlingPrimary2'].value,
          secondary: theme.variables['--StarlingSecondary'].value,
          accent: theme.variables['--StarlingAccent'].value,
          error: theme.variables['--StarlingError'].value,
          success: theme.variables['--StarlingSuccess'].value,
          warning: theme.variables['--StarlingWarning'].value,
          info: theme.variables['--StarlingInfo'].value,
        };

        Object.keys(theme.variables).forEach(cssVar => {
          document.documentElement.style.setProperty(cssVar, theme.variables[cssVar].value);
          if (theme.variables[cssVar].type === 'color') {
            Object.keys(transparentVariants).forEach((key: string) => {
              document.documentElement.style.setProperty(cssVar + key, theme.variables[cssVar].value + transparentVariants[key]);
            });
          }
        });
      }

      if (theme.fonts) {
        Object.keys(theme.fonts).forEach(fontName => {
          const cssId = 'cssFont_' + fontName;
          let found: HTMLLinkElement = document.getElementById(cssId) as HTMLLinkElement;
          if (!found) {
            const head = document.getElementsByTagName('head')[0];
            const link = document.createElement('link');
            link.id = cssId;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.media = 'all';
            head.appendChild(link);
            found = link;
          }

          found.href = theme.fonts[fontName];
        });
      }

      context.commit('configureTheme', payload);
      return Promise.resolve(true);
    },
    appearance(context: ActionContext<IdentityState, RootState>) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/appearance', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(res => {
          if (!context.rootGetters.isNativePlatform && res.configuration && res.configuration.frontendUrl) {
            const { hostname } = new URL(res.configuration.frontendUrl);
            if (context.rootState.afterLoginRoute && router.currentRoute.name === 'sign_in') {
              window.location.href = res.configuration.frontendUrl + '/' + (context.rootState.afterLoginRoute.startsWith('/') ? context.rootState.afterLoginRoute.substr(1) : context.rootState.afterLoginRoute);
              return Promise.resolve({ redirect: true });
            }
            if (hostname !== window.location.hostname) {
              window.location.href = res.configuration.frontendUrl + '/' + (window.location.pathname ? (window.location.pathname.startsWith('/') ? window.location.pathname.substr(1) : window.location.pathname) : '') + (window.location.search ? window.location.search : '') + (window.location.hash ? window.location.hash : '');
              return Promise.resolve({ redirect: true });
            }
          }
          context.commit('setIsAuthorized', res.loggedIn, { root: true });
          context.commit('configureBackend', res);

          const promises = [];
          promises.push(context.dispatch('applyContent', { type: res.type, content: res.content }));

          const currentProgram: Program = res.program ? res.program : Program.MENTAL_FITNESS;

          let computedTheme = mergeDeep({}, defaultThemesByProgram[currentProgram]);
          if (res.theme) {
            computedTheme = mergeDeep(computedTheme, res.theme);
          }
          promises.push(context.dispatch('applyTheme', { type: res.type, theme: computedTheme, content: res.content }));

          if (computedTheme.images?.MEMBER) {
            context.commit('configureLogo', mergeDeep({}, computedTheme.images.MEMBER, { default: computedTheme.images?.MEMBER.imageUrl === defaultThemesByProgram[currentProgram].images?.MEMBER?.imageUrl }));
          } else {
            context.commit('emptyLogo');
          }
          return Promise.all(promises).then(() => {
            return Promise.resolve({ redirect: false });
          });
        });
    },
    locales(context: ActionContext<IdentityState, RootState>) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/locales', {
        method: 'get',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json());
    },
    findAccessCode(context: ActionContext<IdentityState, RootState>, accessCode: string) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/accessCodes/_validate', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify({ accessCode }),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(res => res.json())
        .then(
          data => {
            context.commit('configureBackend', data);
            return data;
          },
          err => {
            context.commit('emptyBackend');
            throw err;
          });
    },
    findAccessCodeByImprint(context: ActionContext<IdentityState, RootState>, accessCodeImprint: string) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/accessCodes/_validateImprint', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify({ accessCodeImprint }),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(res => res.json())
        .then(
          data => {
            context.commit('configureBackend', data);
            return data;
          },
          err => {
            context.commit('emptyBackend');
            throw err;
          });
    },
    findReferral(context: ActionContext<IdentityState, RootState>, referralKey: string) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/referrals/_validate', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        body: JSON.stringify({ referralKey }),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(res => res.json())
        .then(
          data => {
            context.commit('configureBackend', data);
            return data;
          },
          err => {
            context.commit('emptyBackend');
            throw err;
          });
    },
    logout(context: ActionContext<IdentityState, RootState>) {
      return context.dispatch('signOutCognito').then(() => {
        return fetch(process.env.VUE_APP_IDENTITY_API + '/authenticate/_logout', {
          method: 'post',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }).then(res => {
        // Remove DB informations
        // Remove Partner information
        context.commit('emptyBackend');
        context.commit('setIsAuthorized', false, { root: true });
        context.commit('setUser', false, { root: true });
        return context.dispatch('clearStarlingCookie');
      }).then(() => {
        return context.dispatch('appearance');
      });
    },
    updateAccessToken(context: ActionContext<IdentityState, RootState>, token: string) {
      return context.dispatch('clearStarlingCookie').then(() => {
        return fetch(process.env.VUE_APP_IDENTITY_API + '/authenticate/_cognito', {
          method: 'post',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
      }).then(res => {
        return res.json();
      }).then(data => {
        context.state.userIdentity = data;
        return Promise.resolve(data);
      });
    },
    signUp(context: ActionContext<IdentityState, RootState>, payload: any) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/authenticate/_signUp', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }).then(res => res.json());
    },
    claimReferral(context: ActionContext<IdentityState, RootState>, payload: any) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/referrals/_claim', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }).then(res => res.json());
    },
    checkReferral(context: ActionContext<IdentityState, RootState>, payload: any) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/referrals/_check', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }).then(res => {
        return res.status === 200 ? res.json() : Promise.resolve(null);
      });
    },
    verifyAccount(context: ActionContext<IdentityState, RootState>, payload: any) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/authenticate/_verifyAccount', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }).then(res => res.json());
    },
    signInOTP(context: ActionContext<IdentityState, RootState>, payload: any) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/authenticate/_signInOTP', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }).then(res => res.json());
    },
    forgotPassword(context: ActionContext<IdentityState, RootState>, payload: {
      username: string,
      clientMetadata: { [key: string]: string }
    }) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/authenticate/_forgotPassword', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
    },
    confirmForgotPassword(context: ActionContext<IdentityState, RootState>, payload: {
      username: string,
      password: string,
      confirmationCode: string,
      clientMetadata: { [key: string]: string }
    }) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/authenticate/_confirmForgotPassword', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }).then(res => res.json());
    },
    changePassword(context: ActionContext<IdentityState, RootState>, payload: {
      password: string,
    }) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/authenticate/_changePassword', {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
    },
    deleteAccount(context: ActionContext<IdentityState, RootState>, payload: {
        currentPassword: string,
      }) {
      return fetch(process.env.VUE_APP_IDENTITY_API + '/authenticate/_delete', {
        method: 'delete',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }).then(response => {
        if (response.ok) return Promise.resolve();
        return Promise.reject(response);
      });
    },
    login(context: ActionContext<IdentityState, RootState>, payload: AuthenticationUsernamePassword) {
      return context.dispatch('signOutCognito').then(() => {
        return Auth.signIn(payload.username, payload.password, { sso: 'false' });
      }).then(res => {
        context.commit('setCognitoUser', res);
        return Promise.resolve(res);
      }).then(() => {
        return context.dispatch('configureAuth');
      });
    },
    storeAccessCodeQrCode(context: ActionContext<IdentityState, RootState>, payload: AuthenticationUsernamePassword): Promise<boolean> {
      if (!localStorage.getItem(SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY)) {
        return Promise.resolve(false);
      }

      return Auth.currentAuthenticatedUser()
        .then(user => {
          user.Session = user.signInUserSession;
          return Auth.updateUserAttributes(user, { 'custom:access_code': localStorage.getItem(SETUP_ACCESS_CODE_IMPRINT_STORAGE_KEY) }).then(res => {
            return Promise.resolve(true);
          });
        })
        .catch(() => {
          return Promise.resolve(false);
        });
    },
    loginManually(context: ActionContext<IdentityState, RootState>, payload: {
      idToken: string,
      accessToken: string,
      refreshToken: string
    }) {
      const cognitoIdToken = new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: payload.idToken,
      });
      const cognitoAccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: payload.accessToken,
      });
      const cognitoRefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: payload.refreshToken,
      });
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: cognitoIdToken.payload['cognito:username'],
        Pool: Auth['userPool'],
        Storage: Auth['userPool'].storage,
      });

      const cognitoSession = new AmazonCognitoIdentity.CognitoUserSession({
        AccessToken: cognitoAccessToken,
        IdToken: cognitoIdToken,
        RefreshToken: cognitoRefreshToken,
      });

      cognitoUser.setSignInUserSession(cognitoSession);

      return context.dispatch('configureAuth');
    },
    configureAuth(context: ActionContext<IdentityState, RootState>) {
      return Auth.currentSession()
        .then(session => {
          return context.dispatch('updateAccessToken', session.getAccessToken().getJwtToken());
        })
        .catch(err => {
          // @ts-ignore
          Vue.$log.error('An error occured during auth configuration', JSON.stringify(err, null, 2), 'with cognito user ?', JSON.stringify(context.state.cognitoUser, null, 2));
          if (context.state.cognitoUser) {
            return Promise.resolve(context.state.cognitoUser);
          }
          return Promise.resolve(null);
        })
        .then(userIdentity => {
          return context.dispatch('appearance').then(res => {
            const passwordChange = userIdentity && userIdentity.challengeName === 'NEW_PASSWORD_REQUIRED';
            const result = {
              userIdentity,
              passwordChange,
              redirect: res.redirect,
            };
            return Promise.resolve(result);
          });
        });
    },
    signOutCognito(context: ActionContext<IdentityState, RootState>) {
      return Auth.signOut().then(() => {
        if (Capacitor.isNativePlatform()) {
          return CapacitorCookies.clearAllCookies();
        }
        return Promise.resolve();
      });
    },
    clearStarlingCookie() {
      if (Capacitor.isNativePlatform()) {
        return CapacitorCookies.deleteCookie({ url: process.env.VUE_APP_IDENTITY_API, key: 'STARLING_MEMBER_TOKEN' });
      }
      return Promise.resolve();
    },
  },
};
