export default {
  title: 'Définir un nouveau mot de passe sécurisé',
  content: {
    default: 'Il semble que vous vous soyez connecté avec un mot de passe temporaire. Nous vous demandons de définir votre propre mot de passe pour sécuriser votre compte à l\'aide des champs ci-dessous.',
  },
  buttons: {
    set_password: 'Définir',
  },
  fields: {
    password: {
      title: 'Entrez un nouveau mot de passe pour votre compte.',
    },
  },
  error: {
    message: 'Une erreur s\'est produite. Veuillez réessayer.',
  },
};
