var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "mood-tracker-icon",
      staticStyle: {
        display: "inline",
        margin: "0",
        padding: "0",
        width: "auto",
        height: "auto"
      },
      attrs: {
        viewBox: "0 0 95 95",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("rect", {
        attrs: { x: "2", width: "95", height: "95", fill: "white" }
      }),
      _c("circle", {
        attrs: {
          cx: "69.2854",
          cy: "29.3345",
          r: "10.6389",
          transform: "rotate(63.293 69.2854 29.3345)",
          fill: "url(#mt-paint0)",
          "fill-opacity": "0.8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M8.66987 44.5132C5.68716 60.636 35.5293 64.2316 51.8989 66.953C62.5371 68.7283 76.0505 69.5197 84.3664 62.306C87.8946 59.2473 92.6812 50.9781 87.6162 46.7107C84.7475 44.2981 81.0632 46.0523 79.5299 48.7148C82.1094 45.0795 84.8042 39.6777 81.4677 35.5763C77.9456 31.2462 69.1866 30.3385 66.1247 35.0933C70.8468 23.7481 60.1718 14.9713 48.1114 14.1978C36.5054 13.457 33.396 21.4399 32.5068 30.5664C28.0783 28.8911 18.2219 27.4522 19.3041 36.7644C16.2015 33.0237 9.09781 33.5393 6.99887 37.5784C3.88672 43.557 8.76658 43.977 8.66987 44.5132Z",
          fill: "white"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M8.66987 44.5132C5.68716 60.636 35.5293 64.2316 51.8989 66.953C62.5371 68.7283 76.0505 69.5197 84.3664 62.306C87.8946 59.2473 92.6812 50.9781 87.6162 46.7107C84.7475 44.2981 81.0632 46.0523 79.5299 48.7148C82.1094 45.0795 84.8042 39.6777 81.4677 35.5763C77.9456 31.2462 69.1866 30.3385 66.1247 35.0933C70.8468 23.7481 60.1718 14.9713 48.1114 14.1978C36.5054 13.457 33.396 21.4399 32.5068 30.5664C28.0783 28.8911 18.2219 27.4522 19.3041 36.7644C16.2015 33.0237 9.09781 33.5393 6.99887 37.5784C3.88672 43.557 8.76658 43.977 8.66987 44.5132Z",
          fill: "url(#mt-paint1)"
        }
      }),
      _c("path", {
        staticClass: "icon-colorized",
        attrs: {
          d:
            "M58.733 81.9822H42.4257C41.8363 81.9822 41.2469 81.7845 40.8539 81.2902C40.461 80.8948 40.1662 80.3017 40.1662 79.7086V75.0626C38.9874 75.3591 37.7103 75.5568 36.6297 75.6557C34.4685 75.9522 33.2897 75.0626 32.7002 74.2718C32.0126 73.4809 31.9143 72.4924 31.9143 72.3936C31.9143 72.2947 31.9143 72.2947 31.9143 72.1959V63.0027H27.5919C26.9043 63.0027 26.3148 62.6073 26.0201 62.0142C25.7254 61.4211 25.7254 60.7291 26.1184 60.136L30.4408 53.1175C30.932 52.4256 31.7179 51.9313 32.5038 51.9313H65.0202C66.199 51.9313 67.1814 52.9198 67.2796 54.106C67.2796 55.3911 67.1814 57.467 66.2973 60.0371C65.3149 63.0027 63.4484 65.7705 60.9925 68.3407V79.6097C60.9925 80.9937 60.0101 81.9822 58.733 81.9822ZM42.3275 74.5683V79.7086C42.3275 79.7086 42.3275 79.7086 42.3275 79.8074H42.4257H58.733C58.733 79.8074 58.8313 79.8074 58.8313 79.7086V67.9453C58.8313 67.6487 58.9295 67.3521 59.126 67.1544C60.9925 65.2763 65.3149 60.2348 65.1184 54.2049C65.1184 54.2049 65.1184 54.2049 65.0202 54.2049H32.5038C32.4055 54.2049 32.3073 54.3037 32.2091 54.3037L28.1813 60.8279H32.995C33.5844 60.8279 34.0756 61.3222 34.0756 61.9153V72.097C34.1738 72.5913 34.5667 73.6786 36.4332 73.4809C39.4786 73.0855 44.6851 71.7016 44.6851 71.7016C44.7834 71.7016 44.7834 71.7016 44.8816 71.7016C45.0781 71.7016 48.0252 70.9108 49.8917 64.1889C50.0882 63.5958 50.6776 63.2992 51.267 63.3981C51.8564 63.5958 52.1511 64.1889 52.0529 64.782C51.0705 68.2418 49.6952 70.8119 48.0252 72.3936C46.7481 73.4809 45.7657 73.7775 45.3728 73.8763C44.8816 73.9752 43.801 74.2718 42.3275 74.5683Z"
        }
      }),
      _c("path", {
        staticClass: "icon-colorized",
        attrs: {
          d:
            "M46.4534 48.3727C46.0605 48.3727 45.6676 48.175 45.4711 47.7796L40.9522 38.883L38.5945 43.3313C38.398 43.7267 38.0051 43.9244 37.6121 43.9244H33.6827C33.0932 43.9244 32.6021 43.4301 32.6021 42.837C32.6021 42.2439 33.0932 41.7497 33.6827 41.7497H37.1209L40.1663 36.0163C40.3628 35.6209 40.7557 35.4232 41.1487 35.4232C41.5416 35.4232 41.9346 35.6209 42.131 36.0163L46.6499 44.9129L52.7406 33.1496C52.9371 32.7542 53.33 32.5565 53.723 32.5565C54.1159 32.5565 54.5089 32.7542 54.7053 33.1496L59.0278 41.7497H62.859C63.4484 41.7497 63.9396 42.2439 63.9396 42.837C63.9396 43.4301 63.4484 43.9244 62.859 43.9244H58.3401C57.9472 43.9244 57.5542 43.7267 57.3577 43.3313L53.6247 36.0163L47.5341 47.7796C47.2393 48.0762 46.9446 48.3727 46.4534 48.3727Z"
        }
      }),
      _c("path", {
        staticClass: "icon-colorized",
        attrs: {
          d:
            "M29.6549 44.0232C30.3426 44.0232 30.8338 43.529 30.8338 42.837C30.8338 42.145 30.3426 41.6508 29.6549 41.6508C28.9673 41.6508 28.4761 42.145 28.4761 42.837C28.4761 43.4301 28.9673 44.0232 29.6549 44.0232Z"
        }
      }),
      _c("path", {
        staticClass: "icon-colorized",
        attrs: {
          d:
            "M66.7885 44.0232C67.4761 44.0232 67.9673 43.529 67.9673 42.837C67.9673 42.145 67.4761 41.6508 66.7885 41.6508C66.1008 41.6508 65.6096 42.145 65.6096 42.837C65.6096 43.4301 66.1008 44.0232 66.7885 44.0232Z"
        }
      }),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "mt-paint0",
                x1: "48.4248",
                y1: "29.1258",
                x2: "69.2854",
                y2: "39.9734",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", {
                attrs: { "stop-color": "white", "stop-opacity": "0" }
              }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#FFDC75" } })
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "mt-paint1",
                x1: "54.0284",
                y1: "15.1495",
                x2: "43.5865",
                y2: "65.772",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", {
                attrs: { offset: "0.223958", "stop-color": "#B7FBFF" }
              }),
              _c("stop", {
                attrs: {
                  offset: "0.604167",
                  "stop-color": "#D9FDFF",
                  "stop-opacity": "0.551913"
                }
              }),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "white",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }