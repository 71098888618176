export default {
  gender: {
    MALE: 'Homme',
    FEMALE: 'Femme',
    NON_BINARY: 'Non-binaire',
    SELF_DESCRIBE: 'Non listé',
  },
  durations: {
    none: 'durée incorrecte',
    seconds: { multiple: 'secondes', single: 'seconde' },
    minutes: { multiple: 'minutes', single: 'minute' },
    hours: { multiple: 'heures', single: 'heure' },
    days: { multiple: 'jours', single: 'jour' },
    months: { multiple: 'mois', single: 'mois' },
    years: { multiple: 'année', single: 'année' },
  },
};
