import Vue from 'vue';

const you = {
  state: {
    user: {},
    plan: {},
    content: [],
  },
  mutations: {
    resetYouState(state) {
      Object.assign(state, initialState);
    },
    setUser(state, user) {
      state.user = { ...state.user, ...user };
    },
    currentModule(state, index) {
      state.user.courseModuleIndex = index;
    },
    setPlan(state, plan) {
      state.plan = plan;
    },
    setYouContent(state, content) {
      state.content = content;
    },
  },
  actions: {
    getUser({ commit, dispatch, state, rootState }) {
      const callback = res => {
        Vue.$log.debug('Loaded user profile', res);
        commit('setUser', res);
        dispatch('setAppLocale', res.locale);

        return res;
      };
      return dispatch('$apiRequest', [ `/profile`, callback ]);
    },
    getMemberIdConfig({ commit, dispatch, state, rootState }, form) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/profile/organizationMemberIdConfiguration`, callback ]);
    },
    getBookmarks({ commit, dispatch, state, rootState }) {
      const callback = res => {
        Vue.$log.debug('Loaded bookmarks', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/profile/bookmarks`, callback ]);
    },
    editUser({ commit, dispatch, state, rootState }, formData) {
      const userData = {
        ...state.user,
        ...formData,
      };
      const callback = res => {
        commit('setUser', res);
        if (res.locale !== rootState.currentLanguage) {
          Vue.$log.debug('Changing application locale to ', res.locale);
          dispatch('setAppLocale', res.locale);
        }
        return res;
      };
      return dispatch('$apiRequest', [ `/profile`, callback, 'put', userData ]);
    },
    editAvatar({ commit, dispatch, state, rootState }, formData) {
      const callback = res => {
        commit('setUser', res);
        if (res.locale !== rootState.currentLanguage) {
          Vue.$log.debug('Changing application locale to ', res.locale);
          dispatch('setAppLocale', res.locale);
        }
        return res;
      };
      return dispatch('$apiRequest', [ `/profile/avatar`, callback, 'put', formData ]);
    },
    changeUserPassword({ commit, dispatch, state }, form) {
      const callback = res => {
        return dispatch('getUser').then(any => {
          return res;
        });
      };
      return dispatch('$apiRequest', [ `/profile/_changePassword`, callback, 'post', form ]);
    },
    setOrganizationMemberCode({ commit, dispatch, state }, form) {
      const callback = res => {
        return dispatch('getUser').then(any => {
          return res;
        });
      };
      return dispatch('$apiRequest', [ `/profile/organizationMemberIdConfiguration`, callback, 'post', form ]);
    },
    generateCodename({ commit, dispatch }) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/users/codename`, callback ]);
    },
    generateRegistrationCodename({ commit, dispatch }) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/codename/_generate`, callback ]);
    },
    generateTermsOfUseAndPrivacyPolicyWithAccessCode({ commit, dispatch }, accessCode) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/self/agreement/${accessCode}/_generate?lang=${localStorage.getItem('currLang')}`, callback ]);
    },
    generateTermsOfUseAndPrivacyPolicyWithAccessCodeImprint({ commit, dispatch }, accessCodeImprint) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/self/agreement/_generate?lang=${localStorage.getItem('currLang')}&accessCodeImprint=${encodeURIComponent(accessCodeImprint)}`, callback ]);
    },
    getClinicianDisclaimer({ commit, dispatch }, accessCode) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/self/clinicianDisclaimer/${accessCode}/_generate?lang=${localStorage.getItem('currLang')}`, callback ]);
    },
    getClinicianDisclaimerWithImprint({ commit, dispatch }, accessCodeImprint) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/self/clinicianDisclaimer/_generate?lang=${localStorage.getItem('currLang')}&accessCodeImprint=${encodeURIComponent(accessCodeImprint)}`, callback ]);
    },
    generateProfessionsWithAccessCode({ commit, dispatch }, accessCode) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/self/profession/${accessCode}/_generate?lang=${localStorage.getItem('currLang')}`, callback ]);
    },
    generateProfessionsWithAccessCodeImprint({ commit, dispatch }, accessCodeImprint) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/self/profession/_generate?lang=${localStorage.getItem('currLang')}&accessCodeImprint=${encodeURIComponent(accessCodeImprint)}`, callback ]);
    },
    generateBusinessUnitsWithAccessCode({ commit, dispatch }, { accessCode, search, page, sublevels, businessUnitId }) {
      let params = `lang=${localStorage.getItem('currLang')}&page=${page}`;
      if (search) {
        params += `&q=${search}`;
      }
      if (sublevels) {
        params += `&sublevels=${sublevels}`;
      }
      if (businessUnitId) {
        params += `&businessUnitId=${businessUnitId}`;
      }
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/self/businessunit/${accessCode}/_generate?${params}`, callback ]);
    },
    generateBusinessUnitFiltersWithAccessCode({ commit, dispatch }, { accessCode, sublevels }) {
      let params = `lang=${localStorage.getItem('currLang')}`;
      if (sublevels) {
        params += `&sublevels=${sublevels}`;
      }
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/self/businessunit/${accessCode}/_filters?${params}`, callback ]);
    },
    generateBusinessUnitsWithAccessCodeImprint({ commit, dispatch }, { accessCodeImprint, search, page, sublevels, businessUnitId }) {
      let params = `lang=${localStorage.getItem('currLang')}&page=${page}&accessCodeImprint=${encodeURIComponent(accessCodeImprint)}`;
      if (search) {
        params += `&q=${search}`;
      }
      if (sublevels) {
        params += `&sublevels=${sublevels}`;
      }
      if (businessUnitId) {
        params += `&businessUnitId=${businessUnitId}`;
      }
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/self/businessunit/_generate?${params}`, callback ]);
    },
    generateBusinessUnitFiltersWithAccessCodeImprint({ commit, dispatch }, { accessCodeImprint, sublevels }) {
      let params = `lang=${localStorage.getItem('currLang')}&accessCodeImprint=${encodeURIComponent(accessCodeImprint)}`;
      if (sublevels) {
        params += `&sublevels=${sublevels}`;
      }
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/self/businessunit/_filters?${params}`, callback ]);
    },
    generateProfessionsForProfile({ commit, dispatch }) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ '/profile/professions', callback ]);
    },
    generateBusinessUnitsForProfile({ commit, dispatch }) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ '/profile/businessUnits', callback ]);
    },
    generateLocalesForProfile({ commit, dispatch }) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ '/profile/locales', callback ]);
    },
    generateTimezonesForProfile({ commit, dispatch }) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ '/profile/timezones', callback ]);
    },
    generateTermsOfUseAndPrivacyPolicyWithReferralKey({ commit, dispatch }, referralKey) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/referral/agreement/${referralKey}/_generate?lang=${localStorage.getItem('currLang')}`, callback ]);
    },
    getClinicianDisclaimerByReferralKey({ commit, dispatch }, referralKey) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/referral/clinicianDisclaimer/${referralKey}/_generate?lang=${localStorage.getItem('currLang')}`, callback ]);
    },
    generateProfessionsWithReferralKey({ commit, dispatch }, referralKey) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/referral/profession/${referralKey}/_generate?lang=${localStorage.getItem('currLang')}`, callback ]);
    },
    generateBusinessUnitsWithReferralKey({ commit, dispatch }, { referralKey, search, page, sublevels, businessUnitId }) {
      let params = `lang=${localStorage.getItem('currLang')}&page=${page}`;
      if (search) {
        params += `&q=${search}`;
      }
      if (sublevels) {
        params += `&sublevels=${sublevels}`;
      }
      if (businessUnitId) {
        params += `&businessUnitId=${businessUnitId}`;
      }
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/referral/businessunit/${referralKey}/_generate?${params}`, callback ]);
    },
    generateBusinessUnitFiltersWithReferralKey({ commit, dispatch }, { referralKey, sublevels }) {
      let params = `lang=${localStorage.getItem('currLang')}`;
      if (sublevels) {
        params += `&sublevels=${sublevels}`;
      }
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/referral/businessunit/${referralKey}/_filters?${params}`, callback ]);
    },
    generateTimezones({ commit, dispatch }) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/registration/timezones/_generate`, callback ]);
    },
    submitFeedback({ commit, dispatch, state, rootState }, feedback) {
      const data = { content: feedback };
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/feedbacks`, callback, 'post', data ]);
    },
    savePushNotificationToken({ commit, dispatch, state, rootState }, form) {
      const callback = res => {
        return res;
      };
      return dispatch('$apiRequest', [ `/profile/pushNotification`, callback, 'post', form ]);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    email(state) {
      return state.user.email;
    },
    password(state) {
      return state.user.password;
    },
    firstName(state) {
      return state.user.firstName;
    },
    lastName(state) {
      return state.user.lastName;
    },
    avatar(state) {
      return state.user.avatar;
    },
    codeName(state) {
      return state.user.codeName;
    },
    timezone(state) {
      return state.user.timezone;
    },
  },
};

const initialState = { ...you.state };

export default you;
