var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    staticClass: "no-border",
    attrs: {
      readonly: "",
      type: "text",
      rules: _vm.rules,
      placeholder: _vm.$t(
        "public.registration.profile.fields.codename.placeholder"
      )
    },
    model: {
      value: _vm.internalValue,
      callback: function($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }