<template>
  <v-navigation-drawer
      v-model="innerValue"
      fixed
      right
      temporary
      width="700px"
      v-if="this.theme"
  >
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <h1 class="starling-h1">Theme editor</h1>
          <v-btn color="warning" @click="resetTheme">Reset Theme</v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 v-for="(val,key) in this.theme.variables" :key="'variable_'+key">
          <v-layout row wrap style="height:30px;" v-if="val.type==='color'">
            <v-flex xs7 class="text--s">
              {{ key }}
            </v-flex>
            <v-flex xs5 :style="'background-color:'+theme.variables[key].value">

              <label>
                {{ theme.variables[key].value }}
                <input :ref="'input_'+key"
                       :title="key"
                       type="color"
                       v-model="theme.variables[key].value"
                       pattern="#[a-f0-9]{6}"
                       placeholder="#000000"
                       @change="applyTheme"/>
              </label>

            </v-flex>
          </v-layout>
          <v-layout row wrap style="height:30px;" v-else-if="val.type==='text'">
            <v-flex xs7>
              {{ key }}
            </v-flex>
            <v-flex xs5>
              <input type="text"
                     v-model="theme.variables[key].value"
                     @change="applyTheme"/>
            </v-flex>
          </v-layout>
          <v-layout row wrap style="height:30px;" v-else-if="val.type==='numeric'">
            <v-flex xs7>
              {{ key }}
            </v-flex>
            <v-flex xs5>
              <input type="number"
                     v-model="theme.variables[key].value"
                     @change="applyTheme"/>
            </v-flex>
          </v-layout>
          <v-layout row wrap style="height:30px;" v-else-if="val.type==='font'">
            <v-flex xs7>
              {{ key }}
            </v-flex>
            <v-flex xs5>
              <select
                  dense
                  v-model="theme.variables[key].value"
                  @change="applyTheme">
                <option v-for="font in availableFonts" :value="font.name" :key="font.name">{{ font.name }}</option>
              </select>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-navigation-drawer>
</template>

<script>

import { mapState } from 'vuex';
import { mergeDeep } from '@/scripts/mergeDeep';

export default {
  name: 'theme-editor',
  props: {
    value: Boolean,
  },
  data() {
    return {
      innerValue: this.value,
      theme: null,
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
    }),
    availableFonts() {
      return Object.keys(this.theme.fonts).map(key => {
        return {
          name: key,
        };
      });
    },
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
    innerValue() {
      this.$emit('input', this.innerValue);
    },
    'partner.theme': {
      immediate: true,
      deep: true,
      handler(value) {
        this.theme = JSON.parse(JSON.stringify(value));
      },
    },
  },
  methods: {
    applyTheme() {
      const updatedTheme = mergeDeep({}, this.partner.theme, this.theme);
      this.$store.dispatch('identity/applyTheme', { type: this.partner.type, theme: updatedTheme });
    },
    resetTheme() {
      this.$store.dispatch('identity/appearance');
    },
    openPicker(key) {
      this.$refs['input_' + key][0].click();
    },
  },
};
</script>

<style scoped>

</style>
