<template>
  <v-text-field
      ref="field"
      autocomplete="new-password"
      :label="$t('public.registration.account.fields.email.label')"
      :placeholder="$t('public.registration.account.fields.email.placeholder')"
      :hint="$t('public.registration.account.fields.email.hint')"
      name="email"
      :rules="rules"
      v-model="internalValue"
      persistent-hint
      :color="this.loading?'warning':'primary'"
      :loading="this.loading"
      :type="'text'"
      :disabled="disabled">
  </v-text-field>
</template>

<script>
import { VALIDATION_REGEX } from '@/scripts/api';

export default {
  name: 'registration-field-email',
  props: {
    value: String,
    disabled: {
      type: Boolean,
      defaultValue: false,
    },
    shouldFocus: {
      type: Boolean,
      defaultValue: true,
    },
  },
  data() {
    return {
      internalValue: this.value,
      loading: false,
      postValidateError: null,
      timeout: null,
      rules: [
        v => !!v || this.$t('common.errors.required'),
        v => VALIDATION_REGEX.EMAIL.test(v) || this.$t('common.errors.email'),
        v => (v && v.length <= 254) || this.$t('common.errors.max254'),
        v => {
          if (this.loading) {
            return this.$t('common.errors.loading');
          }
          if (this.postValidateError) {
            return this.postValidateError;
          }
          return true;
        },
      ],
    };
  },
  watch: {
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
      }
    },
    internalValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  mounted() {
    if (this.shouldFocus) {
      this.$refs.field.focus();
    }
  },
};
</script>

<style scoped>

</style>
