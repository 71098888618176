export default {
  accessCode: {
    title: 'What\'s your access code?',
    tips: {
      text: 'You can get this from your sponsoring organization. If you don\'t have a code or sponsoring organization, {0}.',
      mailto: {
        label: 'we can help',
        to: 'members@starlingminds.com',
        subject: 'What\'s my access code?',
        body: 'Organization / Union name: ###organization### \nProfession: ###profession###\n\nYour message:\n###freeText###',
      },
      additionalProperties: {
        explanation: 'To help member support quickly locate your access code, please complete the form below. If you\'re unsure, please leave these fields blank, and member support will follow up with you.',
        fields: [
          {
            type: 'input',
            id: 'organization',
            label: 'Organization / Union name',
            placeholder: '',
          },
          {
            type: 'input',
            id: 'profession',
            label: 'Profession',
            placeholder: '',
          },
          {
            type: 'textarea',
            id: 'freeText',
            label: 'Anything else you want us to know',
            placeholder: '',
          },
        ],
      },
    },
    fields: {
      accessCode: {
        label: 'Enter the ACCESS CODE from your sponsoring organization',
        hint: '* Required field',
        errors: {
          generic: 'An error occured.',
          404: 'Your access code is not valid.',
          400: 'Your access code has expired.',
        },
      },
      memberCode: {
        label: 'Member ID',
        hint: '* Required field',
      },
      migratedUser: {
        title: 'Welcome to your new Starling!',
        introduction: 'Here you’ll find the brand new Check-Up providing deeper insights into how you’re doing, personalized training that adapts to suit your current needs, tools to support your mental fitness, and supportive peer-communities.',
        setup: 'To set up your account, you’ll need to answer a few questions and create a new password.',
        start: 'Let’s get started…',
      },
    },
  },
  account: {
    title: 'Welcome to {name}!',
    MENTAL_FITNESS: {
      introduction: [ 'We\'re so glad you\'re here.', 'We can help you learn skills, manage stress, anxiety, and depression.' ],
      privacy: [ 'Everything you do in Starling is 100% confidential. We do not sell or share any of your personal health information with anyone.', 'If you have questions please review the Terms of Use and the Privacy Policy.' ],
      privacy_clinician: [ 'Your activity within Starling is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.', 'Please review the Terms of Use and the Privacy Policy.' ],
    },
    RTH_MENTAL: {
      introduction: [ 'We\'re so glad you\'re here.', 'Being off work can be an uncertain, stressful, and emotional time. We\'re here to support you as you navigate it.' ],
      privacy: [ 'Your activity within Starling is highly confidential. We will only share with your provider what is strictly necessary to support your return to work.', 'If you have questions please review the Terms of Use and the Privacy Policy.' ],
      privacy_clinician: [ 'Your activity within Starling is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.', 'Please review the Terms of Use and the Privacy Policy.' ],
    },
    RTH_PHYSICAL: {
      introduction: [ 'We\'re so glad you\'re here.', 'Being off work can be an uncertain, stressful, and emotional time. We\'re here to support you as you navigate it.' ],
      privacy: [ 'Your activity within Starling is highly confidential. We will only share with your provider what is strictly necessary to support your return to work.', 'If you have questions please review the Terms of Use and the Privacy Policy.' ],
      privacy_clinician: [ 'Your activity within Starling is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.', 'Please review the Terms of Use and the Privacy Policy.' ],
    },
    RTH_VETERANS: {
      introduction: [ 'We\'re so glad you\'re here.', 'We\'re here to support you to build your mental fitness and improve how you feel.' ],
      privacy: [ 'Your activity within Starling is highly confidential. We will only share with your provider what is strictly necessary to support your return to work.', 'If you have questions please review the Terms of Use and the Privacy Policy.' ],
      privacy_clinician: [ 'Your activity within Starling is highly confidential. To support your return to health, we will only share what is strictly necessary with your Clinician and Provider.', 'Please review the Terms of Use and the Privacy Policy.' ],
    },
    account_subtitle: 'Let\'s start by setting up your account',
    privacy_subtitle: 'Your privacy is important',
    fields: {
      email: {
        title: 'What is your email?',
        label: '',
        placeholder: 'Your email',
        hint: '* Required field',
      },
      password: {
        title: 'Set your secure password',
        label: '',
        placeholder: 'Your password',
        hint: '* Required field',
        rules: {
          special_char: 'At least one special character',
          lowercase_char: 'At least one lowercase character',
          uppercase_char: 'At least one uppercase character',
          numerical_char: 'At least one digit',
          size: 'Minimum 12 characters',
        },
        errors: {
          generic: 'All conditions below should be met.',
        },
      },
      passwordConfirmation: {
        title: 'Please confirm your password',
        label: '',
        placeholder: 'Confirm your password',
        hint: '* Required field',
      },
      agreement: {
        label: 'I have read and agree to the ',
        conjunction: ' and ',
        age: ' and confirm I am at least 16 years of age',
        termsOfUseLink: 'https://www.starlingminds.com/terms',
        privacyPolicyLink: 'https://www.starlingminds.com/privacy',
        communityGuidelinesLink: 'https://www.starlingminds.com/community-guidelines/',
      },
      marketing: {
        label: 'Let me know when new webinars, features, and content are available.',
      },
    },
  },
  profile: {
    title: 'It\'s about you.',
    content: 'Now that we\'ve got your account set up, let\'s confirm a few details about you.',
    name_subtitle: 'Your name',
    name_content: 'This is how the application will address you, it is not public.',
    profile_subtitle: 'Your profile',
    profile_content: 'Starling delivers personalized content and community based on these factors.',
    codename_subtitle: 'Community codename',
    codename_content: 'This will be your nickname in our anonymous Starling community.',
    finalisation_content: [ 'All done! If you\'d ever like to change this information, you can do so from your profile.', 'Okay let\'s see how you’re doing!' ],
    registerButton: 'Let\'s go!',
    fields: {
      firstname: {
        title: 'What would you like us to call you?',
        label: '',
        placeholder: 'Your First Name',
        hint: '* Required field',
      },
      businessUnit: {
        title: 'Where do you work?',
        label: '',
        placeholder: 'Select your location',
        hint: '* Required field',
        notListed: 'Not listed',
        notListedPlaceholder: 'Add your {param}',
        skip: {
          label: 'I\'m not sure',
        },
        errors: {
          loading: 'No choices were loaded',
          search: 'No matches were found',
        },
      },
      profession: {
        title: 'What is your occupation?',
        label: '',
        placeholder: 'Select your occupation',
        hint: '* Required field',
        errors: {
          loading: 'No choices were loaded',
          search: 'No matches were found',
        },
      },
      birthYear: {
        title: 'When were you born?',
        label: '',
        placeholder: 'Select your year of birth',
        hint: '',
        skip: {
          label: 'I prefer not to disclose',
        },
        errors: {
          search: 'No matches were found',
        },
      },
      gender: {
        title: 'How do you identify?',
        placeholder: 'Select your gender',
        label: '',
        hint: '',
        skip: {
          label: 'I prefer not to disclose',
        },
        custom: {
          placeholder: 'Add your gender self-identification',
          label: '',
          hint: '',
        },
      },
      codename: {
        placeholder: 'Not generated yet',
        action: 'Generate new random codename',
      },
    },
  },
  referral: {
    title: 'Referral',
    content: [
      { value: 'By accepting this referral, you’ve taken the first step to building your resilience!' },
      { value: 'Learn skills to manage stress, anxiety, and depression through personalized guided therapy customized to suit you.' },
      { value: 'Discover new strategies, gain insights, and connect with a supportive, confidential community.' },
      {
        value: 'Have questions? Feel free to <a target="_blank" href="mailto:members@starlingminds.com?subject=Feedback%20and%20questions">contact us</a> any time',
        cssClass: 'caption text-center mt-4',
      },
    ],
    expired: {
      title: 'Expired Referral',
      content: 'This referral link has expired. Please contact {firstName} {lastName} to resend a referral email.',
    },
    invalid: {
      title: 'Invalid Link',
      content: 'You have used an invalid link.<br/><br/> For additional assistance, please contact us at <a href="mailto:members@starlingminds.com?subject=Referral%20-%20Invalid%20Link">members@starlingminds.com</a>.',
    },
    cancelled: {
      title: 'Cancelled Referral',
      content: 'Please use the most recent email we sent you to accept your referral.<br/><br/> For additional assistance, please contact us at <a href="mailto:members@starlingminds.com?subject=Referral%20-%20Cancelled%20Link">members@starlingminds.com</a>.',
    },
    empty: {
      title: 'Invalid Link',
      content: 'You have used an invalid link.<br/><br/> For additional assistance, please contact us at <a href="mailto:members@starlingminds.com?subject=Referral%20-%20Empty%20Link">members@starlingminds.com</a>.',
    },
    simulated: {
      title: 'Referral Registration',
      content: 'You have used a simulation link.<br/>A valid real link would allow a simplified registration flow.<br/><br/> For additional assistance, please contact us at <a href="mailto:members@starlingminds.com?subject=Referral%20-%20Simulated%20Link">members@starlingminds.com</a>',
    },
  },
};
