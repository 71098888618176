export default {
  accessCode: {
    title: '¿Cuál es tu código de acceso?',
    tips: {
      text: 'Puedes obtenerlo de tu organización patrocinadora. Si no tienes un código o una organización patrocinadora, {0}.',
      mailto: {
        label: 'podemos ayudarte',
        to: 'members@starlingminds.com',
        subject: '¿Cuál es mi código de acceso?',
        body: 'Nombre de la organización o sindicato: ###organization### \nTu profesión: ###profession###\n\nTu mensaje:\n###freeText###',
      },
      additionalProperties: {
        explanation: 'Para ayudar al servicio de atención a los miembros a localizar rápidamente tu código de acceso, rellena el siguiente formulario. Si no recuerdas bien los detalles, deja los campos en blanco y el servicio de atención a los miembros se pondrá en contacto contigo.',
        fields: [
          {
            type: 'input',
            id: 'organization',
            label: 'Nombre de la organización o sindicato',
            placeholder: '',
          },
          {
            type: 'input',
            id: 'profession',
            label: 'Profesión',
            placeholder: '',
          },
          {
            type: 'textarea',
            id: 'freeText',
            label: 'Cualquier otra información que quieras compartir con nosotros',
            placeholder: '',
          },
        ],
      },
    },
    fields: {
      accessCode: {
        label: 'Introducir el CÓDIGO DE ACCESO de tu organización patrocinadora',
        hint: '* Campo obligatorio',
        errors: {
          generic: 'Se ha producido un error.',
          404: 'Tu código de acceso no es válido.',
          400: 'Tu código de acceso ha caducado.',
        },
      },
      memberCode: {
        label: 'Identificación de miembro',
        hint: '* Campo obligatorio',
      },
      migratedUser: {
        title: '¡Te damos la bienvenida a tu nuevo programa Starling!',
        introduction: 'Aquí encontrarás el nuevo Chequeo que proporciona más información sobre tu situación actual, módulos de capacitación personalizados que se adaptan a tus necesidades actuales, herramientas de apoyo a tu salud mental y comunidades solidarias de gente como tú.',
        setup: 'Para configurar tu cuenta, tendrás que responder a algunas preguntas y crear una nueva contraseña.',
        start: 'Empecemos…',
      },
    },
  },
  account: {
    title: '¡Te damos la bienvenida a {name}!',
    MENTAL_FITNESS: {
      introduction: [ '¡Nos alegra que estés aquí!', 'Podemos ayudarte a desarrollar habilidades y a controlar el estrés, la ansiedad y la depresión.' ],
      privacy: [ 'Todo lo que haces en Starling es totalmente confidencial. No vendemos ninguna información personal sobre tu salud a nadie ni la compartimos con nadie.', 'Si tienes preguntas, revisa las Condiciones de Uso y la Política de Privacidad.' ],
      privacy_clinician: [ 'Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.', 'Si tienes preguntas, revisa las Condiciones de Uso y la Política de Privacidad.' ],
    },
    RTH_MENTAL: {
      introduction: [ '¡Nos alegra que estés aquí!', 'Estar fuera del trabajo puede ser un momento incierto, estresante y emocional. Estamos aquí para apoyarte mientras afrontas esta situación.' ],
      privacy: [ 'Tu actividad en Starling es altamente confidencial. Solo compartiremos con tu proveedor lo estrictamente necesario para apoyar tu regreso al trabajo.', 'Si tienes preguntas, revisa las Condiciones de Uso y la Política de Privacidad.' ],
      privacy_clinician: [ 'Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.', 'Si tienes preguntas, revisa las Condiciones de Uso y la Política de Privacidad.' ],
    },
    RTH_PHYSICAL: {
      introduction: [ '¡Nos alegra que estés aquí!', 'Estar fuera del trabajo puede ser un momento incierto, estresante y emocional. Estamos aquí para apoyarte mientras afrontas esta situación.' ],
      privacy: [ 'Tu actividad en Starling es altamente confidencial. Solo compartiremos con tu proveedor lo estrictamente necesario para apoyar tu regreso al trabajo.', 'Si tienes preguntas, revisa las Condiciones de Uso y la Política de Privacidad.' ],
      privacy_clinician: [ 'Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.', 'Si tienes preguntas, revisa las Condiciones de Uso y la Política de Privacidad.' ],
    },
    RTH_VETERANS: {
      introduction: [ '¡Nos alegra que estés aquí!', 'Estar fuera del trabajo puede ser un momento incierto, estresante y emocional. Estamos aquí para apoyarte mientras afrontas esta situación.' ],
      privacy: [ 'Tu actividad en Starling es altamente confidencial. Solo compartiremos con tu proveedor lo estrictamente necesario para apoyar tu regreso al trabajo.', 'Si tienes preguntas, revisa las Condiciones de Uso y la Política de Privacidad.' ],
      privacy_clinician: [ 'Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.', 'Si tienes preguntas, revisa las Condiciones de Uso y la Política de Privacidad.' ],
    },
    account_subtitle: 'Empecemos por configurar tu cuenta.',
    privacy_subtitle: 'Tu privacidad es importante.',
    fields: {
      email: {
        title: '¿Cuál es tu correo electrónico?',
        label: '',
        placeholder: 'Tu correo electrónico',
        hint: '* Campo obligatorio',
      },
      password: {
        title: 'Establece tu contraseña segura.',
        label: '',
        placeholder: 'Tu contraseña',
        hint: '* Campo obligatorio',
        rules: {
          special_char: 'Al menos un carácter especial',
          lowercase_char: 'Al menos un carácter en minúscula',
          uppercase_char: 'Al menos un carácter en mayúscula',
          numerical_char: 'Al menos un dígito',
          size: 'Mínimo 12 caracteres',
        },
        errors: {
          generic: 'Deben cumplirse todas las condiciones a continuación.',
        },
      },
      passwordConfirmation: {
        title: 'Confirma tu contraseña.',
        label: '',
        placeholder: 'Confirma tu contraseña',
        hint: '* Campo obligatorio',
      },
      agreement: {
        label: 'He leído y acepto ',
        conjunction: ' y ',
        age: ' y confirmo soy mayor de 16 años.',
        termsOfUseLink: 'https://www.starlingminds.com/terms-of-use-spanish/',
        privacyPolicyLink: 'https://www.starlingminds.com/privacy-policy-spanish/',
        communityGuidelinesLink: 'https://www.starlingminds.com/community-guidelines-spanish/',
      },
      marketing: {
        label: 'Avísame cuando haya nuevos seminarios web, funciones y contenido disponibles.',
      },
    },
  },
  profile: {
    title: 'Se trata de ti.',
    content: 'Ahora que tenemos tu cuenta configurada, vamos a confirmar algunos detalles sobre ti.',
    name_subtitle: 'Tu nombre',
    name_content: 'Así es como la aplicación se dirigirá a ti. Esta información no es pública.',
    profile_subtitle: 'Tu perfil',
    profile_content: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores.',
    codename_subtitle: 'Seudónimo para la comunidad',
    codename_content: 'Este será tu apodo en nuestra comunidad anónima de Starling.',
    finalisation_content: [ '¡Todo listo! Puedes cambiar esta información en cualquier momento desde tu perfil.', 'Echemos un vistazo a tu situación actual.' ],
    registerButton: '¡Empecemos!',
    fields: {
      firstname: {
        title: '¿Cómo te gustaría que te llamáramos?',
        label: '',
        placeholder: 'Tu nombre',
        hint: '* Campo obligatorio',
      },
      businessUnit: {
        title: '¿Dónde trabajas?',
        label: '',
        placeholder: 'Seleccionar tu ubicación',
        hint: '* Campo obligatorio',
        notListed: 'No listado',
        notListedPlaceholder: 'Añade tu {param}',
        skip: {
          label: 'No estoy seguro',
        },
        errors: {
          loading: 'No se ha cargado ninguna opción',
          search: 'No se ha encontrado ninguna coincidencia.',
        },
      },
      profession: {
        title: '¿A qué te dedicas?',
        label: '',
        placeholder: 'Seleccionar tu ocupación',
        hint: '* Campo obligatorio',
        errors: {
          loading: 'No se ha cargado ninguna opción',
          search: 'No se ha encontrado ninguna coincidencia.',
        },
      },
      birthYear: {
        title: '¿En qué año naciste?',
        label: '',
        placeholder: 'Seleccionar tu año de nacimiento',
        hint: '',
        skip: {
          label: 'Prefiero no responder',
        },
        errors: {
          search: 'No se ha encontrado ninguna coincidencia.',
        },
      },
      gender: {
        title: '¿Cómo te identificas?',
        placeholder: 'Seleccionar tu sexo',
        label: '',
        hint: '',
        skip: {
          label: 'Prefiero no responder',
        },
        custom: {
          placeholder: 'Indicar con qué género te identificas',
          label: '',
          hint: '',
        },
      },
      codename: {
        placeholder: 'Aún no generado',
        action: 'Generar un nuevo seudónimo aleatorio',
      },
    },
  },
  referral: {
    title: 'Referencia',
    content: [
      { value: 'Al aceptar esta referencia, has dado el primer paso para desarrollar tu resiliencia.' },
      { value: 'Desarrolla habilidades para controlar el estrés, la ansiedad y la depresión mediante una terapia guiada personalizada.' },
      { value: 'Descubre nuevas estrategias, adquiere conocimientos y conéctate con una comunidad solidaria y confidencial.' },
      {
        value: '¿Tienes preguntas? Puedes <a target="_blank" href="mailto:members@starlingminds.com?subject=Comentarios%20y%20preguntas">Ponte en contacto con nosotros</a> en cualquier momento.',
        cssClass: 'caption text-center mt-4',
      },
    ],
    expired: {
      title: 'Referencia caducada',
      content: 'Este enlace de referencia ha caducado. Ponte en contacto con {firstName} {lastName} para recibir otro correo electrónico de referencia.',
    },
    invalid: {
      title: 'Enlace no válido',
      content: 'Has utilizado un enlace no válido.<br/><br/>Para obtener asistencia adicional, ponte en contacto con nosotros en <a href="mailto:members@starlingminds.com?subject=Referencia%20%E2%80%93%20Enlace%20no%20v%C3%A1lido">members@starlingminds.com</a>.',
    },
    cancelled: {
      title: 'Referencia cancelada',
      content: 'Utiliza el correo electrónico más reciente que te hemos enviado para aceptar tu referencia.<br/><br/> Para obtener asistencia adicional, ponte en contacto con nosotros en <a href="mailto:members@starlingminds.com?subject=Referencia%20%E2%80%93%20Enlace%20no%20v%C3%A1lido%20">members@starlingminds.com</a>.',
    },
    empty: {
      title: 'Enlace no válido',
      content: 'Has utilizado un enlace no válido.<br/><br/> Para obtener asistencia adicional, ponte en contacto con nosotros en <a href="mailto:members@starlingminds.com?subject=Referencia%20%E2%80%93%20Enlace%20no%20v%C3%A1lido%20">members@starlingminds.com</a>.',
    },
    simulated: {
      title: 'Crear una cuenta a partir de una referencia',
      content: 'Has utilizado un enlace de simulación.<br/>Un enlace real válido simplifica el proceso de crear una cuenta.<br/><br/> Para obtener asistencia adicional, ponte en contacto con nosotros en <a href="mailto:members@starlingminds.com?subject=Referencia%20%E2%80%93%20Enlace%20no%20v%C3%A1lido%20">members@starlingminds.com</a>',
    },
  },
};
