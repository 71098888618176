import 'core-js';
import 'isomorphic-fetch';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/scripts/router';
import store from '@/scripts/store/index';
import i18n, { setLanguage } from '@/scripts/lang';
import vueVimeoPlayer from 'vue-vimeo-player';
import '@/registerServiceWorker';
import Vuetify from '@/scripts/vuetify';
import VueLogger from 'vuejs-logger';
import LoggerOptions from '@/scripts/logger';
import axios from 'axios';
import '@mdi/font/css/materialdesignicons.css';
import feedbackMixin from './scripts/mixins/feedbackMixin';
import testEnvironmentMixin from './scripts/mixins/testEnvironmentMixin';
import textMarkup from './scripts/mixins/textMarkup';
// import Amplify from '@aws-amplify/core';
import * as LottiePlayer from '@lottiefiles/lottie-player';
import VueObserveVisibility from 'vue-observe-visibility';

// Google Tag Manager
import VueGtm from '@gtm-support/vue2-gtm';
// Polyfills for IE
import cssVars from 'css-vars-ponyfill';
import 'element-scroll-polyfill';
import Es6Promise from 'es6-promise';
import moment from 'moment';
import { Amplify } from 'aws-amplify';

// Global custom components
import CustomIcon from '@/views/icons/custom-icon.vue';
import SmMarkupText from '@/views/components/sm-markup-text.vue';

// Mobile
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import capacitorMixin, { capacitorUpdaterInitialize } from './scripts/mixins/capacitorMixin';
import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.use(VueObserveVisibility);

Es6Promise.polyfill();
cssVars({});

store.commit('setContentVersion', process.env.PACKAGE_VERSION);
store.commit('setPlatform', Capacitor.getPlatform());

const amplifyAuth: any = {
  Auth: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: process.env.VUE_APP_COGNITO_IDP_DOMAIN || '',
      scope: [ 'email', 'profile', 'openid' ],
      responseType: 'code',
      redirectSignIn: process.env.VUE_APP_COGNITO_IDP_SIGN_IN || '',
      redirectSignOut: process.env.VUE_APP_COGNITO_IDP_SIGN_OUT || '',
    },
  },
};

if (!Capacitor.isNativePlatform()) {
  amplifyAuth.Auth.cookieStorage = {
    domain: process.env.VUE_APP_COGNITO_COOKIE_DOMAIN,
    secure: true,
    path: '/',
    expires: 365,
  };
} else {
  const appId = process.env.VUE_APP_MOBILE_APP_ID || '';
  amplifyAuth.Auth.oauth.redirectSignIn = amplifyAuth.Auth.oauth.redirectSignIn.replace('https', appId);
  amplifyAuth.Auth.oauth.redirectSignOut = amplifyAuth.Auth.oauth.redirectSignOut.replace('https', appId);
  amplifyAuth.Auth.oauth.urlOpener = async (url: string) => {
    await Browser.open({ url, windowName: '_self' });
  };
}

Amplify.configure(amplifyAuth);

Vue.config.productionTip = false;

Vuetify(Vue);
Vue.use(vueVimeoPlayer);
Vue.use(VueLogger as any, LoggerOptions);

Vue.use(LottiePlayer as any);
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
});

Vue.component('custom-icon', CustomIcon);
Vue.component('sm-markup-text', SmMarkupText);

const momentRequire = require('moment');
require('moment/locale/fr');
momentRequire.locale('en');
const VueMoment = require('vue-moment');
Vue.use(VueMoment, {
  momentRequire,
});

// Google Tag Manager
if (process.env.VUE_APP_GTM_ID && Capacitor.getPlatform() !== 'ios') {
  const gtmOptions: any = {
    id: process.env.VUE_APP_GTM_ID,
    debug: false,
    vueRouter: router,
  };
  if (process.env.VUE_APP_GTM_ENVIRONMENT) {
    gtmOptions.queryParams = JSON.parse(process.env.VUE_APP_GTM_ENVIRONMENT);
  }
  Vue.use(VueGtm, gtmOptions);
}

Vue.prototype.$http = axios;

Vue.mixin(testEnvironmentMixin);
Vue.mixin(feedbackMixin);
Vue.mixin(textMarkup);
Vue.mixin(capacitorMixin);

Vue.filter('getImagePath', (imagePath: any) => {
  if (imagePath.includes('svg') || imagePath.includes('png')) {
    // Use a Promise for dynamic import, "module": "esnext" is added in tsconfig to allow dynamic imports
    return import(`@/assets/${imagePath}`)
      .then((imageModule) => imageModule.default);
  }
  return imagePath;
});

Vue.filter('durationFormat', (period: moment.Duration | string) => {
  const parts = [];
  const duration = moment.duration(period);

  if (!duration) return i18n.t('common.constants.durations.none');

  if (!duration || duration.toISOString() === 'P0D') return `0 ${i18n.t('common.constants.durations.seconds.single')}`;

  if (duration.years() >= 1) {
    const years = Math.floor(duration.years());
    parts.push(`${years} ${years > 1 ? i18n.t('common.constants.durations.years.multiple') : i18n.t('common.constants.durations.years.single')}`);
  }

  if (duration.months() >= 1) {
    const months = Math.floor(duration.months());
    parts.push(`${months} ${months > 1 ? i18n.t('common.constants.durations.months.multiple') : i18n.t('common.constants.durations.months.single')}`);
  }

  if (duration.days() >= 1) {
    const days = Math.floor(duration.days());
    parts.push(`${days} ${days > 1 ? i18n.t('common.constants.durations.days.multiple') : i18n.t('common.constants.durations.days.single')}`);
  }

  if (duration.hours() >= 1) {
    const hours = Math.floor(duration.hours());
    parts.push(`${hours} ${hours > 1 ? i18n.t('common.constants.durations.hours.multiple') : i18n.t('common.constants.durations.hours.single')}`);
  }

  if (duration.minutes() >= 1) {
    const minutes = Math.floor(duration.minutes());
    parts.push(`${minutes} ${minutes > 1 ? i18n.t('common.constants.durations.minutes.multiple') : i18n.t('common.constants.durations.minutes.single')}`);
  }

  if (duration.seconds() >= 1) {
    const seconds = Math.floor(duration.seconds());
    parts.push(`${seconds} ${seconds > 1 ? i18n.t('common.constants.durations.seconds.multiple') : i18n.t('common.constants.durations.seconds.single')}`);
  }

  return `${parts.join(', ')}`;
});

Vue.filter('leadingZeros', (value: string, quantity: number) => {
  if (value === null || !quantity || isNaN(quantity)) return value;
  let output = '' + value;
  while (output.length < quantity) {
    output = '0' + output;
  }
  return output;
});

function initialize() {
  store.commit('setLoadingProgress', 0);

  store.dispatch('identity/configureAuth').then((res) => {
    if (res !== null && res.redirect !== undefined && !res.redirect) {
      new Vue({
        i18n,
        router,
        store,
        render: h => h(App),
      }).$mount('#app');
    }
    return Promise.resolve();
  }).catch(() => {
    new Vue({
      i18n,
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
    router.push({ name: 'maintenance' });
  });

  capacitorUpdaterInitialize();
}

const currentLang = localStorage.getItem('currLang');
if (currentLang) {
  setLanguage(currentLang).then(() => {
    initialize();
  });
} else {
  initialize();
}

const customIcons = require.context('!svg-sprite-loader!./assets/icons', false, /.*\.svg$/);
customIcons.keys().forEach(customIcons);

defineCustomElements(window);
