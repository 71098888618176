import actions from './actions';
import title from './title';
import errors from './errors';
import constants from './constants';
import datePicker from './date_picker';
import maintenance from './maintenance';
import partnership from './partnership';
import memberSupport from './member_support';

export default {
  title,
  actions,
  errors,
  constants,
  maintenance,
  partnership,
  date_picker: datePicker,
  member_support: memberSupport,
};
