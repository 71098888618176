var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registration-agreement" },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loadedAgreement,
              expression: "loadedAgreement"
            }
          ],
          attrs: { row: "", "px-2": "", "gap-xs-2": "", "align-baseline": "" }
        },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-0",
                  attrs: {
                    fab: "",
                    label: "Label",
                    outline: !_vm.internalValue,
                    color: _vm.internalValue ? "checked_checkbox" : "normal"
                  },
                  on: {
                    click: function($event) {
                      _vm.internalValue = !_vm.internalValue
                    }
                  }
                },
                [
                  _vm.internalValue
                    ? _c("v-icon", [_vm._v("mdi-check")])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("v-flex", [
            _c("p", { staticClass: "starling-body" }, [
              _vm._v(
                "\n              " +
                  _vm._s(
                    _vm.$t("public.registration.account.fields.agreement.label")
                  ) +
                  "\n              "
              ),
              _c("a", { ref: "termsOfUseLink" }, [
                _vm._v(_vm._s(_vm.$t("common.actions.termsOfUse")))
              ]),
              _vm._v(",\n              "),
              _c("a", { ref: "privacyPolicyLink" }, [
                _vm._v(_vm._s(_vm.$t("common.actions.privacyPolicy")))
              ]),
              _vm._v(
                "\n              " +
                  _vm._s(
                    _vm.$t(
                      "public.registration.account.fields.agreement.conjunction"
                    )
                  ) +
                  "\n              "
              ),
              _c("a", { ref: "communityGuidelinesLink" }, [
                _vm._v(_vm._s(_vm.$t("common.actions.communityGuidelines")))
              ])
            ])
          ])
        ],
        1
      ),
      _vm.loadedAgreement && _vm.loadedAgreement.termsOfUse
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.xsOnly ? "90%" : "800px",
                "content-class": "radius-15"
              },
              model: {
                value: _vm.displayTermsOfUse,
                callback: function($$v) {
                  _vm.displayTermsOfUse = $$v
                },
                expression: "displayTermsOfUse"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "white", attrs: { elevation: "0" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("starling-logo-colors", {
                        staticClass: "mt-2 mr-2",
                        attrs: {
                          id: "tou-logo",
                          width: "130px",
                          height: "40px"
                        }
                      }),
                      _c("h1", { staticClass: "starling-h1" }, [
                        _vm._v(_vm._s(_vm.$t("common.actions.termsOfUse")))
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", icon: "" },
                          on: {
                            click: function($event) {
                              _vm.displayTermsOfUse = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "py-2" }, [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.loadedAgreement.termsOfUse)
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.loadedAgreement && _vm.loadedAgreement.privacyPolicy
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.xsOnly ? "90%" : "800px",
                "content-class": "radius-15"
              },
              model: {
                value: _vm.displayPrivacyPolicy,
                callback: function($$v) {
                  _vm.displayPrivacyPolicy = $$v
                },
                expression: "displayPrivacyPolicy"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "white", attrs: { elevation: "0" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("starling-logo-colors", {
                        staticClass: "mt-2 mr-2",
                        attrs: { id: "pp-logo", width: "130px", height: "40px" }
                      }),
                      _c("h1", { staticClass: "starling-h1" }, [
                        _vm._v(_vm._s(_vm.$t("common.actions.privacyPolicy")))
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", icon: "" },
                          on: {
                            click: function($event) {
                              _vm.displayPrivacyPolicy = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "py-2" }, [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.loadedAgreement.privacyPolicy)
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.loadedAgreement && _vm.loadedAgreement.communityGuidelines
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.xsOnly ? "90%" : "800px",
                "content-class": "radius-15"
              },
              model: {
                value: _vm.displayCommunityGuidelines,
                callback: function($$v) {
                  _vm.displayCommunityGuidelines = $$v
                },
                expression: "displayCommunityGuidelines"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "white", attrs: { elevation: "0" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("starling-logo-colors", {
                        staticClass: "mt-2 mr-2",
                        attrs: { id: "cg-logo", width: "130px", height: "40px" }
                      }),
                      _c("h1", { staticClass: "starling-h1" }, [
                        _vm._v(
                          _vm._s(_vm.$t("common.actions.communityGuidelines"))
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", icon: "" },
                          on: {
                            click: function($event) {
                              _vm.displayCommunityGuidelines = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "py-2" }, [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.loadedAgreement.communityGuidelines
                        )
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }