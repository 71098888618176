export default {
  gender: {
    MALE: 'Male',
    FEMALE: 'Female',
    NON_BINARY: 'Non-Binary',
    SELF_DESCRIBE: 'Not listed',
  },
  durations: {
    none: 'incorrect duration',
    seconds: { multiple: 'seconds', single: 'second' },
    minutes: { multiple: 'minutes', single: 'minute' },
    hours: { multiple: 'hours', single: 'hour' },
    days: { multiple: 'days', single: 'day' },
    months: { multiple: 'months', single: 'month' },
    years: { multiple: 'years', single: 'year' },
  },
};
