import { BoilerplateView, TopNavDesktopView, TopNavMobileView } from '../common';
import store from '../../store';

const ProductPlayerView = resolve => {
  require.ensure([ '@/views/products/ProductPlayer.vue' ], () => {
    resolve(require('@/views/products/ProductPlayer.vue'));
  }, 'product');
};

const CommunityTabsView = resolve => {
  require.ensure([ '@/views/community/CommunityTabs.vue' ], () => {
    resolve(require('@/views/community/CommunityTabs.vue'));
  }, 'community_tabs');
};

const CommunityCommentsView = resolve => {
  require.ensure([ '@/views/community/CommunityComments.vue' ], () => {
    resolve(require('@/views/community/CommunityComments.vue'));
  }, 'community_comments');
};

export default {
  path: 'community',
  components: {
    topNavDesktop: TopNavDesktopView,
    topNavMobile: TopNavMobileView,
    default: BoilerplateView,
  },
  beforeEnter: async (to, from, next) => {
    store.commit('navigation/topHeader', 'app.community.header_title');
    next();
  },
  children: [
    {
      name: 'community',
      path: '',
      redirect: (to) => {
        const route = to.query.tab === 'comments' ? 'community-comments' : 'community-menu';
        return { name: route };
      },
    },
    {
      name: 'community-tabs',
      path: '',
      component: CommunityTabsView,
      children: [
        {
          name: 'community-comments',
          path: 'posts',
          component: CommunityCommentsView,
          beforeEnter(to, from, next) {
            store.commit('navigation/topEnableBackButton');
            next();
          },
        },
        {
          name: 'community-topics',
          path: 'topics',
          component: BoilerplateView,
          redirect: { name: 'community-menu' },
          children: [
            {
              name: 'community-menu',
              path: process.env.VUE_APP_COMMUNITY_UEN,
              component: ProductPlayerView,
              props: { uen: process.env.VUE_APP_COMMUNITY_UEN },
              beforeEnter(to, from, next) {
                store.commit('navigation/topDisableBackButton');
                next();
              },
            },
            {
              name: 'community-run',
              path: ':uen',
              component: ProductPlayerView,
              props: true,
              beforeEnter(to, from, next) {
                store.commit('navigation/topEnableBackButton');
                next();
              },
            },
          ],
        },
      ],
    },
  ],
};
