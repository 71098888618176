var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "starling-loading",
      staticStyle: { "z-index": "999999" },
      attrs: { row: "" }
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                column: "",
                "fill-height": "",
                "justify-center": "",
                "align-center": ""
              }
            },
            [
              _c("div", { staticClass: "loading-header" }),
              _c("div", { staticClass: "loading-content" }, [
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: "/svg/starling-logo-colors.svg", alt: "" }
                })
              ]),
              _c(
                "div",
                { staticClass: "loading-footer d-flex align-end" },
                [
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loadingProgress,
                        expression: "loadingProgress"
                      }
                    ],
                    attrs: { value: _vm.loadingProgress, height: "10" }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }