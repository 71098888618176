export default {
  'CD-RISC2': {
    name: 'CD-RISC2',
    label: 'Résilience',
    action_label: 'Ajouter une note de résilience',
    color: '#30CEFA',
    order: 0,
  },
  ENERGY: {
    name: 'ENERGY',
    label: 'Énergie',
    action_label: 'Ajouter une note énergie',
    color: '#08E9cf',
    order: 1,
  },
  GAD7: {
    name: 'GAD7',
    label: 'Anxiété',
    action_label: 'Ajouter une note d\'anxiété',
    color: '#FF777D',
    order: 2,
  },
  PHQ9: {
    name: 'PHQ9',
    label: 'Dépression',
    action_label: 'Ajouter une note de dépression',
    color: '#4c75aa',
    order: 3,
  },
  STRESS: {
    name: 'STRESS',
    label: 'Stress',
    action_label: 'Ajouter une note de stress',
    color: '#ffb844',
    order: 4,
  },
  SLEEP: {
    name: 'SLEEP',
    label: 'Qualité du sommeil, évaluation de l\'insomnie',
    action_label: 'Ajouter une note Qualité du sommeil, évaluation de l\'insomnie',
    color: '#08E9cf',
  },
  PANIC: {
    name: 'PANIC',
    label: 'Panique',
    action_label: 'Ajouter une note Panique',
    color: '#08E9cf',
  },
  LDQ: {
    name: 'LDQ',
    label: 'Consommation de substances',
    action_label: 'Ajouter une note Consommation de substances',
    color: '#058189',
  },
  MEBS: {
    name: 'MEBS',
    label: 'Habitudes alimentaires',
    action_label: 'Ajouter une note Habitudes alimentaires',
    color: '#08E9cf',
  },
  ADHD: {
    name: 'ADHD',
    label: 'Trouble déficitaire de l\'attention avec hyperactivité (TDAH)',
    action_label: 'Ajouter une note Trouble déficitaire de l\'attention avec hyperactivité (TDAH)',
    color: '#08E9cf',
  },
  drilldown: {
    unavailable: 'Aucune interpretation de cette évaluation n\'est disponible pour le moment.',
  },
  scoreScale: [
    {
      value: 1,
      name: 'HH',
      labels: {
        long: 'En santé : moyenne élevée',
        short: 'Santé +',
      },
    },
    {
      value: 2,
      name: 'HA',
      labels: {
        long: 'En santé : moyenne',
        short: 'Santé',
      },
    },
    {
      value: 3,
      name: 'HL',
      labels: {
        long: 'En santé : moyenne faible',
        short: 'Santé -',
      },
    },
    {
      value: 4,
      name: 'BL',
      labels: {
        long: 'Frontière',
        short: 'Front.',
      },
    },
    {
      value: 5,
      name: 'ML',
      labels: {
        long: 'Léger',
        short: 'Léger',
      },
    },
    {
      value: 6,
      name: 'MO',
      labels: {
        long: 'Modérée',
        short: 'Modérée',
      },
    },
    {
      value: 7,
      name: 'SE',
      labels: {
        long: 'Grave',
        short: 'Grave',
      },
    },
  ],
  simplifiedScoreScale: [
    {
      value: 3,
      name: 'HI',
      label: 'Élevé(e)',
    },
    {
      value: 2,
      name: 'MD',
      label: 'Moyen(ne)',
    },
    {
      value: 1,
      name: 'LO',
      label: 'Bas(se)',
    },
    {
      value: 0,
      name: 'VL',
      label: 'Trés bas(se)',
    },
    {
      value: -1,
      name: 'NOT_EVALUATED',
      label: 'Non évalué',
      hideInScale: true,
    },
  ],
};
