import { Module } from 'vuex';
import { RootState } from '../../types';
import { FavoriteState, IFavorite } from './types';

export const defaultState: FavoriteState = {
  favorites: [],
};

export const favorites: Module<FavoriteState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    favorites(state, favorites) {
      state.favorites = favorites;
    },
  },
  actions: {
    getAllFavorites({ commit, dispatch }) {
      const callback = (res: IFavorite[]) => {
        commit('favorites', res);
        return res;
      };
      return dispatch('$apiRequest', [ '/favorites', callback ], { root: true });
    },
    addFavoriteVideo({ commit, dispatch }, identifier) {
      const callback = () => {
        return dispatch('getAllFavorites');
      };
      return dispatch('$apiRequest', [ `/favorites/videos/${identifier}`, callback, 'post' ], { root: true });
    },
    addFavoriteMedia({ commit, dispatch }, identifier) {
      const callback = () => {
        return dispatch('getAllFavorites');
      };
      return dispatch('$apiRequest', [ `/favorites/medias/${identifier}`, callback, 'post' ], { root: true });
    },
    removeFavorite({ commit, dispatch }, id) {
      const callback = () => {
        return dispatch('getAllFavorites');
      };
      return dispatch('$apiRequest', [ `/favorites/${id}`, callback, 'delete' ], { root: true });
    },
  },
};
