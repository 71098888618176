/* eslint-disable security/detect-object-injection */
import store from '@/scripts/store';

export default {
  header_title: 'Outils',
  title: 'Que voulez-vous faire aujourd\'hui?',
  home_title: 'Outils de TCC',
  home_subtitle: 'Une fois que vous aurez appris les rudiments, utilisez les outils de TCC pour perfectionner vos compétences.',
  content: [
    {
      id: 'checkup',
      icon: '$vuetify.icons.starling_checkup',
      title: 'Bilan de Santé',
      subtitle: 'Effectuez un nouveau Bilan de Santé pour suivre tous les changements de votre santé mentale.',
      route: { name: 'products-run', params: { uen: process.env.VUE_APP_CHECK_UP_UEN } },
    },
    {
      id: 'checkup_results',
      title: 'Mes progrès',
      subtitle: 'Examinez votre progression and dévoilez vos tendances.',
      route: { name: 'products-run', params: { uen: process.env.VUE_APP_CHECK_UP_RESULT_UEN } },
      iconComponent: {
        name: 'checkup-results-radarchart',
        isValid: (props) => {
          return props && props.values && Object.keys(props.values).map(k => props.values[k].simplifiedValue).every(v => v !== 'NOT_EVALUATED');
        },
        propsLoader: () => {
          return store
            .dispatch('assessment/getMostRecentAssessments')
            .then((res) => {
              if (res.status >= 400) {
                return { values: {}, interpretations: {} };
              }
              return { values: res, interpretations: {} };
            });
        },
      },
    },
    {
      id: 'thought_balancer',
      icon: '$vuetify.icons.starling_thought_balancer',
      title: 'Équilibreur de pensée',
      subtitle: 'Créez des situations équilibrées pour gérer vos pensées répétitives et liées à l\'inquiétude.',
      route: {
        name: 'thoughts-list',
      },
      routeLabel: 'Voir pensées',
      cardImage: 'thought_balancer_fr.svg',
    },
    {
      id: 'goal_setter',
      icon: '$vuetify.icons.starling_goal_setter',
      title: 'Outil d\'établissement d\'objectifs',
      subtitle: 'Établissez des objectifs SMART réalistes et félicitez-vous des réussites.',
      route: {
        name: 'goals-list',
      },
      routeLabel: 'Voir objectifs',
      cardImage: 'goal_setter_fr.svg',
    },
    {
      id: 'journal',
      icon: '$vuetify.icons.starling_workbook',
      title: 'Mon journal',
      subtitle: 'Gardez une trace de vos réflexions, idées et pensées durant votre d\'apprentissage.',
      route: {
        name: 'journals-list',
      },
      routeLabel: 'Votre cahier de travail',
      cardImage: 'my_journal_fr.svg',
    },
    {
      id: 'mood_tracker',
      icon: '$vuetify.icons.starling_mood_tracker',
      title: 'Outil de suivi de l\'humeur',
      subtitle: 'Brisez les tendances nuisibles en suivant vos déclencheurs, humeurs et réactions courantes.',
      route: {
        name: 'moods-list',
      },
      routeLabel: 'Voir humeurs',
      cardImage: 'mood_tracker_fr.svg',
    },
  ],

  goal_setter: {
    header_title: 'Outil d\'établissement d\'objectifs',
    title_first: 'Établissez votre premier objectif, suivez vos progrès et apportez des changements durables.',
    title_other: 'Établissez de meilleurs objectifs, suivez vos progrès et apportez des changements durables.',
    actions: {
      save: 'Sauvegardez votre objectif',
      create: 'Créer un objectif',
    },
    fields: {
      title: {
        label: 'Mon objectif:',
        placeholder: 'Ajouter un titre',
        copy: 'Copie : ',
      },
      task: {
        title: 'Tâche (facultatif) :',
        placeholder: 'Ajouter une tâche',
        guideContent: 'Bel objectif! Si vous souhaitez continuer à le diviser, essayez d\'établir un autre objectif.',
      },
      startDate: {
        title: 'Heure de début :',
      },
      reminder: {
        title: 'Rappel :',
        label: 'avant le début de mon objectif.',
        intervalOptions: [
          { value: 30, text: '30 minutes' },
          { value: 60, text: '1 heures' },
          { value: 1440, text: '1 jour' },
        ],
        madal_title: 'Rappels',
        modal_description: 'Vos paramètres de notification pour l\'outil d\'établissement d\'objectifs seront activées dans votre <strong>profil</strong> lorsque vous définissez un rappel pour cet objectif.',
      },
      repeat: {
        title: 'Répéter :',
        label: 'Chaque',
        frequency: [
          { value: 'DAY', text: 'jour' },
          { value: 'WEEK', text: 'semaine' },
        ],
        weekDays: [
          { value: 'MONDAY', text: 'L', label: 'Lundi' },
          { value: 'TUESDAY', text: 'M', label: 'Mardi' },
          { value: 'WEDNESDAY', text: 'M', label: 'Mercredi' },
          { value: 'THURSDAY', text: 'J', label: 'Jeudi' },
          { value: 'FRIDAY', text: 'V', label: 'Vendredi' },
          { value: 'SATURDAY', text: 'S', label: 'Samedi' },
          { value: 'SUNDAY', text: 'D', label: 'Dimanche' },
        ],
      },
      pinned: {
        title: 'Épingler à la page d\'accueil :',
      },
    },
    sections: {
      started: 'Objectifs commencés',
      upcoming: 'Objectifs à venir',
      paused: 'Objectifs en pause',
      completed: 'Objectifs terminés',
    },
    goal_card: {
      motivational: [
        'Chaque petit pas permet de créer un élan. Continuez!',
        'Bienvenue à nouveau! Comment cela s\'est-il passé?',
        'Vous vous en sortez très bien! Continuez!',
        'Tout pas en avant, aussi petit soit-il, est un progrès. Persévérez! Vous pouvez y arriver! ',
        'Vous pouvez y arriver! Continuez à travailler sur votre objectif.',
        'Trouver ce qui fonctionne exige de faire des essais et des erreurs. Ce n\'est donc pas un échec de découvrir ce qui ne fonctionne pas pour vous.',
        'Ce n\'est pas la quantité de travail que vous faites, mais la constance avec laquelle vous le faites qui mène à des changements plus importants.',
        'Chaque pas que vous faites vous amène dans la bonne direction. Continuez!',
      ],
      edit: 'Voulez-vous modifier votre objectif?',
      delete: 'Aucun problème! Veuillez confirmer la suppression de cet objectif.',
      pause_pt1: 'Aucun problème! Nous désactiverons tous les rappels pour cet objectif et le retirerons de votre page d\'accueil.',
      pause_pt2: 'Vous pouvez poursuivre cet objectif à tout moment à partir de votre <strong>outil d\'établissement d\'objectifs</strong> dans <strong>Outils</strong>.',
      complete: 'Voudriez-vous marquer cet objectif comme complété?',
      duplicate: 'Très bien! Nous créerons une copie que vous pourrez continuer à modifier.',
      pin_first: 'L\'objectif que vous avez épinglé à votre page d\'accueil y restera affiché jusqu\'à ce que vous le terminiez, que vous en épingliez un autre, que vous le mettiez en pause ou que vous le désépingliez.',
      pin_replace: [
        'Le fait d\'épingler cet objectif désépinglera votre objectif précédent.',
        'Vous pouvez toujours trouver vos objectifs dans <strong>Outils</strong>.',
      ],
      unpin: 'Nous retirerons cet objectif de votre page d\'accueil. Vous pouvez le trouver à tout moment dans <strong>Outils</strong>.',
      content: {
        progress: 'Progrès:',
        slider_progress: 'Progrès',
        start: 'Début:',
        before: '{interval} avant',
        reminder: 'Rappel',
        repeat: 'Répéter',
        daily: 'Tous les jours',
        weekly: 'Toutes les semaines',
      },
    },
    notifications: {
      errored: 'Une erreur s\'est produite.',
    },
  },
  thought_balancer: {
    header_title: 'Équilibreur de pensée',
    actions: {
      save: 'Sauvegardez vos pensées équilibrées',
    },
    tabs: {
      tool: {
        title: 'Équilibrer les pensées',
        motivational: 'Maîtrisez les pensées nuisibles en créant des situations plus équilibrées.',
        fields: {
          initial: {
            title: 'Quelle est votre pensée?',
            subtitle: 'Si votre pensée est une question, la réécrire comme un énoncé',
            label: 'Pensée initiale',
            helperText: 'P. ex., je devrais en faire plus.',
          },
          factsFor: {
            title: 'Quels sont les faits qui l\'appuient?',
            subtitle: 'N\'ajoutez que des choses qui sont vraies à 100 %.',
            label: 'Faits à l\'appui',
            helperText: 'P. ex., je suis en retard dans mon travail.',
          },
          factsAgainst: {
            title: 'Quels sont les faits qui la contredisent?',
            subtitle: 'Pensez de façon créative pour faire de nouvelles connexions dans votre cerveau.',
            label: 'Faits qui contredisent',
            helperText: 'P. ex., mes collègues me disent que je suis une personne très efficace.',
          },
          balanced: {
            title: 'Créer une situation équilibrée :',
            subtitle: 'Inscrire une situation qui se veut plus équilibrée considérant à la fois les faits qui l\'appuient et les faits qui la contredisent.',
            label: 'Pensée équilibrée',
            helperText: 'P. ex., je suis aussi efficace que je peux être. Je ne peux en faire plus sans compromettre ma santé.',
          },
        },
        buttons: {
          add_another: '+ ajouter',
        },
      },
      history: {
        title: 'Historique des pensées',
        motivational: 'Voici vos situations équilibrées :',
        empty: {
          title: 'Vous n\'avez pas encore entré de pensées.',
          subtitle: 'Sélectionnez l\'onglet Équilibrer les pensées pour commencer.',
        },
      },
    },
    thought_card: {
      balanced: 'Pensée équilibrée',
      initial: 'Pensée initiale',
      facts_for: 'Faits qui appuient',
      facts_against: 'Faits qui contredisent',
      edit: 'Voulez-vous modifier votre pensée équilibrée?',
      delete: 'Voulez-vous vraiment supprimer cette pensée équilibrée?',
      see_more: 'Voir plus',
      see_less: 'Voir moins',
    },
    notifications: {
      created: 'Pensée équilibrée créée',
      updated: 'Pensée équilibrée mise à jour',
      deleted: 'Pensée équilibrée supprimée',
    },
  },
  mood_tracker: {
    header_title: 'Outil de suivi de l\'humeur',
    actions: {
      save_mood: 'Sélectionnez',
      save_shift: 'Sauvegardez l\'évolution de vos humeurs',
    },
    tabs: {
      tool: {
        title: 'Suivre l\'évolution des humeurs',
        motivational: 'Suivez vos humeurs et déclencheurs pour reconnaître des tendances courantes.',
        fields: {
          emotions: {
            title: 'Sélectionnez une ou plusieurs humeurs à suivre :',
            worried: 'Inquiet',
            sad: 'Triste',
            anxious: 'Anxieux',
            stressed: 'Stressé',
            guilt: 'Coupable',
            angry: 'Fâché',
            relaxed: 'Détendu',
            confident: 'Confiant',
            happy: 'Joyeux',
          },
          intensities: {
            title: 'Évaluez l\'intensité de votre humeur :',
            mild: 'Léger',
            moderate: 'Modérée',
            intense: 'Intense',
            reason: {
              title: 'Inscrivez une description :',
              subtitle: 'Que se passait-il, comment avez-vous réagi ?',
            },
          },
          date: {
            title: 'Indiquez quand ça s\'est passé :',
            label: 'Date',
          },
          time: {
            label: 'Heure',
          },
        },
      },
      daily: {
        title: 'Tous les jours',
        motivational: 'Suivez vos humeurs et déclencheurs pour reconnaître des tendances courantes.',
        empty: {
          title: 'Vous n\'avez pas encore entré d\'humeurs.',
          subtitle: 'Sélectionnez l\'onglet Suivre l\'évolution des humeurs pour commencer.',
        },
        chart: {
          label: {
            xAxis: 'Heure du jour',
            yAxis: 'Intensité des émotions',
          },
        },
      },
      history: {
        title: 'Historique des humeurs',
        motivational: 'Rechercher des tendances comme des événements communs, moments de la journée ou pensées qui se traduisent par des humeurs intenses.',
        empty: {
          title: 'Vous n\'avez pas encore entré d\'humeurs.',
          subtitle: 'Sélectionnez l\'onglet Suivre l\'évolution des humeurs pour commencer.',
        },
        chart: {
          label: {
            xAxis: 'Heure',
            yAxis: 'Intensité des émotions',
          },
        },
      },
    },
    mood_card: {
      edit: 'Voulez-vous modifier votre humeur?',
      delete: 'Voulez-vous vraiment supprimer cette humeur?',
    },
    moods: [
      { value: 'worried', label: 'Inquiet' },
      { value: 'sad', label: 'Triste' },
      { value: 'anxious', label: 'Anxieux' },
      { value: 'stressed', label: 'Stressé' },
      { value: 'guilt', label: 'Culpabilité' },
      { value: 'angry', label: 'Fâché' },
      { value: 'relaxed', label: 'Détendu' },
      { value: 'confident', label: 'Confiant' },
      { value: 'happy', label: 'Joyeux' },
    ],
    notifications: {
      created: 'Humeurs suivies',
      updated: 'Humeurs mises à jour',
      deleted: 'Humeurs supprimées',
    },
  },
  workbook: {
    header_title: 'Mon journal',
    motivational: 'Voici les entrées de journal que vous avez entrées jusqu\'à présent au cours votre d\'apprentissage.  Passez-les en revue en tout temps pour réfléchir et enrichir votre réservoir d\'idées.   ',
    program: {
      toolkit: "Ma trousse d'outils de conditionnement physique",
      stress: 'Plan de gestion du stress au travail',
    },
    empty: {
      title: 'Les entrées de journal écrites durant votre d\'apprentissage apparaîtront ici. Vous n\'avez pas encore fait d\'entrées.',
      subtitle: '',
    },
    entry_card: {
      edit: 'Voulez-vous modifier cette entrée de journal?',
    },
    notifications: {
      updated: 'Entrée de journal mise à jour',
    },
  },
  journal: {
    header_title: 'Mon journal',
    page_title: 'Journal',
    page_sub_title: 'Consigner vos pensées',
    page_instruction: 'Consigner vos observations, vos apprentissages et vos réussites personnelles.',
    actions: {
      create: 'Nouvelle entrée',
    },
    fields: {
      title: {
        placeholder: 'Mon journal',
      },
      description: {
        placeholder: 'Ajouter des pensées',
      },
    },
    sections: {
      title: 'Entrées de journal',
    },
    notifications: {
      errored: 'Une erreur s\'est produite',
    },
    sorts: [ 'Trier par plus récent', 'Trier par plus ancien' ],
    journal_card: {
      delete: 'Aucun problème! Veuillez confirmer la suppression de cette entrée de journal.',
    },
  },
  note: {
    note: 'Note',
  },
};
