var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "logout-icon",
      staticStyle: {
        display: "inline",
        margin: "0",
        padding: "0",
        width: "auto"
      },
      attrs: {
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 24"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#000",
          d:
            "M18.4,4c0.5,0,0.8,0.2,1.2,0.5C19.9,4.7,20,5.1,20,5.6l0,12.8c0,0.5-0.2,0.8-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5H12v-1l6.4,0c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4l0-12.8c0-0.2-0.1-0.3-0.2-0.4C18.7,5,18.6,5,18.4,5L12,5V4L18.4,4zM7.6,8.4l0.7,0.7L6,11.5l8.9,0v1l-8.9,0l2.3,2.3l-0.7,0.7L4,12L7.6,8.4z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }