export default {
  header_title: '',
  introduction: {
    action: 'Got it!',
  },
  subheader: {
    welcome: 'Welcome {name}!',
    welcome_back: 'Welcome back {name}!',
    progress_title: 'Track your progress',
    recommendation_title: 'Today\'s Recommendations:',
    recommendation_subtitle: 'To improve your mental fitness do one, two, or all three.',
    app_store: {
      desktop: 'Search for Starling Minds on your App store',
      mobile: 'Support anywhere',
      button: 'Open app',
      ios_link: 'https://apps.apple.com/ca/app/starling-get-mentally-fit/id6467989915',
      android_link: 'https://play.google.com/store/apps/details?id=com.starlingminds.member',
    },
  },
  recommendations: {
    track_progress: {
      checkup_due: {
        title: 'Check-Up',
        description: 'Track Progress',
        checkup_time: '~15 mins',
      },
      checkup_completed: {
        title: 'Check-Up Complete!',
        next_checkup: 'Next Check-Up:',
        congratulations: 'Great work! We\'ll track your progress in {weeks} weeks.',
      },
    },
    build_skills: {
      checkup_first: {
        title: 'Complete a Check-Up to generate your <strong>Training</strong> recommendation.',
        training_title: 'Training Library',
        training_subtitle: 'Find personalized skill training, 10-20 mins per session.',
      },
      all_complete: {
        title: 'Training Library',
        subtitle: 'Review key sessions and concepts.',
        step_completed: {
          title: 'Training Review Complete!',
          congratulations: 'You did it! Keep using the tools and strategies to strengthen your skills.',
        },
      },
      recommended: {
        title: 'Mental Fitness Training',
      },
    },
    connect_others: {
      community_forums: {
        title: 'Connect and Share',
        description: 'Community',
        time: '~5 mins',
        title_completed: 'Community Connection Complete!',
        congratulations: 'Thanks for being part of the community!',
      },
      strategic_breathing: {
        title: 'Strategic Breathing',
        subtitle: 'Take two moments to breath and relax, before exploring Starling.',
        description: 'Video',
        time: '~2 mins',
        title_completed: 'Strategic Breathing Complete!',
        congratulations: 'Nice breathing! Find this strategy again in <strong>Videos</strong>.',
      },
      guided_imagery: {
        title: 'Guided Imagery',
        subtitle: 'This short exercise will help you relax by imagining a calming scene.',
        description: 'Video',
        time: '~3 mins',
        title_completed: 'Guided Imagery Complete!',
        congratulations: 'Great visualization! Find this strategy again in <strong>Videos</strong>.',
      },
      mindfulness: {
        title: 'Mindfulness Mint',
        subtitle: 'Grab a mint, nut, or a small piece of fruit and use this exercise to bring things into focus.',
        description: 'Video',
        time: '~3 mins',
        title_completed: 'Mindfulness Complete!',
        congratulations: 'Awesome mindfulness! Find this strategy again in <strong>Videos</strong>.',
      },
    },
    practice: {
      checkup_first: {
        title: 'Complete a Check-Up to generate your <strong>Exercise</strong> recommendation.',
      },
      all_complete: {
        title: 'Exercises',
        subtitle: 'Review key exercises.',
        step_completed: {
          title: 'Exercise Review Complete!',
          congratulations: 'Return to the exercises anytime to refresh your skills.',
        },
      },
    },
    buttons: {
      explore: 'Explore',
      more: 'More',
      profile: 'Profile',
    },
  },
  memberIdModal: {
    title: 'Enter your Member ID',
    description: 'asks that you add your Member ID in your profile to verify that you are part of the organization. Your information and results will remain anonymous.',
    contact_text: 'Contact your organization to locate your Member ID.',
    button: 'Save',
  },
  pickup_image: {
    normal: 'pickup_normal.svg',
    active: 'pickup_active.svg',
  },
  learn_about: {
    title: 'Learn about Starling.',
  },
  next_step: {
    title: 'Next Step for You',
    lets_go: 'Let\'s Go!',
  },
  user_stats_card: {
    subtitle: 'Your Stats',
    widgets: {
      battery: {
        value: '{value}/10',
        text: 'Energy',
        additional: '3+ Days',
      },
      anxiety_meter: {
        text: 'Anxiety',
      },
      resilience_meter: {
        text: 'Resilience',
      },
      depression_meter: {
        text: 'Depression',
      },
      checkup_reminder: {
        empty: 'Recommended next Check-Up in <strong class="checkup-value">___</strong> days',
        past: 'Your recommended next Check-up is <strong class="checkup-value">Due</strong>',
        future: 'Recommended next Check-Up in <strong class="checkup-value">{count}</strong> days',
      },
    },
    incentive: 'Your stats will appear here once you have completed your first Check-Up.',
    buttons: {
      first_check_up: 'Take Your First Check-Up',
      check_up: 'Take a Check-Up',
      check_up_results: 'View My Progress',
    },
    explanations: {
      title: 'What your numbers mean',
      global: 'These stats are pulled from your latest Check-Up.',
      battery: '<strong>Battery</strong> = the level of energy you reported that day.',
      anxiety_depression: '<strong>Anxiety and depression scores</strong> = a scientific standard, ranging from:',
      anxiety_depression_levels: [
        { interval: '0 - 2', name: 'Healthy' },
        { interval: '3 - 4', name: 'Borderline' },
        { interval: '5 - 9', name: 'Mild' },
        { interval: '10 - 14', name: 'Moderate' },
        { interval: '> 14', name: 'Severe' },
      ],
      resilience: '<strong>Resilience</strong> = the level of resilience you reported that day, ranging from:',
      resilience_levels: [
        { interval: '6 - 8', name: 'High' },
        { interval: '3 - 5', name: 'Moderate' },
        { interval: '0 - 2', name: 'Low' },
      ],
      incentive: 'Monitor your progress by taking the Check-Up regularly.',
    },
  },
  training_courses: {
    title: 'Guided Therapies',
  },
  community_card: {
    title: 'Starling Community Forums',
    subtitle: 'A safe space to share your experiences, learn from people experiencing similar situations, and find support.',
  },
  video_library_card: {
    title: 'Video Library',
    subtitle: 'Try a strategy, learn more about self-care for mental fitness, or delve into the science behind CBT.',
  },
  referral_acceptance: {
    title: 'Update Your Details',
    subtitle: 'Update Your Details',
    subheader: {
      content: [
        'We\'re here to support you in improving your mental fitness and prioritizing you, during your leave. Discover your patterns, develop strategies, and build your confidence for returning to work.',
        'Before you get started, let\'s confirm some of your details.',
      ],
    },
    profile_title: 'First, let\'s update your Profile',
    cancel_button: 'Cancel',
    message: {
      title: 'You’ve been invited to Return to Health!',
      content: '<i>{caseManager}</i> thinks that the Starling Return-to-Health program could be helpful for you:',
      cta: 'Start exploring today!',
      buttons: {
        accept: 'Accept invitation',
        decline: 'Decline invitation',
      },
    },
    update_profile: {
      title: 'Update your details',
      buttons: {
        accept: 'Let\'s go',
        back: 'Back',
      },
    },
  },
  webinar_banner: {
    register_button: 'Register',
    join_button: 'Join',
    title_today: 'Today',
    title_live: 'Live!',
    title_to: 'to',
    error_message: 'There was an error in processing your request. Please contact <a target="_blank" href="mailto:members@starlingminds.com?subject=Webinar%20registration%20issue">members@starlingminds.com</a> for assistance.',
    confirmation: {
      title: 'Registered!',
      description: 'Thank you for registering. Check your email for a confirmation.',
      continue_button: 'Continue',
    },
  },
};
