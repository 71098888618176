export default {
  notify: {
    title: 'Starling is getting an upgrade!',
    message: 'In <strong>{countdown}</strong>, we’ll be unavailable for a short maintenance period of approximately <strong>{duration}</strong>.<br/> We apologize for the inconvenience and appreciate your patience as we work to make Starling the best it can be.',
  },
  in_progress: {
    title: 'Starling is getting an upgrade!',
    message: [
      'We\'re temporarily down for maintenance.',
      'We apologize for the inconvenience and appreciate your patience as we work to make Starling the best it can be.',
    ],
    estimation: 'Estimated time remaining : {remaining}',
    unknown_estimation: 'Please check again later!',
    over_estimated: 'Oops, we are running late ... again !',
  },
  done: {
    title: 'Starling has been upgraded!',
    message: [ 'We are back online!', 'Please click on the button below to go back to Starling.' ],
  },
  scheduled: {
    title: 'Starling is getting an upgrade!',
    message: [ 'We\'ll soon be unavailable for a short maintenance period.', ' We apologize for the inconvenience and appreciate your patience as we work to make Starling the best it can be.' ],
    estimation: 'Maintenance will start in : {remainingBeforeMaintenance}',
  },
};
