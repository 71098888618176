import { BoilerplateView, TopNavDesktopView, TopNavMobileView } from '../common';
import store from '../../store';
import Vue from 'vue';

const ProductPlayerView = resolve => {
  require.ensure([ '@/views/products/ProductPlayer.vue' ], () => {
    resolve(require('@/views/products/ProductPlayer.vue'));
  }, 'product');
};

const CategoriesView = resolve => {
  require.ensure([ '@/views/content/Categories.vue' ], () => {
    resolve(require('@/views/content/Categories.vue'));
  }, 'regulation');
};

const CategoryView = resolve => {
  require.ensure([ '@/views/content/Category.vue' ], () => {
    resolve(require('@/views/content/Category.vue'));
  }, 'regulation');
};

const VideoView = resolve => {
  require.ensure([ '@/views/content/Video.vue' ], () => {
    resolve(require('@/views/content/Video.vue'));
  }, 'regulation');
};

export default {
  path: 'videos',
  components: {
    topNavDesktop: TopNavDesktopView,
    topNavMobile: TopNavMobileView,
    default: BoilerplateView,
  },
  beforeEnter(to, from, next) {
    store.commit('navigation/topEnableBackButton');
    store.commit('navigation/topHeader', 'app.content.header_title');
    next();
  },
  children: [
    {
      name: 'videos',
      path: '',
      redirect: { name: 'videos-menu' },
    },
    {
      name: 'video-library',
      path: 'library',
      component: CategoriesView,
      props: route => {
        return {
          categoriesResource: store.dispatch('getCategories'),
        };
      },
      beforeEnter(to, from, next) {
        Vue.$log.debug('Before CategoriesView');
        store.commit('navigation/topEnableBackButton');
        next();
      },
    },
    {
      name: 'videos-categories',
      path: 'library/:id',
      component: CategoryView,
      props: route => {
        return {
          categoryResource: store.dispatch('getCategory', route.params.id),
          categoryVideosResource: store.dispatch('getCategoryVideos', route.params.id),
        };
      },
      beforeEnter(to, from, next) {
        Vue.$log.debug('Before CategoryView');
        store.commit('navigation/topEnableBackButton');
        next();
      },
    },
    {
      name: 'video-view',
      path: 'library/watch/:id',
      component: VideoView,
      props: route => {
        return {
          videoResource: store.dispatch('getVideo', route.params.id),
        };
      },
      beforeEnter(to, from, next) {
        store.commit('navigation/topEnableBackButton');
        next();
      },
    },
    {
      name: 'videos-menu',
      path: process.env.VUE_APP_VIDEOS_UEN,
      component: ProductPlayerView,
      props: { uen: process.env.VUE_APP_VIDEOS_UEN },
      beforeEnter(to, from, next) {
        store.commit('navigation/topDisableBackButton');
        next();
      },
    },
    {
      name: 'videos-run',
      path: ':uen',
      component: ProductPlayerView,
      props: true,
      beforeEnter(to, from, next) {
        store.commit('navigation/topEnableBackButton');
        next();
      },
    },
  ],
};
