import { Module } from 'vuex';
import { RootState } from '../../types';
import { ITherapyContentCelebration, ITherapyContentGroup, ITherapyImage, ITherapySession, ITherapyVideo, TherapyContentState } from './types';

const BACK_NOTIFICATION_STORAGE_KEY = 'backNotificationAck';

const backNotificationStored = localStorage.getItem(BACK_NOTIFICATION_STORAGE_KEY) ? (localStorage.getItem(BACK_NOTIFICATION_STORAGE_KEY) === 'true') : false;

export const defaultState: TherapyContentState = {
  therapyContentGroup: undefined,
  nextStepSessionGroup: undefined,
  backNotificationAck: backNotificationStored,
  celebrationPopup: undefined,
};

export const therapy: Module<TherapyContentState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    therapyGroup(state: TherapyContentState, therapyContentGroup: ITherapyContentGroup) {
      state.therapyContentGroup = therapyContentGroup;
    },
    nextStepSession(state: TherapyContentState, therapyContentGroup: ITherapyContentGroup) {
      state.nextStepSessionGroup = therapyContentGroup;
    },
    backNotificationAck(state: TherapyContentState, value: boolean) {
      state.backNotificationAck = value;
    },
    setCelebrationPopup(state: TherapyContentState, celebrationPopup: ITherapyContentCelebration) {
      state.celebrationPopup = celebrationPopup;
    },
  },
  actions: {
    resetTherapyGroup({ commit }) {
      commit('therapyGroup', undefined);
    },
    getTherapyContent({ commit, dispatch }, params: { uen: string, pickup?: boolean }) {
      let path = '/therapy/_next';
      if (params?.uen) path += '?uen=' + params.uen;
      if (params?.pickup) path += '&pickup=' + params.pickup;
      const callback = (res: ITherapyContentGroup) => {
        commit('therapyGroup', res);
        return res;
      };
      return dispatch('$apiRequest', [ path, callback ], { root: true });
    },
    getPreviousTherapy({ commit, dispatch }) {
      const callback = (res: ITherapyContentGroup) => {
        commit('therapyGroup', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/therapy/_back`, callback ], { root: true });
    },
    getTherapyPreview({ commit, dispatch }, uen: string) {
      const callback = (res: ITherapyContentGroup) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/therapy/${uen}/_preview`, callback ], { root: true });
    },
    getNextStepSession({ commit, dispatch }, params: { practice?: boolean }) {
      const filter = params?.practice ? '?practice=true' : '';
      const callback = (res: ITherapyContentGroup) => {
        commit('nextStepSession', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/therapy/session/_next${filter}`, callback ], { root: true });
    },
    saveTherapy({ commit, dispatch }, therapyData) {
      return dispatch('$apiRequest', [ `/therapy/_save`, null, 'post', therapyData ], { root: true });
    },
    updateWorkbookAnswer({ commit, dispatch }, params: { uen: String, userAnswer: String }) {
      return dispatch('$apiRequest', [ `/therapy/${params.uen}/_update`, null, 'put', { userAnswer: params.userAnswer } ], { root: true });
    },
    getTherapyImage({ commit, dispatch, rootGetters }, imageName: string) {
      const callback = (res: ITherapyImage) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/therapy/image/${imageName}`, callback ], { root: true });
    },
    getTherapyVideo({ commit, dispatch, rootGetters }, videoIdentifier: string) {
      const callback = (res: ITherapyVideo) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/therapy/video/${videoIdentifier}`, callback ], { root: true });
    },
    getMedia({ commit, dispatch, rootGetters }, mediaName: string) {
      const callback = (res: ITherapyImage) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/therapy/media/${mediaName}`, callback ], { root: true });
    },
    getReport({ commit, dispatch, rootGetters }, reportType: string) {
      const callback = (res: any) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/therapy/reports/${reportType}`, callback ], { root: true });
    },
    watchVideoEvent({ dispatch }, data) {
      return dispatch('$apiRequest', [ `/therapy/video/_watch`, null, 'post', data ], { root: true });
    },
    getTherapySessionsStatusByUen({ commit, dispatch }, uen: string) {
      const callback = (res: Array<ITherapySession>) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/therapy/sessions/status/${uen}`, callback ], { root: true });
    },
    setBackNotificationAck({ commit }, value) {
      commit('backNotificationAck', !!value);
      localStorage.setItem(BACK_NOTIFICATION_STORAGE_KEY, (!!value).toString());
    },
    checkRegistrationWebinar({ commit, dispatch }, webinarId: string) {
      const callback = (res: Boolean) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/therapy/webinar/${webinarId}`, callback ], { root: true });
    },
    registerWebinar({ commit, dispatch }, webinarId: string) {
      const callback = (res: Boolean) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/therapy/webinar/${webinarId}`, callback, 'post' ], { root: true });
    },
    unregisterWebinar({ commit, dispatch }, webinarId: string) {
      return dispatch('$apiRequest', [ `/therapy/webinar/${webinarId}`, null, 'delete' ], { root: true });
    },
  },
  getters: {
    therapyContentGroup(state) {
      return state.therapyContentGroup;
    },
    nextStepSessionGroup(state) {
      return state.nextStepSessionGroup;
    },
    getBackNotificationAck(state) {
      return state.backNotificationAck;
    },
    getCelebrationPopup(state) {
      return state.celebrationPopup;
    },
  },
};
