var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "workbook-icon",
      staticStyle: {
        display: "inline",
        margin: "0",
        padding: "0",
        width: "auto",
        height: "auto"
      },
      attrs: {
        viewBox: "0 0 95 95",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("rect", { attrs: { width: "95", height: "95", fill: "white" } }),
      _c("path", {
        staticClass: "icon-colorized",
        attrs: {
          d:
            "M68.9689 61.4077V28.5174C69.0292 28.152 69.2102 25.3502 67.0381 23.6448C64.8659 21.9394 62.3318 22.0003 62.2714 22.0003H32.284C31.3186 22.0003 30.5342 22.7921 30.5342 23.7666V66.3412C30.5342 67.133 31.1979 67.803 31.9823 67.803H33.6114V71.7011C33.6114 72.1275 33.8527 72.5538 34.2147 72.7975C34.5768 73.0411 35.0595 73.0411 35.4818 72.8584L37.8953 71.7011C37.8953 71.7011 37.9556 71.7011 38.016 71.7011L41.3345 72.9802C41.4552 73.0411 41.6362 73.0411 41.8172 73.0411C42.0585 73.0411 42.3602 72.9802 42.5412 72.7975C42.9033 72.5538 43.0843 72.1275 43.0843 71.7011V67.7421H62.5731C62.6335 67.7421 62.8145 67.7421 63.0558 67.7421C63.9609 67.7421 65.8917 67.6203 67.1587 66.6458C69.2102 65.184 68.9689 61.834 68.9689 61.4077ZM61.6681 66.6458H56.9015V23.1575H61.6681V66.6458ZM31.6806 66.4021V23.7666C31.6806 23.4621 31.9219 23.1575 32.284 23.1575H55.6947V66.6458H31.9823C31.8616 66.6458 31.6806 66.5239 31.6806 66.4021ZM41.8172 71.8229C41.8172 71.8838 41.8172 71.8838 41.7569 71.9447C41.7569 71.9447 41.6965 72.0057 41.6362 71.9447L38.3177 70.6657C38.016 70.5439 37.5936 70.5439 37.2919 70.7266L34.8784 71.8838C34.8181 71.8838 34.7578 71.8838 34.7578 71.8838C34.7578 71.8838 34.6974 71.8229 34.6974 71.762V67.8639H41.7569V71.8229H41.8172ZM66.3744 65.7931C65.2883 66.5849 63.4782 66.6458 62.8145 66.6458V23.2184C63.5989 23.2793 64.9866 23.5839 66.2537 24.6193C68.0638 25.9593 67.7621 28.3956 67.7621 28.3956V28.4565V61.4686V61.5295C67.7621 61.5295 68.0638 64.5749 66.3744 65.7931Z",
          "stroke-miterlimit": "10"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M41.0031 61.7061C41.0031 61.7061 10.8949 50.7091 17.3552 37.5947C17.3552 37.5947 25.8957 27.2827 34.0178 41.8469C34.0178 41.8469 36.7924 27.1718 48.5915 32.9861C60.3906 38.8004 41.0031 61.7061 41.0031 61.7061Z",
          fill: "#FF777E",
          "fill-opacity": "0.7"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M41.0031 61.7061C41.0031 61.7061 10.8949 50.7091 17.3552 37.5947C17.3552 37.5947 25.8957 27.2827 34.0178 41.8469C34.0178 41.8469 36.7924 27.1718 48.5915 32.9861C60.3906 38.8004 41.0031 61.7061 41.0031 61.7061Z",
          fill: "url(#wb-paint0)"
        }
      }),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "wb-paint0",
                x1: "31.3928",
                y1: "26.4104",
                x2: "39.5867",
                y2: "61.7567",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", { attrs: { "stop-color": "white" } }),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "white",
                  "stop-opacity": "0"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }