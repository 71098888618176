export default {
  menu: [
    {
      id: 'home',
      label: 'Home',
      path: '/app/home',
      icon: '$vuetify.icons.starling_home',
      desktop: true,
      mobile: true,
    },
    {
      id: 'training',
      label: 'Training',
      path: '/app/training',
      icon: '$vuetify.icons.starling_therapy',
      desktop: true,
      mobile: true,
    },
    {
      id: 'exercises',
      label: 'Exercises',
      path: '/app/exercises',
      icon: '$vuetify.icons.starling_exercises',
      desktop: true,
      mobile: true,
    },
    {
      id: 'webinars',
      label: 'Webinars',
      path: '/app/webinars',
      icon: '$vuetify.icons.starling_webinar',
      desktop: true,
      mobile: true,
      popover: {
        badge: 'New',
        title: 'Mindful Sleep',
        description: 'A comprehensive workshop on mastering sleep.',
      },
    },
    {
      id: 'videos',
      label: 'Videos',
      path: '/app/videos',
      icon: '$vuetify.icons.starling_videos',
      desktop: true,
      mobile: true,
    },
    {
      id: 'tools',
      label: 'Tools',
      path: '/app/tools',
      icon: '$vuetify.icons.starling_tools',
      desktop: true,
      mobile: true,
    },
    {
      id: 'community',
      label: 'Community',
      path: '/app/community',
      icon: '$vuetify.icons.starling_community',
      desktop: true,
      mobile: true,
    },
    {
      id: 'profile',
      label: 'Profile',
      path: '/app/profile',
      icon: '$vuetify.icons.starling_profile',
      desktop: true,
      mobile: true,
    },
    {
      id: 'favorites',
      label: 'Favorites',
      path: '/app/favorites',
      icon: '$vuetify.icons.starling_favorite',
      desktop: true,
      mobile: true,
    },
  ],
  more_button: 'More',
  favorites_button: 'Favorites',
  profile_button: 'Profile',
};
