import { render, staticRenderFns } from "./account-logged.vue?vue&type=template&id=74499692&scoped=true&"
import script from "./account-logged.vue?vue&type=script&lang=ts&"
export * from "./account-logged.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74499692",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VContainer,VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src233137091/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74499692')) {
      api.createRecord('74499692', component.options)
    } else {
      api.reload('74499692', component.options)
    }
    module.hot.accept("./account-logged.vue?vue&type=template&id=74499692&scoped=true&", function () {
      api.rerender('74499692', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/landing/setup_referral/account/account-logged.vue"
export default component.exports