import { Module } from 'vuex';
import { RootState } from '@/scripts/store/types';
import { NoteState, INote } from './types';

const initialState: NoteState = {
  notes: [],
};

export const notes: Module<NoteState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetNotesState(state) {
      state.notes = [];
    },
    setNotes(state, noteList: Array<INote>) {
      state.notes = noteList;
    },
  },
  actions: {
    getNotes({ state, commit, dispatch }, { type = '', day = null }) {
      const callback = (res: NoteState) => {
        commit('setNotes', res);
        return res;
      };
      let queryString = '';
      if (type) {
        queryString += `?type=${type}`;
      }
      if (day) {
        queryString += queryString ? `&day=${day}` : `?day=${day}`;
      }
      return dispatch('$apiRequest', [ `/tools/note${queryString}`, callback, 'get' ], { root: true });
    },
    getNote({ commit, dispatch }, id: string) {
      const callback = (res: INote) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/note/${id}`, callback ], { root: true });
    },
    saveNote({ dispatch }, note: INote) {
      const callback = (res: INote) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/note`, callback, 'post', note ], { root: true });
    },
    resetNotes({ state, commit, dispatch }) {
      commit('resetNotesState');
    },
  },
  getters: {
    notes(state): INote[] {
      return state.notes;
    },
  },
};
