export default {
  loading: 'Checking validity...',
  email: 'Please provide a valid email',
  password: '* Required field with a minimum of 12 characters with at least one uppercase, lowercase, digit and special character.',
  passwordMatch: 'Passwords do not match.',
  passwordSimilar: 'New password should not be the same as the current one.',
  required: 'This field is required',
  nonEmpty: 'This field cannot be blank',
  accessCode: 'Your access code is not valid',
  frequency: 'Please enter a number between 1 and 100',
  date: 'Please select a valid date',
  generic: 'No user was found.',
  video: 'There was an error loading your video.',
  min_param: 'Must be at least {min} characters',
  max_param: 'Must be less than {max} characters',
  min6: 'Must be at least 6 characters',
  min8: 'Must be at least 8 characters',
  max50: 'Must be less than 50 characters',
  max60: 'Must be less than 60 characters',
  max100: 'Must be less than 100 characters',
  max254: 'Must be less than 254 characters',
  max255: 'Must be less than 255 characters',
  max256: 'Must be less than 256 characters',
  max1024: 'Must be less than 1024 characters',
  audio: 'There was an error loading the audio file.',
};
