<template>
<!--<svg style="display:inline; margin: 0; padding: 0; width: auto;"-->
<!--     class="logout-icon"-->
<!--     viewBox="0 0 35 27"-->
<!--     fill="none"-->
<!--     xmlns="http://www.w3.org/2000/svg">-->
<!--  <path d="M16.9428 11.9024H34.1108C34.6009 11.9024 34.9988 12.3026 34.9988 12.7956C34.9988 13.2885 34.6009 13.6887 34.1108 13.6887H16.9428C16.4526 13.6887 16.0547 13.2885 16.0547 12.7956C16.0552 12.3026 16.4526 11.9024 16.9428 11.9024Z"/>-->
<!--  <path d="M29.3775 7.13928C29.6048 7.13928 29.8321 7.22591 30.0049 7.40141L34.7409 12.1645C35.0877 12.5138 35.0877 13.0789 34.7409 13.4282L30.0049 18.1913C29.6581 18.5401 29.0957 18.5401 28.7484 18.1913C28.4016 17.8425 28.4016 17.2769 28.7484 16.9282L32.857 12.7961L28.7484 8.66401C28.4016 8.31524 28.4016 7.74962 28.7484 7.40086C28.9229 7.22647 29.1502 7.13928 29.3775 7.13928Z"/>-->
<!--  <path d="M13.0238 26.1964C5.84308 26.1964 0 20.32 0 13.0982C0 5.87645 5.84308 0 13.0238 0C18.4117 0 23.1695 3.26016 25.1451 8.30662C25.3252 8.76493 25.1001 9.28305 24.6444 9.46525C24.1887 9.64411 23.6735 9.4211 23.4923 8.96055C21.7862 4.60212 17.6771 1.7863 13.0238 1.7863C6.82229 1.7863 1.77615 6.86126 1.77615 13.0982C1.77615 19.3352 6.82229 24.4101 13.0238 24.4101C17.6771 24.4101 21.7862 21.5943 23.4923 17.237C23.6724 16.7759 24.1876 16.5534 24.6444 16.7323C25.1001 16.9134 25.3252 17.4326 25.1451 17.8909C23.1695 22.9363 18.4117 26.1964 13.0238 26.1964Z"/>-->
<!--</svg>-->

  <svg style="display:inline; margin: 0; padding: 0; width: auto;"
       class="logout-icon"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 24 24">
    <path fill="#000" d="M18.4,4c0.5,0,0.8,0.2,1.2,0.5C19.9,4.7,20,5.1,20,5.6l0,12.8c0,0.5-0.2,0.8-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5H12v-1l6.4,0c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4l0-12.8c0-0.2-0.1-0.3-0.2-0.4C18.7,5,18.6,5,18.4,5L12,5V4L18.4,4zM7.6,8.4l0.7,0.7L6,11.5l8.9,0v1l-8.9,0l2.3,2.3l-0.7,0.7L4,12L7.6,8.4z"/>
</svg>

</template>

<script>
export default {
  name: 'logout-icon',
};
</script>

<style scoped lang="css">
.logout-icon {
  fill: currentColor;
  stroke: currentColor;
}
</style>
