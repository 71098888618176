export default {
  title: 'Set a new secure password',
  content: {
    default: 'It looks like you signed in using a temporary password, and are required to set a new permanent password to keep your account secure.',
  },
  buttons: {
    set_password: 'Set',
  },
  fields: {
    password: {
      title: 'Enter a new password for your account.',
    },
  },
  error: {
    message: 'An error occured. Please try again.',
  },
};
