import navigation from './navigation';
import content from './content';
import tools from './tools';
import home from './home';
import products from './products';
import profile from './profile';
import feedbacks from './feedbacks';
import community from './community';
import assessments from './assessments';
import favorites from './favorites';
import avatar from './avatar';

export default {
  navigation,
  home,
  tools,
  content,
  products,
  profile,
  feedbacks,
  community,
  assessments,
  favorites,
  avatar,
};
