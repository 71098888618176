<template>
  <v-layout row px-2 gap-xs-2 align-baseline class="registration-marketing">
    <v-flex>
      <v-btn
          class="ma-0" fab
          :label="'Label'"
          :outline="!internalValue"
          :color="internalValue?'checked_checkbox':'normal'"
          @click="internalValue =!internalValue">
        <v-icon v-if="internalValue">mdi-check</v-icon>
      </v-btn>
    </v-flex>

    <v-flex>
      <p class="starling-body">
        {{ $t('public.registration.account.fields.marketing.label') }}
      </p>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'registration-field-marketing',
  props: {
    value: Boolean,
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
      }
    },
    internalValue(newValue) {
      this.$emit('input', newValue);
      this.$emit('form-validate');
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.registration-marketing .v-btn.checked_checkbox {
  background-color: var(--StarlingSuccess) !important;
  color: white;
}
</style>
