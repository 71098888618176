<template>
  <v-autocomplete
      v-if="!loading"
      v-model="internalValue"
      clearable
      item-text="text"
      item-value="value"
      persistent-hint
      :disabled="!itemList || !itemList.length"
      :error-messages="(!itemList || !itemList.length)? $t('public.registration.profile.fields.profession.errors.loading') : null"
      :hint="$t('public.registration.profile.fields.profession.hint')"
      :items="itemList"
      :label="$t('public.registration.profile.fields.profession.label')"
      :no-data-text="$t('public.registration.profile.fields.profession.errors.search')"
      :placeholder="$t('public.registration.profile.fields.profession.placeholder')"
      :rules="rules"></v-autocomplete>
  <v-progress-circular v-else :size="30" color="primary" indeterminate></v-progress-circular>
</template>

<script>
export default {
  name: 'registration-field-profession',
  props: {
    value: String,
    accessCode: {
      type: String,
      required: false,
    },
    accessCodeImprint: {
      type: String,
      required: false,
    },
    referralKey: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
      loading: true,
      itemList: null,
      timeout: null,
      rules: [
        v => !!v || this.$t('common.errors.required'),
      ],
    };
  },
  watch: {
    value(newValue) {
      if (this.internalValue !== newValue) {
        this.internalValue = newValue;
      }
    },
    internalValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  mounted() {
    let apiCall = null;
    if (this.referralKey) {
      apiCall = this.$store.dispatch('generateProfessionsWithReferralKey', this.referralKey);
    } else if (this.accessCodeImprint) {
      apiCall = this.$store.dispatch('generateProfessionsWithAccessCodeImprint', this.accessCodeImprint);
    } else if (this.accessCode) {
      apiCall = this.$store.dispatch('generateProfessionsWithAccessCode', this.accessCode);
    }

    apiCall.then(res => {
      this.itemList = res.map(item => {
        return {
          text: item.text,
          value: item.value,
        };
      });
    }).finally(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>

</style>
