var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    [
      _c(
        "v-card",
        {
          staticClass: "registration-card",
          class: _vm.$vuetify.breakpoint.smAndUp ? "pa-3" : "pa-0",
          style: _vm.cardStyles,
          attrs: {
            flat: "",
            elevation: _vm.elevation,
            "min-height": _vm.cardHeight
          }
        },
        [
          !_vm.loaded
            ? _c("v-card-loader")
            : [
                !_vm.referral
                  ? [
                      !_vm.referral
                        ? _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-container",
                                { attrs: { fluid: "", "pa-0": "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        "align-center": "",
                                        "justify-center": "",
                                        "gap-xs-3": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            "align-center": "",
                                            "justify-center": ""
                                          }
                                        },
                                        [
                                          _vm.error
                                            ? _c("registration-error-image", {
                                                staticClass: "mx-auto d-block",
                                                staticStyle: {
                                                  "max-width": "300px"
                                                }
                                              })
                                            : _c("registration-trees-image", {
                                                staticClass: "mx-auto",
                                                staticStyle: {
                                                  "max-width": "300px",
                                                  display: "block"
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            "text-center": "",
                                            "my-3": ""
                                          }
                                        },
                                        [
                                          _c("h3", {
                                            staticClass:
                                              "starling-h3 StarlingAlmostBlack--text",
                                            domProps: {
                                              textContent: _vm._s(_vm.title)
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: { xs12: "", "text-center": "" }
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "starling-body",
                                            domProps: {
                                              innerHTML: _vm._s(_vm.content)
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm.referral
                  ? [
                      _vm.loggedIn
                        ? [
                            _vm.referralStarlingRegistered
                              ? [
                                  _vm.sameEmail
                                    ? [
                                        _c("account-logged", {
                                          attrs: {
                                            "user-identity":
                                              _vm.identity.userIdentity,
                                            "referral-key": _vm.referralKey,
                                            referral: _vm.referral
                                          },
                                          on: { accepted: _vm.clear }
                                        })
                                      ]
                                    : [
                                        _c("account-logout-login", {
                                          attrs: {
                                            "user-identity":
                                              _vm.identity.userIdentity,
                                            "referral-key": _vm.referralKey,
                                            referral: _vm.referral,
                                            "account-exists":
                                              _vm.referralCognitoRegistered ||
                                              _vm.referralStarlingRegistered
                                          }
                                        })
                                      ]
                                ]
                              : [
                                  _vm.sameEmail
                                    ? [
                                        _c("accept-referral", {
                                          attrs: {
                                            "user-identity":
                                              _vm.identity.userIdentity,
                                            "referral-key": _vm.referralKey,
                                            referral: _vm.referral
                                          },
                                          on: { accepted: _vm.clear }
                                        })
                                      ]
                                    : [
                                        _c("account-logout-login", {
                                          attrs: {
                                            "user-identity":
                                              _vm.identity.userIdentity,
                                            "referral-key": _vm.referralKey,
                                            referral: _vm.referral,
                                            "account-exists":
                                              _vm.referralCognitoRegistered ||
                                              _vm.referralStarlingRegistered
                                          }
                                        })
                                      ]
                                ]
                          ]
                        : [
                            _vm.referralStarlingRegistered
                              ? [
                                  _c("account-login", {
                                    attrs: {
                                      "user-identity":
                                        _vm.identity.userIdentity,
                                      "referral-key": _vm.referralKey,
                                      referral: _vm.referral
                                    }
                                  })
                                ]
                              : _vm.referralCognitoRegistered
                              ? [
                                  _c("account-login", {
                                    attrs: {
                                      "user-identity":
                                        _vm.identity.userIdentity,
                                      "referral-key": _vm.referralKey,
                                      referral: _vm.referral
                                    }
                                  })
                                ]
                              : [
                                  _c("claim-account", {
                                    attrs: {
                                      "user-identity":
                                        _vm.identity.userIdentity,
                                      "referral-key": _vm.referralKey,
                                      referral: _vm.referral
                                    },
                                    on: { claimed: _vm.reload }
                                  })
                                ]
                          ]
                    ]
                  : _vm._e()
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }