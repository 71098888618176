var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.theme
    ? _c(
        "v-navigation-drawer",
        {
          attrs: { fixed: "", right: "", temporary: "", width: "700px" },
          model: {
            value: _vm.innerValue,
            callback: function($$v) {
              _vm.innerValue = $$v
            },
            expression: "innerValue"
          }
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("h1", { staticClass: "starling-h1" }, [
                        _vm._v("Theme editor")
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "warning" },
                          on: { click: _vm.resetTheme }
                        },
                        [_vm._v("Reset Theme")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                _vm._l(this.theme.variables, function(val, key) {
                  return _c(
                    "v-flex",
                    { key: "variable_" + key, attrs: { xs12: "" } },
                    [
                      val.type === "color"
                        ? _c(
                            "v-layout",
                            {
                              staticStyle: { height: "30px" },
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { staticClass: "text--s", attrs: { xs7: "" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(key) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  style:
                                    "background-color:" +
                                    _vm.theme.variables[key].value,
                                  attrs: { xs5: "" }
                                },
                                [
                                  _c("label", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.theme.variables[key].value) +
                                        "\n              "
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.theme.variables[key].value,
                                          expression:
                                            "theme.variables[key].value"
                                        }
                                      ],
                                      ref: "input_" + key,
                                      refInFor: true,
                                      attrs: {
                                        title: key,
                                        type: "color",
                                        pattern: "#[a-f0-9]{6}",
                                        placeholder: "#000000"
                                      },
                                      domProps: {
                                        value: _vm.theme.variables[key].value
                                      },
                                      on: {
                                        change: _vm.applyTheme,
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.theme.variables[key],
                                            "value",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : val.type === "text"
                        ? _c(
                            "v-layout",
                            {
                              staticStyle: { height: "30px" },
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(key) +
                                    "\n          "
                                )
                              ]),
                              _c("v-flex", { attrs: { xs5: "" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.theme.variables[key].value,
                                      expression: "theme.variables[key].value"
                                    }
                                  ],
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.theme.variables[key].value
                                  },
                                  on: {
                                    change: _vm.applyTheme,
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.theme.variables[key],
                                        "value",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ],
                            1
                          )
                        : val.type === "numeric"
                        ? _c(
                            "v-layout",
                            {
                              staticStyle: { height: "30px" },
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(key) +
                                    "\n          "
                                )
                              ]),
                              _c("v-flex", { attrs: { xs5: "" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.theme.variables[key].value,
                                      expression: "theme.variables[key].value"
                                    }
                                  ],
                                  attrs: { type: "number" },
                                  domProps: {
                                    value: _vm.theme.variables[key].value
                                  },
                                  on: {
                                    change: _vm.applyTheme,
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.theme.variables[key],
                                        "value",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ],
                            1
                          )
                        : val.type === "font"
                        ? _c(
                            "v-layout",
                            {
                              staticStyle: { height: "30px" },
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(key) +
                                    "\n          "
                                )
                              ]),
                              _c("v-flex", { attrs: { xs5: "" } }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.theme.variables[key].value,
                                        expression: "theme.variables[key].value"
                                      }
                                    ],
                                    attrs: { dense: "" },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.theme.variables[key],
                                            "value",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        _vm.applyTheme
                                      ]
                                    }
                                  },
                                  _vm._l(_vm.availableFonts, function(font) {
                                    return _c(
                                      "option",
                                      {
                                        key: font.name,
                                        domProps: { value: font.name }
                                      },
                                      [_vm._v(_vm._s(font.name))]
                                    )
                                  }),
                                  0
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }