var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "eye-closed-icon",
      staticStyle: {
        display: "inline",
        margin: "0",
        padding: "0",
        width: "auto"
      },
      attrs: {
        viewBox: "0 0 25 9",
        fill: "transparent",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M23.6736 1.57707L23.8532 1.45443C24.2022 1.2027 24.2848 0.705689 24.0432 0.342074C23.7995 -0.0236918 23.3184 -0.105451 22.9756 0.141979C10.997 8.87088 2.27685 0.572292 1.91136 0.217284C1.60162 -0.0839356 1.1143 -0.0688747 0.827273 0.251709C0.538184 0.572292 0.550574 1.07576 0.858247 1.37698C0.874766 1.39419 1.14734 1.65453 1.64498 2.03751L0.110745 4.67533C-0.108136 5.05185 0.00749939 5.54241 0.368861 5.77263C0.492756 5.85008 0.63317 5.88881 0.76739 5.88881C1.02551 5.88881 1.27536 5.75111 1.42197 5.50583L2.92317 2.93041C3.76359 3.464 4.84354 4.04707 6.12792 4.53978L5.38868 7.1152C5.26685 7.53906 5.49606 7.98228 5.89872 8.10707L6.11967 8.1415C6.44592 8.1415 6.75153 7.91774 6.85065 7.57133L7.58369 5.03034C8.76896 5.36813 10.0967 5.5962 11.5298 5.64999V8.20174C11.5298 8.64281 11.8725 8.99998 12.2958 8.99998C12.7192 8.99998 13.0619 8.64281 13.0619 8.20174V5.63708C14.3112 5.56608 15.6369 5.34231 17.0183 4.93136L17.962 7.52185C18.0797 7.84028 18.3708 8.03607 18.6785 8.03607L18.949 7.98659C19.3455 7.82952 19.5458 7.36909 19.395 6.95814L18.4762 4.4322C19.7358 3.94595 21.0367 3.30263 22.3809 2.45276L23.6219 4.17832C23.7727 4.38702 24.0039 4.4989 24.2332 4.4989C24.3942 4.4989 24.5532 4.44726 24.6916 4.33753C25.0302 4.07289 25.1004 3.57157 24.8464 3.22087L23.6736 1.57707Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }