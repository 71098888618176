var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "starling-avatar-builder",
        "max-width": "1000px",
        persistent: ""
      },
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "radius-15" },
        [
          _c(
            "div",
            { staticClass: "close-button" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: "500" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: { click: _vm.onStartCancel }
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.cancelDialog,
                    callback: function($$v) {
                      _vm.cancelDialog = $$v
                    },
                    expression: "cancelDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-1 radius-15" },
                    [
                      _c("v-card-title", [
                        _c("h2", { staticClass: "starling-h3 px-0" }, [
                          _vm._v(_vm._s(_vm.$t("app.avatar.unsaved.title")))
                        ])
                      ]),
                      _c("v-card-text", { staticClass: "mb-3" }, [
                        _c("p", { staticClass: "starling-body" }, [
                          _vm._v(_vm._s(_vm.$t("app.avatar.unsaved.content")))
                        ])
                      ]),
                      _c(
                        "v-card-actions",
                        { attrs: { "text-center": "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { staticClass: "pr-1" },
                                [
                                  _c(
                                    "secondary-button",
                                    {
                                      attrs: { block: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.cancelDialog = false
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("common.actions.back")
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { staticClass: "pl-1" },
                                [
                                  _c(
                                    "primary-button",
                                    {
                                      attrs: { block: "" },
                                      on: { click: _vm.onConfirmCancel }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("common.actions.continue")
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-title", [
            _c("h2", { staticClass: "starling-h3 px-0" }, [
              _vm._v(_vm._s(_vm.$t("app.avatar.customize.title")))
            ])
          ]),
          _c("v-card-title", { staticClass: "py-0" }, [
            _c("p", { staticClass: "starling-body" }, [
              _vm._v(_vm._s(_vm.$t("app.avatar.customize.tagline")))
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { md4: "", xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _vm.innerValue.widgets.body
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "starling-avatar-selector-shape-carousel",
                                    {
                                      attrs: {
                                        "widget-type": _vm.WidgetType.Body,
                                        available: _vm.SETTINGS.bodyShape
                                      },
                                      model: {
                                        value: _vm.innerValue.widgets.body,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.innerValue.widgets,
                                            "body",
                                            $$v
                                          )
                                        },
                                        expression: "innerValue.widgets.body"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "text-center",
                                              attrs: { xs12: "" }
                                            },
                                            [
                                              _c("starling-avatar-display", {
                                                ref: "avatarDisplay",
                                                on: {
                                                  rendered: _vm.onRendered
                                                },
                                                model: {
                                                  value: _vm.innerValue,
                                                  callback: function($$v) {
                                                    _vm.innerValue = $$v
                                                  },
                                                  expression: "innerValue"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "text-center",
                                              attrs: { xs12: "" }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "starling-body font-weight-semi-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "app.avatar." +
                                                          _vm.WidgetType.Body +
                                                          "." +
                                                          _vm.innerValue.widgets
                                                            .body.shape +
                                                          ".name"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { md8: "", xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.innerValue.widgets.eyes
                                ? _c("starling-avatar-selector-shape-list", {
                                    attrs: {
                                      title: _vm.$tc(
                                        "app.avatar.widget.eyes.name"
                                      ),
                                      "widget-type": _vm.WidgetType.Eyes,
                                      available: _vm.SETTINGS.eyesShape
                                    },
                                    model: {
                                      value: _vm.innerValue.widgets.eyes.shape,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.innerValue.widgets.eyes,
                                          "shape",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "innerValue.widgets.eyes.shape"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.innerValue.widgets.body
                                ? _c("starling-avatar-selector-color-list", {
                                    attrs: {
                                      available: _vm.SETTINGS.widgetColors.body,
                                      title: _vm.$tc(
                                        "app.avatar.widget.body.name"
                                      )
                                    },
                                    model: {
                                      value: _vm.innerValue.widgets.body.color,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.innerValue.widgets.body,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "innerValue.widgets.body.color"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.innerValue.widgets.beak
                                ? _c("starling-avatar-selector-color-list", {
                                    attrs: {
                                      available: _vm.SETTINGS.widgetColors.beak,
                                      title: _vm.$tc(
                                        "app.avatar.widget.beak.name"
                                      )
                                    },
                                    model: {
                                      value: _vm.innerValue.widgets.beak.color,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.innerValue.widgets.beak,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "innerValue.widgets.beak.color"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.innerValue.background.color
                                ? _c("starling-avatar-selector-color-list", {
                                    attrs: {
                                      available: _vm.SETTINGS.backgroundColors,
                                      title: _vm.$tc(
                                        "app.avatar.widget.background.name"
                                      )
                                    },
                                    model: {
                                      value: _vm.innerValue.background.color,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.innerValue.background,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression: "innerValue.background.color"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "text-center mt-3" },
            [
              _c(
                "primary-button",
                {
                  attrs: { large: "" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.onSelect($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.actions.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }