export default {
  loading: 'Comprobando la validez…',
  email: 'Ingresa un correo electrónico válido',
  password: '* Campo obligatorio con un mínimo de 12 caracteres y, al menos, una mayúscula, una minúscula, un dígito y un carácter especial.',
  passwordMatch: 'Las contraseñas no coinciden.',
  passwordSimilar: 'La nueva contraseña no puede ser la misma que la actual.',
  required: 'Este campo es obligatorio.',
  nonEmpty: 'Este campo no puede estar en blanco.',
  accessCode: 'Tu código de acceso no es válido.',
  frequency: 'Introduce un número entre 1 y 100.',
  date: 'Selecciona una fecha válida.',
  generic: 'No se ha encontrado ningún usuario.',
  video: 'Se ha producido un error al cargar tu video.',
  min_param: 'Debe tener al menos {min} caracteres.',
  max_param: 'Debe tener menos de {max} caracteres.',
  min6: 'Debe tener al menos 6 caracteres.',
  min8: 'Debe tener al menos 8 caracteres.',
  max50: 'Debe tener menos de 50 caracteres.',
  max60: 'Debe tener menos de 60 caracteres.',
  max100: 'Debe tener menos de 100 caracteres.',
  max254: 'Debe tener menos de 254 caracteres.',
  max255: 'Debe tener menos de 255 caracteres.',
  max256: 'Debe tener menos de 256 caracteres.',
  max1024: 'Debe tener menos de 1024 caracteres.',
  audio: 'Hubo un error al cargar el archivo de audio.',
};
