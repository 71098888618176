<template>
  <landing-central-card>
    <v-card flat class="registration-card" :elevation="elevation" :min-height="cardHeight" :style="cardStyles"
            :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'pa-0'">
      <v-card-loader v-if="!loaded"></v-card-loader>

      <template v-else>
        <template v-if="!referral">
          <v-card-text class="pa-0" v-if="!referral">
            <v-container fluid pa-0>
              <v-layout row wrap align-center justify-center gap-xs-3>
                <v-flex xs12 align-center justify-center>
                  <registration-error-image v-if="error" class="mx-auto d-block" style="max-width: 300px"/>
                  <registration-trees-image v-else class="mx-auto" style="max-width:300px;display:block;"/>
                </v-flex>
                <v-flex xs12 text-center my-3>
                  <h3 class="starling-h3 StarlingAlmostBlack--text" v-text="title"></h3>
                </v-flex>
                <v-flex xs12 text-center>
                  <div class="starling-body" v-html="content"></div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </template>
        <template v-if="referral">
          <template v-if="loggedIn">
            <template v-if="referralStarlingRegistered">
              <template v-if="sameEmail">
                <account-logged :user-identity="identity.userIdentity"
                                :referral-key="referralKey"
                                :referral="referral"
                                @accepted="clear"/>
              </template>
              <template v-else>
                <account-logout-login :user-identity="identity.userIdentity"
                                      :referral-key="referralKey"
                                      :referral="referral"
                                      :account-exists="referralCognitoRegistered || referralStarlingRegistered"/>
              </template>
            </template>

            <template v-else>
              <template v-if="sameEmail">
                <accept-referral :user-identity="identity.userIdentity"
                                 :referral-key="referralKey"
                                 :referral="referral"
                                 @accepted="clear"/>
              </template>
              <template v-else>
                <account-logout-login :user-identity="identity.userIdentity"
                                      :referral-key="referralKey"
                                      :referral="referral"
                                      :account-exists="referralCognitoRegistered || referralStarlingRegistered"/>
              </template>
            </template>
          </template>

          <template v-else>
            <template v-if="referralStarlingRegistered">
              <account-login :user-identity="identity.userIdentity"
                             :referral-key="referralKey"
                             :referral="referral"/>
            </template>
            <template v-else-if="referralCognitoRegistered">
              <account-login :user-identity="identity.userIdentity"
                             :referral-key="referralKey"
                             :referral="referral"/>
            </template>
            <template v-else>
              <claim-account :user-identity="identity.userIdentity"
                             :referral-key="referralKey"
                             :referral="referral"
                             @claimed="reload"/>
            </template>
          </template>
        </template>
      </template>

    </v-card>
  </landing-central-card>
</template>

<script>
import vCardLoader from '@/views/components/loader/v-card-loader.vue';
import RegistrationErrorImage from '@/views/images/registration-error-image.vue';
import LandingCentralCard from '@/views/landing/landing-central-card.vue';
import RegistrationTreesImage from '@/views/images/registration-trees-image.vue';
import ClaimAccount from '@/views/landing/setup_referral/claim/claim-account.vue';
import AcceptReferral from '@/views/landing/setup_referral/accept/accept-referral.vue';
import AccountLogoutLogin from '@/views/landing/setup_referral/account/account-logout-login.vue';
import AccountLogin from '@/views/landing/setup_referral/account/account-login.vue';
import AccountLogged from '@/views/landing/setup_referral/account/account-logged.vue';

export const REFERRAL_STORAGE_KEY = 'register-referral';
export const REFERRAL_DEMO_KEY = 'fake_referral_key_for_demonstration';

export default {
  components: {
    AccountLogged,
    AccountLogin,
    AccountLogoutLogin,
    AcceptReferral,
    ClaimAccount,
    RegistrationTreesImage,
    LandingCentralCard,
    vCardLoader,
    RegistrationErrorImage,
  },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    return {
      referralKey: null,
      referral: null,
      referralIdentity: null,
      identity: null,
      loaded: false,
      error: null,
      title: null,
      content: null,
    };
  },
  computed: {
    loggedIn() {
      return this.identity && this.identity.userIdentity && this.identity.userIdentity.username;
    },
    referralStarlingRegistered() {
      return this.referral && this.referral.registered;
    },
    referralCognitoRegistered() {
      return this.referral && this.referral.cognitoExists;
    },
    sameEmail() {
      return this.identity.userIdentity && this.identity.userIdentity.username && this.referral ? this.identity.userIdentity.username === this.referral.email : false;
    },
  },
  async mounted() {
    this.referralKey = localStorage.getItem(REFERRAL_STORAGE_KEY);

    if (!localStorage.getItem(REFERRAL_STORAGE_KEY)) {
      this.loaded = true;
      this.referral = null;
      this.error = true;
      this.title = this.$t('public.registration.referral.empty.title');
      this.content = this.$t('public.registration.referral.empty.content');
      return;
    }

    if (localStorage.getItem(REFERRAL_STORAGE_KEY) === REFERRAL_DEMO_KEY) {
      this.loaded = true;
      this.referral = null;
      this.error = false;
      this.title = this.$t('public.registration.referral.simulated.title');
      this.content = this.$t('public.registration.referral.simulated.content');
      return;
    }

    // Load Current Account
    try {
      const identityResponse = await this.$store.dispatch('identity/configureAuth');
      this.identity = identityResponse;
    } catch (error) {
      this.identity = null;
    }

    try {
      // Load referral
      const referralIdentity = await this.$store.dispatch('identity/findReferral', this.referralKey);
      const referral = await this.$store.dispatch('prepareReferralRegister', this.referralKey);

      this.referralIdentity = referralIdentity;
      this.referral = referral;
      this.loaded = true;
      this.error = false;
    } catch (error) {
      this.loaded = true;
      this.referral = null;
      this.error = true;
      this.title = this.$t('public.registration.referral.invalid.title');
      this.content = this.$t('public.registration.referral.invalid.content');
    }
  },
  methods: {
    clear() {
      localStorage.removeItem(REFERRAL_STORAGE_KEY);
    },
    reload() {
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="css">
.bottom-actions {
  position: relative;
  bottom: 5px;
  margin-top: 40px;
  padding-top: 40px;
  width: 100%;
}

.registration-card {
  border-radius: 15px;
}

#btn-next {
  width: 56px;
  height: 56px;
}

#btn-previous {
  width: 56px;
  height: 56px;
}

#btn-to-summary {
  width: 56px;
  height: 56px;
}
</style>
