import { BoilerplateView, TopNavDesktopView, TopNavMobileView } from '../common';
import store from '../../store';

const FavoritesView = resolve => {
  require.ensure([ '@/views/favorites/Favorites.vue' ], () => {
    resolve(require('@/views/favorites/Favorites.vue'));
  }, 'favorites');
};

export default {
  path: 'favorites',
  components: {
    topNavDesktop: TopNavDesktopView,
    topNavMobile: TopNavMobileView,
    default: BoilerplateView,
  },
  beforeEnter(to, from, next) {
    store.commit('navigation/topEnableBackButton');
    next();
  },
  children: [
    {
      name: 'favorites',
      path: '',
      component: FavoritesView,
      beforeEnter(to, from, next) {
        store.commit('navigation/topHeader', 'app.favorites.header_title');
        next();
      },
    },
  ],
};
