export default {
  title: 'Sign In with a one-time password code',
  content: {
    start: 'Please enter your email address to receive a one-time password code.',
    end: 'Please enter the received code.',
  },
  fields: {
    email: 'Email address',
    otp_code: 'One-time password code',
  },
  buttons: {
    send_otp: 'Send a code',
    sign_in: 'Sign In',
  },
};
