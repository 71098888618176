export default {
  header_title: 'Mes favoris',
  title: 'Favoris',
  content: 'Chaque fois que vous marquez quelque chose d’une étoile, elle s’affichera ici.',
  callout: {
    title: 'Vos favoris',
    description: 'Cliquez sur l’étoile pour ajouter n’importe quelle vidéo à vos favoris afin de la regarder à nouveau ici.',
  },
  empty_content: {
    text: 'Vous pouvez trouver les vidéos dans toutes les séances de formation, les exercices et les webinaires, ou utiliser la page « {0} » pour y accéder directement!',
    link: 'Vidéos',
  },
  types: {
    VIDEO: 'Vidéo',
    AUDIO: 'Audio',
  },
};
