import { BoilerplateView, TopNavDesktopView, TopNavMobileView } from '../common';
import store from '../../store';

const ProfileView = resolve => {
  require.ensure([ '@/views/profile/Profile.vue' ], () => {
    resolve(require('@/views/profile/Profile.vue'));
  }, 'user');
};
const ProfileAccountView = resolve => {
  require.ensure([ '@/views/profile/ProfileAccount.vue' ], () => {
    resolve(require('@/views/profile/ProfileAccount.vue'));
  }, 'user');
};
const FeedbackView = resolve => {
  require.ensure([ '@/views/profile/Feedback.vue' ], () => {
    resolve(require('@/views/profile/Feedback.vue'));
  }, 'feedback');
};

const BookmarksView = resolve => {
  require.ensure([ '@/views/profile/Bookmarks.vue' ], () => {
    resolve(require('@/views/profile/Bookmarks.vue'));
  }, 'bookmarks');
};

export default {
  path: 'profile',
  components: {
    topNavDesktop: TopNavDesktopView,
    topNavMobile: TopNavMobileView,
    default: BoilerplateView,
  },
  beforeEnter(to, from, next) {
    next();
  },
  children: [
    {
      name: 'profile',
      path: '',
      redirect: { name: 'profile-account' },
      component: ProfileView,
      beforeEnter(to, from, next) {
        store.commit('navigation/topEnableBackButton');
        store.commit('navigation/topHeader', 'app.profile.header_title');
        next();
      },
      children: [
        {
          name: 'profile-comments',
          path: 'comments',
          redirect: { name: 'community-comments' },
        },
        {
          name: 'profile-account',
          path: 'account',
          component: ProfileAccountView,
        },
      ],
    },
    {
      name: 'feedback',
      path: 'feedback',
      component: FeedbackView,
      beforeEnter(to, from, next) {
        store.commit('navigation/topEnableBackButton');
        store.commit('navigation/topHeader', 'app.feedbacks.header_title');
        next();
      },
    },
    {
      name: 'bookmarks',
      path: 'bookmarks',
      component: BookmarksView,
      props: route => {
        return {
          bookmarks: store.dispatch('getBookmarks'),
        };
      },
      beforeEnter(to, from, next) {
        store.commit('navigation/topEnableBackButton');
        store.commit('navigation/topHeader', 'app.profile.bookmarks.header_title');
        next();
      },
    },
  ],
};
