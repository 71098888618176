import header from './header';
import landing from './landing';
import registration from './registration';
import signup from './signup';
import signin from './signin';
import signinOtp from './signin_otp';
import forgottenPassword from './forgotten_password';
import resetPassword from './reset_password';
import newPassword from './new_password';
import emailVerification from './email_verification';
import federated from './federated';

export default {
  header,
  landing,
  registration,
  signup,
  signin,
  signin_otp: signinOtp,
  federated,
  email_verification: emailVerification,
  forgotten_password: forgottenPassword,
  reset_password: resetPassword,
  new_password: newPassword,
};
