var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c("img", { ref: "image", staticClass: "starling-avatar-image" }),
      _c("starling-avatar-builder", {
        key: _vm.key,
        attrs: { opened: _vm.edit },
        on: {
          cancel: _vm.onCancel,
          save: _vm.onSave,
          rendered: _vm.onRendered
        },
        model: {
          value: _vm.innerValue,
          callback: function($$v) {
            _vm.innerValue = $$v
          },
          expression: "innerValue"
        }
      }),
      _c(
        "secondary-button",
        {
          staticClass: "starling-avatar-edit-button",
          attrs: { loading: _vm.loading, icon: "", small: "" },
          nativeOn: {
            click: function($event) {
              _vm.edit = true
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-pencil")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }