























import Vue, { PropType } from 'vue';
import { Color, CompositeColorRenderer } from '@/views/avatar/types';

const compositeRenderer = new CompositeColorRenderer();

export default Vue.extend({
  name: 'starling-avatar-selector-color-list',
  props: {
    title: {
      type: String,
    },
    value: {
      type: Object as PropType<Color>,
    },
    available: {
      type: Array as PropType<Array<Color>>,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    'value'(val: Color) {
      this.innerValue = val;
    },
    innerValue(val: Color) {
      this.$emit('input', val);
    },
  },
  methods: {
    onSelect(index: number): void {
      this.innerValue = this.available[index];
    },
    colorEquals(color?: Color, other?: Color): boolean {
      return compositeRenderer.isEqual(color, other);
    },
    colorStyle(color: Color): string {
      return compositeRenderer.toCSS(color, {
        content: '',
        gradientCoordinates: { x1: 0, y1: 1, x2: 0, y2: 1 },
      });
    },
  },
});
