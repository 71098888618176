export default {
  title: 'Ouvrir une session',
  fields: {
    username: 'Adresse courriel',
    password: 'Mot de passe',
  },
  buttons: {
    signin: 'Ouvrir une session',
    not_registered: 'Pas encore inscrit?',
    having_questions: 'Des questions? N\'hésitez pas à <a target="_blank" href="mailto:membres@starlingminds.com?subject=Retours%20and%20questions">nous appeler</a> en tout temps.',
    forgotten_password: 'Mot de passe oublié?',
  },
  error: {
    message: 'Nom d\'utilisateur ou mot de passe invalides',
    disabled: 'Votre compte n\'a pas été activé ou est temporairement désactivé. Veuillez communiquer avec nous.',
    expired: 'Votre compte est expiré. Veuillez communiquer avec nous.',
    locked: 'Votre compte est temporairement verrouillé. Veuillez réessayer dans une heure.',
  },
};
