import { Module } from 'vuex';
import { RootState } from '@/scripts/store/types';
import { IThoughtBalancer, ThoughtBalancerState } from './types';

const defaultFormData: IThoughtBalancer = {
  id: '',
  initial: '',
  factsFor: [ { title: '' } ],
  factsAgainst: [ { title: '' } ],
  balanced: '',
};

export const defaultState: ThoughtBalancerState = {
  thoughts: [],
  thoughtFormData: {
    ...defaultFormData,
  },
};

export const thoughts: Module<ThoughtBalancerState, RootState> = {
  namespaced: true,
  state: defaultState,
  mutations: {
    resetThoughtsState(state) {
      Object.assign(state, defaultFormData);
    },
    thoughts(state, thoughtList) {
      state.thoughts = thoughtList;
    },
    thoughtFormData(state, thoughtData) {
      state.thoughtFormData = thoughtData;
    },
    resetThoughtFormData(state) {
      state.thoughtFormData = { ...defaultFormData };
    },
  },
  actions: {
    getThoughts({ commit, dispatch }) {
      const callback = (res: Array<IThoughtBalancer>) => {
        commit('thoughts', res);
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/thoughts`, callback ], { root: true });
    },
    getThought({ commit, dispatch }, id: string) {
      const callback = (res: IThoughtBalancer) => {
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/thoughts/${id}`, callback ], { root: true });
    },
    postThought({ commit, state, dispatch, rootGetters }, thoughtFormData: IThoughtBalancer) {
      const thought = { ...thoughtFormData };
      const callback = (res: IThoughtBalancer) => {
        dispatch('getThoughts');
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/thoughts`, callback, 'post', thought ], { root: true });
    },
    editThought({ commit, state, dispatch, rootGetters }, thoughtFormData: IThoughtBalancer) {
      const thought = { ...thoughtFormData };
      const callback = (res: IThoughtBalancer) => {
        dispatch('getThoughts');
        return res;
      };
      return dispatch('$apiRequest', [ `/tools/thoughts/${thoughtFormData.id}`, callback, 'put', thought ], { root: true });
    },
    deleteThought({ commit, getters, dispatch }, thoughtId: string) {
      const callback = () => {
        dispatch('getThoughts');
      };
      return dispatch('$apiRequest', [ `/tools/thoughts/${thoughtId}`, callback, 'delete' ], { root: true });
    },
    setFavouriteThought({ commit, dispatch, getters, state }, thought: IThoughtBalancer) {
      thought.favourite = !thought.favourite;
      const callback = () => {
        return dispatch('getThoughts');
      };
      return dispatch('$apiRequest', [ `/tools/thoughts/${thought.id}`, callback, 'put', thought ], { root: true });
    },
  },
  getters: {
    thoughts(state) {
      return state.thoughts;
    },
    thoughtFormData(state) {
      return state.thoughtFormData;
    },
  },
};
