export default {
  notify: {
    title: '¡Starling se está actualizando!',
    message: 'En <strong>{countdown}</strong>, no estaremos disponibles durante un breve periodo de mantenimiento de aproximadamente <strong>{duration}</strong>.<br/> Nos disculpamos por las molestias y agradecemos tu paciencia mientras trabajamos para que Starling sea lo mejor posible.',
  },
  in_progress: {
    title: '¡Starling se está actualizando!',
    message: [
      'Estamos temporalmente fuera de servicio por mantenimiento.',
      'Nos disculpamos por las molestias y agradecemos tu paciencia mientras trabajamos para que Starling sea lo mejor posible.',
    ],
    estimation: 'Tiempo estimado restante: {remaining}',
    unknown_estimation: 'Vuelve a intentarlo más tarde.',
    over_estimated: 'Todavía no hemos terminado. ¡Lo sentimos!',
  },
  done: {
    title: '¡Starling se ha actualizado!',
    message: [ '¡Estamos de vuelta en línea!', 'Haz clic en el botón de abajo para volver a Starling.' ],
  },
  scheduled: {
    title: '¡Starling se está actualizando!',
    message: [ 'Dentro de poco, no estaremos disponibles durante un breve periodo de mantenimiento.', 'Nos disculpamos por las molestias y agradecemos tu paciencia mientras trabajamos para que Starling sea lo mejor posible.' ],
    estimation: 'El mantenimiento se iniciará en: {remainingBeforeMaintenance}',
  },
};
