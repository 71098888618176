export default {
  account: {
    title: 'Regístrese',
    fields: {
      email: {
        title: '¿Cuál es tu correo electrónico?',
      },
      password: {
        title: 'Establece tu contraseña segura.',
      },
      passwordConfirmation: {
        title: 'Confirma tu contraseña.',
      },
    },
    buttons: {
      signup: 'Crea cuenta',
    },
    error: {
      message: 'Hubo un error al crear tu cuenta.',
    },
  },
  verify: {
    start: {
      title: 'Verificacion de email',
      content: [
        'Hemos enviado un enlace de verificación a: <strong>{email}</strong>.',
        'Tienes <strong>una hora</strong> para hacer clic en el enlace.',
        '¿No has recibido un enlace?',
        '<ol><li>Comprueba que la dirección de correo electrónico anterior es válida. Si no, haz <a href="/landing/sign_up">clic aquí</a> para registrarse con el correo electrónico correcto. </li><li>Revisa tu carpeta de correo no deseado.</li><li>Si no has recibido ningún enlace, envía un correo electrónico a <a href="mailto:members@starlingminds.com?subject=C%F3digo%20de%20verificaci%F3n%20de%20correo%20electr%F3nico%20no%20recibido">members@starlingminds.com</a> para solicitar asistencia técnica.</li></ol>',
      ],
    },
    end: {
      valid: {
        title: 'Correo electrónico verificado',
        content: [
          'Gracias por verificar tu dirección de correo electrónico. Continúa con la configuración de tu cuenta en los pasos siguientes.',
        ],
        button: 'Crea tu contraseña',
      },
      invalid: {
        title: 'Enlace inválido',
        content: [
          'El enlace en el que hizo clic no es válido o recargó la página.',
        ],
      },
      error: {
        title: 'Ocurrió un error',
        content: [
          'Ocurrió un error',
        ],
      },
      confirmed: {
        title: 'Cuenta verificada',
        content: [
          'Su cuenta <strong>{email}</strong> está verificada, ahora puede iniciar sesión.',
        ],
        button: 'Iniciar sesión',
      },
      expired: {
        title: 'Enlace caducado',
        content: [
          'El enlace ha caducado. Haz clic en el botón de abajo para recibir un nuevo enlace para seguir configurando tu cuenta.',
        ],
        button: 'Reenviar el enlace',
      },
    },
  },
  setup_referral: {
    claim_account: {
      title: '¡Te damos la bienvenida a {name}!',
      content: [ '¡Nos alegra que estés aquí!', 'Estar fuera del trabajo puede ser un momento incierto, estresante y emocional. Estamos aquí para apoyarte mientras afrontas esta situación.' ],
      account_cta: 'Empecemos por configurar tu cuenta.',
      fields: {
        email: {
          title: '¿Cuál es tu correo electrónico?',
        },
        password: {
          title: 'Crea tu contraseña',
        },
      },
    },
    accept_referral: {
      title: 'Se trata de ti.',
      content: 'Ahora que tenemos tu cuenta configurada, vamos a confirmar algunos detalles sobre ti.',
      name: {
        title: 'Tu nombre',
        content: 'Así es como la aplicación se dirigirá a ti. Esta información no es pública.',
        fields: {
          firstname: {
            title: '¿Cómo te gustaría que te llamáramos?',
          },
        },
      },
      profile: {
        title: 'Tu perfil',
        content: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores.',
        fields: {
          birthYear: {
            title: '¿En qué año naciste?',
          },
          gender: {
            title: '¿Cómo te identificas?',
          },
          memberCode: {
            title: '¿Cuál es su identificación de miembro?',
          },
        },
      },
      community: {
        title: 'Configuración de la comunidad',
        content: 'Personaliza tu avatar y nombre en clave para usarlos en nuestra comunidad anónima.',
      },
      privacy: {
        title: 'Tu privacidad es importante',
        content: {
          MENTAL_FITNESS: {
            privacy: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Todo lo que haces en Starling es totalmente confidencial. No vendemos ninguna información personal sobre tu salud a nadie ni la compartimos con nadie.',
            privacy_clinician: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.',
          },
          RTH_MENTAL: {
            privacy: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Solo compartiremos con tu proveedor lo estrictamente necesario para apoyar tu regreso al trabajo.',
            privacy_clinician: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.',
          },
          RTH_PHYSICAL: {
            privacy: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Solo compartiremos con tu proveedor lo estrictamente necesario para apoyar tu regreso al trabajo.',
            privacy_clinician: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.',
          },
          RTH_VETERANS: {
            privacy: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Solo compartiremos con tu proveedor lo estrictamente necesario para apoyar tu regreso al trabajo.',
            privacy_clinician: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.',
          },
        },
      },
      buttons: {
        register: '¡Empecemos!',
      },
    },
    account_login: {
      title: 'Cuenta existente',
      content: 'Parece que ya tienes una cuenta con la dirección de correo electrónico <strong>{email}</strong>.',
      signin_cta: 'Haga clic a continuación para iniciar sesión y aceptar su invitación.',
      button: 'Iniciar sesión',
    },
    incorrect_account: {
      title: 'Cuenta incorrecta',
      content: 'La cuenta en la que ha iniciado sesión actualmente no coincide con la dirección de correo electrónico de su invitación.',
      signin_cta: 'Por favor cierre sesión e inicie sesión en su cuenta con el correo electrónico <strong>{email}</strong>.',
      button: 'Cerrar sesión',
    },
    account_logged: {
      title: 'Acepta tu invitación',
      content: '<strong>{caseManager}</strong> cree que el programa Starling Return-to-Health podría resultarle útil:',
      button: 'Aceptar',
    },
  },
  setup_access_code: {
    title: '¡Te damos la bienvenida a {name}!',
    content: 'Controle su aptitud mental y descubra estrategias sencillas para controlar el estrés, la ansiedad y la depresión.',
    account_cta: 'Empecemos por configurar tu cuenta.',
    fields: {
      access_code: {
        title: '¿Cuál es tu código de acceso?',
        tips: {
          text: 'Puedes obtenerlo de tu organización patrocinadora. Si no tienes un código o una organización patrocinadora, {0}',
          mailto: {
            label: 'podemos ayudarte',
            to: 'members@starlingminds.com',
            subject: '¿Cuál es mi código de acceso?',
            body: 'Nombre de la organización o sindicato: ###organization### \nTu profesión: ###profession###\n\nTu mensaje:\n###freeText###',
          },
          additionalProperties: {
            explanation: 'Para ayudar al servicio de atención a los miembros a localizar rápidamente tu código de acceso, rellena el siguiente formulario. Si no recuerdas bien los detalles, deja los campos en blanco y el servicio de atención a los miembros se pondrá en contacto contigo.',
            fields: [
              {
                type: 'input',
                id: 'organization',
                label: 'Nombre de la organización o sindicato',
                placeholder: '',
              },
              {
                type: 'input',
                id: 'profession',
                label: 'Profesión',
                placeholder: '',
              },
              {
                type: 'textarea',
                id: 'freeText',
                label: 'Cualquier otra información que quieras compartir con nosotros',
                placeholder: '',
              },
            ],
          },
        },
      },
      memberCode: {
        title: '¿Cuál es su identificación de miembro?',
      },
      profession: {
        title: '¿A qué te dedicas?',
      },
      businessUnit: {
        title: '¿Dónde trabajas?',
      },
    },
  },
  setup_profile: {
    title: 'Se trata de ti.',
    content: 'Ahora que tenemos tu cuenta configurada, vamos a confirmar algunos detalles sobre ti.',
    profile: {
      title: 'Tu perfil',
      content: {
        MENTAL_FITNESS: {
          privacy: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Todo lo que haces en Starling es totalmente confidencial. No vendemos ninguna información personal sobre tu salud a nadie ni la compartimos con nadie.',
          privacy_clinician: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.',
        },
        RTH_MENTAL: {
          privacy: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Solo compartiremos con tu proveedor lo estrictamente necesario para apoyar tu regreso al trabajo.',
          privacy_clinician: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.',
        },
        RTH_PHYSICAL: {
          privacy: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Solo compartiremos con tu proveedor lo estrictamente necesario para apoyar tu regreso al trabajo.',
          privacy_clinician: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.',
        },
        RTH_VETERANS: {
          privacy: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Solo compartiremos con tu proveedor lo estrictamente necesario para apoyar tu regreso al trabajo.',
          privacy_clinician: 'Starling ofrece contenido personalizado y acceso a comunidades en función de estos factores. Tu actividad en Starling es altamente confidencial. Para ayudarte a recuperar la salud, solo compartiremos lo estrictamente necesario con tu médico y proveedor de atención médica.',
        },
      },
      fields: {
        firstname: {
          title: 'Tu nombre',
          content: 'Así es como la aplicación se dirigirá a ti. Esta información no es pública.',
        },
        birthYear: {
          title: '¿En qué año naciste?',
        },
        gender: {
          title: '¿Cómo te identificas?',
        },
      },
    },
    community: {
      title: 'Configuración de la comunidad',
      content: 'Personaliza tu avatar y nombre en clave para usarlos en nuestra comunidad anónima.',
    },
    buttons: {
      register: '¡Empecemos!',
    },
  },
};
