var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "exercises-icon",
      staticStyle: {
        display: "inline",
        margin: "0",
        padding: "0",
        width: "auto"
      },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 30 30",
        fill: "none"
      }
    },
    [
      _c("path", {
        staticClass: "icon-content",
        attrs: {
          d:
            "M18.042 0h-1.667L6.125 17.083a.625.625 0 0 0 0 .75c.167.25.333.417.667.417h6.083L11.208 30h1.667l10.167-17.083c.166-.25.166-.5 0-.834-.167-.25-.417-.416-.667-.416h-6L18.042 0Z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M16.458 2.333 15.125 11.5l-.167 1.417h6.667l-8.833 14.75 1.333-9.25.167-1.417H7.625l8.833-14.667ZM18.042 0h-1.667L6.125 17.083a.625.625 0 0 0 0 .75c.167.25.333.417.667.417h6.083L11.208 30h1.667l10.167-17.083c.166-.25.166-.5 0-.834-.167-.25-.417-.416-.667-.416h-6L18.042 0Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }