import { BoilerplateView } from '../common';
import store from '../../store';

const ThoughtBalancerView = resolve => {
  require.ensure([ '@/views/tools/thoughts/Thoughts.vue' ], () => {
    resolve(require('@/views/tools/thoughts/Thoughts.vue'));
  }, 'thoughts-list');
};
const EditThoughtView = resolve => {
  require.ensure([ '@/views/tools/thoughts/EditThought.vue' ], () => {
    resolve(require('@/views/tools/thoughts/EditThought.vue'));
  }, 'thoughts-edit');
};

export default {
  path: 'thoughts',
  component: BoilerplateView,
  beforeEnter(to, from, next) {
    store.commit('navigation/topEnableBackButton');
    store.commit('navigation/topHeader', 'app.tools.thought_balancer.header_title');
    next();
  },
  children: [
    {
      name: 'thoughts-list',
      path: '',
      component: ThoughtBalancerView,
    },
    {
      name: 'thoughts-edit',
      path: ':id/edit',
      component: EditThoughtView,
      props: route => {
        return {
          thought: store.dispatch('thoughts/getThought', route.params.id),
        };
      },
    } ],
};
